// @flow
import React, { useEffect, useState } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useSettings } from '../settings/useSettings';
import type { GTASettings } from '../settings/gta_settings';
import { $NotNull } from '../globals';

type TParams = {
    audioUrl: string,
    skipGuess: () => void,
    guesses: number,
    gameState: string,
    playFull: boolean,
    style: string,
    className: string,
};

const CustomAudioPlayer = ({
    audioUrl,
    skipGuess,
    guesses,
    gameState,
    playFull = false,
    style = {},
    className = '',
    offset = 0,
}: TParams): React$Element<any> => {
    // $FlowIgnore
    const settings: GTASettings = useSettings();
    console.log('offset: ', offset);
    useEffect(() => {
        if (playFull) {
            return;
        }
        setPlayDuration(settings.playDurationDict[(guesses + 1).toString()] + offset);
    }, [guesses, playFull, offset, settings]);

    const playDurationTime = playFull ? 1 : settings.playDurationDict[guesses.toString()] + offset;
    const [playDuration, setPlayDuration] = useState(playDurationTime);

    const [player, setPlayer] = useState(null);

    console.log('guesses', guesses);

    const onPlay = () => {
        if (player) {
            player.audio.current.currentTime = offset;
            // player.audio.current.currentTime = 0;
            console.log('currenttime:', player.audio.current.currentTime);
            console.log('playduration:', playDuration);
        }
    };

    const onListen = () => {
        console.log('listen');
        console.log('Current play duration: ', playDuration);
        const player_nn = $NotNull(player);
        if (playFull) {
            return;
        }
        if (player_nn.audio.current.currentTime >= playDuration + offset) {
            player_nn.audio.current.pause();
            player_nn.audio.current.currentTime = 0;
        }
        // set a maximum time that the snippet can be played for legal purposes
        if (player_nn.audio.current.currentTime >= 10 + offset) {
            player_nn.audio.current.pause();
            player_nn.audio.current.currentTime = 0;
        }
    };

    const onSkip = () => {
        console.log('skip');
        console.log('current guess: ', guesses);
        setPlayDuration(settings.playDurationDict[(guesses + 1).toString()] + offset);

        skipGuess();
    };

    return (
        <AudioPlayer
            src={audioUrl}
            onPlay={onPlay}
            onListen={onListen}
            onClickNext={onSkip}
            listenInterval={100}
            customAdditionalControls={[]}
            ref={setPlayer}
            showSkipControls={false}
            showJumpControls={false}
            customProgressBarSection={gameState === 'playing' ? [RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR] : []}
            style={style}
            className={className}
        />
    );
};

export default CustomAudioPlayer;
