/* eslint-disable no-useless-escape */
// @flow

export type TGTANGLEAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
};

type TGTANGLEAnswers = {
    [string]: TGTANGLEAnswer
};

export const answers: TGTANGLEAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["175"],
        affiliate_links: [],
        franchise: 'None',
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["245"],
        affiliate_links: [],
        franchise: 'None',
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["135"],
        affiliate_links: [],
        franchise: 'None',
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["25"],
        affiliate_links: [],
        franchise: 'None',
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["280"],
        affiliate_links: [],
        franchise: 'None',
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["245"],
        affiliate_links: [],
        franchise: 'None',
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["290"],
        affiliate_links: [],
        franchise: 'None',
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["315"],
        affiliate_links: [],
        franchise: 'None',
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["30"],
        affiliate_links: [],
        franchise: 'None',
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["220"],
        affiliate_links: [],
        franchise: 'None',
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["325"],
        affiliate_links: [],
        franchise: 'None',
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["265"],
        affiliate_links: [],
        franchise: 'None',
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["65"],
        affiliate_links: [],
        franchise: 'None',
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["95"],
        affiliate_links: [],
        franchise: 'None',
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["150"],
        affiliate_links: [],
        franchise: 'None',
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["210"],
        affiliate_links: [],
        franchise: 'None',
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["205"],
        affiliate_links: [],
        franchise: 'None',
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["10"],
        affiliate_links: [],
        franchise: 'None',
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["135"],
        affiliate_links: [],
        franchise: 'None',
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["240"],
        affiliate_links: [],
        franchise: 'None',
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["45"],
        affiliate_links: [],
        franchise: 'None',
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["225"],
        affiliate_links: [],
        franchise: 'None',
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["275"],
        affiliate_links: [],
        franchise: 'None',
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["155"],
        affiliate_links: [],
        franchise: 'None',
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["340"],
        affiliate_links: [],
        franchise: 'None',
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["145"],
        affiliate_links: [],
        franchise: 'None',
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["85"],
        affiliate_links: [],
        franchise: 'None',
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["286"],
        affiliate_links: [],
        franchise: 'None',
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["40"],
        affiliate_links: [],
        franchise: 'None',
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["342"],
        affiliate_links: [],
        franchise: 'None',
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["164"],
        affiliate_links: [],
        franchise: 'None',
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["134"],
        affiliate_links: [],
        franchise: 'None',
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["12"],
        affiliate_links: [],
        franchise: 'None',
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["72"],
        affiliate_links: [],
        franchise: 'None',
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["310"],
        affiliate_links: [],
        franchise: 'None',
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["202"],
        affiliate_links: [],
        franchise: 'None',
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["180"],
        affiliate_links: [],
        franchise: 'None',
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["224"],
        affiliate_links: [],
        franchise: 'None',
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["38"],
        affiliate_links: [],
        franchise: 'None',
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["174"],
        affiliate_links: [],
        franchise: 'None',
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["60"],
        affiliate_links: [],
        franchise: 'None',
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["328"],
        affiliate_links: [],
        franchise: 'None',
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["292"],
        affiliate_links: [],
        franchise: 'None',
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["194"],
        affiliate_links: [],
        franchise: 'None',
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["228"],
        affiliate_links: [],
        franchise: 'None',
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["252"],
        affiliate_links: [],
        franchise: 'None',
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["6"],
        affiliate_links: [],
        franchise: 'None',
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["208"],
        affiliate_links: [],
        franchise: 'None',
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["314"],
        affiliate_links: [],
        franchise: 'None',
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["68"],
        affiliate_links: [],
        franchise: 'None',
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["102"],
        affiliate_links: [],
        franchise: 'None',
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["330"],
        affiliate_links: [],
        franchise: 'None',
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["122"],
        affiliate_links: [],
        franchise: 'None',
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["142"],
        affiliate_links: [],
        franchise: 'None',
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["324"],
        affiliate_links: [],
        franchise: 'None',
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["88"],
        affiliate_links: [],
        franchise: 'None',
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["120"],
        affiliate_links: [],
        franchise: 'None',
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["334"],
        affiliate_links: [],
        franchise: 'None',
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["54"],
        affiliate_links: [],
        franchise: 'None',
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["146"],
        affiliate_links: [],
        franchise: 'None',
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["240"],
        affiliate_links: [],
        franchise: 'None',
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["212"],
        affiliate_links: [],
        franchise: 'None',
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["112"],
        affiliate_links: [],
        franchise: 'None',
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["96"],
        affiliate_links: [],
        franchise: 'None',
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["144"],
        affiliate_links: [],
        franchise: 'None',
    },
    '78': {
        content: '',
        submitted_by: '',
        answers: ["22"],
        affiliate_links: [],
        franchise: 'None',
    },
    '79': {
        content: '',
        submitted_by: '',
        answers: ["168"],
        affiliate_links: [],
        franchise: 'None',
    },
    '80': {
        content: '',
        submitted_by: '',
        answers: ["276"],
        affiliate_links: [],
        franchise: 'None',
    },
    '81': {
        content: '',
        submitted_by: '',
        answers: ["46"],
        affiliate_links: [],
        franchise: 'None',
    },
    '82': {
        content: '',
        submitted_by: '',
        answers: ["226"],
        affiliate_links: [],
        franchise: 'None',
    },
    '83': {
        content: '',
        submitted_by: '',
        answers: ["28"],
        affiliate_links: [],
        franchise: 'None',
    },
    '84': {
        content: '',
        submitted_by: '',
        answers: ["126"],
        affiliate_links: [],
        franchise: 'None',
    },
    '85': {
        content: '',
        submitted_by: '',
        answers: ["306"],
        affiliate_links: [],
        franchise: 'None',
    },
    '86': {
        content: '',
        submitted_by: '',
        answers: ["154"],
        affiliate_links: [],
        franchise: 'None',
    },
    '87': {
        content: '',
        submitted_by: '',
        answers: ["114"],
        affiliate_links: [],
        franchise: 'None',
    },
    '88': {
        content: '',
        submitted_by: '',
        answers: ["234"],
        affiliate_links: [],
        franchise: 'None',
    },
    '89': {
        content: '',
        submitted_by: '',
        answers: ["346"],
        affiliate_links: [],
        franchise: 'None',
    },
    '90': {
        content: '',
        submitted_by: '',
        answers: ["74"],
        affiliate_links: [],
        franchise: 'None',
    },
    '91': {
        content: '',
        submitted_by: '',
        answers: ["2"],
        affiliate_links: [],
        franchise: 'None',
    },
    '92': {
        content: '',
        submitted_by: '',
        answers: ["282"],
        affiliate_links: [],
        franchise: 'None',
    },
    '93': {
        content: '',
        submitted_by: '',
        answers: ["320"],
        affiliate_links: [],
        franchise: 'None',
    },
    '94': {
        content: '',
        submitted_by: '',
        answers: ["356"],
        affiliate_links: [],
        franchise: 'None',
    },
    '95': {
        content: '',
        submitted_by: '',
        answers: ["56"],
        affiliate_links: [],
        franchise: 'None',
    },
    '96': {
        content: '',
        submitted_by: '',
        answers: ["98"],
        affiliate_links: [],
        franchise: 'None',
    },
    '97': {
        content: '',
        submitted_by: '',
        answers: ["348"],
        affiliate_links: [],
        franchise: 'None',
    },
    '98': {
        content: '',
        submitted_by: '',
        answers: ["288"],
        affiliate_links: [],
        franchise: 'None',
    },
    '99': {
        content: '',
        submitted_by: '',
        answers: ["326"],
        affiliate_links: [],
        franchise: 'None',
    },
    '100': {
        content: '',
        submitted_by: '',
        answers: ["94"],
        affiliate_links: [],
        franchise: 'None',
    },
    '101': {
        content: '',
        submitted_by: '',
        answers: ["266"],
        affiliate_links: [],
        franchise: 'None',
    },
    '102': {
        content: '',
        submitted_by: '',
        answers: ["64"],
        affiliate_links: [],
        franchise: 'None',
    },
    '103': {
        content: '',
        submitted_by: '',
        answers: ["316"],
        affiliate_links: [],
        franchise: 'None',
    },
    '104': {
        content: '',
        submitted_by: '',
        answers: ["190"],
        affiliate_links: [],
        franchise: 'None',
    },
    '105': {
        content: '',
        submitted_by: '',
        answers: ["78"],
        affiliate_links: [],
        franchise: 'None',
    },
    '106': {
        content: '',
        submitted_by: '',
        answers: ["36"],
        affiliate_links: [],
        franchise: 'None',
    },
    '107': {
        content: '',
        submitted_by: '',
        answers: ["188"],
        affiliate_links: [],
        franchise: 'None',
    },
    '108': {
        content: '',
        submitted_by: '',
        answers: ["76"],
        affiliate_links: [],
        franchise: 'None',
    },
    '109': {
        content: '',
        submitted_by: '',
        answers: ["34"],
        affiliate_links: [],
        franchise: 'None',
    },
    '110': {
        content: '',
        submitted_by: '',
        answers: ["250"],
        affiliate_links: [],
        franchise: 'None',
    },
    '111': {
        content: '',
        submitted_by: '',
        answers: ["358"],
        affiliate_links: [],
        franchise: 'None',
    },
    '112': {
        content: '',
        submitted_by: '',
        answers: ["8"],
        affiliate_links: [],
        franchise: 'None',
    },
    '113': {
        content: '',
        submitted_by: '',
        answers: ["152"],
        affiliate_links: [],
        franchise: 'None',
    },
    '114': {
        content: '',
        submitted_by: '',
        answers: ["52"],
        affiliate_links: [],
        franchise: 'None',
    },
    '115': {
        content: '',
        submitted_by: '',
        answers: ["116"],
        affiliate_links: [],
        franchise: 'None',
    },
    '116': {
        content: '',
        submitted_by: '',
        answers: ["278"],
        affiliate_links: [],
        franchise: 'None',
    },
    '117': {
        content: '',
        submitted_by: '',
        answers: ["178"],
        affiliate_links: [],
        franchise: 'None',
    },
    '118': {
        content: '',
        submitted_by: '',
        answers: ["354"],
        affiliate_links: [],
        franchise: 'None',
    },
    '119': {
        content: '',
        submitted_by: '',
        answers: ["204"],
        affiliate_links: [],
        franchise: 'None',
    },
    '120': {
        content: '',
        submitted_by: '',
        answers: ["236"],
        affiliate_links: [],
        franchise: 'None',
    },
    '121': {
        content: '',
        submitted_by: '',
        answers: ["32"],
        affiliate_links: [],
        franchise: 'None',
    },
    '122': {
        content: '',
        submitted_by: '',
        answers: ["198"],
        affiliate_links: [],
        franchise: 'None',
    },
    '123': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: [],
        franchise: 'None',
    },
    '124': {
        content: '',
        submitted_by: '',
        answers: ["170"],
        affiliate_links: [],
        franchise: 'None',
    },
    '125': {
        content: '',
        submitted_by: '',
        answers: ["214"],
        affiliate_links: [],
        franchise: 'None',
    },
    '126': {
        content: '',
        submitted_by: '',
        answers: ["300"],
        affiliate_links: [],
        franchise: 'None',
    },
    '127': {
        content: '',
        submitted_by: '',
        answers: ["4"],
        affiliate_links: [],
        franchise: 'None',
    },
    '128': {
        content: '',
        submitted_by: '',
        answers: ["338"],
        affiliate_links: [],
        franchise: 'None',
    },
    '129': {
        content: '',
        submitted_by: '',
        answers: ["220"],
        affiliate_links: [],
        franchise: 'None',
    },
    '130': {
        content: '',
        submitted_by: '',
        answers: ["108"],
        affiliate_links: [],
        franchise: 'None',
    },
    '131': {
        content: '',
        submitted_by: '',
        answers: ["280"],
        affiliate_links: [],
        franchise: 'None',
    },
    '132': {
        content: '',
        submitted_by: '',
        answers: ["44"],
        affiliate_links: [],
        franchise: 'None',
    },
    '133': {
        content: '',
        submitted_by: '',
        answers: ["172"],
        affiliate_links: [],
        franchise: 'None',
    },
    '134': {
        content: '',
        submitted_by: '',
        answers: ["272"],
        affiliate_links: [],
        franchise: 'None',
    },
    '135': {
        content: '',
        submitted_by: '',
        answers: ["232"],
        affiliate_links: [],
        franchise: 'None',
    },
    '136': {
        content: '',
        submitted_by: '',
        answers: ["42"],
        affiliate_links: [],
        franchise: 'None',
    },
    '137': {
        content: '',
        submitted_by: '',
        answers: ["100"],
        affiliate_links: [],
        franchise: 'None',
    },
    '138': {
        content: '',
        submitted_by: '',
        answers: ["308"],
        affiliate_links: [],
        franchise: 'None',
    },
    '139': {
        content: '',
        submitted_by: '',
        answers: ["10"],
        affiliate_links: [],
        franchise: 'None',
    },
    '140': {
        content: '',
        submitted_by: '',
        answers: ["158"],
        affiliate_links: [],
        franchise: 'None',
    },
    '141': {
        content: '',
        submitted_by: '',
        answers: ["274"],
        affiliate_links: [],
        franchise: 'None',
    },
    '142': {
        content: '',
        submitted_by: '',
        answers: ["132"],
        affiliate_links: [],
        franchise: 'None',
    },
    '143': {
        content: '',
        submitted_by: '',
        answers: ["256"],
        affiliate_links: [],
        franchise: 'None',
    },
    '144': {
        content: '',
        submitted_by: '',
        answers: ["66"],
        affiliate_links: [],
        franchise: 'None',
    },
    '145': {
        content: '',
        submitted_by: '',
        answers: ["284"],
        affiliate_links: [],
        franchise: 'None',
    },
    '146': {
        content: '',
        submitted_by: '',
        answers: ["218"],
        affiliate_links: [],
        franchise: 'None',
    },
    '147': {
        content: '',
        submitted_by: '',
        answers: ["24"],
        affiliate_links: [],
        franchise: 'None',
    },
    '148': {
        content: '',
        submitted_by: '',
        answers: ["268"],
        affiliate_links: [],
        franchise: 'None',
    },
    '149': {
        content: '',
        submitted_by: '',
        answers: ["50"],
        affiliate_links: [],
        franchise: 'None',
    },
    '150': {
        content: '',
        submitted_by: '',
        answers: ["210"],
        affiliate_links: [],
        franchise: 'None',
    },
    '151': {
        content: '',
        submitted_by: '',
        answers: ["350"],
        affiliate_links: [],
        franchise: 'None',
    },
    '152': {
        content: '',
        submitted_by: '',
        answers: ["230"],
        affiliate_links: [],
        franchise: 'None',
    },
    '153': {
        content: '',
        submitted_by: '',
        answers: ["92"],
        affiliate_links: [],
        franchise: 'None',
    },
    '154': {
        content: '',
        submitted_by: '',
        answers: ["270"],
        affiliate_links: [],
        franchise: 'None',
    },
    '155': {
        content: '',
        submitted_by: '',
        answers: ["340"],
        affiliate_links: [],
        franchise: 'None',
    },
    '156': {
        content: '',
        submitted_by: '',
        answers: ["20"],
        affiliate_links: [],
        franchise: 'None',
    },
    '157': {
        content: '',
        submitted_by: '',
        answers: ["182"],
        affiliate_links: [],
        franchise: 'None',
    },
    '158': {
        content: '',
        submitted_by: '',
        answers: ["106"],
        affiliate_links: [],
        franchise: 'None',
    },
    '159': {
        content: '',
        submitted_by: '',
        answers: ["318"],
        affiliate_links: [],
        franchise: 'None',
    },
    '160': {
        content: '',
        submitted_by: '',
        answers: ["296"],
        affiliate_links: [],
        franchise: 'None',
    },
    '161': {
        content: '',
        submitted_by: '',
        answers: ["216"],
        affiliate_links: [],
        franchise: 'None',
    },
    '162': {
        content: '',
        submitted_by: '',
        answers: ["246"],
        affiliate_links: [],
        franchise: 'None',
    },
    '163': {
        content: '',
        submitted_by: '',
        answers: ["150"],
        affiliate_links: [],
        franchise: 'None',
    },
    '164': {
        content: '',
        submitted_by: '',
        answers: ["90"],
        affiliate_links: [],
        franchise: 'None',
    },
    '165': {
        content: '',
        submitted_by: '',
        answers: ["206"],
        affiliate_links: [],
        franchise: 'None',
    },
    '166': {
        content: '',
        submitted_by: '',
        answers: ["258"],
        affiliate_links: [],
        franchise: 'None',
    },
    '167': {
        content: '',
        submitted_by: '',
        answers: ["70"],
        affiliate_links: [],
        franchise: 'None',
    },
    '168': {
        content: '',
        submitted_by: '',
        answers: ["48"],
        affiliate_links: [],
        franchise: 'None',
    },
    '169': {
        content: '',
        submitted_by: '',
        answers: ["136"],
        affiliate_links: [],
        franchise: 'None',
    },
    '170': {
        content: '',
        submitted_by: '',
        answers: ["254"],
        affiliate_links: [],
        franchise: 'None',
    },
    '171': {
        content: '',
        submitted_by: '',
        answers: ["110"],
        affiliate_links: [],
        franchise: 'None',
    },
    '172': {
        content: '',
        submitted_by: '',
        answers: ["184"],
        affiliate_links: [],
        franchise: 'None',
    },
    '173': {
        content: '',
        submitted_by: '',
        answers: ["344"],
        affiliate_links: [],
        franchise: 'None',
    },
    '174': {
        content: '',
        submitted_by: '',
        answers: ["26"],
        affiliate_links: [],
        franchise: 'None',
    },
    '175': {
        content: '',
        submitted_by: '',
        answers: ["196"],
        affiliate_links: [],
        franchise: 'None',
    },
    '176': {
        content: '',
        submitted_by: '',
        answers: ["238"],
        affiliate_links: [],
        franchise: 'None',
    },
    '177': {
        content: '',
        submitted_by: '',
        answers: ["84"],
        affiliate_links: [],
        franchise: 'None',
    },
    '178': {
        content: '',
        submitted_by: '',
        answers: ["62"],
        affiliate_links: [],
        franchise: 'None',
    },
    '179': {
        content: '',
        submitted_by: '',
        answers: ["30"],
        affiliate_links: [],
        franchise: 'None',
    },
    '180': {
        content: '',
        submitted_by: '',
        answers: ["176"],
        affiliate_links: [],
        franchise: 'None',
    },
    '181': {
        content: '',
        submitted_by: '',
        answers: ["262"],
        affiliate_links: [],
        franchise: 'None',
    },
    '182': {
        content: '',
        submitted_by: '',
        answers: ["14"],
        affiliate_links: [],
        franchise: 'None',
    },
    '183': {
        content: '',
        submitted_by: '',
        answers: ["336"],
        affiliate_links: [],
        franchise: 'None',
    },
    '184': {
        content: '',
        submitted_by: '',
        answers: ["162"],
        affiliate_links: [],
        franchise: 'None',
    },
    '185': {
        content: '',
        submitted_by: '',
        answers: ["128"],
        affiliate_links: [],
        franchise: 'None',
    },
    '186': {
        content: '',
        submitted_by: '',
        answers: ["302"],
        affiliate_links: [],
        franchise: 'None',
    },
    '187': {
        content: '',
        submitted_by: '',
        answers: ["352"],
        affiliate_links: [],
        franchise: 'None',
    },
    '188': {
        content: '',
        submitted_by: '',
        answers: ["82"],
        affiliate_links: [],
        franchise: 'None',
    },
    '189': {
        content: '',
        submitted_by: '',
        answers: ["260"],
        affiliate_links: [],
        franchise: 'None',
    },
    '190': {
        content: '',
        submitted_by: '',
        answers: ["294"],
        affiliate_links: [],
        franchise: 'None',
    },
    '191': {
        content: '',
        submitted_by: '',
        answers: ["130"],
        affiliate_links: [],
        franchise: 'None',
    },
    '192': {
        content: '',
        submitted_by: '',
        answers: ["312"],
        affiliate_links: [],
        franchise: 'None',
    },
    '193': {
        content: '',
        submitted_by: '',
        answers: ["18"],
        affiliate_links: [],
        franchise: 'None',
    },
    '194': {
        content: '',
        submitted_by: '',
        answers: ["160"],
        affiliate_links: [],
        franchise: 'None',
    },
    '195': {
        content: '',
        submitted_by: '',
        answers: ["58"],
        affiliate_links: [],
        franchise: 'None',
    },
    '196': {
        content: '',
        submitted_by: '',
        answers: ["138"],
        affiliate_links: [],
        franchise: 'None',
    },
    '197': {
        content: '',
        submitted_by: '',
        answers: ["200"],
        affiliate_links: [],
        franchise: 'None',
    },
    '198': {
        content: '',
        submitted_by: '',
        answers: ["156"],
        affiliate_links: [],
        franchise: 'None',
    },
    '199': {
        content: '',
        submitted_by: '',
        answers: ["332"],
        affiliate_links: [],
        franchise: 'None',
    },
    '200': {
        content: '',
        submitted_by: '',
        answers: ["222"],
        affiliate_links: [],
        franchise: 'None',
    },
    '201': {
        content: '',
        submitted_by: '',
        answers: ["124"],
        affiliate_links: [],
        franchise: 'None',
    },
    '202': {
        content: '',
        submitted_by: '',
        answers: ["140"],
        affiliate_links: [],
        franchise: 'None',
    },
    '203': {
        content: '',
        submitted_by: '',
        answers: ["186"],
        affiliate_links: [],
        franchise: 'None',
    },
    '204': {
        content: '',
        submitted_by: '',
        answers: ["80"],
        affiliate_links: [],
        franchise: 'None',
    },
    '205': {
        content: '',
        submitted_by: '',
        answers: ["244"],
        affiliate_links: [],
        franchise: 'None',
    },
    '206': {
        content: '',
        submitted_by: '',
        answers: ["148"],
        affiliate_links: [],
        franchise: 'None',
    },
    '207': {
        content: '',
        submitted_by: '',
        answers: ["16"],
        affiliate_links: [],
        franchise: 'None',
    },
    '208': {
        content: '',
        submitted_by: '',
        answers: ["104"],
        affiliate_links: [],
        franchise: 'None',
    },
    '209': {
        content: '',
        submitted_by: '',
        answers: ["304"],
        affiliate_links: [],
        franchise: 'None',
    },
    '210': {
        content: '',
        submitted_by: '',
        answers: ["322"],
        affiliate_links: [],
        franchise: 'None',
    },
    '211': {
        content: '',
        submitted_by: '',
        answers: ["242"],
        affiliate_links: [],
        franchise: 'None',
    },
    '212': {
        content: '',
        submitted_by: '',
        answers: ["192"],
        affiliate_links: [],
        franchise: 'None',
    },
    '213': {
        content: '',
        submitted_by: '',
        answers: ["86"],
        affiliate_links: [],
        franchise: 'None',
    },
    '214': {
        content: '',
        submitted_by: '',
        answers: ["118"],
        affiliate_links: [],
        franchise: 'None',
    },
    '215': {
        content: '',
        submitted_by: '',
        answers: ["298"],
        affiliate_links: [],
        franchise: 'None',
    },
    '216': {
        content: '',
        submitted_by: '',
        answers: ["264"],
        affiliate_links: [],
        franchise: 'None',
    },
    '217': {
        content: '',
        submitted_by: '',
        answers: ["166"],
        affiliate_links: [],
        franchise: 'None',
    },
    '218': {
        content: '',
        submitted_by: '',
        answers: ["290"],
        affiliate_links: [],
        franchise: 'None',
    },
    '219': {
        content: '',
        submitted_by: '',
        answers: ["248"],
        affiliate_links: [],
        franchise: 'None',
    },
};
