// @flow
import React from 'react';
import { PUZZLE_TYPES } from '../constants';
import { resetState, resetGlobalState } from '../localStateUtils';
import { createSearchParams, useNavigate, useLocation, matchPath } from 'react-router-dom';
import { useSettings } from '../settings/useSettings';
import { setPuzzleType } from '../store/slices/uxSlice';
import { setShowInterAd } from '../store/slices/uxSlice';
import type { TDispatch } from '../store/store';
import { useDispatch } from 'react-redux';
import { goToDay } from '../clientUtils';

const DevTools = (): React$Element<'div'> => {
    const navigate = useNavigate();
    const dispatch: TDispatch = useDispatch();
    const location = useLocation();
    const settings = useSettings();
    const baseUrl = settings.puzzle_type === PUZZLE_TYPES.GTGC ? '/gtgc/' : '/p/';
    
    const puzzle_url = settings.puzzle_url().replace('?', '');
    let match = matchPath({ path: puzzle_url }, location.pathname);
    let puzzleId;
    puzzleId = match?.params?.puzzleId ?? settings.num_days_from_start_date().toString();

    console.log(Object.keys(PUZZLE_TYPES));

    return (
        <div>
            <button
                className={'reset-state'}
                style={{ marginBottom: '15px' }}
                onClick={() => {
                    resetState(puzzleId, settings);
                }}
            >
                Reset State
            </button>
            <button
                className={'reset-state'}
                style={{ marginBottom: '15px' }}
                onClick={() => {
                    resetGlobalState();
                }}
            >
                Reset Global State
            </button>
            <button
                style={{ marginBottom: '15px' }}
                onClick={() => {
                    if (location.pathname === settings.paths.previous_games) {
                        navigate(baseUrl);
                    } else {
                        navigate({
                            pathname: settings.paths.previous_games,
                            search: createSearchParams({ puzzleType: settings.puzzle_type }).toString(),
                        });
                    }
                }}
            >
                Swap Screen
            </button>
            <button
                style={{ marginBottom: '15px' }}
                onClick={() => {
                    goToDay(Number(puzzleId) - 1, 'puzzle', settings);
                }}
            >
                Prev Game
            </button>
            <button
                style={{ marginBottom: '15px' }}
                onClick={() => {
                    goToDay(Number(puzzleId) + 1, 'puzzle', settings);
                }}
            >
                Next Game
            </button>
            <button
                style={{ marginBottom: '15px' }}
                onClick={() => {
                    dispatch(setShowInterAd(true));
                }}
            >
                Force Interstitial Ad
            </button>
        </div>
    );
};

export default DevTools;
