import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { setModal } from '../store/slices/uxSlice';
import { useSettings } from '../settings/useSettings';
import { useQuests } from './Puzzle/hooks/questHooks'
import { calcQuestProgressBar} from '../puzzleUtils';
import { answers } from '../puzzles/gtgq_puzzles';
import { IMAGE_EXTENSION } from '../constants';

const Quests = ({ puzzleType }) => {
    const dispatch: TDispatch = useDispatch();
    const navigate = useNavigate();
    const settings = useSettings(puzzleType);
    const userState = useSelector((state: State) => state.user);

    const quests = useQuests(settings)

    const onQuestClick = (quest) => {
        const navigateFunc = () => navigate({ pathname: `/gtgq/${quest.id}/1`, state: { quest } });
        if (!userState.user) {
            dispatch(setModal('inter-ad'));
            window.globalThis.interAdNextFunc = navigateFunc;
        } else {
            navigateFunc();
        }
    };

    const currentUrl = window.location;
    const description = 'List of quests to complete!';
    const pageTitle = `${settings.website_title} - Quests`;

    return (
        <div className="quests-container">
            <Helmet>
                <title>{pageTitle}</title>
                <link rel="canonical" href={currentUrl} />
                <meta property="og:url" content={currentUrl} />
                <meta property="twitter:url" content={currentUrl} />

                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta property="twitter:description" content={description} />

                <meta property="og:title" content={pageTitle} />
                <meta property="twitter:title" content={pageTitle} />

                <meta property="og:type" content="website" />
                <meta property="og:site_name" content={settings.website_url} />
            </Helmet>

            <h1 className="quests-title"> Embark on a Quest </h1>
            <div className="quests-list">
                {quests && quests.map((quest) => (
                    <div
                        key={quest.id}
                        className="quest-item"
                        onClick={() => onQuestClick(quest)}
                        style={{
                            opacity: quest.completed ? 0.6 : 1,
                        }}
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <p className="quest-item-title">{quest.title}</p>
                            <span>{quest.completed && '✅'}</span>
                        </div>
                        <p className="quest-item-description">{quest.description}</p>
                        <img className="quest-item-image" src={`gtgq_games/${quest.id}/cover.webp`} alt={quest.title} />
                        <button className="quest-item-button">
                            <span style={{ fontSize: '12px' }}>{quest.completed ? '⟳' : '🎮'}</span>
                            <span> {quest.completed ? 'Replay' : 'Embark'} </span>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Quests;
