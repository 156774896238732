import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { useParams } from 'react-router-dom';
import { useSettings } from '../settings/useSettings';

const AngleAnimation = ({ angle }) => {
    const lineLength = 100; // Length of the line
    const radius = 20; // Radius of the arc
    const settings = useSettings();

    // Get puzzle ID from URL params or settings
    let { puzzleId } = useParams();
    puzzleId = puzzleId ?? settings.num_days_from_start_date().toString();
    const isAprilFools = puzzleId === '52';

    // State for the drag angle
    const [dragAngle, setDragAngle] = useState(0);
    // State for April Fools angle offset
    const [aprilFoolsOffset, setAprilFoolsOffset] = useState(0);

    // Create rotation animation
    const displayAngle = isAprilFools ? angle + aprilFoolsOffset : angle;
    const props = useSpring({
        to: { deg: displayAngle + dragAngle },
        from: { deg: 0 },
        config: { duration: 2000 },
    });

    // April Fools effect - change angle every few seconds
    useEffect(() => {
        if (isAprilFools) {
            const interval = setInterval(() => {
                // Change by 10-30 degrees randomly
                const change = Math.floor(Math.random() * 21) + 10;
                // Randomly decide if change is positive or negative
                const direction = Math.random() > 0.5 ? 1 : -1;

                setAprilFoolsOffset((prev) => {
                    // Calculate new offset
                    const newOffset = prev + change * direction;
                    // Make sure the total angle stays between 2 and 360
                    const totalAngle = angle + newOffset;
                    if (totalAngle < 2) return 2 - angle;
                    if (totalAngle > 360) return 360 - angle;
                    return newOffset;
                });
            }, 3000); // Change every 3 seconds

            return () => clearInterval(interval);
        }
    }, [isAprilFools, angle]);

    // Calculate arc path
    const largeArcFlag = displayAngle > 180 ? 1 : 0;

    // Drag gesture handler
    const bind = useDrag(({ down, movement: [mx, my] }) => {
        // Calculate the angle based on the mouse movement
        const newDragAngle = Math.atan2(my, mx) * (180 / Math.PI);
        setDragAngle(down ? newDragAngle : 0);
    });

    return (
        <svg
            width="100%"
            height="auto"
            viewBox="0 -50 250 300"
            style={{ aspectRatio: 1.6 }}
            // width="400" height="250"
        >
            <defs>
                {/* Define arrow marker */}
                <marker id="arrow" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto">
                    <path d="M0,0 L10,5 L0,10 Z" fill="white" />
                </marker>
                <marker id="arrowRed" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto">
                    <path d="M0,0 L10,5 L0,10 Z" fill="red" />
                </marker>
            </defs>

            {/* Static line with arrow */}
            <line
                x1="100"
                y1="100"
                x2={100 + lineLength}
                y2="100"
                stroke="white"
                strokeWidth="2"
                markerEnd="url(#arrow)"
            />

            {/* Rotating line with arrow */}
            <animated.line
                {...bind()}
                x1="100"
                y1="100"
                x2={props.deg.interpolate((deg) => 100 + lineLength * Math.cos(deg * (Math.PI / 180)))}
                y2={props.deg.interpolate((deg) => 100 + lineLength * Math.sin(deg * (Math.PI / 180)))}
                stroke="red"
                strokeWidth="2"
                markerEnd="url(#arrowRed)"
            />
            {/* Arc indicating inner angle */}
            <animated.path
                d={props.deg.interpolate((deg) => {
                    const yOffset = displayAngle === 360 ? 1 : 0;
                    const endX = 100 + radius * Math.cos(deg * (Math.PI / 180));
                    const endY = 100 - yOffset + radius * Math.sin(deg * (Math.PI / 180));
                    return `M 100 100 L 120 100 A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY} Z`;
                })}
                fill="none"
                stroke="white"
                strokeWidth="1"
            />
        </svg>
    );
};

export default AngleAnimation;
