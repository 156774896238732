// @flow

import { assembleClipboard, copyToClipboard } from '../clientUtils';
import React, { useState } from 'react';
import type { State } from '../store/types';
import { useSelector } from 'react-redux';

import { useSettings } from '../settings/useSettings';

type TProps = {
    guesses: number,
    gameState: ?string,
    puzzleId: string,
    franchiseMatch: Array<boolean>,
    maxGuesses: number,
};

const ShareResult = ({
    guesses,
    gameState,
    puzzleId,
    franchiseMatch,
    maxGuesses,
}: TProps): React$Element<React$FragmentType> => {
    const { puzzleType, rankToggle, franchiseToggle, linkToPuzzleToggle } = useSelector((state: State) => state.ux);
    const settings = useSettings();
    const { currentRank } = useSelector((state: State) => state.stats[puzzleType]);

    const [shareButtonText, setButtonText] = useState('Share Results');
    const user = useSelector((state: State) => state.user);
    console.log('user: ', user.user);

    const url = linkToPuzzleToggle ? settings.game_url(puzzleId) : settings.website_url;
    const tag = settings.clipboard_tag;

    return (
        <>
            <button
                className="mainButton share-results-btn copy-to-clipboard"
                id={'share-results-btn'}
                onClick={() => {
                    const text = assembleClipboard(
                        guesses,
                        gameState === 'win',
                        franchiseMatch,
                        maxGuesses,
                        franchiseToggle,
                    );
                    let rankTag = '';
                    if (rankToggle && currentRank) {
                        rankTag = `${user.user ? '🕹️' : ''}#${currentRank.replaceAll(' ', '')}${
                            user.user ? '🕹️' : ''
                        }\n`;
                    }
                    const clipboard_text =
                        tag + ` #${puzzleId}\n\n${settings.website_emoji} ${text.join(' ')}\n\n` + rankTag + url;
                    copyToClipboard(clipboard_text);
                    setButtonText('Copied');
                    setTimeout(() => {
                        setButtonText('Share Results');
                    }, 2000);
                }}
            >
                {shareButtonText}
            </button>
        </>
    );
};

export default ShareResult;
