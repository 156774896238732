// @flow
import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_BASE, calculatePuzzleDate } from '../globals';
import { motion } from 'framer-motion';
import { answers } from '../puzzles/gtg_puzzles';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { ResponsivePie } from '@nivo/pie';
import { goToDay } from '../clientUtils';
import Footer from './Footer';
import { useSelector } from 'react-redux';
import type { State } from '../store/types';
import { useSettings } from '../settings/useSettings';

declare var __GTG_BUILD__: boolean;
declare var __GTA_BUILD__: boolean;
declare var __GTB_BUILD__: boolean;
declare var __GTM_BUILD__: boolean;
declare var __GTL_BUILD__: boolean;
declare var __GTANGLE_BUILD__: boolean;

const CustomLayerComponent = (myProps: string) => (layerProps: { centerX: number, centerY: number }) => {
    const { centerX, centerY } = layerProps;

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '6cqw',
                fontWeight: '600',
                fill: 'white',
            }}
        >
            {myProps}
        </text>
    );
};

const Stats = (): React$Element<'div'> => {
    const { puzzleId } = useParams();
    const [stats, setStats] = useState(null);
    const [wasBannerSetup, setWasBannerSetup] = useState(false);
    const [currentTweetIndex, setCurrentTweetIndex] = useState(0); // set initial tweet index to 0
    // const tweetIds = ['1661605928221777920', '1661857766422478850', '1656424701592256512']; // list of tweet ids
    const [tweetIds, setTweetIds] = useState([]); // list of tweet ids
    const settings = useSettings();

    const userState = useSelector((state: State) => state.user);

    const affiliate_links = answers[puzzleId].affiliate_links || [];
    const answer = answers[puzzleId].answers;
    const console_platform = answers[puzzleId].console_platform;

    const tweetsStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '512px',
        margin: 'auto',
    };

    const tweetStyle = {
        flexGrow: '1',
        textAlign: 'center',
    };

    const navIconStyle = {
        cursor: 'pointer',
    };

    const statStyle = {
        paddingBottom: '10px',
        paddingRight: '10px',
        paddingLeft: '10px',
        color: 'white',
    };

    const buttonStyle = {
        display: 'flex',
        textAlign: 'center',
        margin: '20px auto',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const listStyle = {
        listStyleType: 'none',
        paddingTop: '5px',
    };

    const statsInfo = {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        margin: '20px auto',
    };

    const nextTweet = () => {
        setCurrentTweetIndex((prevIndex) => (prevIndex + 1) % tweetIds.length);
    };

    const previousTweet = () => {
        setCurrentTweetIndex((prevIndex) => (prevIndex - 1 + tweetIds.length) % tweetIds.length);
    };

    useEffect(() => {
        const getStats = async () => {
            try {
                const response = await axios.get(
                    `${API_BASE}/api/get_stats/` + puzzleId + '/' + settings.puzzle_type + '/',
                );
                setStats(response.data['results']);
                setTweetIds(response.data['results']['twitter_ids']);
            } catch (error) {
                console.error(error);
            }
        };

        // Call it immediately and also set it on an interval if it's the latest puzzle
        if (puzzleId === settings.num_days_from_start_date().toString()) {
            getStats();
            const statsInterval = setInterval(() => {
                getStats();
            }, 5000);

            // Cleanup function to clear the intervals when the component unmounts
            return () => {
                clearInterval(statsInterval);
            };
        } else {
            // If it's not the latest puzzle, just get the stats once
            getStats();
        }
    }, [puzzleId, settings]);

    useEffect(() => {
        if (stats && !wasBannerSetup && !userState.user) {
            const slotId = 'guessthe_stats_banner';
            const builds = [
                { condition: __GTG_BUILD__, placementName: 'guessthe-game_stats_banner' },
                { condition: __GTA_BUILD__, placementName: 'guesstheaudio_banner' },
                { condition: __GTB_BUILD__, placementName: 'guessthebook_banner' },
            ];

            builds.forEach((build) => {
                if (build.condition) {
                    window.freestar.config.enabled_slots.push({
                        placementName: build.placementName,
                        slotId: slotId,
                    });
                }
            });

            setWasBannerSetup(true);
        }
    }, [stats, userState.user, wasBannerSetup]);

    if (!stats) {
        console.log('done');
        return <div className={'loadingStyle'}>Loading...</div>;
    }
    console.log(stats);
    console.log(Object.keys(stats.guesses_percentages));
    // if userState.user use stats.guesses_percentages_members else use stats.guesses_percentages
    const pieChartData = Object.entries(
        userState.user ? stats.guesses_percentages_members : stats.guesses_percentages,
    ).map(([index, percentage]) => {
        return {
            id: index,
            label: index,
            value: percentage,
        };
    });
    console.log('piechart data: ', pieChartData);

    return (
        <div className="current-game">
            <meta name="browsi" />
            <div
                className={'current-game-number'}
                style={{
                    textAlign: 'center',
                    color: '#eee',
                    opacity: '50%',
                    paddingBottom: '10px',
                    fontStyle: 'oblique',
                }}
            >
                {settings.guess_item} #{puzzleId} Statistics
                <br />
                {calculatePuzzleDate(puzzleId, settings)}
            </div>
            <div style={statStyle}>
                <div></div>
                <div style={{ height: '370px', containerType: 'inline-size' }}>
                    <ResponsivePie
                        data={pieChartData}
                        margin={{ top: 19, bottom: 70, right: 55, left: 55 }}
                        innerRadius={0.5}
                        valueFormat={'>-.2%'}
                        padAngle={0.7}
                        colors={{ scheme: 'accent' }}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.2]],
                        }}
                        tooltip={({ datum }) => (
                            <div style={{ background: '#0f172a', borderRadius: '3px', padding: '3px' }}>{`${(
                                datum.value * 100
                            ).toFixed(2)}% ${datum.id}`}</div>
                        )}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#fbfcff"
                        arcLinkLabelsThickness={3}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [['darker', 2]],
                        }}
                        defs={[]}
                        fill={[]}
                        layers={['arcs', 'arcLinkLabels', 'arcLabels', 'legends', CustomLayerComponent('% Correct')]}
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: -10,
                                translateY: 45,
                                itemsSpacing: 0,
                                itemWidth: 35,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 10,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#fff',
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
                <div align="center" data-freestar-ad="__512x512" id="guessthe_stats_banner"></div>

                {tweetIds.length !== 0 && (
                    <>
                        <div className={'tweet-title'}>Best Tweets:</div>
                        <div style={tweetsStyle}>
                            <button
                                type="button"
                                style={navIconStyle}
                                onClick={previousTweet}
                                className="mainButton iconButton"
                            >
                                <span aria-hidden="true" className="icon-backward2"></span>
                            </button>
                            <div style={tweetStyle}>
                                <TwitterTweetEmbed
                                    key={tweetIds[currentTweetIndex]}
                                    tweetId={tweetIds[currentTweetIndex]}
                                />
                            </div>
                            <button
                                type="button"
                                onClick={nextTweet}
                                className="mainButton iconButton"
                                style={navIconStyle}
                            >
                                <span aria-hidden="true" className="icon-forward3"></span>
                            </button>
                        </div>
                    </>
                )}
                <div className={'stats-info'} style={statsInfo}>
                    {/*<div>*/}
                    {/*    Users who continued their longest streak:*/}
                    {/*    <ul style={listStyle}>*/}
                    {/*        <li>Jorge:342</li>*/}
                    {/*        <br />*/}
                    {/*        <li>Jorge:342</li>*/}
                    {/*        <br />*/}
                    {/*        <li>Jorge:342</li>*/}
                    {/*        <br />*/}
                    {/*        <li>Jorge:342</li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <div>
                        Most Common Wrong Guesses:
                        <motion.ul style={listStyle}>
                            {Object.entries(stats.top_incorrect_answers).map(([index, incorrect_answer]) => {
                                return (
                                    <>
                                        <motion.li
                                            key={`${index}-${incorrect_answer}`}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            {incorrect_answer[0]} - {incorrect_answer[1]}
                                        </motion.li>
                                        <br />
                                    </>
                                );
                            })}
                        </motion.ul>
                    </div>
                </div>

                <button
                    className="mainButton share-results-btn"
                    style={buttonStyle}
                    onClick={() => goToDay(puzzleId, 'puzzle', settings)}
                >
                    Play This Puzzle
                </button>
                <button className="mainButton share-results-btn" style={buttonStyle}>
                    <a
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        href={`https://twitter.com/search?q=%22%23${settings.clipboard_tag.substring(
                            1,
                        )}%20%23${puzzleId}%22&src=spelling_expansion_revert_click&f=live`}
                    >
                        See Other Players Scores
                    </a>
                </button>
            </div>
            <Footer puzzleId={puzzleId} currentPage={'stats'} puzzleType={settings.puzzle_type} />
        </div>
    );
};

export default Stats;
