// @flow

import { ADMIN_URL, EMAIL, SEARCH_ITEM_SUBMISSION_URL } from '../../constants';

import { useDispatch } from 'react-redux';
import type { TDispatch } from '../../store/store';

import React from 'react';
import { setModal } from '../../store/slices/uxSlice';
import { useSettings } from '../../settings/useSettings';
import CloseModalButton from './CloseModalButton';

const AboutModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();
    const { modalTexts } = settings.TEXTS.modal.AboutModal;

    return (
        <div className={`modal about-modal`}>
            <div className="about-modal-inner">
                <CloseModalButton />
                <h3 onClick={() => (window.location.href += `?${ADMIN_URL}=True`)}>{'About'}</h3>
                <p>
                    {settings.website_url} is a daily puzzle game inspired by{' '}
                    <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank" rel="noreferrer noopener">
                        Wordle
                    </a>
                    , Heardle &{' '}
                    <a href="https://framed.wtf/" target="_blank" rel="noreferrer noopener">
                        Framed.wtf
                    </a>
                    .
                </p>
                {modalTexts.map((text, index) => (
                    <p key={index}>
                        {typeof text === 'string' ? (
                            text
                        ) : (
                            <>
                                {text.beforeLink}
                                <a href={text.url} target="_blank" rel="noopener noreferrer">
                                    {text.linkText}
                                </a>
                                {text.afterLink}
                            </>
                        )}
                    </p>
                ))}

                <p>
                    Did you try searching for a {settings.guess_item.toLowerCase()} only to be disappointed that you
                    couldn't find it?{' '}
                    <a href={SEARCH_ITEM_SUBMISSION_URL} target="_blank" rel="noreferrer noopener">
                        Submit a {settings.guess_item.toLowerCase()} to our database
                    </a>
                </p>

                <p>
                    Notice any broken features or bugs? Please let me know what kind of device and browser you're using
                    and send us an email at <a href={'mailto:' + EMAIL}>{EMAIL}</a>.
                </p>
                <br />
                <p style={{ fontSize: '0.7em' }}>
                    Our copyright policy: Copyright for the images, games, songs or other displays is likely retained by
                    the publisher/producer and/or artist(s) who produced the item in question. It is believed that the
                    use of these images falls under United States fair use law. Any other uses of this image might be
                    copyright infringement. If you require that the image/game/puzzle or any other source be removed
                    from the site, please reach out via email.
                </p>
            </div>
        </div>
    );
};

export default AboutModal;
