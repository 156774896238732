// @flow
export const MANAGEMENT_SITE = 'sheeshblockchaineducation.click';
export const IMAGE_EXTENSION = 'webp';
export const EMAIL = 'support@guessthe.wtf';

export const RETAILERS = ['Amazon', 'Ebay', 'G2A', 'GMG'];

export const INSTAGRAM_URL = 'https://www.instagram.com/guess.the.wtf.app/';
export const TWITTER_URL = 'https://twitter.com/GuessTheGameApp';
export const FACEBOOK_URL = 'https://www.facebook.com/profile.php?id=100090974522980';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCKDIHWp2z17zMBpHmn56dvw';

export const GTG_COMMUNITY_SUBMISSION_URL = 'https://forms.gle/5sX97oAdezEm5LuP9';
export const SEARCH_ITEM_SUBMISSION_URL = 'https://api.guessthe.game/api/submit_item/';

export const ADMIN_URL = 'admin-url';

export const CORRECT_EMOJI = '🟩'; // Green square
export const INCORRECT_EMOJI = '🟥'; // Red square
export const PARTIAL_EMOJI = '🟨'; // Yellow square
export const REMAINING_EMOJI = '⬜'; // White square

export const MIXPANEL_TOKEN = '86d760615a4b2101d07cdcf1682c4e3e';

// Kofi stuff
export const KOFI_URL = 'https://ko-fi.com/guessthegame';

// guess image colors
export const GUESS_IMAGE_SUCCESS_COLOR = '#059669';
export const GUESS_IMAGE_NEUTRAL_COLOR = '#1e293b';
export const GUESS_IMAGE_FRANCHISE_COLOR = 'rgb(159, 159, 40)';
export const GUESS_IMAGE_FAILURE_COLOR = '#ba1c1c';

export const LNGS = {
    en: { nativeName: 'English' },
    es: { nativeName: 'Spanish' },
    fr: { nativeName: 'French' },
};

export const TIERS = {
    1: { name: 'New Inspector' },
    2: { name: 'Seasoned Sleuth' },
    3: { name: 'Elite Detective' },
};

export const PUZZLE_TYPES = Object.freeze({
    GTG: 'gtg',
    GTGC: 'gtgc',
    GTGQ: 'gtgq',
    GTA: 'gta',
    GTAC: 'gtac',
    GTB: 'gtb',
    GTM: 'gtm',
    GTMC: 'gtmc',
    GTL: 'gtl',
    GTH: 'gth',
    GTANGLE: 'gtangle',
});
export type PUZZLE_TYPES_TYPE = $Values<typeof PUZZLE_TYPES>;

// Local Storage
export const lastPlayedKey = 'lastPlayed';
export const gamesPlayedCountKey = 'gamesPlayedCount';
export const lastShownInterAdKey = 'lastShownInterAdKey';

// Interstitial ads
export const numGamesBeforeInterAd = 15;
export const timeToInvalidateGamesPlayed = 3 * 60 * 60 * 1000; // milliseconds / 3 hours
export const minimumTimeBetweenInterAd = 1 * 60 * 60 * 1000; // milliseconds / 1 hour
export const interAdSkipDuration = 5 * 1000; // milliseconds
