// @flow
import { setModal, setShowInterAd } from '../store/slices/uxSlice';
import { DEV_MODE } from '../globals';

import { useDispatch } from 'react-redux';

import React, { useEffect, useRef, useState } from 'react';
import type { TDispatch } from '../store/store';

import { KOFI_URL, interAdSkipDuration } from '../constants';

declare var __GTG_BUILD__: boolean;
declare var __GTA_BUILD__: boolean;
declare var __GTB_BUILD__: boolean;

// const CloseButtonIcon = () => (
//     <svg width="25" height="25" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path
//             d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
//             fill="currentColor"
//             fillRule="evenodd"
//             clipRule="evenodd"
//         ></path>
//     </svg>
// );

const CircularProgress = ({ progress = 70, width = '30px', height = '30px', style = {} }) => {
    const radius = 70;
    const dashArray = 2 * 3.14 * 70;
    const dashOffset = dashArray * ((100 - progress) / 100);

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 160 160"
            style={{
                transform: 'rotate(-90deg)',
                // ,
                ...style,
            }}
        >
            {/* <circle r={radius} cx="80" cy="80" fill="transparent" stroke="#e0e0e0" stroke-width="12px" /> */}
            <circle
                r={radius}
                cx="80"
                cy="80"
                fill="transparent"
                stroke="#e0e0e0"
                stroke-linecap="round"
                stroke-width="12px"
                stroke-dasharray={`${dashArray}px`}
                stroke-dashoffset={`${dashOffset}px`}
                style={{
                    transition: 'all 0.3s ease-in-out',
                }}
            />
        </svg>
    );
};

const InterstitialAdModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();

    const [wasBannerSetup, setWasBannerSetup] = useState(false);
    const [skipTimeLeft, setSkipTimeLeft] = useState(interAdSkipDuration);
    const timerProgress = Math.min(100, Math.max(0, (skipTimeLeft / interAdSkipDuration) * 100));
    const [allowSkip, setAllowSkip] = useState(false);
    const timerIdRef = useRef<?number>(null);

    const modalRef = useRef<?HTMLDivElement>(null);

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
        }
    }, []);

    useEffect(() => {
        if (!wasBannerSetup) {
            const slotId = 'guessthe_custom_interstitial';
            const builds = [
                { condition: __GTG_BUILD__, placementName: 'guessthe-game_custom_interstitial' },
                { condition: __GTA_BUILD__, placementName: 'guesstheaudio_interstitial' },
                { condition: __GTB_BUILD__, placementName: 'guessthebook_interstitial' },
            ];
            builds.forEach((build) => {
                if (build.condition) {
                    window.freestar.config.enabled_slots.push({
                        placementName: build.placementName,
                        slotId: slotId,
                    });
                }
            });
            setWasBannerSetup(true);
        }
    }, [wasBannerSetup]);

    const closeModal = () => {
        if (allowSkip) {
            dispatch(setModal('none'));
            if (typeof window.globalThis.interAdNextFunc == 'function') {
                window.globalThis.interAdNextFunc();
                window.globalThis.interAdNextFunc = undefined;
            }
            dispatch(setShowInterAd(false));
        }
    };

    useEffect(() => {
        const timerId = setInterval(() => {
            setSkipTimeLeft((skipTimeLeft) => (skipTimeLeft > 0 ? skipTimeLeft - 1000 : 0));
        }, 1000);
        timerIdRef.current = timerId;

        return () => clearInterval(timerId);
    }, []);

    useEffect(() => {
        if (skipTimeLeft <= 0) {
            setAllowSkip(true);
            clearInterval(timerIdRef.current);
        }
    }, [skipTimeLeft]);

    return (
        <div ref={modalRef} className="modal inter-ad-modal">
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '2px',
                    }}
                >
                    <p style={{ width: '90%' }}>
                        Sorry for this short intrusion, but it helps keep the site going! Don't want to see this again?
                        Consider supporting us on&nbsp;
                        <a href={KOFI_URL} target="_blank" rel="noopener noreferrer">
                            Ko-fi
                        </a>
                        !
                    </p>

                    <button
                        type="button"
                        className="mainButton iconButton skipButton"
                        onClick={closeModal}
                        disabled={!allowSkip}
                    >
                        {!allowSkip && (
                            <span style={{ position: 'relative', marginRight: '2px', width: '24px' }}>
                                <CircularProgress
                                    width="100%"
                                    height="100%"
                                    progress={timerProgress}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 0,
                                    }}
                                />
                                <span style={{ fontSize: '12px', verticalAlign: 'middle' }}>
                                    {Math.floor(skipTimeLeft / 1000)}
                                </span>
                            </span>
                        )}
                        <span style={{ color: allowSkip ? 'inherit' : '#c7c7c7' }}>Skip</span>
                        {allowSkip && <span aria-hidden="true" className="icon-forward3"></span>}
                    </button>
                </div>

                <>
                    <div
                        align="center"
                        style={{ minWidth: 512, minHeight: 512 }}
                        data-freestar-ad="__336x280 __300x600"
                        id="guessthe_custom_interstitial"
                    />
                </>
            </div>
        </div>
    );
};

export default InterstitialAdModal;
