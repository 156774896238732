// @flow

import PreviousPuzzles from '../components/PreviousPuzzles';
import GTANGLEPuzzle from '../components/Puzzle/GTANGLEPuzzle';
import Setting from '../components/Setting';
import Stats from '../components/Stats';
import { type GameSettings } from './game_settings';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import ResetPuzzle from '../components/ResetPuzzle';
import { PUZZLE_TYPES } from '../constants';
import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { AnswerUnion } from './game_settings';
import { answers } from '../puzzles/gtangle_puzzles';
import { BaseSettings } from './BaseSettings';

export class GTANGLESettings extends BaseSettings {
    static PATHS: {
        email_callback: string,
        guess_the_cover: string,
        original_images: string,
        previous_games: string,
        puzzle: string,
        reset: string,
        setting: string,
        stats: string,
        test_affiliate: string,
        ...
    } = {
        puzzle: '/p/:puzzleId',
        previous_games: '/previous-games',
        reset: '/reset/:puzzleId/:puzzleType',
        stats: '/stats/:puzzleId',
        email_callback: '/email_callback/',
        setting: '/setting',
    };

    routes(): React$Element<React$FragmentType> {
        return (
            <>
                <Route element={<GTANGLEPuzzle />} path="/" />
                <Route path={GTANGLESettings.PATHS.puzzle} element={<GTANGLEPuzzle />} />
                <Route path={GTANGLESettings.PATHS.stats} element={<Stats />} />
                <Route path={GTANGLESettings.PATHS.previous_games} element={<PreviousPuzzles />} />
                <Route path={GTANGLESettings.PATHS.reset} element={<ResetPuzzle />} />
                <Route path={GTANGLESettings.PATHS.setting} element={<Setting />} />
                <Route path={GTANGLESettings.PATHS.email_callback} element={<Setting fromEmailCallback={true} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </>
        );
    }

    puzzle_start_date: Date = new Date('2/9/2025');

    website_url: string = 'GuessTheAngle.wtf';
    title: string = 'Guess The Angle';
    website_title: string = 'Guess The Angle';
    parent_abbr: Lowercase<string> = 'gtangle'; // used for submitlogin. must be lower case

    max_guesses: number = 4;
    root_url: string = '/';
    puzzle_type: PUZZLE_TYPES_TYPE = PUZZLE_TYPES.GTANGLE;
    clipboard_tag: string = '#GuessTheAngle';
    storage_keys: { guess: string, game_state: string } = { guess: 'guess', game_state: 'gamestate' };
    answers: AnswerUnion = answers;
    website_emoji: string = '📐';
    guess_item: string = 'Angle';
    tolerance_percentage: number = 0;

    progress_ranks: { [string]: string } = {
        '0': 'Angle Amateur',
        '5': 'Degree Novice',
        '10': 'Bisector Beginner',
        '20': 'Angle Apprentice',
        '40': 'Protractor Padawan',
        '80': 'Radian Rookie',
        '150': 'Degree Detective',
        '200': 'Geometry Guru',
        '250': 'Angle Analyst',
        '300': 'Sector Scholar',
        '350': 'Protractor Professional',
        '400': 'Master of Measurement',
        '430': 'Elite Angle Expert',
        '470': 'Champion of Circles',
        '500': 'Prodigious Protractor Predictor',
        '530': 'Masterful Measurement Maven',
        '560': 'Superior Sector Strategist',
        '600': 'Grandmaster of Geometry',
        '630': 'Supreme Segment Sage',
        '700': 'Sovereign of Angles',
        '750': 'Grand Guru of Geometry',
        '800': 'Ultimate Angle Authority',
        '900': 'Eternal Expert of Estimation',
        '1000': 'Master of Magnitudes',
        '1100': 'Galactic Guru of Angles',
        '1300': 'Legendary Line Luminary',
    };

    // $FlowIgnore
    get_hint_text(imageIndex: number, puzzle: TGTAngleAnswer, settings: GameSettings): string {
        return '';
    }

    TEXTS: any = {
        modal: {
            AboutModal: {
                modalTexts: [
                    `Every day a new ${this.guess_item.toLowerCase()} is selected. Try to guess the ${this.guess_item.toLowerCase()}.`,
                    `You'll have ${
                        this.max_guesses
                    } guesses in total to help you guess the ${this.guess_item.toLowerCase()}. `,
                ],
            },
            HowToPlayModal: {
                modalTexts: [
                    `Enter what you think the ${this.guess_item.toLowerCase()} is and click submit.`,
                    `You get ${this.max_guesses} guesses in total.`,
                    `⬆️ means your guess is too low`,
                    `⬇️️ means your guess is too high`,
                ],
            },
        },
    };
}
