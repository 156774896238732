// ProgressBar.js
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { GUESS_IMAGE_FAILURE_COLOR, GUESS_IMAGE_NEUTRAL_COLOR, GUESS_IMAGE_SUCCESS_COLOR } from '../constants';

const ProgressBar = ({ itemStatus, baseUrl, currentItem }) => {
    const navigate = useNavigate();

    const handleItemClick = (itemId) => {
        navigate(`${baseUrl}/${itemId}`);
    };

    return (
        <div style={{ display: 'flex', width: '100%', height: '20px' }}>
            {itemStatus.map((status, index) => (
                <Tooltip
                    title={`Game #${index + 1} - ${
                        status === 's' ? 'Correct' : status === 'q' ? 'Unplayed' : 'Incorrect'
                    }${currentItem === index + 1 ? ' (Current)' : ''}`}
                    key={index}
                >
                    <div
                        onClick={() => handleItemClick(index + 1)}
                        style={{
                            flex: 1,
                            cursor: 'pointer',
                            backgroundColor:
                                status === 's'
                                    ? GUESS_IMAGE_SUCCESS_COLOR
                                    : status === 'f'
                                    ? GUESS_IMAGE_FAILURE_COLOR
                                    : GUESS_IMAGE_NEUTRAL_COLOR,
                            borderRight:
                                currentItem === index + 1
                                    ? '1px solid white'
                                    : index < itemStatus.length - 1
                                    ? '2px solid black'
                                    : '',
                            borderLeft: currentItem === index + 1 ? '1px solid white' : '',
                            position: 'relative',
                            // Add top highlight border for current item
                            borderTop: currentItem === index + 1 ? '1px solid white' : 'none',
                            // Add bottom highlight border for current item
                            borderBottom: currentItem === index + 1 ? '1px solid white' : 'none',
                            // Compensate for borders to avoid layout shift
                            // height: currentItem === index + 1 ? 'calc(100% - 6px)' : '100%',
                        }}
                    />
                </Tooltip>
            ))}
        </div>
    );
};

export default ProgressBar;
