// @flow
import type { PUZZLE_TYPES_TYPE } from '../../constants';
import type { TpreviousPuzzles } from '../../prevPuzzleUtils';
import type { AsyncThunk, ExtractActionType, PayloadAction } from '@reduxjs/toolkit';

import { PUZZLE_TYPES } from '../../constants';
import { isAnswerCorrect } from '../../globals';
import { calcStats, dummyStats, loadLocalStorageItem, saveLocalStorageItem } from '../../localStateUtils';
import { calcPreviousPuzzleResults, dummyPreviousPuzzleResults } from '../../prevPuzzleUtils';
import { settingsFactory } from '../../settings/settingsFactory';
import { API_BASE, post_wrapper } from './apiUtils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

type TGuessMap = {
    [string]: Array<{ guess: string, guess_num: number, puzzle_num: number }>,
};

export type StatFields = {
    played: number,
    won: number,
    winPercentage: number,
    currentStreak: number,
    maxStreak: number,
    currentRank: ?string,
    guesses: TGuessMap,
    previousPuzzles: TpreviousPuzzles,
    numWinsAtGuess: { [string]: number },
    isSyncing: boolean,
};

export type StatState = {
    [PUZZLE_TYPES_TYPE]: StatFields,
};

declare var __GTG_BUILD__: boolean;
declare var __GTA_BUILD__: boolean;
declare var __GTB_BUILD__: boolean;
declare var __GTM_BUILD__: boolean;
declare var __GTL_BUILD__: boolean;
declare var __GTH_BUILD__: boolean;
declare var __GTANGLE_BUILD__: boolean;

function calcInitialState(): StatState {
    let gtgInitialStats = dummyStats();
    let gtgcInitialStats = dummyStats();
    let gtaInitialStats = dummyStats();
    let gtacInitialStats = dummyStats();
    let gtbInitialStats = dummyStats();
    let gtmInitialStats = dummyStats();
    let gtmcInitialStats = dummyStats();
    let gtlInitialStats = dummyStats();
    let gthInitialStats = dummyStats();
    let gtangleInitialStats = dummyStats();

    let gtgPreviousPuzzleResults = dummyPreviousPuzzleResults();
    let gtgcPreviousPuzzleResults = dummyPreviousPuzzleResults();
    let gtaPreviousPuzzleResults = dummyPreviousPuzzleResults();
    let gtacPreviousPuzzleResults = dummyPreviousPuzzleResults();
    let gtbPreviousPuzzleResults = dummyPreviousPuzzleResults();
    let gtmPreviousPuzzleResults = dummyPreviousPuzzleResults();
    let gtmcPreviousPuzzleResults = dummyPreviousPuzzleResults();
    let gtlPreviousPuzzleResults = dummyPreviousPuzzleResults();
    let gthPreviousPuzzleResults = dummyPreviousPuzzleResults();
    let gtanglePreviousPuzzleResults = dummyPreviousPuzzleResults();

    let progressRanks = {};

    if (__GTG_BUILD__) {
        console.log('StatSlice GTG BUILD');
        const gtgSettings = settingsFactory(PUZZLE_TYPES.GTG);
        const gtgcSettings = settingsFactory(PUZZLE_TYPES.GTGC);

        gtgInitialStats = calcStats(gtgSettings);
        gtgcInitialStats = calcStats(gtgcSettings);
        gtgPreviousPuzzleResults = calcPreviousPuzzleResults(gtgSettings);
        gtgcPreviousPuzzleResults = calcPreviousPuzzleResults(gtgcSettings);
        progressRanks = gtgSettings.progress_ranks;
    }

    if (__GTA_BUILD__) {
        console.log('StatSlice GTA BUILD');
        const gtaSettings = settingsFactory(PUZZLE_TYPES.GTA);
        const gtacSettings = settingsFactory(PUZZLE_TYPES.GTAC);
        gtaInitialStats = calcStats(gtaSettings);
        gtaPreviousPuzzleResults = calcPreviousPuzzleResults(gtaSettings);
        progressRanks = gtaSettings.progress_ranks;

        gtacInitialStats = calcStats(gtacSettings);
        gtacPreviousPuzzleResults = calcPreviousPuzzleResults(gtacSettings);
    }

    if (__GTB_BUILD__) {
        console.log('StatSlice GTB BUILD');
        const gtbSettings = settingsFactory(PUZZLE_TYPES.GTB);
        gtbInitialStats = calcStats(gtbSettings);
        gtbPreviousPuzzleResults = calcPreviousPuzzleResults(gtbSettings);
        progressRanks = gtbSettings.progress_ranks;
    }

    if (__GTM_BUILD__) {
        console.log('StatSlice GTM BUILD');
        const gtmSettings = settingsFactory(PUZZLE_TYPES.GTM);
        const gtmcSettings = settingsFactory(PUZZLE_TYPES.GTMC);
        gtmInitialStats = calcStats(gtmSettings);
        gtmPreviousPuzzleResults = calcPreviousPuzzleResults(gtmSettings);
        progressRanks = gtmSettings.progress_ranks;

        gtmcInitialStats = calcStats(gtmcSettings);
        gtmcPreviousPuzzleResults = calcPreviousPuzzleResults(gtmcSettings);
    }

    if (__GTL_BUILD__) {
        console.log('StatSlice GTL BUILD');
        const gtlSettings = settingsFactory(PUZZLE_TYPES.GTL);
        gtlInitialStats = calcStats(gtlSettings);
        gtlPreviousPuzzleResults = calcPreviousPuzzleResults(gtlSettings);
        progressRanks = gtlSettings.progress_ranks;
    }

    if (__GTH_BUILD__) {
        console.log('StatSlice GTH BUILD');
        const gthSettings = settingsFactory(PUZZLE_TYPES.GTH);
        gthInitialStats = calcStats(gthSettings);
        gthPreviousPuzzleResults = calcPreviousPuzzleResults(gthSettings);
        progressRanks = gthSettings.progress_ranks;
    }
    if (__GTANGLE_BUILD__) {
        console.log('StatSlice GTAngle BUILD');
        const gtangleSettings = settingsFactory(PUZZLE_TYPES.GTANGLE);
        gtangleInitialStats = calcStats(gtangleSettings);
        gtanglePreviousPuzzleResults = calcPreviousPuzzleResults(gtangleSettings);
        progressRanks = gtangleSettings.progress_ranks;
    }

    return {
        [PUZZLE_TYPES.GTG]: {
            played: gtgInitialStats.totalPlayed,
            won: gtgInitialStats.totalWon,
            winPercentage: Math.floor((gtgInitialStats.totalWon / gtgInitialStats.totalPlayed) * 100),
            currentStreak: gtgInitialStats.currentStreak,
            maxStreak: gtgInitialStats.maxStreak,
            currentRank: getCurrentRank(gtgInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gtgPreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gtgPreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
        [PUZZLE_TYPES.GTGC]: {
            played: gtgcInitialStats.totalPlayed,
            won: gtgcInitialStats.totalWon,
            winPercentage: Math.floor((gtgcInitialStats.totalWon / gtgcInitialStats.totalPlayed) * 100),
            currentStreak: gtgcInitialStats.currentStreak,
            maxStreak: gtgcInitialStats.maxStreak,
            currentRank: getCurrentRank(gtgcInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gtgcPreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gtgcPreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
        [PUZZLE_TYPES.GTA]: {
            played: gtaInitialStats.totalPlayed,
            won: gtaInitialStats.totalWon,
            winPercentage: Math.floor((gtaInitialStats.totalWon / gtaInitialStats.totalPlayed) * 100),
            currentStreak: gtaInitialStats.currentStreak,
            maxStreak: gtaInitialStats.maxStreak,
            currentRank: getCurrentRank(gtaInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gtaPreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gtaPreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
        [PUZZLE_TYPES.GTAC]: {
            played: gtacInitialStats.totalPlayed,
            won: gtacInitialStats.totalWon,
            winPercentage: Math.floor((gtacInitialStats.totalWon / gtacInitialStats.totalPlayed) * 100),
            currentStreak: gtacInitialStats.currentStreak,
            maxStreak: gtacInitialStats.maxStreak,
            currentRank: getCurrentRank(gtacInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gtacPreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gtacPreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
        [PUZZLE_TYPES.GTB]: {
            played: gtbInitialStats.totalPlayed,
            won: gtbInitialStats.totalWon,
            winPercentage: Math.floor((gtbInitialStats.totalWon / gtbInitialStats.totalPlayed) * 100),
            currentStreak: gtbInitialStats.currentStreak,
            maxStreak: gtbInitialStats.maxStreak,
            currentRank: getCurrentRank(gtbInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gtbPreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gtbPreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
        [PUZZLE_TYPES.GTM]: {
            played: gtmInitialStats.totalPlayed,
            won: gtmInitialStats.totalWon,
            winPercentage: Math.floor((gtmInitialStats.totalWon / gtmInitialStats.totalPlayed) * 100),
            currentStreak: gtmInitialStats.currentStreak,
            maxStreak: gtmInitialStats.maxStreak,
            currentRank: getCurrentRank(gtmInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gtmPreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gtmPreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
        [PUZZLE_TYPES.GTMC]: {
            played: gtmcInitialStats.totalPlayed,
            won: gtmcInitialStats.totalWon,
            winPercentage: Math.floor((gtmcInitialStats.totalWon / gtmcInitialStats.totalPlayed) * 100),
            currentStreak: gtmcInitialStats.currentStreak,
            maxStreak: gtmcInitialStats.maxStreak,
            currentRank: getCurrentRank(gtmcInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gtmcPreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gtmcPreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
        [PUZZLE_TYPES.GTL]: {
            played: gtlInitialStats.totalPlayed,
            won: gtlInitialStats.totalWon,
            winPercentage: Math.floor((gtlInitialStats.totalWon / gtlInitialStats.totalPlayed) * 100),
            currentStreak: gtlInitialStats.currentStreak,
            maxStreak: gtlInitialStats.maxStreak,
            currentRank: getCurrentRank(gtlInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gtlPreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gtlPreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
        [PUZZLE_TYPES.GTH]: {
            played: gthInitialStats.totalPlayed,
            won: gthInitialStats.totalWon,
            winPercentage: Math.floor((gthInitialStats.totalWon / gthInitialStats.totalPlayed) * 100),
            currentStreak: gthInitialStats.currentStreak,
            maxStreak: gthInitialStats.maxStreak,
            currentRank: getCurrentRank(gthInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gthPreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gthPreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
        [PUZZLE_TYPES.GTANGLE]: {
            played: gtangleInitialStats.totalPlayed,
            won: gtangleInitialStats.totalWon,
            winPercentage: Math.floor((gtangleInitialStats.totalWon / gtangleInitialStats.totalPlayed) * 100),
            currentStreak: gtangleInitialStats.currentStreak,
            maxStreak: gtangleInitialStats.maxStreak,
            currentRank: getCurrentRank(gtangleInitialStats.totalWon, progressRanks),
            guesses: {},
            previousPuzzles: gtanglePreviousPuzzleResults.previousPuzzles,
            numWinsAtGuess: gtanglePreviousPuzzleResults.numWinsAtGuess,
            isSyncing: false,
        },
    };
}

function getCurrentRank(won: number, progress_ranks: Object) {
    let titleKeys = Object.keys(progress_ranks).map(Number); // Convert string keys to numbers
    titleKeys.sort((a, b) => b - a); // Sort the keys in descending order

    for (let i = 0; i < titleKeys.length; i++) {
        if (won >= titleKeys[i]) {
            return progress_ranks[titleKeys[i]];
        }
    }

    return null; // Returns null if no title found
}

const uploadDiffGuesses = async (existingGuesses: TGuessesPayload) => {
    let uploadGuesses: TGuessMap = {};
    const puzzleType = existingGuesses.puzzleType;
    const settings = settingsFactory(puzzleType);

    const maxDay = settings.num_days_from_start_date();
    const maxNumGuesses = settings.max_guesses;
    const gameStateKey = settings.storage_keys.game_state;
    const guessKey = settings.storage_keys.guess;

    for (let i = 1; i <= maxDay; i++) {
        if (loadLocalStorageItem(i, gameStateKey)) {
            const existingGuessesArr = existingGuesses.guesses[i.toString()];
            let guesses = [];
            for (let j = 1; j <= maxNumGuesses; j++) {
                const matchingGuessNumExists =
                    existingGuessesArr == null
                        ? false
                        : existingGuessesArr.filter((guess) => guess.guess_num === j).length > 0;
                const guess = loadLocalStorageItem(i, `${guessKey}${j}`);
                if (guess && !matchingGuessNumExists) {
                    guesses.push({ guess: guess, guess_num: j, puzzle_num: i });
                }
            }
            if (guesses.length > 0) {
                uploadGuesses[i.toString()] = guesses;
            }
        }
    }
    // If there's no different gueses, then we don't need to sync anything
    if (Object.keys(uploadGuesses).length > 0) {
        await post_wrapper(`${API_BASE}/api/sync_member_guesses/`, {
            data: { guesses: uploadGuesses, puzzle_type: puzzleType },
        });
    }
};

type TFetchGuessesPayload = {
    puzzleType: PUZZLE_TYPES_TYPE,
};

const fetchGuessesThunk: AsyncThunk<string, TFetchGuessesPayload, any> = createAsyncThunk(
    'stats/fetchGuesses',
    async (payload: TFetchGuessesPayload, thunkAPI) => {
        const state = thunkAPI.getState();
        const isSyncing = state.stats[payload.puzzleType].isSyncing;
        if (!isSyncing) {
            await thunkAPI.dispatch(setIsSyncing({ isSyncing: true, puzzleType: payload.puzzleType }));
            // Load the stored guesses from the backend
            const response = await axios.get(`${API_BASE}/api/fetch_guesses`, {
                params: { puzzle_type: payload.puzzleType },
            });
            await uploadDiffGuesses(response.data);
            thunkAPI.dispatch(setGuesses(response.data));
            await thunkAPI.dispatch(setIsSyncing({ isSyncing: false, puzzleType: payload.puzzleType }));
        }
    },
);

type TGuessesPayload = {
    guesses: { [string]: Array<{ guess_num: number, guess: string, puzzle_num: number }> },
    puzzleType: PUZZLE_TYPES_TYPE,
};

type TStatsPayload = {
    played: number,
    won: number,
    currentStreak: number,
    maxStreak: number,
    puzzleType: PUZZLE_TYPES_TYPE,
};

type TpreviousPuzzlesPayload = {
    previousPuzzles: TpreviousPuzzles,
    puzzleType: PUZZLE_TYPES_TYPE,
};

type TIsSyncingPayload = {
    isSyncing: boolean,
    puzzleType: PUZZLE_TYPES_TYPE,
};

// $FlowIgnore
const statsSlice = createSlice({
    name: 'stats',
    // $FlowIgnore
    initialState: () => {
        return calcInitialState();
    },
    reducers: {
        setStats: (state: StatState, action: PayloadAction<TStatsPayload>) => {
            const { played, won, currentStreak, maxStreak, puzzleType } = action.payload;

            const settings = settingsFactory(puzzleType);
            let gameState = state[puzzleType];
            gameState.played = played;
            gameState.won = won;
            gameState.currentStreak = currentStreak;
            gameState.maxStreak = maxStreak;
            gameState.winPercentage = Math.floor((won / played) * 100);
            gameState.currentRank = getCurrentRank(won, settings.progress_ranks);
            console.log(`${puzzleType} stats: `, gameState.currentRank);
        },
        setPreviousPuzzles: (state: StatState, action: PayloadAction<TpreviousPuzzlesPayload>) => {
            let gameState = state[action.payload.puzzleType];
            gameState.previousPuzzles = action.payload.previousPuzzles;
            let numWinsAtGuess: { [string]: number } = { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0 };
            gameState.previousPuzzles.forEach((puzzle) => {
                if (!puzzle || !(puzzle.result === 'success')) {
                    return;
                }
                const winIndex = puzzle.cubes.indexOf('s') + 1;
                numWinsAtGuess[winIndex.toString()] += 1;
            });
            gameState.numWinsAtGuess = numWinsAtGuess;
        },
        setGuesses: (state: StatState, action: PayloadAction<TGuessesPayload>) => {
            let gameState = state[action.payload.puzzleType];
            gameState.guesses = action.payload.guesses;

            const settings = settingsFactory(action.payload.puzzleType);
            const gameStateKey = settings.storage_keys.game_state;
            const guessKey = settings.storage_keys.guess;
            const gameAnswers = settings.answers;

            for (const key in gameState.guesses) {
                const currentGameState = loadLocalStorageItem(key, gameStateKey);
                // If the local state is already a win/lose then skip it
                if (currentGameState !== 'win' && currentGameState !== 'lose') {
                    const numGuesses = gameState.guesses[key].length;
                    let gameStatus = 'playing';
                    for (const guess of gameState.guesses[key]) {
                        let currentGuess = loadLocalStorageItem(key, `${guessKey}${guess.guess_num}`);
                        if (currentGuess === null) {
                            saveLocalStorageItem(key, `${guessKey}${guess.guess_num}`, guess.guess);
                            currentGuess = guess.guess;
                        }
                        if (currentGuess && isAnswerCorrect(currentGuess, gameAnswers[key.toString()].answers)) {
                            gameStatus = 'win';
                        }
                    }
                    if (gameStatus === 'playing' && numGuesses >= settings.max_guesses) {
                        gameStatus = 'lose';
                    }
                    saveLocalStorageItem(key, gameStateKey, gameStatus);
                }
            }

            const updatedStats = calcStats(settings);

            gameState.played = updatedStats.totalPlayed;
            gameState.won = updatedStats.totalWon;
            gameState.winPercentage = Math.floor((updatedStats.totalWon / updatedStats.totalPlayed) * 100);
            gameState.currentStreak = updatedStats.currentStreak;
            gameState.maxStreak = updatedStats.maxStreak;
            gameState.currentRank = getCurrentRank(updatedStats.totalWon, settings.progress_ranks);

            // Recalc the previous puzzles with the synced data
            const previousPuzzleResults = calcPreviousPuzzleResults(settings);
            gameState.previousPuzzles = previousPuzzleResults.previousPuzzles;
            gameState.numWinsAtGuess = previousPuzzleResults.numWinsAtGuess;
        },
        setIsSyncing: (state: StatState, action: PayloadAction<TIsSyncingPayload>) => {
            let gameState = state[action.payload.puzzleType];
            gameState.isSyncing = action.payload.isSyncing;
        },
    },
});

// For some reason flow won't type this in other files unless i explicitly add the type
//  do let's do that to be safe
const setStats: (TStatsPayload) => PayloadAction<TStatsPayload> = statsSlice.actions.setStats;
const setGuesses: (TGuessesPayload) => PayloadAction<TGuessesPayload> = statsSlice.actions.setGuesses;
const setPreviousPuzzles: (TpreviousPuzzlesPayload) => PayloadAction<TpreviousPuzzlesPayload> =
    statsSlice.actions.setPreviousPuzzles;
const setIsSyncing: (TIsSyncingPayload) => PayloadAction<TIsSyncingPayload> = statsSlice.actions.setIsSyncing;

export type TStatActions = $ObjMap<typeof statsSlice.actions.setStats, ExtractActionType>;
export { setStats, fetchGuessesThunk, setPreviousPuzzles };
export default statsSlice;
