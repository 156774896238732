import React, { useState, useMemo } from 'react';

const Hint = ({ hintText, customStyle, quest = false }) => {
    const [showHints, setShowHint] = useState(quest ? false : true);
    
    const hintText_ = useMemo(() => {
        if (quest) {
            return showHints ? hintText : 'Show Hint?';
        }
        return hintText;
    }, [hintText, quest, showHints])

    const handleClick = () => {
        setShowHint(!showHints);
    };
    const style = {
        minWidth: '100px', // todo test out if minwidth is needed
        width: 'auto',
        display: hintText ? 'block' : 'none',
        ...customStyle,
    };

    const visible = quest || showHints;

    return (
        <div className={`guess-hint`} onClick={handleClick} style={style}>
            <span style={{ visibility: visible ? 'visible' : 'hidden' }}>
                {hintText_}
            </span>
        </div>
    );
};

export default Hint;
