
import { useMemo } from 'react';
import { PUZZLE_TYPES } from '../../..//constants';
import { calcQuestProgressBar } from '../../../puzzleUtils';
import { answers as questAnswers } from '../../../puzzles/gtgq_puzzles';

export const useQuests = (settings) => {
    const quests = useMemo(() =>  {
        const isQuest = settings.puzzle_type === PUZZLE_TYPES.GTGQ;
        if (isQuest) {
            return Object.entries(questAnswers).map(([puzzleId, quest], index) => {
                const totalItems = quest.puzzles.length; // Simplified - now we can just get array length
                const questState = calcQuestProgressBar(settings, totalItems, puzzleId);
                const completed = questState.every((item) => item === 's');
                return {
                    id: index + 1,
                    title: quest.title,
                    subscriber_only: quest.subscriber_only,
                    description: quest.description,
                    completed: completed,
                };
            });
        }
        return undefined;
    }, [settings]);

    return quests;
}


