// @flow
import type { PUZZLE_TYPES_TYPE } from '../../constants';
import type { AsyncThunk, ExtractActionType, PayloadAction } from '@reduxjs/toolkit';

import { PUZZLE_TYPES } from '../../constants';
import { $NotNull } from '../../globals';
import { clearJwt, clearUser, loadJwt, loadUser, storeJwt, storeUser } from '../../localStateUtils';
import { settingsFactory } from '../../settings/settingsFactory';
import { API_BASE, post_wrapper, updateCSRF } from './apiUtils';
import { fetchGuessesThunk } from './statSlice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

export type TSubmitEmailPayload = {
    email: string,
    puzzleType: PUZZLE_TYPES_TYPE,
};

type TPollLoginPayload = {
    email: string,
    polling_uuid: string,
};

export type TValidateEmailPayload = {
    email: string,
    uuid: string,
};

declare var __GTG_BUILD__: boolean;
declare var __GTA_BUILD__: boolean;
declare var __GTB_BUILD__: boolean;
declare var __GTM_BUILD__: boolean;
declare var __GTL_BUILD__: boolean;
declare var __GTH_BUILD__: boolean;
declare var __GTANGLE_BUILD__: boolean;

const POLLING_WAIT_TIME = 10 * 1000; // 10 seconds
const MAX_POLLING_TRIES = (60 * 30 * 1000) / POLLING_WAIT_TIME; // 30 minutes

// Function to remove an element by id
const removeElementById = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.remove();
    }
};

const disableAds = () => {
    window.freestar.queue.push(window.freestar.deleteStickyFooter);
    window.freestar.queue.push(window.freestar.deleteVideo);
    window.freestar.queue.push(window.freestar.deleteStandAloneVideo);
    window.freestar.queue.push(window.freestar.deleteSidewall);
    window.freestar.queue.push(window.freestar.deletePushdown);
    window.freestar.queue.push(window.freestar.deleteAdSlots);

    // Create an observer instance
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes) {
                mutation.addedNodes.forEach((node) => {
                    if (node instanceof HTMLElement) {
                        if (node.id === 'fs-sticky-footer') {
                            removeElementById('fs-sticky-footer');
                        }
                        if (node.id === 'fs-player-container') {
                            removeElementById('fs-player-container');
                        }
                        if (node.id === 'primisPlayerContainerDiv') {
                            removeElementById('primisPlayerContainerDiv');
                        }
                        if (node.classList.contains('ima-ad-container')) {
                            node.remove();
                        }
                        if (node.classList.contains('orp-player-wrapper')) {
                            node.remove();
                        }
                        if (node.classList.contains('orp-light-player-wrapper')) {
                            node.remove();
                        }
                        if (node.classList.contains('primiss')) {
                            node.remove();
                        }
                        if (node.classList.contains('primisslate')) {
                            node.remove();
                        }
                        if (node.id === 'sekindoVpaidIframe') {
                            node.remove();
                        }
                        if (node.id === 'primisPlayerContainerDiv') {
                            node.remove();
                        }
                        if (node.classList.contains('primisslate')) {
                            node.remove();
                        }
                    }
                });
            }
        });
    });

    // Configuration of the observer
    const config = { childList: true, subtree: false };

    // Pass in the target node and the observer options
    observer.observe($NotNull(document.body), config);

    // remove all preconnect links to ad networks. not sure if this will do anything useful
    // document.querySelectorAll('link').forEach(function (link) {
    //     if (
    //         (link.rel === 'preconnect' &&
    //             (link.href.startsWith('https://a.pub.network/') ||
    //                 link.href.startsWith('https://b.pub.network/') ||
    //                 link.href.startsWith('https://c.pub.network/') ||
    //                 link.href.startsWith('https://d.pub.network/') ||
    //                 link.href.startsWith('https://c.amazon-adsystem.com') ||
    //                 link.href.startsWith('https://s.amazon-adsystem.com') ||
    //                 link.href.startsWith('https://secure.quantserve.com/') ||
    //                 link.href.startsWith('https://rules.quantcount.com/') ||
    //                 link.href.startsWith('https://pixel.quantserve.com/') ||
    //                 link.href.startsWith('https://cmp.quantcast.com/') ||
    //                 link.href.startsWith('https://btloader.com/') ||
    //                 link.href.startsWith('https://api.btloader.com/') ||
    //                 link.href.startsWith('https://confiant-integrations.global.ssl.fastly.net'))) ||
    //         (link.rel === 'stylesheet' && link.href === 'https://a.pub.network/core/pubfig/cls.css')
    //     ) {
    //         $NotNull(link.parentNode).removeChild(link);
    //     }
    // });

    // Delete all ad slots on a page
    window.freestar.queue.push(function () {
        window.freestar.deleteAdSlots();
    });

    window.freestar.config.disabledProducts = {
        video: true,
        superflex: true,
        videoAdhesion: true,
        stickyFooter: true,
        dynamicAds: true,
        slidingUnit: true,
        googleInterstitial: true,
    };

    // If the standalone ad loads slow, then the above functions might not remove it.
    // To be safe, lets just delete the div
    removeElementById('guessthe-video');
    removeElementById('primisPlayerContainerDiv');
    removeElementById('fs-player-container');
    removeElementById('fs-sticky-footer');
    removeElementById('fs-sticky-popup-container');
    // stats page
    removeElementById('guessthe_stats_banner');
    //screenshots page (ogi)
    removeElementById('guessthe-game_incontent_1');
    removeElementById('guessthe-game_incontent_2');
    removeElementById('guessthe-game_incontent_3');
    removeElementById('pmLink');
    removeElementById('guessthe-game_sidewall-left');
    removeElementById('guessthe-game_sidewall-right');

    const adsBanner = document.getElementsByClassName('ads-banner')[0];
    if (adsBanner) {
        adsBanner.remove();
    }
    const stickyAds = document.getElementsByClassName('stickyads')[0];
    if (stickyAds) {
        stickyAds.remove();
    }
    const imprtntCnt = document.getElementsByClassName('imprtnt-cnt')[0];
    if (imprtntCnt) {
        imprtntCnt.remove();
    }
    // technically there are 2 of these so if get complaints may need to remove both
    const fs_sidewall = document.getElementsByClassName('fs-sidewall-container')[0];
    if (fs_sidewall) {
        fs_sidewall.remove();
    }

    // find all kofi element and delete them
    const kofiClass = document.getElementsByClassName('kufi-button');
    // causes flash so commented out for now
    // while (kofiClass[0]) {
    //     kofiClass[0].remove();
    // }
    // change all kofiClass elements to visibility hidden
    for (let i = 0; i < kofiClass.length; i++) {
        kofiClass[i].style.visibility = 'hidden';
    }

    const blockedAds = document.getElementsByClassName('bl-a');
    if (blockedAds[0]) {
        blockedAds[0].remove();
    }

    // should disable google ads
    window.adsbygoogle = {
        enabled: true,
        loaded: true,
        push: function () {
            return;
        },
        google_ad_client: '',
        enable_page_level_ads: true,
    };
    // should disable google ads
    const googleAds = document.getElementsByClassName('adsbygoogle');
    for (let i = 0; i < googleAds.length; i++) {
        googleAds[i].remove();
    }
};

const loadUserThunk: AsyncThunk<string, null, any> = createAsyncThunk('user/loadUser', async (payload, thunkAPI) => {
    const response = await axios.get(`${API_BASE}/api/load_user/`);
    thunkAPI.dispatch(setUser(response.data.user));
    if (response.data.user) {
        disableAds();
        storeUser(response.data.user);
        if (__GTG_BUILD__) {
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTG }));
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTGC }));
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTGQ }));
        }
        if (__GTA_BUILD__) {
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTA }));
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTAC }));
        }
        if (__GTB_BUILD__) {
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTB }));
        }
        if (__GTM_BUILD__) {
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTM }));
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTMC }));
        }
        if (__GTL_BUILD__) {
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTL }));
        }
        if (__GTH_BUILD__) {
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTH }));
        }
        if (__GTANGLE_BUILD__) {
            thunkAPI.dispatch(fetchGuessesThunk({ puzzleType: PUZZLE_TYPES.GTANGLE }));
        }
    } else {
        clearUser();
    }
});

const pollLoginThunk: AsyncThunk<string, TPollLoginPayload, any> = createAsyncThunk(
    'user/pollLogin',
    async (payload: TPollLoginPayload, thunkAPI) => {
        thunkAPI.dispatch(setIsPolling(true));
        let numTries = 0;
        while (numTries < MAX_POLLING_TRIES) {
            try {
                const response = await axios.get(`${API_BASE}/api/login_poll/`, {
                    params: {
                        email: payload.email,
                        polling_uuid: payload.polling_uuid,
                    },
                });
                // CSRF tokens rotate after login, so we need to update them based on the response cookies
                updateCSRF();

                updateUserToken(response.data.token);

                thunkAPI.dispatch(loadUserThunk(null));
                thunkAPI.dispatch(setLoginStatus(`Successfully logged into ${payload.email}`));
                thunkAPI.dispatch(setIsPolling(false));
                break;
            } catch (err) {
                const statusCode = err.response.status;
                if (statusCode === 403) {
                    // The server is waiting for the email to be verified.  continue loops
                    numTries += 1;
                } else {
                    // Otherwise there's a non-retryable error.  So break out
                    console.log(err);
                    thunkAPI.dispatch(
                        setLoginStatus(
                            `Error: ${err.response.data.message}. If you believe this is incorrect, please reach out to me through email or social media.`,
                        ),
                    );
                    thunkAPI.dispatch(setIsPolling(false));
                    break;
                }
            }

            for (let i = 0; i < POLLING_WAIT_TIME / 1000; i++) {
                await new Promise((r) => setTimeout(r, 1000));
                thunkAPI.dispatch(
                    setLoginStatus(
                        `Email sent to ${payload.email}.  Click the link there to log in.  Checking again in ${
                            POLLING_WAIT_TIME / 1000 - i
                        }.`,
                    ),
                );
            }
        }
    },
);

//called when user toggles email notifications or changes hour
export const updateEmailNotificationToggleThunk = createAsyncThunk(
    'user/updateEmailToggle',
    async ({ emailToggleState, hour, puzzleType, userEmail }, { rejectWithValue }) => {
        console.log(emailToggleState, hour, puzzleType, userEmail);
        // send as utc

        // Convert local hour to UTC
        const today = new Date();
        today.setHours(hour, 0, 0, 0);
        const utcHour = today.getUTCHours();

        try {
            const response = await axios.post(`${API_BASE}/api/update_email_notifications/`, {
                emailToggle: emailToggleState,
                hour: utcHour,
                puzzleType: puzzleType,
                email: userEmail,
            });
            return response.data;
        } catch (error) {
            console.log("Error: Update email toggle didn't work");
            return rejectWithValue(error.response.data);
        }
    },
);

const submitEmailThunk: AsyncThunk<string, TSubmitEmailPayload, any> = createAsyncThunk(
    'user/submitEmail',
    async (payload: TSubmitEmailPayload, { dispatch }) => {
        try {
            const response = await post_wrapper(`${API_BASE}/api/submit_login/`, {
                email: payload.email,
                abbr: settingsFactory(payload.puzzleType).parent_abbr,
            });
            console.log(response.data.polling_uuid);
            dispatch(pollLoginThunk({ email: payload.email, polling_uuid: response.data.polling_uuid }));
        } catch (err) {
            console.log(err);
            const errorMessage = err.response.data.message;
            console.log(errorMessage);
            dispatch(
                setLoginStatus(
                    `Error: ${errorMessage}. If you haven't signed up, please click the Ko-Fi button above to register! If you believe this is incorrect, please reach out through email.  `,
                ),
            );
            clearJwt();
            delete axios.defaults.headers.common['Authorization'];
        }
    },
);

const updateUserToken = (token: string) => {
    // Set the header for all requests
    axios.defaults.headers.common['Authorization'] = token;

    // Store token for later requests
    storeJwt(token);
};

const jwt_token = loadJwt();
if (jwt_token) {
    axios.defaults.headers.common['Authorization'] = jwt_token;
}

const validateEmailThunk: AsyncThunk<string, TValidateEmailPayload, any> = createAsyncThunk(
    'user/validateEmail',
    async (payload: TValidateEmailPayload, { dispatch }) => {
        try {
            const response = await axios.get(`${API_BASE}/api/email_callback/`, {
                params: {
                    email: payload.email,
                    uuid: payload.uuid,
                },
            });
            // CSRF tokens rotate after login, so we need to update them based on the response cookies
            updateCSRF();

            updateUserToken(response.data.token);

            dispatch(loadUserThunk(null));
        } catch (err) {
            console.log(err);
            const errorMessage = err.response.data.message;
            console.log(errorMessage);
            dispatch(setEmailCallbackError(errorMessage));
            clearJwt();
            delete axios.defaults.headers.common['Authorization'];
        }
    },
);

export type User = {
    email: string,
    expiration: string,
    uuid: string,
    member_tier: string,
};

export type UserState = {
    isPolling: boolean,
    emailCallbackError: ?string,
    user: ?User,
    loginStatus: ?string,
};
const initialState: UserState = {
    isPolling: false,
    emailCallbackError: null,
    user: loadUser(),
    loginStatus: null,
};

// $FlowIgnore
const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: (state: UserState, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.emailCallbackError = null;
        },
        setEmailCallbackError: (state: UserState, action: PayloadAction<string>) => {
            state.emailCallbackError = action.payload;
        },
        setLoginStatus: (state: UserState, action: PayloadAction<string>) => {
            state.loginStatus = action.payload;
        },
        setIsPolling: (state: UserState, action: PayloadAction<boolean>) => {
            state.isPolling = action.payload;
        },
    },
});

const setEmailCallbackError: (string) => PayloadAction<string> = userSlice.actions.setEmailCallbackError;
const setUser: (User) => PayloadAction<User> = userSlice.actions.setUser;
const setLoginStatus: (string) => PayloadAction<string> = userSlice.actions.setLoginStatus;
const setIsPolling: (boolean) => PayloadAction<boolean> = userSlice.actions.setIsPolling;

export type TUserActions = $ObjMap<
    // $FlowIgnore - Can't figure out how to type these
    typeof submitEmailThunk.call | typeof userSlice.actions.setEmailCallbackError,
    ExtractActionType,
>;
export { submitEmailThunk, validateEmailThunk, loadUserThunk, setLoginStatus };
export default userSlice;
