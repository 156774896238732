// @flow
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { TDispatch } from '../store/store';

import { KOFI_URL, TIERS } from '../constants';
import uxSlice, {
    setRankToggle,
    setFranchiseToggle,
    setLinkToPuzzleToggle,
    setEmailNotificationToggle,
    setEmailNotificationHourSetting,
} from '../store/slices/uxSlice';
import { setLoginStatus, submitEmailThunk, updateEmailNotificationToggleThunk } from '../store/slices/userSlice';
import type { State } from '../store/types';
import Switch from 'react-switch';
import { validateEmailThunk } from '../store/slices/userSlice';
import strftime from 'strftime';
import { useNavigate } from 'react-router-dom';
import { $NotNull } from '../globals';
import versionData from '../version.json';
import { useSettings } from '../settings/useSettings';
import SubscribeOnKofiButton from './SubscribeOnKofiButton';

type TProps = {
    fromEmailCallback?: boolean,
};

const Setting = ({ fromEmailCallback = false }: TProps): React$Element<'div'> => {
    const settingsStyle = {
        color: '#eee',
        borderRadius: '5px',
        top: '100px',
        left: '0',
        right: '0',
        margin: '0 auto',
        padding: '12px',
        backgroundColor: 'rgba(51, 65, 85, 1)',
        border: '1px solid #eee',
        width: 'auto !important',
        maxWidth: '500px',
    };

    const hStyle = {
        padding: 0,
        margin: 0,
    };

    const h4Style = {
        fontSize: '17px',
        marginBottom: '10px',
    };

    const emailInput = {
        fontSize: '18px',
        width: '100%',
        marginBottom: '5px',
        paddingTop: '5px',
        paddingBottom: '5px',
    };

    const loginButton = {
        backgroundColor: 'rgba(6, 78, 59, 1)',
        fontSize: '18px',
        width: '100%',
        paddingTop: '5px',
        paddingBottom: '5px',
        position: 'relative',
    };

    const headerStyle = {
        display: 'flex', // Enables flexbox
        justifyContent: 'space-between', // Aligns children at both ends
        alignItems: 'center', // Aligns children vertically in the center
        marginBottom: '20px', // Optional: Adds some space below the header
    };

    // Add this style object with the existing styles
    const timePickerStyle = {
        fontSize: '16px',
        padding: '5px',
        marginLeft: '10px',
        backgroundColor: 'rgba(51, 65, 85, 1)',
        color: '#eee',
        border: '1px solid #eee',
        borderRadius: '3px',
    };

    const dispatch: TDispatch = useDispatch();
    const navigate = useNavigate();
    const [version, setVersion] = useState('');
    const settings = useSettings();

    const userState = useSelector((state: State) => state.user);
    const emailNotificationToggle = useSelector((state: State) => state.ux.emailNotificationToggle);
    const emailNotificationHourSetting = useSelector((state: State) => state.ux.emailNotificationHourSetting);
    const rankToggle = useSelector((state: State) => state.ux.rankToggle);
    const franchiseToggle = useSelector((state: State) => state.ux.franchiseToggle);
    const linkToPuzzleToggle = useSelector((state: State) => state.ux.linkToPuzzleToggle);

    const [copied, setCopied] = useState(false); // State to manage copy status

    // Determine the class name based on userState.user
    const frosted = userState.user ? '' : 'frosted';

    const handleEmailNotificationToggleChange = (checked: boolean) => {
        //Called when the notification email switch is toggled
        const puzzleType = settings.puzzle_type;
        const userEmail = userState.user.email;
        console.log('emailNotificationToggle', checked, emailNotificationHourSetting, puzzleType, userEmail);

        dispatch(setEmailNotificationToggle(checked));
        dispatch(
            updateEmailNotificationToggleThunk({
                emailToggleState: checked,
                hour: emailNotificationHourSetting,
                puzzleType: puzzleType,
                userEmail: userEmail,
            }),
        );
    };

    const handleHourChange = (event: SyntheticEvent<HTMLSelectElement>) => {
        const newHour = parseInt(event.currentTarget.value, 10);
        dispatch(setEmailNotificationHourSetting(newHour));
        dispatch(
            updateEmailNotificationToggleThunk({
                emailToggleState: true,
                hour: newHour,
                puzzleType: settings.puzzle_type,
                userEmail: userState.user.email,
            }),
        );
    };

    const handleRankSwitchChange = (checked: boolean) => {
        dispatch(setRankToggle(checked)); // Dispatch the setRankToggle action when the switch is toggled
    };

    const handleFranchiseSwitchChange = (checked: boolean) => {
        dispatch(setFranchiseToggle(checked)); // Dispatch the setRankToggle action when the switch is toggled
    };

    const handleLinkToPuzzleSwitchChange = (checked: boolean) => {
        dispatch(setLinkToPuzzleToggle(checked)); // Dispatch the setRankToggle action when the switch is toggled
    };

    useEffect(() => {
        if (fromEmailCallback) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            dispatch(validateEmailThunk({ email: params.email, uuid: params.uuid })).then(() => {});
        }
    }, [fromEmailCallback, dispatch]);

    useEffect(() => {
        setVersion(versionData.version);
    }, []);

    const [email, setEmail] = useState(null);

    const submitEmail = () => {
        if (email && !userState.isPolling) {
            dispatch(setLoginStatus(`Checking for donation from ${email}.`));
            dispatch(submitEmailThunk({ email: email, puzzleType: settings.puzzle_type }));
        }
    };

    const handleCopy = () => {
        if (userState.user) {
            navigator.clipboard.writeText(userState.user.uuid).then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 3000); // Reset the copied state after 2 seconds
            });
        }
    };

    return (
        <div style={settingsStyle}>
            <div className="about-modal-inner">
                <div style={headerStyle}>
                    <h3 style={hStyle}>{'Settings'}</h3>
                    <button onClick={() => navigate(-1, { replace: true })}> Back </button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h4 style={h4Style}>{'Member Login'}:</h4>
                    <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                        <img src={'/img/kofi_s_tag_dark.png'} alt="Ko-Fi" style={{ height: '50px' }} />
                    </a>
                </div>
                <p style={hStyle}>
                    {'Donate or Subscribe on'}{' '}
                    <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                        <span style={{ whiteSpace: 'nowrap' }}>Ko-Fi</span>
                    </a>{' '}
                    {'to get'}:
                </p>
                <ul>
                    <li>{'No Ads'}!</li>
                    <li>{'Automatic Sync Across Multiple Devices'}</li>
                    <li>{'Manual Backup'}</li>
                    <li>{'Access to early features'}</li>
                    <li>{'New Puzzle Modes'}</li>
                </ul>
                {userState.user ? (
                    <p>
                        {'Thanks for the support'} <b>{$NotNull(userState.user).email}</b>!
                        <br />
                        {'Your membership tier is '} <b>{TIERS[userState.user.member_tier].name}</b>
                        <br />
                        {'Your premium membership status will expire on '}
                        {strftime('%b %-d at %I:%M %p', new Date($NotNull(userState.user).expiration))}
                    </p>
                ) : userState.emailCallbackError ? (
                    <p>Error: {userState.emailCallbackError}</p>
                ) : (
                    <>
                        <div>
                            <input
                                style={emailInput}
                                id="email_input"
                                className="email-input"
                                type="email"
                                placeholder="Email"
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </div>
                        <div>
                            <button style={loginButton} className="mainButton login-button" onClick={submitEmail}>
                                {'Login'}
                            </button>
                        </div>
                        {userState.loginStatus ? <p>{userState.loginStatus}</p> : null}
                    </>
                )}

                <h4 style={h4Style}>{'Notifications'}:</h4>
                <p>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            flexWrap: 'wrap',
                            gap: '10px',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ marginRight: '10px', marginTop: '0px' }}>
                                {'Send email when new daily game available'}{' '}
                            </label>
                            {userState.user ? (
                                <Switch
                                    onChange={handleEmailNotificationToggleChange}
                                    checked={emailNotificationToggle}
                                    height={17}
                                    width={31}
                                />
                            ) : (
                                <p>Login above to access this setting.</p>
                            )}
                        </div>
                        {userState.user && emailNotificationToggle && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label style={{ marginRight: '10px' }}>{'at'}</label>
                                <select
                                    value={emailNotificationHourSetting}
                                    onChange={handleHourChange}
                                    style={timePickerStyle}
                                >
                                    {Array.from({ length: 24 }, (_, i) => (
                                        <option key={i} value={i}>
                                            {i === 0
                                                ? '12 AM'
                                                : i < 12
                                                ? `${i} AM`
                                                : i === 12
                                                ? '12 PM'
                                                : `${i - 12} PM`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                </p>

                <h4 style={h4Style}>{'Language'}:</h4>
                {/*<p>*/}
                {/*    {('Select Language')}:*/}
                {/*    <LanguageSelector />*/}
                {/*    <br />*/}
                {/*    <br />*/}
                {/*    {(*/}
                {/*        'If you notice an incorrect translation and/or would like to help us translate please contact us!',*/}
                {/*    )}{' '}*/}
                {/*</p>*/}
                <h4 style={h4Style}>{'Sharing'}:</h4>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <label style={{ marginRight: '10px', marginTop: '0px' }}>{'Show rank when sharing results'} </label>
                    <Switch onChange={handleRankSwitchChange} checked={rankToggle} height={17} width={31} />
                </div>
                <br />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <label style={{ marginRight: '10px', marginTop: '0px' }}>
                        {'Show franchises when sharing results'}{' '}
                    </label>
                    <Switch onChange={handleFranchiseSwitchChange} checked={franchiseToggle} height={17} width={31} />
                </div>
                <br />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <label style={{ marginRight: '10px', marginTop: '0px' }}>{'Link to Corresponding Puzzle'} </label>
                    <Switch
                        onChange={handleLinkToPuzzleSwitchChange}
                        checked={linkToPuzzleToggle}
                        height={17}
                        width={31}
                    />
                </div>
                <h4 style={h4Style}>{'Stats Backup'}:</h4>
                <div
                    style={{
                        display: 'flex',
                        flexFlow: 'row nowrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    {!userState.user ? <SubscribeOnKofiButton /> : null}
                    <p className={frosted}>
                        <button
                            className={'mainButton share-results-btn export-btn'}
                            disabled={!userState.user}
                            onClick={() => {
                                try {
                                    var data = JSON.parse(prompt('Paste data here'));
                                    Object.keys(data).forEach(function (k) {
                                        localStorage.setItem(k, data[k]);
                                    });
                                    //reload
                                    window.location.reload();
                                } catch (e) {
                                    console.error(e);
                                    alert('Invalid data format');
                                }
                            }}
                        >
                            {'Import Stats'}
                        </button>
                        <br />
                        <button
                            className={'mainButton share-results-btn export-btn'}
                            disabled={!userState.user}
                            onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(localStorage));
                            }}
                        >
                            {'Export Stats To Clipboard'}
                        </button>{' '}
                        - {'Paste the copied text into a file, email, or message to save your stats'}
                    </p>
                </div>
                <h4 style={h4Style}>
                    {'UUID'}:{' '}
                    {userState.user ? (
                        <span
                            onClick={handleCopy}
                            className={copied ? 'copied' : ''} // Add class based on copied state
                        >
                            {' '}
                            {userState.user.uuid} <span className={'icon-files-empty'}></span>
                        </span>
                    ) : (
                        <>{'Log In To See'}</>
                    )}
                </h4>
            </div>
            <div>Version: {version}</div>
        </div>
    );
};

export default Setting;
