// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';

import type { TDispatch } from '../../store/store';

import { answers } from '../../puzzles/gtgq_puzzles';
import { GUESS_IMAGE_NEUTRAL_COLOR, IMAGE_EXTENSION, KOFI_URL, PUZZLE_TYPES } from '../../constants';
import { DEV_MODE, isAnswerCorrect } from '../../globals';
import AutoComplete from '../AutoComplete';
import { autocompleteSearchThunk, submitGuessThunk } from '../../store/slices/puzzleSlice';
import { useHotkeys } from 'react-hotkeys-hook';

import { loadLocalStorageItem, saveLocalStorageItem } from '../../localStateUtils';
import { calcQuestProgressBar, populateQuestGameList } from '../../puzzleUtils';
import { setModal, setPuzzleId, setPuzzleType } from '../../store/slices/uxSlice';
import { debounce } from 'lodash';
import KofiButton from '../Kofi';
import { Helmet } from 'react-helmet';
import ImageMagnifier from '../ImageMagnifier';
import { goToDay } from '../../clientUtils';
import { useSettings } from '../../settings/useSettings';
import { getInitialGameState, getInitialGuesses, getInitialGuessList, shakeInput } from './PuzzleUtils';
import FinalAnswer from '../FinalAnswer';
import FinalMessage from '../FinalMessage';
import ShowGuesses from '../ShowGuesses';
import ProgressBar from '../ProgressBar';
import type { State } from '../../store/types';
import PremiumMemberOnly from '../PremiumMemberOnly';
import CustomAudioPlayer from '../CustomAudioPlayer';

const getInitialFranchiseMatchList = (originalSaveState: ?string, puzzleId: string, settings: GameSettings) => {
    // make an array from one to settings.max_guesses
    // side note - if this works this should probably be how it should be implemented in all the other puzzles
    const maxGuessesArray = Array.from({ length: settings.max_guesses }, (_, i) => i + 1);
    console.log('maxGuessesArray: ', maxGuessesArray);
    return originalSaveState
        ? maxGuessesArray.map(
              // change array to 1, to settings. max guess
              (val) => loadLocalStorageItem(puzzleId, `${settings.storage_keys.guess}${val}_f`) === 'true',
          )
        : Array(settings.max_guesses).fill(false);
};

const GTGQPuzzle = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();
    const puzzleType = PUZZLE_TYPES.GTGQ;

    const navigate = useNavigate();

    let { puzzleId, itemId } = useParams();
    console.log('puzzleId: ', puzzleId, 'itemId: ', itemId);

    const gameIdx = puzzleId + '_' + itemId; // do it like this b/c we access the quests via puzzleId/ItemId

    const userState = useSelector((state: State) => state.user);

    const savedState = loadLocalStorageItem(gameIdx, settings.storage_keys.game_state);
    // b/c this is a subpuzzle of GTG we need to check if the puzzle type is the same as the settings
    if (savedState == null && settings.puzzle_type === puzzleType) {
        // Populate new state
        saveLocalStorageItem(gameIdx, settings.storage_keys.game_state, 'playing');
    }

    useHotkeys(
        'ArrowRight',
        () => {
            if (Number(itemId) < totalItems) {
                goToDay(puzzleId, 'guess_the_quest', settings, Number(itemId) + 1);
            }
        },
        [itemId],
    );
    useHotkeys(
        'ArrowLeft',
        () => {
            if (Number(itemId) > 1) {
                goToDay(puzzleId, 'guess_the_quest', settings, Number(itemId) - 1);
            }
        },
        [itemId],
    );

    const [gameState, setGameState] = useState(() => getInitialGameState(savedState));
    const [guessNum, setGuessNum] = useState(() => getInitialGuesses(savedState, gameIdx, settings));
    const [guessList, setGuessList] = useState(() => getInitialGuessList(savedState, gameIdx, settings));
    const [franchiseMatch, setFranchiseMatch] = useState(() =>
        getInitialFranchiseMatchList(savedState, gameIdx, settings),
    );

    const [guessInputValue, setGuessInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [filteredSuggestionsMetadata, setFilteredSuggestionsMetadata] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [previousGuesses, setPreviousGuesses] = useState(Object.create(null));
    const [ACloading, setACloading] = useState(false);

    const [imageLoading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(setPuzzleType(puzzleType));
    });

    useEffect(() => {
        const savedState = loadLocalStorageItem(gameIdx, settings.storage_keys.game_state);
        setGameState(getInitialGameState(savedState));
        setGuessNum(getInitialGuesses(savedState, gameIdx, settings));
        setGuessList(getInitialGuessList(savedState, gameIdx, settings));
        setFranchiseMatch(getInitialFranchiseMatchList(savedState, gameIdx, settings));
    }, [gameIdx, puzzleId, settings]);

    const answer = answers[puzzleId].puzzles[itemId - 1].answers;
    const audio = answers[puzzleId].puzzles[itemId - 1].audio;
    const franchise = answers[puzzleId].puzzles[itemId - 1].franchise;
    const puzzle_num = answers[puzzleId].puzzles[itemId - 1].puzzle_num;
    const questTitle = answers[puzzleId].title;
    const totalItems = answers[puzzleId].puzzles.length; // Simplified way to get total items
    const subscriber_only = answers[puzzleId].subscriber_only;

    useEffect(() => {
        const hasSeenHowToPlay = localStorage.getItem(`${puzzleType}_hasSeenHowToPlay`);

        // If the user has not seen the "How to Play" modal
        if (!hasSeenHowToPlay) {
            dispatch(setModal('howtoplay'));
            localStorage.setItem(`${puzzleType}_hasSeenHowToPlay`, 'true');
        }
    }, [dispatch, puzzleType]);

    const [progressBarState, setProgressBarState] = useState([]);

    useEffect(() => {
        const questState = calcQuestProgressBar(settings, totalItems, puzzleId);
        console.log('questState: ', questState);
        setProgressBarState(questState);
        
        // Check if every item in quest state is either 's' or 'f' (completed or failed)
        if (questState.every((item) => item === 's' || item === 'f')) {
            console.log('Quest completed!');
            
            // Check if we've already shown the completion modal for this quest
            const completionModalShown = localStorage.getItem(`${puzzleType}_quest_${puzzleId}_completion_shown`);
            
            if (!completionModalShown) {
                dispatch(setPuzzleId(puzzleId));
                dispatch(setModal('questcompletion'));
                
                // Store that we've shown the modal
                localStorage.setItem(`${puzzleType}_quest_${puzzleId}_completion_shown`, 'true');
            }
        }
    }, [puzzleId, settings, totalItems, gameState, dispatch, puzzleType]);

    const debouncedSave = useMemo(
        (userInput, previousGuesses) =>
            debounce((userInput, previousGuesses) => {
                const delayTimer = setTimeout(() => {
                    const unLinked = populateQuestGameList(answers).filter(
                        (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
                    );
                    setFilteredSuggestions(unLinked);
                    setActiveSuggestionIndex(0);
                    setShowSuggestions(true);
                    setACloading(false);
                }, 3000);

                dispatch(
                    autocompleteSearchThunk({
                        userInput: userInput,
                        puzzleId: puzzleId,
                        itemType: settings.guess_item.toLowerCase(),
                        puzzleType: puzzleType,
                    }),
                )
                    .unwrap()
                    .then((response) => {
                        clearTimeout(delayTimer); // Cancel the timeout
                        const unLinked = response.map((result) => result.title);
                        setFilteredSuggestions(unLinked);
                        setFilteredSuggestionsMetadata(response);
                        setActiveSuggestionIndex(0);
                        setShowSuggestions(true);

                        // store unlinked in previous guesses
                        setPreviousGuesses({
                            ...previousGuesses,
                            [userInput]: { suggestions: unLinked, metadata: response },
                        });
                        setACloading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching autocomplete results:', error);
                        setACloading(false);
                    });
            }, 290),
        [dispatch, puzzleId, settings, puzzleType],
    );

    const submitGuess = (e: Event | null, isSkipGuess: boolean) => {
        let currentInputValue;

        if (isSkipGuess !== undefined) {
            currentInputValue = 'Skipped!';
        } else {
            currentInputValue = guessInputValue;
        }

        if (guessInputValue === '' && isSkipGuess !== true) return;

        const guessFranchise = filteredSuggestionsMetadata.filter(
            (suggestion) => suggestion.title.toLowerCase() === currentInputValue.toLowerCase(),
        )[0]?.franchise;
        const guessFranchiseMatch = guessFranchise === franchise;

        let guessNum = 0;
        for (let i = 0; i < guessList.length; i++) {
            if (guessList[i] === '') {
                let newArr = [...guessList];
                newArr[i] = currentInputValue;
                setGuessList(newArr);

                // franchise
                let newFranchiseMatch = [...franchiseMatch];
                newFranchiseMatch[i] = guessFranchiseMatch;
                setFranchiseMatch(newFranchiseMatch);
                saveLocalStorageItem(gameIdx, `${settings.storage_keys.guess}${i + 1}_f`, currentInputValue);
                // end franchise

                saveLocalStorageItem(gameIdx, `${settings.storage_keys.guess}${i + 1}`, currentInputValue);
                guessNum = i + 1;
                setGuessNum(i + 1);
                if (isAnswerCorrect(currentInputValue, answer)) {
                    setGameState('win');
                    saveLocalStorageItem(gameIdx, settings.storage_keys.game_state, 'win');
                } else {
                    shakeInput();
                }
                break;
            }
        }
        setGuessInputValue('');

        setProgressBarState(calcQuestProgressBar(settings, totalItems, puzzleId));

        // TODO Disabled sync for now
        // dispatch(
        //     submitGuessThunk({
        //         puzzleId: parseInt(puzzle_num), // note that we use puzzle_num from answers b/c of how we have this setup on the backend
        //         guessNum: guessNum,
        //         guess: currentInputValue,
        //         puzzleType: puzzleType,
        //     }),
        // );
    };

    const onLoaded = () => {
        setLoading(false);
    };

    const onChange = (e: Event) => {
        // $FlowIgnore target has value
        const userInput = e.target.value;
        setGuessInputValue(userInput);
        if (userInput.length <= 2) {
            setFilteredSuggestions([]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
            return;
        }
        // check if userInput is in previous guesses
        if (previousGuesses[userInput]) {
            debouncedSave.cancel();
            console.log('already searched this');
            setFilteredSuggestions(previousGuesses[userInput].suggestions);
            setFilteredSuggestionsMetadata(previousGuesses[userInput].metadata);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
            setACloading(false);
            return;
        }
        setACloading(true);
        debouncedSave(userInput, previousGuesses);
    };

    const onKeyDown = (key: KeyboardEvent) => {
        if (key.keyCode === 13 || key.keyCode === 9) {
            if (filteredSuggestions.length > 0) {
                setGuessInputValue(filteredSuggestions[activeSuggestionIndex]);
            }
            setShowSuggestions(false);
        }
    };

    const onClick = (e: Event) => {
        setFilteredSuggestions([]);
        // $FlowIgnore target has innertext
        setGuessInputValue(e.target.innerText);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    };

    const currentPuzzleUrl = settings.game_url(puzzleId, itemId);
    const description = 'Try to ' + settings.website_title + '? Quest #' + puzzleId;
    const puzzleTitle = 'Can you Guess The.. ' + questTitle;

    const completed = progressBarState.filter((item) => item === 's').length;
    const questScoreText = `Quest Score: ${completed}/${progressBarState.length}`;

    const [copiedClipboardStatus, setCopiedClipboardStatus] = useState(false);
    const copyScoreToClipboard = async () => {
        let status;
        try {
            // Create emoji representation of progress bar
            const emojiProgressBar = progressBarState
                .map((status) => {
                    if (status === 's') return '🟩'; // Success (green)
                    if (status === 'f') return '🟥'; // Failure (red)
                    return '⬜'; // Unplayed/neutral (white)
                })
                .join('');

            // Get base URL for sharing - removing any trailing slashes
            const baseURL = window.location.origin.replace(/\/$/, '');

            // Build the share link
            const shareLink = `${baseURL}/${settings.puzzle_type}/${puzzleId}`;

            // Combine title, score, emoji bar, and link
            const shareText = `${questTitle}\n${questScoreText}\n${emojiProgressBar}\n\n${shareLink}`;

            await navigator.clipboard.writeText(shareText);
            status = true;
        } catch (err) {
            console.error('Failed to copy to clipboard: ', err);
            status = 'failed';
        } finally {
            setCopiedClipboardStatus(status);
            setTimeout(() => {
                setCopiedClipboardStatus(false);
            }, 1500);
        }
    };

    // Handler for the give up button
    const handleGiveUp = () => {
        if (window.confirm('Are you sure you want to give up? This will count as a loss.')) {
            setGameState('lose');
            saveLocalStorageItem(gameIdx, settings.storage_keys.game_state, 'lose');

            // Update the progress bar immediately
            setProgressBarState(calcQuestProgressBar(settings, totalItems, puzzleId));
        }
    };

    return (
        <div className="current-game">
            <Helmet>
                <title>Can you {questTitle}?</title>

                <link rel="canonical" href={currentPuzzleUrl} />
                <meta property="og:url" content={currentPuzzleUrl} />
                <meta property="twitter:url" content={currentPuzzleUrl} />

                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta property="twitter:description" content={description} />

                <meta property="og:title" content={puzzleTitle} />
                <meta property="twitter:title" content={puzzleTitle} />

                <meta property="og:type" content="website" />

                <meta property="og:site_name" content={settings.website_url} />

                <meta property="twitter:image" content={`/${settings.puzzle_type}/${puzzleId}/1.${IMAGE_EXTENSION}`} />
                <meta property="og:image" content={`/${settings.puzzle_url}/${puzzleId}/1.${IMAGE_EXTENSION}`} />

                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div
                className={'current-game-number'}
                style={{
                    textAlign: 'center',
                    color: '#eee',
                    opacity: '50%',
                    paddingBottom: '10px',
                    fontStyle: 'oblique',
                }}
            >
                {questTitle}
            </div>
            <div className="Screenshots">
                <div className="audio-image-area">
                    {!userState.user && !DEV_MODE && subscriber_only ? (
                        <PremiumMemberOnly puzzleText={'Quest'} />
                    ) : (
                        <></>
                    )}
                    <div
                        className="game-image game-image-1"
                        style={{
                            visibility: 'visible',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        {audio ? (
                            <CustomAudioPlayer
                                style={{ paddingTop: '52px' }}
                                audioUrl={`/${settings.puzzle_type}_games/${puzzleId}/${itemId}.mp3`}
                                skipGuess={2} // doesn't matter
                                guesses={guessNum}
                                gameState={gameState}
                                playFull={true}
                            />
                        ) : (
                            <ImageMagnifier
                                src={`/${settings.puzzle_type}_games/${puzzleId}/${itemId}.${IMAGE_EXTENSION}`}
                                component={
                                    <motion.img
                                        initial={{ opacity: 0 }}
                                        animate={{
                                            opacity: imageLoading ? 0 : 1,
                                        }}
                                        transition={{ opacity: { delay: 0.0, duration: 0.1 } }}
                                        onLoad={onLoaded}
                                        src={`/${settings.puzzle_type}_games/${puzzleId}/${itemId}.${IMAGE_EXTENSION}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                        width="100%"
                                        height="100%"
                                    />
                                }
                                width="100%"
                                height="100%"
                                magnifierHeight={100}
                                magnifierWidth={100}
                                zoomLevel={1.8}
                                alt={`Screenshot 1`}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="footer" style={{ marginTop: '10px', marginBottom: '10px', alignItems: 'center' }}>
                <div
                    className={'countdownControls'}
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        background: GUESS_IMAGE_NEUTRAL_COLOR,
                        alignItems: 'center',
                    }}
                >
                    <button
                        type="button"
                        onClick={() => goToDay(puzzleId, 'guess_the_quest', settings, Number(itemId) - 1)}
                        className={`mainButton iconButton ${Number(itemId) <= 1 ? 'disabled' : ''}`}
                        style={{ marginTop: '0px' }}
                        disabled={Number(itemId) <= 1}
                    >
                        <span aria-hidden="true" className="icon-backward2"></span>
                    </button>
                    <div className={'countdown-label'} style={{ fontSize: '20px' }}>
                        {itemId} / {totalItems}
                    </div>
                    <button
                        type="button"
                        onClick={() => goToDay(puzzleId, 'guess_the_quest', settings, Number(itemId) + 1)}
                        className={`mainButton iconButton ${Number(itemId) >= totalItems ? 'disabled' : ''}`}
                        style={{ marginTop: '0px' }}
                        disabled={Number(itemId) >= totalItems}
                    >
                        <span aria-hidden="true" className="icon-forward3"></span>
                    </button>
                </div>
            </div>
            {gameState !== 'playing' && (
                <div className="result">
                    <FinalMessage gameState={gameState} num_guesses={guessNum} />
                    <FinalAnswer answer={answer} />
                    {!userState.user && <KofiButton preset="kufi-center" />}
                    <ShowGuesses guessList={guessList} answer={answer} puzzleId={puzzleId} />

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            marginBottom: '10px',
                        }}
                    ></div>
                </div>
            )}
            {gameState === 'playing' && (
                <div className="PlayArea">
                    <div className="input-area">
                        <AutoComplete
                            onChangeFn={onChange}
                            onKeyDownFn={onKeyDown}
                            onClickFn={onClick}
                            activeSuggestionIndex={activeSuggestionIndex}
                            filteredSuggestionsList={filteredSuggestions}
                            inputValue={guessInputValue}
                            shouldShowSuggestions={showSuggestions}
                            loading={ACloading}
                        />
                    </div>

                    <div
                        className="button-container"
                        style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}
                    >
                        <button type="button" className="mainButton submitButton" onClick={submitGuess}>
                            {'Submit'}
                        </button>
                    </div>
                    {guessList.map((guess, idx) => {
                        let emoji = null;
                        if (isAnswerCorrect(guess, answer)) {
                            emoji = '✅';
                        } else if (franchiseMatch[idx]) {
                            emoji = '🟡';
                        } else if (!isAnswerCorrect(guess, answer)) {
                            emoji = '❌';
                        }
                        return guess === '' ? null : (
                            <div key={'gl-' + idx} className="guess-result">
                                {emoji}
                                {guess}
                                <span className="guess-result-franchise">
                                    {franchiseMatch[idx] && ` (Franchise: ${franchise})`}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
            <div className="quest-progress-container">
                <div className={'quest-progress'}>
                    <div style={{ marginBottom: '10px' }}>Quest Progress:</div>
                    <ProgressBar
                        itemStatus={progressBarState}
                        baseUrl={`/${settings.puzzle_type}/${puzzleId}`}
                        currentItem={Number(itemId)}
                    />
                </div>
                <p style={{ color: '#059669', margin: 0 }}>{questScoreText}</p>
                <button type="button" className="mainButton questScoreButton" onClick={copyScoreToClipboard}>
                    {copiedClipboardStatus === true && '✅ Copied!'}
                    {copiedClipboardStatus === 'failed' && '❌ Failed to copy!'}
                    {copiedClipboardStatus === false && 'Share Quest Score'}
                </button>
                <button
                    type="button"
                    className="mainButton questScoreButton"
                    onClick={handleGiveUp}
                    style={{ backgroundColor: '#e74c3c' }}
                >
                    {'Give Up'}
                </button>
            </div>
            <br />
            <div style={{ color: 'white' }}>
                WARNING: THIS MODE IS IN BETA. YOU MAY ENCOUNTER SOME BUGS. YOUR ANSWERS WILL LIKELY BE CLEARED IN THE
                FUTURE. PLEASE SEND US FEEDBACK @ <a href={'mailto:support@guessthe.wtf'}>support@guessthe.wtf</a>
            </div>
        </div>
    );
};

export default GTGQPuzzle;
