import React from 'react';
import { RETAILERS } from '../constants';

type TAffiliateInput = {
    affiliate_links: Array<string>,
    game_name: string,
    console_platform: string,
};

const AffiliateLink = ({ affiliate_links, game_name, console_platform }: TAffiliateInput): React$Element<'div'> => {
    // console.log('affiliate_links', affiliate_links);
    // console.log('console_platform', console_platform);
    const platformLogos = {
        pc: '/img/affiliate/pc.svg',
        Xbox: '/img/affiliate/xbox.svg',
        Playstation: '/img/affiliate/playstation.svg',
        Switch: '/img/affiliate/nintendo-switch.svg',
        Gamecube: '/img/affiliate/gamecube.svg',
        Gameboy: '/img/affiliate/gameboy.svg',
        N64: '/img/affiliate/n64.svg',
        Wii: '/img/affiliate/wii.svg',
        NES: '/img/affiliate/nes.svg',
        PSP: '/img/affiliate/psp.svg',
        Dreamcast: '/img/affiliate/dreamcast.svg',
    };

    const platformsForGame = [];
    const pcKeywords = ['windows', 'pc', 'steam'];
    const xboxKeywords = ['xbox', 'xone', '360'];
    const playstationKeywords = ['playstation', 'ps4', 'ps5', 'ps3', 'ps2'];
    const switchKeywords = ['switch'];
    const gamecubeKeywords = ['gamecube'];
    const gameboyKeywords = ['gameboy', 'gba'];
    const n64Keywords = ['n64', 'nintendo 64'];
    const wiiKeywords = ['wii'];
    const nesKeywords = ['nes', 'nintendo entertainment system'];
    const pspKeywords = ['psp'];
    const dreamcastKeywords = ['dreamcast'];

    const lowerCaseConsolePlatform = console_platform.toLowerCase();

    if (pcKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('pc');
    }
    if (xboxKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('Xbox');
    }
    if (playstationKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('Playstation');
    }
    if (switchKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('Switch');
    }
    if (gamecubeKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('Gamecube');
    }
    if (gameboyKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('Gameboy');
    }
    if (n64Keywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('N64');
    }
    if (wiiKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('Wii');
    }
    if (nesKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('NES');
    }
    if (pspKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('PSP');
    }
    if (dreamcastKeywords.some((kw) => lowerCaseConsolePlatform.includes(kw))) {
        platformsForGame.push('Dreamcast');
    }

    const retailerLinkElements = RETAILERS.map((retailer) => {
        const retailerLinks = platformsForGame
            .map((platform) => {
                let href;
                if (retailer === 'Amazon') {
                    href = `https://www.amazon.com/gp/search?ie=UTF8&index=videogames&tag=guessthegam06-20&keywords=${encodeURI(
                        `${game_name} ${platform}`,
                    )}`;
                } else if (retailer === 'Ebay') {
                    href = `https://www.ebay.com/sch/i.html?_from=R40&_trksid=p2380057.m570.l1313&_nkw=${encodeURI(
                        `${game_name} ${platform}`,
                    )}&_sacat=0&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338993128&customid=&toolid=10001&mkevt=1`;
                } else {
                    const matchingLink = affiliate_links.find(
                        (link) =>
                            link.includes(retailer.toLowerCase().replace('gmg', 'greenmangaming')) &&
                            link.includes(platform.toLowerCase()),
                    );
                    if (matchingLink) {
                        href = matchingLink;
                    }
                }

                if (href) {
                    return (
                        <div key={platform} >
                            <a href={href} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={platformLogos[platform] || ''}
                                    alt={platform}
                                    height={28}
                                    width={28}
                                    style={{ margin: '5px' }}
                                />
                            </a>
                        </div>
                    );
                }

                return null;
            })
            .filter(Boolean);

        if (retailerLinks.length > 0) {
            return (
                <div key={retailer}>
                    <div style={{ fontSize: '1em' }}>
                        <strong>
                            <i>
                                <u>{retailer}</u>
                            </i>
                        </strong>
                    </div>
                    {retailerLinks}
                </div>
            );
        }
        return null;
    })
    .filter(Boolean);

    return (
        retailerLinkElements.length > 0 ?
            <div className="linkaff">
                <div style={{ marginBottom: '1px' }}>
                    Purchase from the following retailers:
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: 'center',
                    }}
                >
                    {retailerLinkElements}
                </div>
            </div>
        :
            <></>
    );
};

export default AffiliateLink;
