/* eslint-disable no-useless-escape */
// @flow

export type TGTGQAnswer = {
    title: string,
    subscriber_only: boolean,
    logo: string,
    audio: boolean,
};

type TGTGQAnswers = {
    [string]: TGTGQAnswer,
};

export const answers: TGTGQAnswers = {
    '1': {
        title: 'Guess The Grass',
        subscriber_only: false,
        description: 'How well do you know your foliage?',
        completion_message: "You've guessed the grass! Now go outside and touch some real grass...",
        puzzles: [
            {
                answers: ['Super Mario 64'],
                franchise: 'Mario',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 1,
                audio: false,
            },
            {
                answers: ['The Legend of Zelda: Breath of the Wild'],
                franchise: 'Zelda',
                hint: 'Released in 2017, this game was in development for 5 years',
                puzzle_num: 2,
                audio: false,
            },
            {
                answers: ['Death Stranding'],
                franchise: 'None',
                hint: 'This games features a cameo from film director Edgar Wright',
                puzzle_num: 3,
                audio: false,
            },
            {
                answers: ['Dragons Dogma II', 'Dragons Dogma 2'],
                franchise: 'Dragons Dogma',
                hint: 'This 2024 game was the sequel to a 2012 game by Capcom',
                puzzle_num: 4,
                audio: false,
            },
            {
                answers: ['Elden Ring'],
                franchise: 'Elden Ring',
                hint: 'This game featured a collaboration with famous fantasy author George R. R. Martin',
                puzzle_num: 5,
                audio: false,
            },
            {
                answers: ['The Witcher 3: Wild Hunt', 'Witcher 3'],
                franchise: 'The Witcher',
                hint: 'This game was released in 2015 and was the third game in the series',
                puzzle_num: 6,
                audio: false,
            },
            {
                answers: ['Luigis Mansion'],
                franchise: 'Mario',
                hint: "This game features a character who won a mansion in a contest he doesn't remember entering",
                puzzle_num: 7,
                audio: false,
            },
            {
                answers: ['Minecraft'],
                franchise: 'Minecraft',
                hint: 'This game was released in 2011 and was developed by Markus Persson',
                puzzle_num: 8,
                audio: false,
            },
            {
                answers: ['The Last of Us'],
                franchise: 'The Last of Us',
                hint: 'The game\'s scenery setting was partially influenced by Alan Weisman\'s book "The World Without Us"',
                puzzle_num: 9,
                audio: false,
            },
            {
                answers: ['Red Dead Redemption II', 'Red Dead Redemption 2'],
                franchise: 'Red Dead Redemption',
                hint: 'Praised for its attention to detail, this 2018 game features half a million lines of dialogue',
                puzzle_num: 10,
                audio: false,
            },
            {
                answers: ['The Elder Scrolls V: Skyrim', 'The Elder Scrolls 5'],
                franchise: 'The Elder Scrolls',
                hint: 'This 2011 game was the fifth in the series and featured a dragon as the main antagonist',
                puzzle_num: 11,
                audio: false,
            },
            {
                answers: ['Grand Theft Auto V', 'Grand Theft Auto 5'],
                franchise: 'Grand Theft Auto',
                hint: 'This game was released in 2013 and was the first in the series to feature three protagonists',
                puzzle_num: 12,
                audio: false,
            },
            {
                answers: ['Halo Infinite'],
                franchise: 'Halo',
                hint: 'This game was released in 2021 and was the sixth main entry in the series',
                puzzle_num: 13,
                audio: false,
            },
            {
                answers: ['Metal Gear Solid V: The Phantom Pain', 'Metal Gear Solid 5'],
                franchise: 'Metal Gear Solid',
                hint: 'This game was released in 2015 and was the last game in the series to be directed by Hideo Kojima',
                puzzle_num: 14,
                audio: false,
            },
            {
                answers: ['Apex Legends'],
                franchise: 'Apex Legends',
                hint: 'One of the most popular battle royale games, this game was released in 2019',
                puzzle_num: 15,
                audio: false,
            },
            {
                answers: ['Dota 2'],
                franchise: 'Dota',
                hint: 'This game was released in 2013 and is the sequel to a 2003 mod for Warcraft III',
                puzzle_num: 16,
                audio: false,
            },
            {
                answers: ['Fable 2', 'Fable II'],
                franchise: 'Fable',
                hint: 'This 2008 game was the second in the series by Lionhead Studios',
                puzzle_num: 17,
                audio: false,
            },
            {
                answers: ['Dark Souls'],
                franchise: 'Dark Souls',
                hint: "This game was released in 2011 and was the spiritual successor to Demon's Souls",
                puzzle_num: 18,
                audio: false,
            },
            {
                answers: ['Animal Crossing: New Horizons'],
                franchise: 'Animal Crossing',
                hint: 'This 2020 game features a deserted island setting and was developed by Nintendo',
                puzzle_num: 19,
                audio: false,
            },
            {
                answers: ["Assassin's Creed: Valhalla"],
                franchise: "Assassin's Creed",
                hint: 'This game was released in 2020 and was the twelfth main entry in the series',
                puzzle_num: 20,
                audio: false,
            },
        ],
    },
    '2': {
        title: 'Guess The Audio (90s Gaming Edition)',
        subscriber_only: false,
        description: 'Can you recognize these games based on sound alone?',
        completion_message: 'You know too many sounds, go outside and listen to some birds...',
        puzzles: [
            {
                answers: ['Chrono Trigger'],
                franchise: 'Chrono',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 21,
                audio: true,
                reference: 'Chrono Trigger Huh',
            },
            {
                answers: ['Half-Life', 'Half Life'],
                franchise: 'Half-life',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 22,
                audio: true,
                reference: 'Half life access denied',
            },
            {
                answers: ['Metal Gear Solid'],
                franchise: 'Metal Gear',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 23,
                audio: true,
                reference: 'Metal Gear Solid alert',
            },
            {
                answers: ['Pokemon'],
                franchise: 'Pokemon',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 24,
                audio: true,
                reference: 'pokemon red blue level up alert',
            },
            {
                answers: ['Starcraft'],
                franchise: 'Starcraft',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 25,
                audio: true,
                reference: 'starcraft ready to roll out',
            },
            {
                answers: ['Goldeneye 007'],
                franchise: 'James Bond',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 26,
                audio: true,
                reference: 'GoldenEye 007 Switch Reloading',
            },
            {
                answers: ['Castlevania: Symphony of the Night'],
                franchise: 'Castlevania',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 27,
                audio: true,
                reference: 'Castlevania Symphony of the Night - Thank You!',
            },
            {
                answers: ['Sonic the Hedgehog'],
                franchise: 'Sonice the Hedgehog',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 28,
                audio: true,
                reference: 'Sonic the Hedgehog - drown',
            },
            {
                answers: ['Street Fighter II', 'Street Fighter 2'],
                franchise: 'Street Fighter',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 29,
                audio: true,
                reference: 'Street Fighter II - Coin',
            },
            {
                answers: ['Super Mario 64'],
                franchise: 'Mario',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 30,
                audio: true,
                reference: 'Super Mario 64 - Okey-dokey',
            },
            {
                answers: ['The Legend of Zelda: A Link to the Past'],
                franchise: 'The Legend of Zelda',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 31,
                audio: true,
                reference: 'The Legend Of Zelda A Link to the Past - Key',
            },
            {
                answers: ['Final Fantasy VII', 'Final Fantasy 7'],
                franchise: 'Final Fantasy',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 32,
                audio: true,
                reference: 'Final Fantasy VII - victory',
            },
            {
                answers: ['Super Metroid'],
                franchise: 'Metroid',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 33,
                audio: true,
                reference: 'Super Metroid - Mother Brain roar',
            },
            {
                answers: ['Crash Bandicoot'],
                franchise: 'Crash Bandicoot',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 34,
                audio: true,
                reference: 'WOAH',
            },
            {
                answers: ['Super Mario World'],
                franchise: 'Mario',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 35,
                audio: true,
                reference: 'Super mario world -  yoshi sound',
            },
            {
                answers: ['Diablo'],
                franchise: 'Diablo',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 36,
                audio: true,
                reference: 'Diablo -  gold drop.mp3',
            },
            {
                answers: ['Donkey Kong Country'],
                franchise: 'Donkey Kong',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 37,
                audio: true,
                reference: 'Donkey Kong Country - Death music.mp3',
            },
            {
                answers: ['Fallout 2'],
                franchise: 'Fallout',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 38,
                audio: true,
                reference: 'Fallout 2 - Sergeant Dornan.mp3',
            },
            {
                answers: ['Gran Turismo'],
                franchise: 'Gran Turismo',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 39,
                audio: true,
                reference: 'Gran Turismo- Countdown',
            },
            {
                answers: ['Mario Kart 64'],
                franchise: 'Mario Kart',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 40,
                audio: true,
                reference: 'Mario Kart 64 - Item.mp3',
            },
            {
                answers: ['Silent Hill'],
                franchise: 'Silent Hill',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 41,
                audio: true,
                reference: 'Silent Hill - Murphy Scream',
            },
            {
                answers: ['Resident Evil 2'],
                franchise: 'Resident Evil',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 42,
                audio: true,
                reference: 'Resident Evil 2 - Inventory.mp3',
            },
            {
                answers: ['Mortal Kombat 2', 'Mortal Kombat II'],
                franchise: 'Mortal Kombat',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 43,
                audio: true,
                reference: 'toastyy',
            },
            {
                answers: ['Planescape Torment'],
                franchise: 'Planescape Torment',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 44,
                audio: true,
                reference: 'Planescape Torment - Updated my journal',
            },
            {
                answers: [
                    "Super Mario World 2: Yoshi's Island",
                    'Super Mario World 2',
                    'Super Mario World II',
                    "Yoshi's Island",
                    'Yoshis Island',
                ],
                franchise: 'Mario',
                hint: 'First game in this franchise to feature 3D graphics and open world exploration',
                puzzle_num: 45,
                audio: true,
                reference: "Yoshi's Island - Fuzzy Sound Effect",
            },
        ],
    },
};
