// @flow

import React from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../store/slices/uxSlice';
import CloseModalButton from './CloseModalButton';

const AngleChangeModal = () => {
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(setModal('none'));
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <CloseModalButton onClick={closeModal} />
                <h2>Important Update!</h2>
                <div className="modal-text">
                    <p>Starting from Game #40, the angles will now be <strong>even numbers only</strong>.</p>
                    <p>This means you'll be guessing angles like 2°, 4°, 6°, 8°, 10°, etc. instead of multiples of 5.</p>
                    <p>Good luck with your angle estimations!</p>
                </div>
            </div>
        </div>
    );
};

export default AngleChangeModal; 