// @flow

import { useDispatch, useSelector } from 'react-redux';
import type { TDispatch } from '../../store/store';

import React, { useMemo, useEffect } from 'react';
import { setModal } from '../../store/slices/uxSlice';
import HowToPlayModal from './HowToPlayModal';
import AboutModal from './AboutModal';
import StatsModal from './StatsModal';
import LoveModal from './LoveModal';
import InterstitialAdModal from '../InterstitialAdModal';
import RedirectModal from './RedirectModal';
import AngleChangeModal from './AngleChangeModal';
import type { State } from '../../store/types';
import QuestCompletion from './QuestCompletion';
import { loadMustRedirect } from '../../localStateUtils';

const unclosableModals = ['redirect'];

const Modals = (): React$Element<React$FragmentType> => {
    const dispatch: TDispatch = useDispatch();
    const currentModal = useSelector((state: State) => state.ux.modal);
    const isEmbeddedExternally = useSelector((state: State) => state.ux.isEmbeddedExternally);

    const allowBackgroundClose = useMemo(() => {
        if (currentModal === 'inter-ad') {
            return false;
        }
        return true;
    }, [currentModal]);

    const closeModal = () => {
        if (!unclosableModals.includes(currentModal)) {
            dispatch(setModal('none'));
            if (typeof window.globalThis.interAdNextFunc == 'function') {
                window.globalThis.interAdNextFunc();
                window.globalThis.interAdNextFunc = undefined;
            }
        }
    };

    // Open redirect modal if already played. Not sure where to place this check
    useEffect(() => {
        if (isEmbeddedExternally) {
            const mustRedirect = loadMustRedirect();
            if (mustRedirect) {
                dispatch(setModal('redirect'));
            }
        }
    }, [dispatch, isEmbeddedExternally]);

    return (
        <>
            {currentModal !== 'none' && (
                <div className="modal-bg" onClick={allowBackgroundClose ? closeModal : undefined} />
            )}
            {currentModal === 'love' && <LoveModal />}

            {currentModal === 'stats' && <StatsModal />}

            {currentModal === 'about' && <AboutModal />}

            {currentModal === 'howtoplay' && <HowToPlayModal />}

            {currentModal === 'questcompletion' && <QuestCompletion />}

            {currentModal === 'inter-ad' && <InterstitialAdModal />}

            {currentModal === 'redirect' && <RedirectModal />}
            
            {currentModal === 'angle-change' && <AngleChangeModal />}
        </>
    );
};

export default Modals;
