// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import type { TDispatch } from '../../store/store';

import { answers } from '../../puzzles/gth_puzzles';
import {
    IMAGE_EXTENSION,
    PUZZLE_TYPES,
    lastPlayedKey,
    lastShownInterAdKey,
    timeToInvalidateGamesPlayed,
    numGamesBeforeInterAd,
    minimumTimeBetweenInterAd,
    GUESS_IMAGE_SUCCESS_COLOR,
    GUESS_IMAGE_FAILURE_COLOR,
} from '../../constants';
import { DEV_MODE, isAnswerCorrect, priceCheckAnswer } from '../../globals';
import { setPreviousPuzzles, setStats } from '../../store/slices/statSlice';
import AutoComplete from '../AutoComplete';
import { autocompleteSearchThunk, submitGuessThunk } from '../../store/slices/puzzleSlice';
import ImageSelectorButtons from '../ImageSelectorButtons';
import ShareResult from '../ShareResult';
import ShowGuesses from '../ShowGuesses';
import { useHotkeys } from 'react-hotkeys-hook';

import {
    calcStats,
    loadLocalStorageItem,
    saveLocalStorageItem,
    loadDateFromLocalStorage,
    saveDateToLocalStorage,
    loadGamesPlayedCount,
    saveGamesPlayedCount,
} from '../../localStateUtils';
import { calcPreviousPuzzleResults } from '../../prevPuzzleUtils';
import { populateGameList } from '../../puzzleUtils';
import { setModal, setPuzzleType, setShowInterAd } from '../../store/slices/uxSlice';
import { debounce } from 'lodash';
import KofiButton from '../Kofi';
import type { State } from '../../store/types';
import { handleCustomPuzzleLogic } from '../../easterEgg';
import NavigateButton from '../NavigateButton';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import ImageMagnifier from '../ImageMagnifier';
import { goToDay } from '../../clientUtils';
import { useSettings } from '../../settings/useSettings';
import Hint from '../Hint';
import {
    getInitialGameState,
    getInitialGuesses,
    getInitialGuessList,
    idxToLocalstateNameMapping,
    shakeInput,
} from './PuzzleUtils';
import FinalAnswer from '../FinalAnswer';
import FinalMessage from '../FinalMessage';

const GTHPuzzle = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();
    const puzzleType = PUZZLE_TYPES.GTH;

    let { puzzleId } = useParams();
    puzzleId = puzzleId ?? settings.num_days_from_start_date().toString();
    console.log('Num of days from puzzle start date: ', settings.num_days_from_start_date().toString());
    console.log('puzzleId', puzzleId);

    useHotkeys('ArrowRight', () => goToDay(Number(puzzleId) + 1, 'puzzle', settings), [puzzleId]);
    useHotkeys('ArrowLeft', () => goToDay(Number(puzzleId) - 1, 'puzzle', settings), [puzzleId]);

    const savedState = loadLocalStorageItem(puzzleId, settings.storage_keys.game_state);
    if (savedState == null) {
        // Populate new state
        saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'playing');
    }

    const [gameState, setGameState] = useState(() => getInitialGameState(savedState));
    const [guessNum, setGuessNum] = useState(() => getInitialGuesses(savedState, puzzleId, settings));
    const [guessList, setGuessList] = useState(() => getInitialGuessList(savedState, puzzleId, settings));
    // const [franchiseMatch, setFranchiseMatch] = useState(() => getInitialFranchiseMatchList(savedState, puzzleId));

    const [selectedImage, selectImage] = useState(1);
    const [guessInputValue, setGuessInputValue] = useState('');
    // const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    // const [filteredSuggestionsMetadata, setFilteredSuggestionsMetadata] = useState([]);
    // const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    // const [showSuggestions, setShowSuggestions] = useState(false);
    // const [previousGuesses, setPreviousGuesses] = useState(Object.create(null));
    // const [ACloading, setACloading] = useState(false);

    const userState = useSelector((state: State) => state.user);

    const stateGuesses = useSelector((state: State) => state.stats[puzzleType].guesses);
    const numWinsAtGuess = useSelector((state: State) => state.stats[puzzleType].numWinsAtGuess);

    // useEffect(() => handleCustomPuzzleLogic(gameState, puzzleId));
    useEffect(() => {
        dispatch(setPuzzleType(puzzleType));
    });

    useEffect(() => {
        const hasSeenHowToPlay = localStorage.getItem(`${puzzleType}_hasSeenHowToPlay`);

        // If the user has not seen the "How to Play" modal
        if (!hasSeenHowToPlay) {
            dispatch(setModal('howtoplay'));
            localStorage.setItem(`${puzzleType}_hasSeenHowToPlay`, 'true');
        }
    }, [dispatch, puzzleType]);

    useEffect(() => {
        const savedState = loadLocalStorageItem(puzzleId, settings.storage_keys.game_state);
        setGameState(getInitialGameState(savedState));
        setGuessNum(getInitialGuesses(savedState, puzzleId, settings));
        setGuessList(getInitialGuessList(savedState, puzzleId, settings));
    }, [stateGuesses, puzzleId, settings]);

    useEffect(() => handleCustomPuzzleLogic(puzzleType, gameState, puzzleId));

    const answer = answers[puzzleId].answers;
    const affiliate_links = answers[puzzleId].affiliate_links;
    const sold_on = answers[puzzleId].sold_on;
    const city_state = answers[puzzleId].city_state;
    const bed_bath = answers[puzzleId].bed_bath;
    const year_built = answers[puzzleId].year_built;
    const square_footage = answers[puzzleId].square_footage;
    const game_content = answers[puzzleId].content;

    // const debouncedSave = useMemo(
    //     (userInput, previousGuesses) =>
    //         debounce((userInput, previousGuesses) => {
    //             const delayTimer = setTimeout(() => {
    //                 const unLinked = populateGameList(answers).filter(
    //                     (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    //                 );
    //                 setFilteredSuggestions(unLinked);
    //                 setActiveSuggestionIndex(0);
    //                 setShowSuggestions(true);
    //                 setACloading(false);
    //             }, 3000);
    //
    //             dispatch(
    //                 autocompleteSearchThunk({
    //                     userInput: userInput,
    //                     puzzleId: puzzleId,
    //                     itemType: settings.guess_item.toLowerCase(),
    //                     puzzleType: puzzleType,
    //                 }),
    //             )
    //                 .unwrap()
    //                 .then((response) => {
    //                     clearTimeout(delayTimer); // Cancel the timeout
    //                     const unLinked = response.map((result) => result.title);
    //                     setFilteredSuggestions(unLinked);
    //                     setFilteredSuggestionsMetadata(response);
    //                     setActiveSuggestionIndex(0);
    //                     setShowSuggestions(true);
    //
    //                     // store unlinked in previous guesses
    //                     setPreviousGuesses({
    //                         ...previousGuesses,
    //                         [userInput]: { suggestions: unLinked, metadata: response },
    //                     });
    //                     setACloading(false);
    //                 })
    //                 .catch((error) => {
    //                     console.error('Error fetching autocomplete results:', error);
    //                     setACloading(false);
    //                 });
    //         }, 290),
    //     [dispatch, puzzleId, settings, puzzleType],
    // );
    //
    // const onLoaded = () => {
    //     setLoading(false);
    // };

    const changeImage = (newImageNum: number) => {
        if (guessNum < newImageNum - 1 && gameState === 'playing') return;
        selectImage(newImageNum);
    };
    useHotkeys('1', () => changeImage(1), [guessNum]);
    useHotkeys('2', () => changeImage(2), [guessNum]);
    useHotkeys('3', () => changeImage(3), [guessNum]);
    useHotkeys('4', () => changeImage(4), [guessNum]);
    useHotkeys('5', () => changeImage(5), [guessNum]);
    useHotkeys('6', () => changeImage(6), [guessNum]);

    const submitGuess = (e: Event | null, isSkipGuess: boolean) => {
        let currentInputValue;

        // console.log("is it a skip guess?", isSkipGuess);
        // console.log("Current input value:", currentInputValue)
        // console.log("Guess input value:", guessInputValue);

        if (isSkipGuess !== undefined) {
            currentInputValue = 'Skipped!';
        } else {
            currentInputValue = guessInputValue;
        }

        if (guessInputValue === '' && isSkipGuess !== true) return;

        // const guessFranchise = filteredSuggestionsMetadata.filter(
        //     (suggestion) => suggestion.title.toLowerCase() === currentInputValue.toLowerCase(),
        // )[0]?.franchise;
        // const guessFranchiseMatch = guessFranchise === franchise;

        let guessNum = 0;
        const numWinsAtGuessArr: Array<number> = [
            numWinsAtGuess['1'],
            numWinsAtGuess['2'],
            numWinsAtGuess['3'],
            numWinsAtGuess['4'],
            numWinsAtGuess['5'],
            numWinsAtGuess['6'],
        ];
        let newNumWinsAtGuess = [...numWinsAtGuessArr];
        for (let i = 0; i < guessList.length; i++) {
            if (guessList[i] === '') {
                let newArr = [...guessList];
                newArr[i] = currentInputValue;
                setGuessList(newArr);

                // franchise
                // let newFranchiseMatch = [...franchiseMatch];
                // newFranchiseMatch[i] = guessFranchiseMatch;
                // setFranchiseMatch(newFranchiseMatch);
                // saveLocalStorageItem(puzzleId, `guess${i + 1}_f`, guessFranchiseMatch.toString());
                // end franchise

                saveLocalStorageItem(puzzleId, `${settings.storage_keys.guess}${i + 1}`, currentInputValue);
                guessNum = i + 1;
                setGuessNum(i + 1);
                const { isCorrect, direction } = priceCheckAnswer(
                    currentInputValue,
                    answer[0],
                    settings.tolerance_percentage,
                );
                if (isCorrect) {
                    setGameState('win');
                    saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'win');

                    newNumWinsAtGuess[i] += 1;
                    // $FlowIgnore storing num in state
                    localStorage.setItem(idxToLocalstateNameMapping[i], newNumWinsAtGuess[i]);

                    // Interstitial ad - win-scenario
                    if (!userState.user) {
                        const now = new Date();
                        const lastShownInterAd = loadDateFromLocalStorage(lastShownInterAdKey);
                        // $FlowIgnore
                        if (lastShownInterAd && now - lastShownInterAd <= minimumTimeBetweenInterAd) {
                            console.log('Inter-ad: Already shown ad. Wait for the next ad window');
                        } else {
                            const lastPlayed = loadDateFromLocalStorage(lastPlayedKey);
                            // $FlowIgnore
                            if (lastPlayed == null || now - lastPlayed > timeToInvalidateGamesPlayed) {
                                saveGamesPlayedCount(1);
                                console.log(
                                    'Inter-ad: Reset games played, but start at 1 to account for current finished game',
                                );
                            } else {
                                const gamesPlayed = loadGamesPlayedCount();
                                if (gamesPlayed >= numGamesBeforeInterAd - 1) {
                                    // We're in the last game before showing interstitial ad
                                    console.log('Inter-ad: showInterstitialAd and reset games played to 0');
                                    dispatch(setShowInterAd(true));
                                    saveGamesPlayedCount(0); // reset games played
                                    saveDateToLocalStorage(lastShownInterAdKey); // Record the time the inter-ad is shown
                                } else {
                                    console.log('Inter-ad: increment games played');
                                    saveGamesPlayedCount(gamesPlayed + 1);
                                }
                            }
                        }
                        saveDateToLocalStorage(lastPlayedKey);
                    }
                } else {
                    // If we're on guess 1-5, then shake and go to the next image
                    if (i < guessList.length - 1) {
                        shakeInput();
                        selectImage(i + 2);
                    } else {
                        // If we're on guess 6, then they lost
                        setGameState('lose');
                        saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'lose');

                        // Interstitial ad - lost-scenario
                        if (!userState.user) {
                            const now = new Date();
                            const lastPlayed = loadDateFromLocalStorage(lastPlayedKey);
                            // $FlowIgnore Dates can be subtracted
                            if (lastPlayed == null || now - lastPlayed > timeToInvalidateGamesPlayed) {
                                saveGamesPlayedCount(1);
                                console.log(
                                    'Inter-ad: Reset games played, but start at 1 to account for current finished game',
                                );
                            } else {
                                const gamesPlayed = loadGamesPlayedCount();
                                console.log('Inter-ad: increment games played');
                                saveGamesPlayedCount(gamesPlayed + 1);
                            }
                            saveDateToLocalStorage(lastPlayedKey);
                        }
                    }
                }
                break;
            }
        }
        const initialStats = calcStats(settings);
        dispatch(
            setStats({
                won: initialStats.totalWon,
                played: initialStats.totalPlayed,
                currentStreak: initialStats.currentStreak,
                maxStreak: initialStats.maxStreak,
                puzzleType: puzzleType,
            }),
        );
        dispatch(
            setPreviousPuzzles({
                previousPuzzles: calcPreviousPuzzleResults(settings).previousPuzzles,
                puzzleType: puzzleType,
            }),
        );

        setGuessInputValue('');

        dispatch(
            submitGuessThunk({
                puzzleId: parseInt(puzzleId),
                guessNum: guessNum,
                guess: currentInputValue,
                puzzleType: puzzleType,
            }),
        );
        window.scrollTo(0, 0);
    };

    const onChange = (e: Event) => {
        // $FlowIgnore target has value
        const userInput = e.target.value;
        // remove any non numeric characters
        const cleanedInput = userInput.replace(/[^0-9.]/g, '');
        // convert to number with commas
        const numberValue = parseFloat(cleanedInput);
        const numberWithCommas = isNaN(numberValue) ? '0' : Math.trunc(numberValue).toLocaleString('en-US');

        setGuessInputValue(numberWithCommas);
        // if (userInput.length <= 2) {
        //     setFilteredSuggestions([]);
        //     setActiveSuggestionIndex(0);
        //     setShowSuggestions(false);
        //     return;
        // }
        // // check if userInput is in previous guesses
        // if (previousGuesses[userInput]) {
        //     debouncedSave.cancel();
        //     console.log('already searched this');
        //     setFilteredSuggestions(previousGuesses[userInput].suggestions);
        //     setFilteredSuggestionsMetadata(previousGuesses[userInput].metadata);
        //     setActiveSuggestionIndex(0);
        //     setShowSuggestions(true);
        //     setACloading(false);
        //     return;
        // }
        // setACloading(true);
        // debouncedSave(userInput, previousGuesses);
    };
    //
    const onKeyDown = (key: KeyboardEvent) => {
        if (key.keyCode === 13 || key.keyCode === 9) {
            submitGuess(null, undefined);
        }
    };

    // const onClick = (e: Event) => {
    //     setFilteredSuggestions([]);
    //     // $FlowIgnore target has innertext
    //     setGuessInputValue(e.target.innerText);
    //     setActiveSuggestionIndex(0);
    //     setShowSuggestions(false);
    // };

    const currentPuzzleUrl = settings.game_url(puzzleId);
    const description = 'Try to ' + settings.website_title + '? Daily Puzzle #' + puzzleId;
    const puzzleTitle = settings.website_title + ' Daily Puzzle #' + puzzleId;

    return (
        <div className="current-game">
            <Helmet>
                <title>
                    {settings.website_title} #{puzzleId}
                </title>

                <link rel="canonical" href={currentPuzzleUrl} />
                <meta property="og:url" content={currentPuzzleUrl} />
                <meta property="twitter:url" content={currentPuzzleUrl} />

                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta property="twitter:description" content={description} />

                <meta property="og:title" content={puzzleTitle} />
                <meta property="twitter:title" content={puzzleTitle} />

                <meta property="og:type" content="website" />

                <meta property="og:site_name" content={settings.website_url} />

                <meta
                    property="twitter:image"
                    content={`/${settings.puzzle_type}_games/${puzzleId}/1.${IMAGE_EXTENSION}`}
                />
                <meta property="og:image" content={`/${settings.puzzle_type}_games/${puzzleId}/1.${IMAGE_EXTENSION}`} />

                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div
                className={'current-game-number'}
                style={{
                    textAlign: 'center',
                    color: '#eee',
                    opacity: '50%',
                    paddingBottom: '10px',
                    fontStyle: 'oblique',
                }}
            >
                {settings.guess_item} #{puzzleId}
            </div>
            <div className="Screenshots">
                <div className="image-area">
                    {[1, 2, 3, 4, 5, 6].map((imageIndex) => (
                        <div
                            key={imageIndex}
                            className="game-image"
                            style={{
                                visibility: selectedImage === imageIndex ? 'visible' : 'hidden',
                            }}
                        >
                            <ImageMagnifier
                                src={`/${settings.puzzle_type}_games/${puzzleId}/${imageIndex}.${IMAGE_EXTENSION}`}
                                width="100%"
                                height="100%"
                                magnifierHeight={100}
                                magnifierWidth={100}
                                zoomLevel={1.8}
                                alt={`Screenshot ${imageIndex}`}
                            />
                            {selectedImage === imageIndex && (
                                <Hint hintText={settings.get_hint_text(imageIndex, answers[puzzleId], settings)} />
                            )}
                        </div>
                    ))}
                </div>
                <div className="image-selector">
                    <ImageSelectorButtons
                        selectedImage={selectedImage}
                        changeImage={changeImage}
                        gameState={gameState}
                        guesses={guessNum}
                        franchiseMatch={{}}
                        // franchiseMatch={franchiseMatch}
                    />
                    {/*{gameState === 'playing' && (*/}
                    {/*    <button type="button" className="mainButton iconButton skipButton" onClick={skipGuess}>*/}
                    {/*        Skip <span aria-hidden="true" className="icon-forward3"></span>*/}
                    {/*    </button>*/}
                    {/*)}*/}
                </div>
            </div>
            {gameState !== 'playing' && (
                <div className="result">
                    <FinalMessage gameState={gameState} num_guesses={guessNum} />
                    <FinalAnswer answer={answer} message={'This house sold for'} />
                    <div
                        className={'answer'}
                        style={{ color: gameState === 'win' ? GUESS_IMAGE_SUCCESS_COLOR : GUESS_IMAGE_FAILURE_COLOR }}
                    >
                        {(
                            (Math.abs(
                                Number(guessList[guessNum - 1].replaceAll(',', '').replaceAll('$', '')) -
                                    Number(answer[0].replaceAll(',', '').replaceAll('$', '')),
                            ) /
                                Number(answer[0].replaceAll(',', '').replaceAll('$', ''))) *
                            100
                        ).toFixed(2)}
                        % from the exact price
                    </div>
                    <ShareResult
                        guesses={guessNum}
                        gameState={gameState}
                        puzzleId={puzzleId}
                        franchiseMatch={{}}
                        maxGuesses={settings.max_guesses}
                    />
                    <p className="game_content" style={{ color: 'white' }}>
                        {game_content}
                    </p>
                    <a href={affiliate_links[0]} target="_blank" rel="noopener noreferrer" >
                        See this listing
                    </a>
                    <br />
                    <KofiButton preset="kufi-center" />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <NavigateButton
                            text={'Play Previous Days'}
                            path={settings.paths.previous_games}
                            hash={puzzleId}
                        />

                        {/*<NavigateButton text={'See Stats'} path={settings.paths.stats.replace(':puzzleId', puzzleId)} />*/}
                    </div>
                    <div className={'game-info'}>
                        {/*    <p className="game_content">{game_content}</p>*/}
                        {/*    <br />*/}
                        {/*    {submitted_by && (*/}
                        {/*        <p className={'submitted-by'}>*/}
                        {/*            Submitted By: <a href={submitted_by}>{submitted_by}</a>*/}
                        {/*        </p>*/}
                        {/*    )}*/}
                    </div>
                </div>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guessNum < settings.max_guesses - 1 && (
                <p className="guesses-remaining">
                    {settings.max_guesses - guessNum} {settings.max_guesses - guessNum === 1 ? 'guess' : 'guesses'}{' '}
                    {'remaining'}!
                </p>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guessNum >= settings.max_guesses - 1 && (
                <p className="guesses-remaining">{'Last guess'}!</p>
            )}
            {gameState === 'playing' && (
                <div className="PlayArea">
                    {gameState === 'playing' && (
                        <div className="input-area">
                            <AutoComplete
                                onChangeFn={onChange}
                                onKeyDownFn={onKeyDown}
                                onClickFn={() => {}}
                                activeSuggestionIndex={{}}
                                filteredSuggestionsList={{}}
                                inputValue={guessInputValue}
                                shouldShowSuggestions={false}
                                loading={false}
                                placeholder={`Enter a price`}
                            />
                        </div>
                    )}
                    <button type="button" className="mainButton submitButton" onClick={submitGuess}>
                        {'Submit'}
                    </button>
                    {guessList.map((guess, idx) => {
                        if (guess === '') return null;
                        const { isCorrect, direction } = priceCheckAnswer(
                            guess,
                            answer[0],
                            settings.tolerance_percentage,
                        );
                        let emoji;
                        if (isCorrect) {
                            emoji = '✅';
                        } else if (direction === 'veryup') {
                            emoji = '⬆️⬆️️';
                        } else if (direction === 'up') {
                            emoji = '⬆️';
                        } else if (direction === 'verydown') {
                            emoji = '⬇️⬇️';
                        } else if (direction === 'down') {
                            emoji = '⬇️';
                        }
                        return (
                            <div key={'gl-' + idx} className="guess-result">
                                {emoji} ${guess}
                            </div>
                        );
                    })}
                </div>
            )}
            <Footer puzzleId={puzzleId} currentPage={'puzzle'} puzzleType={puzzleType} />
        </div>
    );
};

export default GTHPuzzle;
