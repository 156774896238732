// @flow

import type { AnswerUnion, GameSettings } from './settings/game_settings';
import { loadLocalStorageItem } from './localStateUtils';

export const gameHasBeenPlayed = (puzzleId: number, settings: GameSettings) => {
    const gameState = loadLocalStorageItem(puzzleId, settings.storage_keys.game_state);
    return gameState === 'lose' || gameState === 'win';
};

export const populateGameList = (answers: AnswerUnion): Array<string> => {
    let gameList = [];
    // Add all answers from answers.js to gameList, avoiding duplicates. In case the server goes down people can still guess
    for (const gameID in answers) {
        for (const answer of answers[gameID].answers) {
            if (!gameList.some((game) => game.toLowerCase() === answer.toLowerCase())) {
                gameList.unshift(answer);
            }
        }
    }
    return gameList;
};

export const populateQuestGameList = (answers: AnswerUnion): Array<string> => {
    let gameList = [];
    // Add all answers from answers.js to gameList, avoiding duplicates
    for (const puzzleId in answers) {
        const quest = answers[puzzleId];
        quest.puzzles.forEach((puzzle) => {
            for (const answer of puzzle.answers) {
                if (!gameList.some((game) => game.toLowerCase() === answer.toLowerCase())) {
                    gameList.unshift(answer);
                }
            }
        });
    }
    return gameList;
};

export const calcQuestProgressBar = (settings: GameSettings, totalItems: number, puzzleId: number) => {
    const prevPuzzles = [];

    console.log('quest totalItems:', totalItems);
    for (let itemId = 1; itemId <= totalItems; itemId++) {
        // find the game state
        const gameState = loadLocalStorageItem(puzzleId + '_' + itemId, settings.storage_keys.game_state);
        console.log('gamestate:', gameState);
        switch (gameState) {
            case 'win':
                prevPuzzles.push('s');
                break;
            case 'lose':
                prevPuzzles.push('f');
                break;
            // case 'playing':
            //     prevPuzzles.push('f');
            //     break;
            case 'unplayed':
                prevPuzzles.push('q');
                break;
            default:
                prevPuzzles.push('q');
                break;
        }
    }
    console.log('prevPuzzles Quest Progress bar:', prevPuzzles);
    return prevPuzzles;
};
