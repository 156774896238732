/* eslint-disable no-useless-escape */
// @flow

export type TGTAAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    track_length: string,
    release_year: string,
    genre: string,
    album: string,
    artist: string,
    preview_url: string,
    offset: number,
};

type TGTAAnswers = {
    [string]: TGTAAnswer
};

export const answers: TGTAAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Never Gonna Give You Up"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/never-gonna-give-you-up/1558533900?i=1558534271&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '1987',
        genre: 'Dance-Pop',
        album: 'Whenever You Need Somebody',
        artist: 'Rick Astley',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview124/v4/16/0e/1e/160e1e17-1b38-1980-06ac-ec5e21f85a06/mzaf_542976056230652325.plus.aac.ep.m4a',
        offset: '0',
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["Munch"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/munch-feelin-u/1637158002?i=1637158003&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '1:44',
        release_year: '2022',
        genre: 'Hip-hop/Rap',
        album: 'Like..?',
        artist: 'Ice Spice',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/28/ae/72/28ae7227-3524-8f2f-6d63-7373c9fe0a97/mzaf_10958608429377822024.plus.aac.p.m4a',
        offset: '0',
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["Anti-Hero", "Anti"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/anti-hero/1649434004?i=1649434293&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:20',
        release_year: '2022',
        genre: 'Pop',
        album: 'Midnights',
        artist: 'Taylor Swift',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d4/97/76/d4977661-7199-f4e8-2eb6-37c9933ff29c/mzaf_6637430209106433636.plus.aac.ep.m4a',
        offset: '0',
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Feel Good Inc.", "Feel Good Inc"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/feel-good-inc/850571319?i=850571371&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:41',
        release_year: '2005',
        genre: 'Alternative',
        album: 'Demon Days',
        artist: 'Gorillaz',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/3e/d9/5f/3ed95f77-3df4-db27-6147-d7cd49a77a5b/mzaf_15696408187123049121.plus.aac.ep.m4a',
        offset: '0',
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Renegades"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/renegades/1440816349?i=1440816490&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:15',
        release_year: '2015',
        genre: 'Alternative',
        album: 'VHS',
        artist: 'X Ambassadors',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c2/23/62/c22362eb-e0f7-9b78-90e1-08cbc9b0e3a7/mzaf_13322690910737304619.plus.aac.ep.m4a',
        offset: '0',
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["Blurred Lines"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/blurred-lines-feat-t-i-pharrell/1443223626?i=1443223753&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:22',
        release_year: '2013',
        genre: 'Pop',
        album: 'Single',
        artist: 'Robin Thicke',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/53/32/8b/53328bdd-d670-144e-3a1a-2161aec0bae2/mzaf_13097104864967968695.plus.aac.ep.m4a',
        offset: '0',
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Jump"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/jump/170146852?i=170147030&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:26',
        release_year: '1992',
        genre: 'Hip Hop',
        album: 'Totally Krossed Out',
        artist: 'Kriss Kross',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e2/dd/53/e2dd53ce-6d2b-7f61-4f44-69602b600b4f/mzaf_9665485161426959711.plus.aac.ep.m4a',
        offset: '0',
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["Fireflies"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/fireflies/1440782380?i=1440783289&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:48',
        release_year: '2008',
        genre: 'Electronic',
        album: 'Ocean Eyes',
        artist: 'Owl City',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/d6/24/ce/d624cedb-8945-4ee2-ce01-072eacdb28f3/mzaf_10341725247521542382.plus.aac.ep.m4a',
        offset: '0',
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["I Gotta Feeling"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-gotta-feeling/1440768902?i=1440769310&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:51',
        release_year: '2008',
        genre: 'Pop',
        album: 'The E.N.D',
        artist: 'The Black Eyed Peas',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/4b/0d/40/4b0d4032-3f23-dbf3-217f-d444830a7a39/mzaf_17947386294478091042.plus.aac.ep.m4a',
        offset: '0',
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Shadow Dancing"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/shadow-dancing/1442254032?i=1442254272&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:34',
        release_year: '1978',
        genre: 'Pop',
        album: 'Single',
        artist: 'Andy Gibb',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/32/6a/03/326a0381-f117-e0e0-89b5-f547629e38ff/mzaf_9993179902823537291.plus.aac.ep.m4a',
        offset: '0',
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["Bohemian Rhapsody"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bohemian-rhapsody/1440650428?i=1440650711&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:54',
        release_year: '1975',
        genre: 'Progressive Rock',
        album: 'A Night at the Opera',
        artist: 'Queen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/ae/ac/e7/aeace7b3-74d8-7025-0770-37508d58896c/mzaf_3293557138349057650.plus.aac.ep.m4a',
        offset: '0',
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["Hey Ya!"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hey-ya/1032178894?i=1032178989&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:09',
        release_year: '2003',
        genre: 'Funk, Pop',
        album: 'Speakerboxxx/The Love Below',
        artist: 'OutKast',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/6d/38/8a/6d388add-01af-2ee7-0f0b-5868fb4cfb63/mzaf_10837445602037807283.plus.aac.ep.m4a',
        offset: '0',
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Billie Jean"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/billie-jean/269572838?i=269573364&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:54',
        release_year: '1982',
        genre: 'Pop',
        album: 'Thriller',
        artist: 'Michael Jackson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/11/0a/ed/110aed00-d673-04a2-d750-d970bc9b52f5/mzaf_2941147192862021024.plus.aac.ep.m4a',
        offset: '0',
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["I Will Always Love You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-will-always-love-you-film-version/1293435381?i=1293435389&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:31',
        release_year: '1992',
        genre: 'Pop, R&B',
        album: 'The Bodyguard: Original Soundtrack Album',
        artist: 'Whitney Houston',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c5/c3/01/c5c301e0-7d4f-8506-10a8-323527ab0eaf/mzaf_15627616686540652497.plus.aac.ep.m4a',
        offset: '0',
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Trains"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/trains/1581760123?i=1581760127&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:56',
        release_year: '2002',
        genre: 'Progressive Rock',
        album: 'In Absentia',
        artist: 'Porcupine Tree',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/bd/3f/c9/bd3fc9a1-0ab0-307e-85a2-30e7a0aca2b9/mzaf_7966878880540051706.plus.aac.ep.m4a',
        offset: '0',
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Zombie"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/zombie/682197269?i=682197272&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '12:26',
        release_year: '1976',
        genre: 'Afrobeat',
        album: 'Zombie',
        artist: 'Fela Kuti',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/77/2d/7c/772d7ca1-a5ff-d6c2-8adf-f228aa681401/mzaf_12391757040224236769.plus.aac.ep.m4a',
        offset: '0',
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["Hey Jude"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hey-jude/1441133100?i=1441133277&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '7:11',
        release_year: '1968',
        genre: 'Pop, Rock',
        album: 'Single',
        artist: 'The Beatles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/3b/ef/5e/3bef5efe-973b-7680-18b2-5298380fbbe2/mzaf_17263020089865440864.plus.aac.ep.m4a',
        offset: '0',
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["Crazy"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/crazy/152471339?i=152471393&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:58',
        release_year: '2006',
        genre: 'Neo-Soul, Psychedelic Soul',
        album: 'St. Elsewhere',
        artist: 'Gnarls Barkley',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/79/b3/22/79b32271-94ba-c354-f6ae-6d39e3d47916/mzaf_3847518460098434595.plus.aac.ep.m4a',
        offset: '0',
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["Smells Like Teen Spirit"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/smells-like-teen-spirit/1440783617?i=1440783625&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:01',
        release_year: '1991',
        genre: 'Grunge, Alternative Rock',
        album: 'Nevermind',
        artist: 'Nirvana',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/a6/53/1e/a6531efa-397c-eb73-ecab-9b2790c1471e/mzaf_7698442056504214822.plus.aac.ep.m4a',
        offset: '0',
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["My Heart Will Go On"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/my-heart-will-go-on-love-theme-from-titanic/479204871?i=479204873&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:41',
        release_year: '1997',
        genre: 'Pop, Adult Contemporary',
        album: 'Lets Talk About Love, Titanic: Music from the Motion Picture',
        artist: 'Celine Dion',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/9b/e8/fb/9be8fb2a-4ea3-81f1-0751-94fd5f5271b1/mzaf_7335235615337317962.plus.aac.ep.m4a',
        offset: '0',
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["Teardrop"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/teardrop/724466069?i=724466700&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:31',
        release_year: '1998',
        genre: 'Trip-hop',
        album: 'Mezzanine',
        artist: 'Massive Attack',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fc/bd/b5/fcbdb5db-ae0c-4be3-f55b-d67788d5d45c/mzaf_8581019489050053651.plus.aac.ep.m4a',
        offset: '0',
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["Like a Prayer"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/like-a-prayer/83448003?i=83445997&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:41',
        release_year: '1989',
        genre: 'Pop',
        album: 'Single',
        artist: 'Madonna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/25/02/61/2502618e-9958-94fb-40d9-84ad7716df77/mzaf_6469127560280158347.plus.aac.ep.m4a',
        offset: '0',
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Do I Wanna Know?"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/do-i-wanna-know/663097964?i=663097965&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:33',
        release_year: '2013',
        genre: 'Indie Rock',
        album: 'AM',
        artist: 'Arctic Monkeys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/df/c3/9c/dfc39caa-a559-b5ac-5b50-472a1c300ca6/mzaf_15682988291301281989.plus.aac.ep.m4a',
        offset: '0',
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["Beautiful Day"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/beautiful-day/1440729856?i=1440729857&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:05',
        release_year: '2000',
        genre: 'Rock',
        album: 'All That You Cant Leave Behind',
        artist: 'U2',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/33/35/fc/3335fc81-81f0-72ed-3713-4521aa750f8b/mzaf_12760007013222114628.plus.aac.ep.m4a',
        offset: '0',
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["Paint It, Black"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/paint-it-black/1440764786?i=1440765582&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:45',
        release_year: '1966',
        genre: 'Rock',
        album: 'Aftermath',
        artist: 'The Rolling Stones',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/6a/58/90/6a589098-51ab-2581-4480-02bc6479d9d9/mzaf_16704525850788433313.plus.aac.ep.m4a',
        offset: '0',
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["All I Want for Christmas Is You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/all-i-want-for-christmas-is-you/585972750?i=585972803&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '4:01',
        release_year: '1994',
        genre: 'Pop',
        album: 'Merry Christmas',
        artist: 'Mariah Carey',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3a/6a/0c/3a6a0c22-1b3b-0233-a640-d67f279db021/mzaf_15744846541146197075.plus.aac.ep.m4a',
        offset: '0',
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["21st Century Schizoid Man"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/21st-century-schizoid-man-radio-version/918534711?i=918534782&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '7:20',
        release_year: '1969',
        genre: 'Progressive Rock',
        album: 'In the Court of the Crimson King',
        artist: 'King Crimson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/56/f6/da/56f6da29-bb91-9eae-4ffb-8af21724cac2/mzaf_4937684559161029706.plus.aac.ep.m4a',
        offset: '0',
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["So What"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/so-what/268443092?i=268443097&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '9:22',
        release_year: '1959',
        genre: 'Jazz',
        album: 'Kind of Blue',
        artist: 'Miles Davis',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/07/c4/5d/07c45dd9-1c4c-db5d-e0d8-fb658e0417ed/mzaf_15679891071737199127.plus.aac.ep.m4a',
        offset: '0',
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["Rocket Man"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rocket-man-i-think-its-going-to-be-a-long-long-time/1667276747?i=1667279707&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '4:41',
        release_year: '1972',
        genre: 'Pop, Soft Rock',
        album: 'Honky Château',
        artist: 'Elton John',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/c5/b0/4a/c5b04a51-0d6a-0f5c-9aaf-b1b76d898669/mzaf_14318249477604490598.plus.aac.ep.m4a',
        offset: '0',
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["I Will Wait"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-will-wait/1451531115?i=1451531639&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '4:36',
        release_year: '2012',
        genre: 'Folk Rock',
        album: 'Babel',
        artist: 'Mumford & Sons',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/ff/86/f5/ff86f525-db18-bc77-8e3a-58fea75ae12f/mzaf_7169201912212078052.plus.aac.ep.m4a',
        offset: '0',
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["Umbrella"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/umbrella-feat-jay-z/1441154435?i=1441154437&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '4:35',
        release_year: '2007',
        genre: 'Pop, R&B',
        album: 'Good Girl Gone Bad',
        artist: 'Rihanna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/81/fa/c2/81fac225-5a24-9973-4e49-06faf896febc/mzaf_16991140813676911599.plus.aac.ep.m4a',
        offset: '0',
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Three Little Birds"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/three-little-birds/1469575763?i=1469575900&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '3:00',
        release_year: '1977',
        genre: 'Reggae',
        album: 'Exodus',
        artist: 'Bob Marley',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/77/7c/f4/777cf450-f96d-01e8-759a-9724bab45cd4/mzaf_17254608282900833427.plus.aac.ep.m4a',
        offset: '0',
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Stayin' Alive"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/stayin-alive-from-saturday-night-fever-soundtrack/1442259177?i=1442259185&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '4:45',
        release_year: '1977',
        genre: 'Disco',
        album: 'Saturday Night Fever',
        artist: 'Bee Gees',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/1c/fa/fd/1cfafd11-4119-8a08-48f1-daced3f5684a/mzaf_17044899870040274080.plus.aac.ep.m4a',
        offset: '0',
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["pick up the phone"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/pick-up-the-phone/1150135681?i=1150135947&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'Young Thug',
        track_length: '3:11',
        release_year: '2016',
        genre: 'Hip-Hop/Rap',
        album: 'Birds in the Trap Sing McKnight',
        artist: 'Young Thug feat. Travis Scott',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0f/96/9a/0f969a74-0b34-0684-c661-6ef5e7bf7081/mzaf_8895940594498998454.plus.aac.ep.m4a',
        offset: '0',
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["Roygbiv"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/roygbiv/281116024?i=281116081&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '2:31',
        release_year: '1998',
        genre: 'IDM/Electronic',
        album: 'Music Has the Right to Children',
        artist: 'Boards of Canada',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c5/fe/9b/c5fe9bbd-3511-6797-560c-bd77759d63d0/mzaf_18333533155458506848.plus.aac.ep.m4a',
        offset: '0',
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["Can't Help Falling in Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cant-help-falling-in-love/217633715?i=217635636&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '2:59',
        release_year: '1961',
        genre: 'Pop',
        album: 'Blue Hawaii',
        artist: 'Elvis Presley',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/5b/73/cc/5b73ccbb-3d42-8489-5f34-c45f28886fb9/mzaf_13426629074151349216.plus.aac.ep.m4a',
        offset: '0',
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["Black Skinhead"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/black-skinhead/1440873068?i=1440873230&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '3:13',
        release_year: '2013',
        genre: 'Hip-Hop/Rap',
        album: 'Yeezus',
        artist: 'Kanye West',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0c/10/8f/0c108f4d-c3dc-4374-ceb3-080aaaadc341/mzaf_11569181726934725291.plus.aac.ep.m4a',
        offset: '0',
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["In Too Deep"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/in-too-deep/1440920798?i=1440921233&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '3:34',
        release_year: '2000',
        genre: 'Punk, Pop-Punk',
        album: 'All Killer No Filler',
        artist: 'Sum 41',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/90/f0/69/90f069b1-15e4-4baf-9142-ea0b039d7fc0/mzaf_13795663722232958952.plus.aac.ep.m4a',
        offset: '0',
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Hotel California"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hotel-california/635770200?i=635770202&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '6:30',
        release_year: '1976',
        genre: 'Rock',
        album: 'Single',
        artist: 'Eagles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a7/1b/f0/a71bf07d-f498-05c9-2c8a-d12af7d019d8/mzaf_11432856538063967872.plus.aac.ep.m4a',
        offset: '0',
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["Livin' on a Prayer", "Living on a Prayer"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/livin-on-a-prayer/1440677662?i=1440677665&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'Bon Jovi',
        track_length: '4:09',
        release_year: '1986',
        genre: 'Rock',
        album: 'Slippery When Wet',
        artist: 'Bon Jovi',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/2e/a4/41/2ea441db-a4a8-1a5e-e3a3-c00fbc6990cc/mzaf_1088094180482199584.plus.aac.ep.m4a',
        offset: '0',
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["Hoppipolla"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hopp%C3%ADpolla/1447486974?i=1447486980&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '4:28',
        release_year: '2005',
        genre: 'Post-Rock',
        album: 'Takk...',
        artist: 'Sigur Rós',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/dd/b7/80/ddb780d0-3a33-2921-922b-57822f1d1623/mzaf_784789249760073410.plus.aac.ep.m4a',
        offset: '0',
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["Trouble on My Mind"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/trouble-on-my-mind-feat-tyler-the-creator/1570362163?i=1570362348&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '2011',
        genre: 'Hip-Hop/Rap',
        album: 'Fear of God II: Let Us Pray',
        artist: 'Pusha T',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/86/a7/e1/86a7e15f-9f27-2c42-a833-bd9bde910b63/mzaf_12085435566669224245.plus.aac.ep.m4a',
        offset: '0',
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["Dancing Queen"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dancing-queen/1422648512?i=1422648513&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:51',
        release_year: '1976',
        genre: 'Pop',
        album: 'Arrival',
        artist: 'Abba',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/91/27/9c/91279cdb-1720-549e-b53e-1990191ba4af/mzaf_13147789945387957607.plus.aac.ep.m4a',
        offset: '0',
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["A-Punk", "A"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/a-punk/270425072?i=270425151&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:17',
        release_year: '2008',
        genre: 'Indie Rock',
        album: 'Vampire Weekend',
        artist: 'Vampire Weekend',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/3d/cd/9e/3dcd9e9a-39cd-a48d-147f-804de46cdb0c/mzaf_6964561585936715341.plus.aac.p.m4a',
        offset: '0',
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["SexyBack"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sexyback/400946401?i=400946435&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:03',
        release_year: '2006',
        genre: 'Pop, Dance-pop',
        album: 'FutureSex/LoveSounds',
        artist: 'Justin Timberlake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/07/30/fb/0730fb89-c2be-8437-c1ff-759aa6e0ccf5/mzaf_5558201454770369785.plus.aac.ep.m4a',
        offset: '0',
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["Stairway to Heaven"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/stairway-to-heaven/580708175?i=580708180&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '8:02',
        release_year: '1971',
        genre: 'Hard Rock, Folk Rock',
        album: 'Led Zeppelin IV',
        artist: 'Led Zeppelin',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/60/61/5f/60615fd1-7425-78b3-21ab-fed7411a89ea/mzaf_2976356068904288562.plus.aac.ep.m4a',
        offset: '0',
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["All The Small Things"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/all-the-small-things/1440839912?i=1440840510&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\" height=\"175px\" frameborder=\"0\"></iframe>"],
        franchise: 'None',
        track_length: '2:51',
        release_year: '1999',
        genre: 'Punk, Punk-pop',
        album: 'All The Small Things',
        artist: 'Blink-182',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/48/18/c0/4818c0c9-4a95-eb7b-8632-1db6dd9b22dc/mzaf_15406558891233392369.plus.aac.ep.m4a',
        offset: '0',
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["Hold On"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hold-on/1485070836?i=1485070839&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:33',
        release_year: '1999',
        genre: 'Blues/Rock',
        album: 'Mule Variations',
        artist: 'Tom Waits',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/91/6f/c6/916fc65b-3305-b306-621f-2702e014f580/mzaf_14516677071627621624.plus.aac.ep.m4a',
        offset: '0',
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["Windowlicker"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/windowlicker/281953326?i=281953327&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Aphex Twin',
        track_length: '6:07',
        release_year: '1999',
        genre: 'Electronic/IDM',
        album: 'Windowlicker EP',
        artist: 'Aphex Twin',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/8f/83/d1/8f83d198-92ce-c7bd-a892-0dafc9940dd2/mzaf_3996517969073652523.plus.aac.ep.m4a',
        offset: '1',
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["My Way"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/my-way/1440858119?i=1440858717&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:35',
        release_year: '1963',
        genre: 'Traditional/Older Pop',
        album: 'Single',
        artist: 'Frank Sinatra',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d1/a8/c1/d1a8c1cc-3fcd-9da1-2132-53b299e2da2c/mzaf_9780720677925300232.plus.aac.ep.m4a',
        offset: '0',
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["Hold On"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hold-on/1072477718?i=1072477719&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:46',
        release_year: '2012',
        genre: 'Southern Rock/Blues Rock',
        album: 'Boys & Girls',
        artist: 'Alabama Shakes',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/0a/1f/83/0a1f83c6-bbb6-63e7-6b53-b9dc27ba7b70/mzaf_7761683400497991086.plus.aac.ep.m4a',
        offset: '0',
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["Hips Don't Lie"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hips-dont-lie-feat-wyclef-jean/155658405?i=155658510&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:38',
        release_year: '2005',
        genre: 'Pop, Salsa, Worldbeat',
        album: 'Oral Fixation, Vol. 2',
        artist: 'Shakira',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/80/ac/44/80ac449f-3003-4deb-9d97-bebb6d1676ee/mzaf_8298589059033803343.plus.aac.ep.m4a',
        offset: '0',
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["Every Breath You Take"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/every-breath-you-take/1440882817?i=1440882897&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:13',
        release_year: '1983',
        genre: 'New wave, Rock',
        album: 'Synchronicity',
        artist: 'The Police',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/f3/88/58/f38858bd-5a0f-e94e-48a1-5402f9a3db29/mzaf_12294568415819492040.plus.aac.ep.m4a',
        offset: '0',
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["Superstition"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/superstition/1440808973?i=1440808985&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:26',
        release_year: '1972',
        genre: 'Funk, Soul',
        album: 'Talking Book',
        artist: 'Stevie Wonder',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/14/72/26/14722685-0421-a852-6ad8-40a58b709d10/mzaf_2307962638269790147.plus.aac.ep.m4a',
        offset: '0',
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["BackOutsideBoyz"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/backoutsideboyz/1652998965?i=1652999431&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:33',
        release_year: '2022',
        genre: 'Hip-Hop',
        album: 'Her Loss',
        artist: 'Drake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0b/0a/c3/0b0ac3e7-57ca-8ed1-dd01-ae800d0c9a25/mzaf_18290980148741717641.plus.aac.ep.m4a',
        offset: '0',
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["Giant Steps"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/giant-steps-2020-remaster/1531037502?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"450px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:43',
        release_year: '1960',
        genre: 'Jazz',
        album: 'Single',
        artist: 'John Coltrane',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/ac/36/80/ac36807f-dd04-4ded-a91e-4c08774ef99c/mzaf_17412488162878807343.plus.aac.ep.m4a',
        offset: '0',
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["Rolling in the Deep"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rolling-in-the-deep/1544491232?i=1544491233&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:48',
        release_year: '2010',
        genre: 'Soul, Pop',
        album: '21',
        artist: 'Adele',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/9f/07/1d/9f071dc7-791c-c869-dfa2-06b25936a287/mzaf_3679399808385576247.plus.aac.ep.m4a',
        offset: '0',
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["Dog Days Are Over"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dog-days-are-over/1440729743?i=1440729744&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:12',
        release_year: '2008',
        genre: 'Indie Pop/Art Pop/Alternative',
        album: 'Lungs',
        artist: 'Florence + The Machine',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/93/0a/18/930a18f4-fc27-bbd8-0dc6-b064393a4f2b/mzaf_4791639690037941434.plus.aac.ep.m4a',
        offset: '0',
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["Clocks"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/clocks/1122775993?i=1122776156&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:01',
        release_year: '2002',
        genre: 'Alternative Rock',
        album: 'A Rush of Blood to the Head',
        artist: 'Coldplay',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/6f/fb/b3/6ffbb31c-8861-4b2b-e496-80f675dc260c/mzaf_16725587548737347504.plus.aac.ep.m4a',
        offset: '0',
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["Comfortably Numb"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/comfortably-numb/1065975633?i=1065976170&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:21',
        release_year: '1979',
        genre: 'Progressive Rock',
        album: 'The Wall',
        artist: 'Pink Floyd',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fe/44/11/fe441140-ff52-28ef-d7c4-d87b3084d959/mzaf_16992442534158252100.plus.aac.ep.m4a',
        offset: '0',
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["I Want It That Way"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-want-it-that-way/283567119?i=283567164&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:33',
        release_year: '1999',
        genre: 'Pop',
        album: 'Millennium',
        artist: 'The Backstreet Boys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/2f/fa/73/2ffa73ff-9908-a539-ad04-db1cf9ca5011/mzaf_6860888862493030395.plus.aac.ep.m4a',
        offset: '0',
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["Grace"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/grace/1046187510?i=1046187578&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:22',
        release_year: '1994',
        genre: 'Alternative Rock',
        album: 'Single',
        artist: 'Jeff Buckley',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d6/a6/3c/d6a63c45-eda0-e034-f991-9fdaabe5bafa/mzaf_75699969029982752.plus.aac.ep.m4a',
        offset: '0',
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["Space is the Place"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/space-is-the-place/1443831739?i=1443831974&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '21:14',
        release_year: '1973',
        genre: 'Avant-garde Jazz',
        album: 'Single',
        artist: 'Sun Ra',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ce/24/7b/ce247b62-2549-771d-ab2c-2f1cd0202f0a/mzaf_7277949865012526888.plus.aac.ep.m4a',
        offset: '0',
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["Purple Rain"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/purple-rain/214145442?i=214146211&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '8:41',
        release_year: '1984',
        genre: 'Pop, Rock, R&B',
        album: 'Single',
        artist: 'Prince',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b4/d3/4f/b4d34f80-3d53-49b3-2753-42d9b093858a/mzaf_6228848173519847396.plus.aac.ep.m4a',
        offset: '0',
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["Aliens Exist"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/aliens-exist/1440839912?i=1440840155&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:22',
        release_year: '1998',
        genre: 'Pop Punk',
        album: 'Enema of the State',
        artist: 'Blink-182',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/dd/e6/eb/dde6eba3-1ee1-4760-ca36-da030422c862/mzaf_5362936522202079594.plus.aac.ep.m4a',
        offset: '0',
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["This Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/this-love/1440851650?i=1440851719&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:26',
        release_year: '2002',
        genre: 'Pop Rock, Blue-eyed Soul',
        album: 'Songs About Jane',
        artist: 'Maroon 5',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/7a/bb/7a/7abb7a54-7e96-b071-23c2-e3c866308b64/mzaf_8945696957303848130.plus.aac.ep.m4a',
        offset: '0',
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["Space Oddity"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/space-oddity/1039799506?i=1039800298&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:15',
        release_year: '1969',
        genre: 'Art Rock, Psychedelic Rock',
        album: 'Single',
        artist: 'David Bowie',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/2a/85/7f/2a857fae-d0b0-d282-3ab7-0e026b04c194/mzaf_16878156657559556832.plus.aac.ep.m4a',
        offset: '0',
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["...Baby One More Time"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/baby-one-more-time/273143723?i=273143820&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:30',
        release_year: '1998',
        genre: 'Pop, Teen Pop',
        album: 'Single',
        artist: 'Britney Spears',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/54/2f/10/542f10c0-8f6f-8803-37f6-bc45c3327b41/mzaf_9547459566157646314.plus.aac.ep.m4a',
        offset: '0',
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["I Just Wanna Run"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-just-wanna-run/431764465?i=431764480&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:20',
        release_year: '2011',
        genre: 'Pop Rock, Power Pop',
        album: 'Lets be Animals',
        artist: 'The Downtown Fiction',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/86/37/7a/86377acf-e2e6-7b91-2573-0f3034008585/mzaf_445050678622593397.plus.aac.ep.m4a',
        offset: '0',
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["Patiently Waiting"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/patiently-waiting-feat-eminem/1440841450?i=1440841845&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:49',
        release_year: '2003',
        genre: 'Hip Hop/Rap',
        album: 'Get Rich or Die Tryin',
        artist: '50 Cent',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/97/c3/24/97c32474-7eb0-a34e-01df-52e3aa2cb0ef/mzaf_11717285147717014718.plus.aac.ep.m4a',
        offset: '0',
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["Good Vibrations"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/good-vibrations/1442864862?i=1442865074&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:37',
        release_year: '1966',
        genre: 'Psychedelic Pop, Progressive Pop',
        album: 'Smiley Smile',
        artist: 'The Beach Boys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/11/ab/cd/11abcdd3-4328-ceae-ddce-ecccf934b7d0/mzaf_4014312645122534205.plus.aac.ep.m4a',
        offset: '0',
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["The Suburbs"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-suburbs/1252757950?i=1252758311&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:15',
        release_year: '2009',
        genre: 'Indie Rock',
        album: 'Same as Song Title',
        artist: 'Arcade Fire',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/01/c9/16/01c91671-0b00-9a35-57ea-17b8a72eba38/mzaf_14664104864256359519.plus.aac.ep.m4a',
        offset: '0',
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["Lose Yourself"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lose-yourself/1440903339?i=1440903439&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:20',
        release_year: '2002',
        genre: 'Hip Hop',
        album: '8 Mile: Music from and Inspired by the Motion Picture',
        artist: 'Eminem',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/62/0a/a5/620aa56f-189e-708a-80f0-cebdada3872e/mzaf_17934827624436799635.plus.aac.ep.m4a',
        offset: '0',
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["Thriller"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/thriller/269572838?i=269573303&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:57',
        release_year: '1982',
        genre: 'Pop, Post-Disco',
        album: 'Single',
        artist: 'Michael Jackson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/38/69/23/38692386-9124-5a49-f11e-3690c056d009/mzaf_12330215510067601989.plus.aac.ep.m4a',
        offset: '0',
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["Taste"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/taste-feat-offset/1384304786?i=1384304787&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:52',
        release_year: '2018',
        genre: 'Hip Hop',
        album: 'Single',
        artist: 'Tyga',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/bb/6b/c8/bb6bc802-78d2-c0b9-1434-cdcd7c6c7eb3/mzaf_17869881776558752489.plus.aac.ep.m4a',
        offset: '0',
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["Late Nights in My Car"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/late-nights-in-my-car/1553606875?i=1553606876&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:32',
        release_year: '2012',
        genre: 'Pop Punk',
        album: 'Put yourself back together',
        artist: 'Real Friends',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/64/22/0b/64220b2e-1148-a9e5-a6b4-0c840308e3f4/mzaf_3209643196278601969.plus.aac.ep.m4a',
        offset: '0',
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["Stronger"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/stronger/1442845779?i=1442846328&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:12',
        release_year: '2006',
        genre: 'Hip Hop',
        album: 'Graduation',
        artist: 'Kanye West',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/96/f2/25/96f225f2-cd07-3639-4133-0910aa9725c0/mzaf_2532149939411909598.plus.aac.ep.m4a',
        offset: '0',
    },
    '78': {
        content: '',
        submitted_by: '',
        answers: ["Born in the U.S.A."],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/born-in-the-u-s-a/203708420?i=203708455&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:39',
        release_year: '1984',
        genre: 'Heartland Rock, Pop Rock',
        album: 'Single',
        artist: 'Bruce Springsteen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/47/97/9e/47979e22-8765-4d1b-add9-49f48fe79746/mzaf_12370679233428014631.plus.aac.ep.m4a',
        offset: '0',
    },
    '79': {
        content: '',
        submitted_by: '',
        answers: ["Summertime Sadness"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/summertime-sadness/1440811595?i=1440812085&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:25',
        release_year: '2010',
        genre: 'Dream Pop, Trip Hop',
        album: 'Born to Die',
        artist: 'Lana Del Rey',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f5/a3/d0/f5a3d08a-635d-dc15-4c3f-ad8a04246d80/mzaf_7546814019285958589.plus.aac.ep.m4a',
        offset: '0',
    },
    '80': {
        content: '',
        submitted_by: '',
        answers: ["Where Is The Love?"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/where-is-the-love/1615142131?i=1615142635&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:32',
        release_year: '2002',
        genre: 'Hip Hop, Pop',
        album: 'Elephunk',
        artist: 'The Black Eyed Peas',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ed/f9/a3/edf9a3ab-e683-b443-9293-64a183165eac/mzaf_12865082490414141106.plus.aac.ep.m4a',
        offset: '0',
    },
    '81': {
        content: '',
        submitted_by: '',
        answers: ["Light My Fire"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/light-my-fire/640047463?i=640047583&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '7:06',
        release_year: '1967',
        genre: 'Psychedelic Rock',
        album: 'The Doors',
        artist: 'The Doors',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e9/0b/a1/e90ba14d-afa3-7707-8c73-87b1d8815983/mzaf_9265418118278763898.plus.aac.ep.m4a',
        offset: '0',
    },
    '82': {
        content: '',
        submitted_by: '',
        answers: ["Radioactive"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/radioactive/1440873107?i=1440873126&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:06',
        release_year: '2012',
        genre: 'Alternative Rock, Electronic Rock',
        album: 'Night Visions',
        artist: 'Imagine Dragons',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/be/66/b7/be66b7f1-7e62-4565-69db-27cb411b7b5c/mzaf_15274320387563800922.plus.aac.ep.m4a',
        offset: '0',
    },
    '83': {
        content: '',
        submitted_by: '',
        answers: ["A Milli"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/a-milli/1440737099?i=1440737322&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:42',
        release_year: '2008',
        genre: 'Hip Hop',
        album: 'Tha Carter III',
        artist: 'Lil Wayne',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/a1/45/47/a1454729-887c-bb65-e15d-1eb338338590/mzaf_3815776628996725255.plus.aac.ep.m4a',
        offset: '0',
    },
    '84': {
        content: '',
        submitted_by: '',
        answers: ["Friday"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/friday/1650021643?i=1650021644&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:30',
        release_year: '2011',
        genre: 'Bubblegum Pop',
        album: 'Single',
        artist: 'Rebecca Black',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/32/f9/76/32f9764e-8703-450e-95b3-d83d4eea7289/mzaf_17288481291982485549.plus.aac.ep.m4a',
        offset: '0',
    },
    '85': {
        content: '',
        submitted_by: '',
        answers: ["Respect"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/respect/937107826?i=937107838&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:27',
        release_year: '1967',
        genre: 'R&B, Soul',
        album: 'I Never Loved a Man the Way I Love You',
        artist: 'Aretha Franklin',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/ee/3d/73/ee3d7366-bdcb-1370-a807-e9a138345974/mzaf_8633168135836320660.plus.aac.p.m4a',
        offset: '0',
    },
    '86': {
        content: '',
        submitted_by: '',
        answers: ["Blinding Lights"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/blinding-lights/1488408555?i=1488408568&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:22',
        release_year: '2019',
        genre: 'Synth-Pop/R&B/Soul',
        album: 'After Hours',
        artist: 'The Weeknd',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/fb/a9/64/fba9649a-837c-a4c5-d1c7-6a1512e15818/mzaf_8265222757986749284.plus.aac.ep.m4a',
        offset: '0',
    },
    '87': {
        content: '',
        submitted_by: '',
        answers: ["Get Ur Freak On"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/get-ur-freak-on/83134338?i=83134182&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:57',
        release_year: '2001',
        genre: 'Hip Hop',
        album: 'Miss E... So Addictive',
        artist: 'Missy Elliott',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ab/3d/7c/ab3d7ce6-7f2c-615f-4d28-7fcfc7cf3281/mzaf_4040737768492119402.plus.aac.ep.m4a',
        offset: '0',
    },
    '88': {
        content: '',
        submitted_by: '',
        answers: ["Sultans Of Swing"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sultans-of-swing/89345591?i=89342340&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:50',
        release_year: '1978',
        genre: 'Roots Rock, Pub Rock',
        album: 'Dire Straits',
        artist: 'Dire Straits',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f5/f4/38/f5f43817-dbca-5a46-0bb4-6013ef6e0c8f/mzaf_10935367918427482847.plus.aac.ep.m4a',
        offset: '0',
    },
    '89': {
        content: '',
        submitted_by: '',
        answers: ["(Everything I Do) I Do It for You", "I Do It for You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/everything-i-do-i-do-it-for-you/1442961663?i=1442962254&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:34',
        release_year: '1991',
        genre: 'Soft Rock, Pop Rock',
        album: 'Waking Up the Neighbours, Robin Hood: Prince of Thieves (soundtrack)',
        artist: 'Bryan Adams',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f9/56/6e/f9566ed5-9fd9-9c59-3f49-585085fa84b9/mzaf_9540636288273738371.plus.aac.ep.m4a',
        offset: '0',
    },
    '90': {
        content: '',
        submitted_by: '',
        answers: ["Right Back At It Again"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/right-back-at-it-again/1488642960?i=1488642962&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:20',
        release_year: '2013',
        genre: 'Pop Punk, Metalcore',
        album: 'Common Courtesy',
        artist: 'A Day to Remember',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/e6/e5/4f/e6e54f61-7856-b141-1d3f-c5645a2888ba/mzaf_10913851208794211140.plus.aac.ep.m4a',
        offset: '0',
    },
    '91': {
        content: '',
        submitted_by: '',
        answers: ["Dota"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dota-radio-edit/284621432?i=284621463&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:21',
        release_year: '2006',
        genre: 'Eurodance, Trance',
        album: 'LOL <(^^,)>',
        artist: 'Basshunter',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/8a/81/97/8a81973a-2cc7-4ea7-1ceb-24f7ee117bb6/mzaf_4653113355038117088.plus.aac.ep.m4a',
        offset: '0',
    },
    '92': {
        content: '',
        submitted_by: '',
        answers: ["Girls Just Want to Have Fun", "Girls Just Wanna Have Fun"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/girls-just-want-to-have-fun/187488190?i=187488205&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:58',
        release_year: '1983',
        genre: 'New Wave, Synth-Pop',
        album: 'She\'s So Unusual',
        artist: 'Cyndi Lauper',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/50/5e/c6/505ec653-bb11-bb73-7f92-834668c0a1f2/mzaf_11651826255188467204.plus.aac.ep.m4a',
        offset: '0',
    },
    '93': {
        content: '',
        submitted_by: '',
        answers: ["Royals"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/royals/1440818584?i=1440818664&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:10',
        release_year: '2013',
        genre: 'Art Pop',
        album: 'Pure Heroine',
        artist: 'Lorde',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/9e/ec/a5/9eeca5b0-cdb6-384f-2765-01eb5665e220/mzaf_4846363714476803448.plus.aac.ep.m4a',
        offset: '0',
    },
    '94': {
        content: '',
        submitted_by: '',
        answers: ["Crazy In Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/crazy-in-love-feat-jay-z/201274359?i=201274644&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:56',
        release_year: '2003',
        genre: 'R&B, Pop',
        album: 'Dangerously in Love',
        artist: 'Beyoncé',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/49/17/a0/4917a0c8-4776-2317-4c2c-0d5eecad3c3b/mzaf_10579780887059666186.plus.aac.ep.m4a',
        offset: '0',
    },
    '95': {
        content: '',
        submitted_by: '',
        answers: ["Like a Rolling Stone"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/like-a-rolling-stone/201281514?i=201281527&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:13',
        release_year: '1965',
        genre: 'Rock',
        album: 'Highway 61 Revisited',
        artist: 'Bob Dylan',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0d/07/ce/0d07cef9-c7a5-013c-1414-aa9f9370ebfe/mzaf_15708780492522202132.plus.aac.ep.m4a',
        offset: '0',
    },
    '96': {
        content: '',
        submitted_by: '',
        answers: ["Jolene"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/jolene-single-version/282883573?i=282883578&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:43',
        release_year: '1973',
        genre: 'Country',
        album: 'Jolene',
        artist: 'Dolly Parton',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/24/d9/0e/24d90e1e-1abb-c5ba-be6f-3a0ee383250f/mzaf_17885982450530985620.plus.aac.ep.m4a',
        offset: '0',
    },
    '97': {
        content: '',
        submitted_by: '',
        answers: ["Fallen Leaves"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/fallen-leaves/160346607?i=160346639&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '2006',
        genre: 'Punk Rock, Alternative Rock',
        album: 'Album Name similar to Artist Name',
        artist: 'Billy Talent',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c2/52/6f/c2526f39-f92b-4fd4-d878-071639213951/mzaf_642275983557752676.plus.aac.ep.m4a',
        offset: '0',
    },
    '98': {
        content: '',
        submitted_by: '',
        answers: ["Bad Guy"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bad-guy/1450695723?i=1450695739&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:14',
        release_year: '2019',
        genre: 'Pop, Electropop',
        album: 'When We All Fall Asleep, Where Do We Go?',
        artist: 'Billie Eilish',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/17/7c/dd/177cdd36-078e-177f-492d-438497d1f873/mzaf_14642689126288033553.plus.aac.ep.m4a',
        offset: '0',
    },
    '99': {
        content: '',
        submitted_by: '',
        answers: ["Hit the Road Jack"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hit-the-road-jack/1569552576?i=1569553330&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '1:58',
        release_year: '1960',
        genre: 'Rhythm and Blues, Soul',
        album: 'Single release',
        artist: 'Ray Charles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/cf/1e/9a/cf1e9a3a-42c9-07a7-ed31-fa77969a62d8/mzaf_15565412750101856381.plus.aac.p.m4a',
        offset: '0',
    },
    '100': {
        content: '',
        submitted_by: '',
        answers: ["Little Lion Man"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/little-lion-man/1451599078?i=1451599335&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:07',
        release_year: '2008',
        genre: 'Folk Rock, Indie Folk',
        album: 'Sigh No More',
        artist: 'Mumford & Sons',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/13/47/8a/13478a9a-ee7f-662e-a824-4273aad20a95/mzaf_12223352262284823579.plus.aac.ep.m4a',
        offset: '0',
    },
    '101': {
        content: '',
        submitted_by: '',
        answers: ["Get Lucky"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/get-lucky/617154241?i=617154366&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:07',
        release_year: '2013',
        genre: 'Disco, Funk',
        album: 'Random Access Memories',
        artist: 'Daft Punk',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/7f/7d/15/7f7d15c1-01f2-00b6-6ac3-49721657e093/mzaf_2908768494634755682.plus.aac.ep.m4a',
        offset: '0',
    },
    '102': {
        content: '',
        submitted_by: '',
        answers: ["Go Your Own Way"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/go-your-own-way/651879763?i=651880157&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:38',
        release_year: '1977',
        genre: 'Rock',
        album: 'Rumours',
        artist: 'Fleetwood Mac',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3d/b8/95/3db895a0-885d-a130-f841-4b66925d17f3/mzaf_3825528044513687380.plus.aac.ep.m4a',
        offset: '0',
    },
    '103': {
        content: '',
        submitted_by: '',
        answers: ["Wannabe"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/wannabe/1573849246?i=1573849255&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:53',
        release_year: '1996',
        genre: 'Pop, Dance-Pop',
        album: 'Spice',
        artist: 'Spice Girls',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/31/e3/98/31e398f7-0b5d-ae54-df08-229293d32750/mzaf_15284147917290107874.plus.aac.ep.m4a',
        offset: '0',
    },
    '104': {
        content: '',
        submitted_by: '',
        answers: ["Seven Nation Army", "7 Nation Army"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/seven-nation-army/1533513536?i=1533513537&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:52',
        release_year: '2003',
        genre: 'Garage Rock Revival, Alternative Rock',
        album: 'Elephant',
        artist: 'The White Stripes',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/61/54/97/61549744-a83b-1c4d-58cf-e56b36beb4a7/mzaf_16366784674446965555.plus.aac.ep.m4a',
        offset: '0',
    },
    '105': {
        content: '',
        submitted_by: '',
        answers: ["Phenomenal"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/phenomenal/1440836832?i=1440837108&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:43',
        release_year: '2015',
        genre: 'Hip Hop',
        album: 'Southpaw (Music from and Inspired By the Motion Picture)',
        artist: 'Eminem',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/9c/8b/ca/9c8bca78-1bba-8bd7-63ac-4d1c58edccef/mzaf_8259296949445326318.plus.aac.ep.m4a',
        offset: '0',
    },
    '106': {
        content: '',
        submitted_by: '',
        answers: ["Stop! In The Name Of Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/stop-in-the-name-of-love/1440739742?i=1440739750&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:52',
        release_year: '1965',
        genre: 'Pop, Soul',
        album: 'More Hits by The Supremes',
        artist: 'The Supremes',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/14/bc/92/14bc92b6-20aa-30df-2b40-9872dc56facf/mzaf_3690468934999892375.plus.aac.ep.m4a',
        offset: '0',
    },
    '107': {
        content: '',
        submitted_by: '',
        answers: ["Lotus Flower"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lotus-flower/1109714965?i=1109715151&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:01',
        release_year: '2011',
        genre: 'Art Rock',
        album: 'The King of Limbs',
        artist: 'Radiohead',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/5c/a0/14/5ca014cd-a673-f065-edce-55be237799ce/mzaf_11744364765655468226.plus.aac.ep.m4a',
        offset: '0',
    },
    '108': {
        content: '',
        submitted_by: '',
        answers: ["I'm an Albatraoz"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/im-an-albatraoz/922326016?i=922326032&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:46',
        release_year: '2014',
        genre: 'Electro House',
        album: 'Single release',
        artist: 'AronChupa',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/4f/b8/16/4fb816c2-05ff-bddc-bc29-35c3e13823e1/mzaf_14602970260314720146.plus.aac.ep.m4a',
        offset: '0',
    },
    '109': {
        content: '',
        submitted_by: '',
        answers: ["London Calling"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/london-calling/684811762?i=684811768&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '1979',
        genre: 'Punk Rock',
        album: 'Same as song name',
        artist: 'The Clash',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d7/6e/76/d76e7640-c88a-3537-a3a6-bd3ed9fcb8d7/mzaf_2797958541103146980.plus.aac.ep.m4a',
        offset: '0',
    },
    '110': {
        content: '',
        submitted_by: '',
        answers: ["Tiny Dancer"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/tiny-dancer/1440643462?i=1440643464&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:17',
        release_year: '1971',
        genre: 'Soft Rock, Pop',
        album: 'Madman Across the Water',
        artist: 'Elton John',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0d/72/9d/0d729d01-f794-813b-0836-cb0994fe8650/mzaf_17306961241980950236.plus.aac.ep.m4a',
        offset: '0',
    },
    '111': {
        content: '',
        submitted_by: '',
        answers: ["Antidote"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/antidote/1456176981?i=1456177234&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:22',
        release_year: '2015',
        genre: 'Hip Hop, Trap',
        album: 'Rodeo',
        artist: 'Travis Scott',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/04/aa/b5/04aab58a-87ff-a323-b715-fd6a33d79827/mzaf_9686425562573585079.plus.aac.ep.m4a',
        offset: '0',
    },
    '112': {
        content: '',
        submitted_by: '',
        answers: ["Pain"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/pain/266221870?i=266221938&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:23',
        release_year: '2006',
        genre: 'Alternative Rock, Post-Grunge',
        album: 'One-X',
        artist: 'Three Days Grace',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/31/ae/2b/31ae2b8f-7a1a-807f-d07d-3cdef4e0f18a/mzaf_4555953639122046022.plus.aac.ep.m4a',
        offset: '0',
    },
    '113': {
        content: '',
        submitted_by: '',
        answers: ["Listen to The Music"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/listen-to-the-music/1110961242?i=1110962222&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:49',
        release_year: '1972',
        genre: 'Rock, Pop',
        album: 'Toulouse Street',
        artist: 'The Doobie Brothers',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/20/a2/1f/20a21f57-d2c2-0c36-3a62-6f604bbaca3c/mzaf_11431473463715073854.plus.aac.ep.m4a',
        offset: '0',
    },
    '114': {
        content: '',
        submitted_by: '',
        answers: ["Halo"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/halo/296016891?i=296016893&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:21',
        release_year: '2008',
        genre: 'Pop, R&B',
        album: 'I Am... Sasha Fierce',
        artist: 'Beyoncé',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f0/0c/f0/f00cf05a-aaf5-d726-ed7f-e2c0113eba09/mzaf_14991813459049565888.plus.aac.ep.m4a',
        offset: '0',
    },
    '115': {
        content: '',
        submitted_by: '',
        answers: ["Hot"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hot-feat-gunna/1477895569?i=1477895573&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:13',
        release_year: '2019',
        genre: 'Hip-Hop',
        album: 'So Much Fun',
        artist: 'Young Thug',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b2/6a/8a/b26a8a75-f214-dfee-0b1a-98a2cf1e64d4/mzaf_407367428303168067.plus.aac.ep.m4a',
        offset: '0',
    },
    '116': {
        content: '',
        submitted_by: '',
        answers: ["Rude Boy"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rude-boy/1440775689?i=1440775739&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:42',
        release_year: '2009',
        genre: 'Dancehall, R&B',
        album: 'Rated R',
        artist: 'Rihanna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/de/42/e3/de42e3f1-c9c9-a06f-f359-311488af5ad4/mzaf_14448502655070420643.plus.aac.ep.m4a',
        offset: '0',
    },
    '117': {
        content: '',
        submitted_by: '',
        answers: ["Teenage Dirtbag"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/teenage-dirtbag/186203414?i=186204315&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:02',
        release_year: '2000',
        genre: 'Alternative Rock, Pop Rock',
        album: 'Wheatus',
        artist: 'Wheatus',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/36/8b/b6/368bb660-86de-eb35-4500-fd61e8863bad/mzaf_2844326135659043162.plus.aac.ep.m4a',
        offset: '0',
    },
    '118': {
        content: '',
        submitted_by: '',
        answers: ["Song 2"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/song-2-2012-remaster/726416050?i=726416473&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:01',
        release_year: '1997',
        genre: 'Alternative Rock, Britpop',
        album: 'Blur',
        artist: 'Blur',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/28/e0/25/28e0251d-6776-9b6b-15c1-8726a4e4b4d0/mzaf_8643175428794861511.plus.aac.p.m4a',
        offset: '0',
    },
    '119': {
        content: '',
        submitted_by: '',
        answers: ["Harder Better Faster Stronger"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/harder-better-faster-stronger/697194953?i=697195787&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:44',
        release_year: '2001',
        genre: 'House, Electronic',
        album: 'Discovery',
        artist: 'Daft Punk',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a5/47/c4/a547c4dd-f2c4-e388-e561-cf2bfb665008/mzaf_15383493293660350235.plus.aac.ep.m4a',
        offset: '0',
    },
    '120': {
        content: '',
        submitted_by: '',
        answers: ["Locked Out of Heaven"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/locked-out-of-heaven/573962245?i=573962551&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:53',
        release_year: '2012',
        genre: 'Pop, Reggae Rock',
        album: 'Unorthodox Jukebox',
        artist: 'Bruno Mars',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f4/37/44/f4374481-e6e8-54d1-32ad-893ec2f4d495/mzaf_15497409838745172040.plus.aac.ep.m4a',
        offset: '0',
    },
    '121': {
        content: '',
        submitted_by: '',
        answers: ["Stay"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/stay/1574378620?i=1574378625&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:21',
        release_year: '2021',
        genre: 'Pop',
        album: 'F*ck Love 3: Over You',
        artist: 'The Kid LAROI, Justin Bieber',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/37/f0/54/37f054c2-bd6e-1db2-2e22-c0094f9a7271/mzaf_1532106700925966880.plus.aac.p.m4a',
        offset: '0',
    },
    '122': {
        content: '',
        submitted_by: '',
        answers: ["Numb"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/numb/590423275?i=590423552&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Linkin Park',
        track_length: '3:06',
        release_year: '2003',
        genre: 'Alternative Rock, Nu Metal',
        album: 'Meteora',
        artist: 'Linkin Park',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/bb/13/76/bb1376a7-4db0-ed68-c1a6-d0278cc4b320/mzaf_4772533833416655520.plus.aac.ep.m4a',
        offset: '0',
    },
    '123': {
        content: '',
        submitted_by: '',
        answers: ["Mask Off"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/mask-off/1253105919?i=1253106277&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:24',
        release_year: '2017',
        genre: 'Hip Hop, Rap',
        album: 'Single',
        artist: 'Future',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/03/95/03/0395036b-2b04-4334-ff16-2361446e4670/mzaf_6636984414823743860.plus.aac.ep.m4a',
        offset: '0',
    },
    '124': {
        content: '',
        submitted_by: '',
        answers: ["Immigrant Song"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/immigrant-song/580708279?i=580708280&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:26',
        release_year: '1970',
        genre: 'Hard Rock, Heavy Metal',
        album: 'Led Zeppelin III',
        artist: 'Led Zeppelin',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/7b/6f/31/7b6f310b-3775-8029-ff37-4f7fc9f29e1d/mzaf_2518887581616530634.plus.aac.p.m4a',
        offset: '0',
    },
    '125': {
        content: '',
        submitted_by: '',
        answers: ["Dancing Queen"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dancing-queen/1422648512?i=1422648513&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:50',
        release_year: '1976',
        genre: 'Europop, Disco',
        album: 'Arrival',
        artist: 'ABBA',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/91/27/9c/91279cdb-1720-549e-b53e-1990191ba4af/mzaf_13147789945387957607.plus.aac.ep.m4a',
        offset: '0',
    },
    '126': {
        content: '',
        submitted_by: '',
        answers: ["Never Too Much"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/never-too-much/190652453?i=190652482&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:50',
        release_year: '1981',
        genre: 'R&B, Soul',
        album: 'Never Too Much',
        artist: 'Luther Vandross',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/42/21/e4/4221e436-1d89-c314-9d7c-7fcbd659022b/mzaf_12493490441957379505.plus.aac.ep.m4a',
        offset: '0',
    },
    '127': {
        content: '',
        submitted_by: '',
        answers: ["Californication"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/californication-2014-remaster/945575406?i=945575413&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:21',
        release_year: '1999',
        genre: 'Alternative Rock, Funk Rock',
        album: 'Californication',
        artist: 'Red Hot Chili Peppers',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/30/57/a2/3057a2dc-cbd7-bea7-66fd-7680c0a47c68/mzaf_5402294790218115669.plus.aac.ep.m4a',
        offset: '0',
    },
    '128': {
        content: '',
        submitted_by: '',
        answers: ["Get The Party Started"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/get-the-party-started/399891666?i=399891668&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:12',
        release_year: '2001',
        genre: 'Pop, Dance-pop',
        album: 'Missundaztood',
        artist: 'Pink',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/f7/8d/7b/f78d7bc8-9318-dad9-bdcf-2492ed472840/mzaf_17746815142231605496.plus.aac.ep.m4a',
        offset: '0',
    },
    '129': {
        content: '',
        submitted_by: '',
        answers: ["Wonderwall"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/wonderwall-remastered/1525933483?i=1525933490&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:18',
        release_year: '1995',
        genre: 'Britpop, Alternative Rock',
        album: '(Whats the Story) Morning Glory?',
        artist: 'Oasis',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/28/83/23/288323b4-3eb6-3a8b-ed54-55e3e9dbd702/mzaf_17647198752646393107.plus.aac.ep.m4a',
        offset: '0',
    },
    '130': {
        content: '',
        submitted_by: '',
        answers: ["Forgot About Dre"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/forgot-about-dre-feat-eminem/1440782221?i=1440783376&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:42',
        release_year: '1999',
        genre: 'Hip Hop, Rap',
        album: '2001',
        artist: 'Dr. Dre',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/44/b0/21/44b021ab-29e1-2c21-aaa8-b5037d3523be/mzaf_15751382214286408462.plus.aac.ep.m4a',
        offset: '0',
    },
    '131': {
        content: '',
        submitted_by: '',
        answers: ["So Yesterday"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/so-yesterday/1443199322?i=1443199770&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:34',
        release_year: '2003',
        genre: 'Pop, Rock, Pop-rock',
        album: 'Metamorphosis',
        artist: 'Hillary Duff',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/85/48/70/85487073-92c9-7d4d-cdff-1aab46508ef4/mzaf_12980909560608550258.plus.aac.ep.m4a',
        offset: '0',
    },
    '132': {
        content: '',
        submitted_by: '',
        answers: ["Tik Tok", "Tik-Toc", "Tik"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/tik-tok/339972787?i=339972792&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:20',
        release_year: '2009',
        genre: 'Electropop, Dance-pop',
        album: 'Animal',
        artist: 'Ke$ha',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ee/bc/62/eebc6215-358a-51a6-da75-f69e12199ff7/mzaf_3006645925749645400.plus.aac.ep.m4a',
        offset: '0',
    },
    '133': {
        content: '',
        submitted_by: '',
        answers: ["Trade Winds"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/trade-winds/1444194687?i=1444195326&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:16',
        release_year: '1940',
        genre: 'Traditional Pop',
        album: 'Single',
        artist: 'Bing Crosby',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b5/2d/df/b52ddfaa-9bfb-900f-ba68-40c9704dfe2a/mzaf_7085651749805489889.plus.aac.ep.m4a',
        offset: '0',
    },
    '134': {
        content: '',
        submitted_by: '',
        answers: ["Temperature"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/temperature/80429921?i=80429794&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:37',
        release_year: '2005',
        genre: 'Dancehall, Reggae Fusion',
        album: 'The Trinity',
        artist: 'Sean Paul',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/24/db/93/24db93d4-1a9f-212b-c754-7ebadc83002a/mzaf_15427718319384281307.plus.aac.ep.m4a',
        offset: '0',
    },
    '135': {
        content: '',
        submitted_by: '',
        answers: ["Dangerous"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dangerous-feat-akon-feat-akon/1442992461?i=1442992477&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:06',
        release_year: '2008',
        genre: 'Hip Hop, Reggae Fusion',
        album: 'Not 4 Sale',
        artist: 'Kardinal Offishall',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/27/68/d9/2768d9df-5191-af36-779c-d2e1ab191305/mzaf_10049771922059753784.plus.aac.ep.m4a',
        offset: '0',
    },
    '136': {
        content: '',
        submitted_by: '',
        answers: ["Can't Hold Us"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cant-hold-us-feat-ray-dalton/560097651?i=560097694&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:18',
        release_year: '2011',
        genre: 'Hip Hop',
        album: 'The Heist',
        artist: 'Macklemore & Ryan Lewis',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/eb/b9/f9/ebb9f915-ec79-358f-679f-69213b40097f/mzaf_8253018247449108417.plus.aac.ep.m4a',
        offset: '0',
    },
    '137': {
        content: '',
        submitted_by: '',
        answers: ["Feel So Close"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/feel-so-close-radio-edit/570038793?i=570038801&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:26',
        release_year: '2011',
        genre: 'Electropop, House',
        album: '18 Months',
        artist: 'Calvin Harris',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ed/7b/b6/ed7bb636-635f-0e05-c196-dc918ac7496d/mzaf_1458387685740288776.plus.aac.ep.m4a',
        offset: '0',
    },
    '138': {
        content: '',
        submitted_by: '',
        answers: ["Sk8er Boi"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/let-go/315025768?app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"450px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:24',
        release_year: '2002',
        genre: 'Pop Punk',
        album: 'Let Go',
        artist: 'Avril Lavigne',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/8a/86/39/8a86394d-0483-f608-0330-4f0e5f163da4/mzaf_9688946735220170044.plus.aac.ep.m4a',
        offset: '0',
    },
    '139': {
        content: '',
        submitted_by: '',
        answers: ["Sweet Thing"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sweet-thing/1469358206?i=1469358211&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:21',
        release_year: '1993',
        genre: 'Rock',
        album: 'Wandering Spirit',
        artist: 'Mick Jagger',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/ab/d3/eb/abd3ebbb-1146-2943-2ee7-3d1c9b02f195/mzaf_8936493222363214245.plus.aac.ep.m4a',
        offset: '0',
    },
    '140': {
        content: '',
        submitted_by: '',
        answers: ["Celebration"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/celebration/329043011?i=329043343&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:35',
        release_year: '2009',
        genre: 'Electropop',
        album: 'Celebration',
        artist: 'Madonna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/38/b1/40/38b1402a-bcf9-0494-8e1e-7c38df703d33/mzaf_7645194311259772214.plus.aac.ep.m4a',
        offset: '0',
    },
    '141': {
        content: '',
        submitted_by: '',
        answers: ["Hotel Room Service"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hotel-room-service/326893904?i=326894032&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:58',
        release_year: '2009',
        genre: 'Hip House',
        album: 'Rebelution',
        artist: 'Pitbull',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/50/15/ed/5015edb7-f30f-a2a0-b3be-43c47caaa448/mzaf_12684711304652356926.plus.aac.ep.m4a',
        offset: '0',
    },
    '142': {
        content: '',
        submitted_by: '',
        answers: ["Boyfriend"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/boyfriend/1440650852?i=1440650857&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:52',
        release_year: '2012',
        genre: 'Pop, R&B',
        album: 'Believe',
        artist: 'Justin Bieber',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/5a/b1/b5/5ab1b56e-cd2b-0c88-8f15-556f22bf1319/mzaf_7009881654373678710.plus.aac.ep.m4a',
        offset: '0',
    },
    '143': {
        content: '',
        submitted_by: '',
        answers: ["Ohio"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/ohio/322842571?i=322842576&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:58',
        release_year: '1970',
        genre: 'Folk Rock',
        album: 'Single',
        artist: 'Crosby, Stills, Nash & Young',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/37/6d/13/376d13f2-cbb3-f51e-bf7e-0ccf0ef47797/mzaf_9035596112983090700.plus.aac.ep.m4a',
        offset: '0',
    },
    '144': {
        content: '',
        submitted_by: '',
        answers: ["Uptown Funk!", "Uptown Funk"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/uptown-funk-feat-bruno-mars/943946661?i=943946671&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:30',
        release_year: '2014',
        genre: 'Funk Pop',
        album: 'Uptown Special',
        artist: 'Mark Ronson ft. Bruno Mars',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/c3/da/54/c3da54ef-3043-4d1b-0dfc-7d96cf02b399/mzaf_15309296674525820203.plus.aac.ep.m4a',
        offset: '0',
    },
    '145': {
        content: '',
        submitted_by: '',
        answers: ["Shape Of You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/shape-of-you/1193701079?i=1193701392&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:53',
        release_year: '2017',
        genre: 'Pop',
        album: '÷ (Divide)',
        artist: 'Ed Sheeran',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/1b/58/57/1b5857b8-e22a-7eb0-5f63-5c9f6832ed74/mzaf_14159612383885456861.plus.aac.ep.m4a',
        offset: '0',
    },
    '146': {
        content: '',
        submitted_by: '',
        answers: ["Old Town Road"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/old-town-road/1468166325?i=1468166468&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '1:53',
        release_year: '2019',
        genre: 'Country Rap',
        album: '7 EP',
        artist: 'Lil Nas X',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/9b/1f/b9/9b1fb99c-9111-91da-9296-5ab8d82028ee/mzaf_11237315064991720435.plus.aac.p.m4a',
        offset: '0',
    },
    '147': {
        content: '',
        submitted_by: '',
        answers: ["All About That Bass"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/all-about-that-bass/929825574?i=929825615&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:11',
        release_year: '2014',
        genre: 'Doo-wop/Pop',
        album: 'Title',
        artist: 'Meghan Trainor',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fb/b2/5b/fbb25b78-d658-58e0-53fa-0d3afa68639a/mzaf_742427917766113285.plus.aac.ep.m4a',
        offset: '0',
    },
    '148': {
        content: '',
        submitted_by: '',
        answers: ["God's Plan"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/gods-plan/1418213110?i=1418213269&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '2018',
        genre: 'Hip hop',
        album: 'Scary Hours',
        artist: 'Drake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/40/ef/be/40efbe69-6e26-18cb-6f16-3ed4af6758bb/mzaf_2013877337721445701.plus.aac.ep.m4a',
        offset: '0',
    },
    '149': {
        content: '',
        submitted_by: '',
        answers: ["Rockstar"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rockstar-feat-21-savage/1373516902?i=1373516920&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:38',
        release_year: '2017',
        genre: 'Rap/Hip hop',
        album: 'Beerbongs & Bentleys',
        artist: 'Post Malone',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/10/c1/3d/10c13d42-fd93-9b85-2576-dcdf3d65ef76/mzaf_16968465791460660080.plus.aac.ep.m4a',
        offset: '0',
    },
    '150': {
        content: '',
        submitted_by: '',
        answers: ["All Of Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/all-of-me/1441844369?i=1441844542&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:29',
        release_year: '2013',
        genre: 'R&B/Soul',
        album: 'Love in the Future',
        artist: 'John Legend',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/11/44/d2/1144d237-02e9-fc18-1047-c0d278995ccf/mzaf_3350391810472712725.plus.aac.ep.m4a',
        offset: '0',
    },
    '151': {
        content: '',
        submitted_by: '',
        answers: ["Shake It Off"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/shake-it-off/1440935467?i=1440936016&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:39',
        release_year: '2014',
        genre: 'Pop',
        album: '1989',
        artist: 'Taylor Swift',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/5d/5a/ff/5d5affc9-924d-fee8-b50e-16ba227f1b04/mzaf_1921125936153335770.plus.aac.ep.m4a',
        offset: '0',
    },
    '152': {
        content: '',
        submitted_by: '',
        answers: ["Counting Stars"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/counting-stars/1440862673?i=1440862803&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:17',
        release_year: '2013',
        genre: 'Pop rock',
        album: 'Native',
        artist: 'OneRepublic',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/31/42/71/314271c7-82d6-d092-7be5-e5daa62d5884/mzaf_12413681772928804297.plus.aac.ep.m4a',
        offset: '0',
    },
    '153': {
        content: '',
        submitted_by: '',
        answers: ["Trap Queen"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/trap-queen/1039458694?i=1039459590&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:42',
        release_year: '2014',
        genre: 'Hip hop',
        album: 'Fetty Wap',
        artist: 'Fetty Wap',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/4e/d3/eb/4ed3eb54-8f46-59b2-df3f-a9307ef2943c/mzaf_17381897575642498702.plus.aac.ep.m4a',
        offset: '0',
    },
    '154': {
        content: '',
        submitted_by: '',
        answers: ["Thinking Out Loud"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/thinking-out-loud/1050204616?i=1050204631&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:41',
        release_year: '2014',
        genre: 'Soul, Pop',
        album: 'x',
        artist: 'Ed Sheeran',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/04/f6/a8/04f6a832-6b19-76b6-f8c4-599e7b17413e/mzaf_17137047432413658286.plus.aac.ep.m4a',
        offset: '0',
    },
    '155': {
        content: '',
        submitted_by: '',
        answers: ["Wake Me Up!", "Wake Me Up"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/wake-me-up/1440872730?i=1440872929&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Avicii',
        track_length: '4:09',
        release_year: '2013',
        genre: 'Folktronica, House',
        album: 'True',
        artist: 'Avicii',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/50/72/ea/5072eab7-bf3e-23db-6b75-dff17db23b77/mzaf_10157262288196559972.plus.aac.ep.m4a',
        offset: '0',
    },
    '156': {
        content: '',
        submitted_by: '',
        answers: ["Cheap Thrills"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cheap-thrills-feat-sean-paul/1082505789?i=1082506273&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '2016',
        genre: 'Electropop, Reggae Fusion',
        album: 'This Is Acting',
        artist: 'Sia',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/9d/5a/b3/9d5ab3b3-0426-aabd-eabc-1c992c6a72ff/mzaf_4721966873045352192.plus.aac.ep.m4a',
        offset: '0',
    },
    '157': {
        content: '',
        submitted_by: '',
        answers: ["7 Rings"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/7-rings/1450330588?i=1450330685&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:59',
        release_year: '2019',
        genre: 'Trap-Pop, R&B',
        album: 'thank u, next',
        artist: 'Ariana Grande',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7b/06/0a/7b060ae5-6be8-efc1-2662-ea787481b3b5/mzaf_1286044745086463465.plus.aac.ep.m4a',
        offset: '0',
    },
    '158': {
        content: '',
        submitted_by: '',
        answers: ["What's Love Got to Do with It"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/whats-love-got-to-do-with-it/995303638?i=995304381&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:48',
        release_year: '1984',
        genre: 'Pop',
        album: 'What\'s Love Got to Do with It',
        artist: 'Tina Turner',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/30/e9/f3/30e9f3d3-fe93-012a-fa54-6f66fb90ce52/mzaf_14047701493568532331.plus.aac.ep.m4a',
        offset: '0',
    },
    '159': {
        content: '',
        submitted_by: '',
        answers: ["Pumped up Kicks"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/pumped-up-kicks/435761204?i=435761212&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:58',
        release_year: '2010',
        genre: 'Alternative',
        album: 'Torches',
        artist: 'Foster the People',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/dd/a9/80/dda980a0-3b62-f7b7-9588-11b929a30b3c/mzaf_11085691404179687806.plus.aac.ep.m4a',
        offset: '0',
    },
    '160': {
        content: '',
        submitted_by: '',
        answers: ["Fallin'"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/fallin/256936779?i=256937038&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:30',
        release_year: '2001',
        genre: 'R&B/Soul',
        album: 'Songs in A Minor',
        artist: 'Alicia Keys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/d0/85/94/d0859448-fa77-15e1-9e3b-9bba8f75095d/mzaf_8840071085623091623.plus.aac.ep.m4a',
        offset: '0',
    },
    '161': {
        content: '',
        submitted_by: '',
        answers: ["Baba O'Riley"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/baba-oriley/1440815866?i=1440815872&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:08',
        release_year: '1971',
        genre: 'HARD ROCK',
        album: 'Who\'s Next',
        artist: 'The Who',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/fa/1a/53/fa1a5312-7f06-95e6-ebcb-582ed3ee82ff/mzaf_7262738230245731368.plus.aac.ep.m4a',
        offset: '0',
    },
    '162': {
        content: '',
        submitted_by: '',
        answers: ["I Will Survive"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-will-survive/1443818103?i=1443818368&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:18',
        release_year: '1978',
        genre: 'Pop',
        album: 'Love Tracks',
        artist: 'Gloria Gaynor',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/97/55/dd/9755dd8f-a332-4486-4946-c049059f0edf/mzaf_12083336491709557204.plus.aac.ep.m4a',
        offset: '0',
    },
    '163': {
        content: '',
        submitted_by: '',
        answers: ["Heaven or Las Vegas"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/heaven-or-las-vegas/258198881?i=258200075&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:58',
        release_year: '1990',
        genre: 'Rock',
        album: 'Heaven or Las Vegas',
        artist: 'Cocteau Twins',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f8/31/97/f831977c-6fd2-d40f-c2c9-fba79106fdf7/mzaf_8887445644625240797.plus.aac.ep.m4a',
        offset: '0',
    },
    '164': {
        content: '',
        submitted_by: '',
        answers: ["Believe"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/believe/73273758?i=73273491&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:52',
        release_year: '1998',
        genre: 'Pop',
        album: 'Believe',
        artist: 'Cher',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c1/0d/a8/c10da837-e5d3-fee3-e494-cea140cd3400/mzaf_15267259681384767849.plus.aac.ep.m4a',
        offset: '0',
    },
    '165': {
        content: '',
        submitted_by: '',
        answers: ["Poker Face"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/poker-face/1440818588?i=1440818665&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:58',
        release_year: '2008',
        genre: 'POP',
        album: 'The Fame',
        artist: 'Lady Gaga',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/e5/fd/0b/e5fd0b8e-954e-f6ab-9bd8-350aedb64dc4/mzaf_4772454810607132051.plus.aac.ep.m4a',
        offset: '0',
    },
    '166': {
        content: '',
        submitted_by: '',
        answers: ["The Sound of Silence", "The Sounds of Silence"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-sound-of-silence/192480256?i=192480260&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:01',
        release_year: '1965',
        genre: 'Folk, Classic rock',
        album: 'Wednesday Morning, 3 A.M',
        artist: 'Simon & Garfunkel',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/58/50/44/58504445-1bf2-0560-b55f-91b4b6adbf0b/mzaf_15616805678473663400.plus.aac.ep.m4a',
        offset: '0',
    },
    '167': {
        content: '',
        submitted_by: '',
        answers: ["Ain't No Mountain High Enough"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/aint-no-mountain-high-enough/1443133885?i=1443134344&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:20',
        release_year: '1967',
        genre: 'R&B/SOUL',
        album: 'Diana Ross',
        artist: 'Diana Ross',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/70/f4/ab/70f4ab1b-bcef-91ef-1414-9f2ae414422a/mzaf_13170368363250384086.plus.aac.ep.m4a',
        offset: '0',
    },
    '168': {
        content: '',
        submitted_by: '',
        answers: ["Love Will Tear Us Apart"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/love-will-tear-us-apart/996111401?i=996111414&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:23',
        release_year: '1980',
        genre: 'ALTERNATIVE',
        album: 'Substance',
        artist: 'Joy Division',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/5a/6f/e4/5a6fe405-cda2-dec8-5869-e128e7b001fc/mzaf_7568530753271252901.plus.aac.ep.m4a',
        offset: '0',
    },
    '169': {
        content: '',
        submitted_by: '',
        answers: ["vampire"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/vampire/1694386825?i=1694386830&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:39',
        release_year: '2023',
        genre: 'Pop rock, Piano Rock, Pop',
        album: 'GUTS',
        artist: 'Olivia Rodrigo',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/44/b1/89/44b18994-0f58-5007-cb38-92e866303b60/mzaf_10223256425894007794.plus.aac.ep.m4a',
        offset: '0',
    },
    '170': {
        content: '',
        submitted_by: '',
        answers: ["Imagine"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/imagine/1440853752?i=1440853776&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:57',
        release_year: '1971',
        genre: 'Classic Rock, 70s, Pop',
        album: 'Imagine',
        artist: 'John Lennon',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/75/ed/4b/75ed4b2b-30bd-b61f-ea6d-3173745be5ca/mzaf_8236499463160563883.plus.aac.ep.m4a',
        offset: '0',
    },
    '171': {
        content: '',
        submitted_by: '',
        answers: ["Juice"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/juice/1498195341?i=1498195344&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:16',
        release_year: '2019',
        genre: 'Pop, Funk',
        album: 'Cuz I Love You',
        artist: 'Lizzo',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/12/2b/8d/122b8d04-3629-b461-3fa9-1ab8b2084be5/mzaf_10255244544470436744.plus.aac.ep.m4a',
        offset: '0',
    },
    '172': {
        content: '',
        submitted_by: '',
        answers: ["White Dress"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/white-dress/1545567745?i=1545567748&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:33',
        release_year: '2021',
        genre: 'Soft Rock, Rock, Alternative',
        album: 'Chemtrails Over the Country Club',
        artist: 'Lana Del Rey',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/d0/d3/17/d0d317c1-e4f4-d09a-453f-9b58afcb1ca2/mzaf_17793109849959405167.plus.aac.ep.m4a',
        offset: '0',
    },
    '173': {
        content: '',
        submitted_by: '',
        answers: ["Welcome to the Party"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/welcome-to-the-party/1473525230?i=1473525323&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:34',
        release_year: '2019',
        genre: 'Gangsta Rap, East Coast Hip Hop, HIP-HOP/RAP',
        album: 'Meet the Woo',
        artist: 'Pop Smoke',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/1b/66/9c/1b669c3e-e595-3cc7-29d8-3c87d067a364/mzaf_657840167898015.plus.aac.ep.m4a',
        offset: '0',
    },
    '174': {
        content: '',
        submitted_by: '',
        answers: ["It Must Have Been Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/it-must-have-been-love/976541442?i=976541447&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:20',
        release_year: '1987',
        genre: '80s, Pop',
        album: 'Joyride',
        artist: 'Roxette',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/51/c6/e6/51c6e60c-3a5d-18d1-a804-054625f780d3/mzaf_12010090002992505053.plus.aac.ep.m4a',
        offset: '0',
    },
    '175': {
        content: '',
        submitted_by: '',
        answers: ["Son of a Preacher Man"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/son-of-a-preacher-man/257837795?i=257837972&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:23',
        release_year: '1968',
        genre: 'Soul, 60s, Pop',
        album: 'Dusty in Memphis',
        artist: 'Dusty Springfield',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/f2/af/1a/f2af1a3f-35b0-6d34-2705-4330ab60fb23/mzaf_13892491604968614390.plus.aac.p.m4a',
        offset: '0',
    },
    '176': {
        content: '',
        submitted_by: '',
        answers: ["As It Was"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/as-it-was/1615584999?i=1615585008&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:47',
        release_year: '2022',
        genre: 'Pop, Rock',
        album: 'Harry\'s House',
        artist: 'Harry Styles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/96/7e/ea/967eea0f-3d8e-9bb6-b4c5-fb255f50b906/mzaf_16046209671483865399.plus.aac.ep.m4a',
        offset: '0',
    },
    '177': {
        content: '',
        submitted_by: '',
        answers: ["Levitating"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/levitating/1538003494?i=1538003843&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:24',
        release_year: '2020',
        genre: 'Pop, Disco',
        album: 'Future Nostalgia',
        artist: 'Dua Lipa',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/33/93/80/339380f0-89bd-8d3e-c75a-2ff8752bf157/mzaf_6859821902163021841.plus.aac.ep.m4a',
        offset: '0',
    },
    '178': {
        content: '',
        submitted_by: '',
        answers: ["Rock with You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rock-with-you/186166282?i=186166358&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:25',
        release_year: '1979',
        genre: 'Pop, Disco',
        album: 'Off the Wall',
        artist: 'Michael Jackson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/0d/b8/49/0db8491c-820c-16dc-ad08-30501e76ecc2/mzaf_12903129673735039890.plus.aac.ep.m4a',
        offset: '0',
    },
    '179': {
        content: '',
        submitted_by: '',
        answers: ["Heat Waves"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/heat-waves/1508562310?i=1508562516&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:58',
        release_year: '2020',
        genre: 'Alternative',
        album: 'Dreamland',
        artist: 'Glass Animals',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/59/ad/93/59ad930a-5c2b-7111-3cee-cc5c45c2f7b4/mzaf_15876385621249228901.plus.aac.ep.m4a',
        offset: '0',
    },
    '180': {
        content: '',
        submitted_by: '',
        answers: ["I Walk the Line"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-walk-the-line/825830841?i=825832056&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:46',
        release_year: '1970',
        genre: 'Country, Folk',
        album: 'I Walk the Line',
        artist: 'Johnny Cash',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/5a/7c/7f/5a7c7ff6-5b87-dfd0-0477-dbc41dca44af/mzaf_4592224667163522827.plus.aac.ep.m4a',
        offset: '0',
    },
    '181': {
        content: '',
        submitted_by: '',
        answers: ["Hits Different"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hits-different/1689131527?i=1689132079&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:54',
        release_year: '2022',
        genre: 'Pop, Pop Rock',
        album: 'Midnights',
        artist: 'Taylor Swift',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/c2/6f/7a/c26f7abf-9d6b-e164-eb0f-a72ad808e30d/mzaf_312528276133684896.plus.aac.ep.m4a',
        offset: '0',
    },
    '182': {
        content: '',
        submitted_by: '',
        answers: ["What a Wonderful World"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/what-a-wonderful-world/1440787250?i=1440787254&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:17',
        release_year: '1967',
        genre: 'Jazz, Oldies',
        album: 'What A Wonderful World',
        artist: 'Louis Armstrong',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/9f/11/6f/9f116ff9-959e-c21f-3003-544b7d5f52ac/mzaf_18115901485558021537.plus.aac.p.m4a',
        offset: '0',
    },
    '183': {
        content: '',
        submitted_by: '',
        answers: ["U Can't Touch This", "Can\u2019t Touch This"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/u-cant-touch-this/724647474?i=724647729&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:17',
        release_year: '1990',
        genre: '90s, Hip-Hop, Rap',
        album: 'Please Hammer Don\'t Hurt \'Em',
        artist: 'MC Hammer',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/a3/76/fb/a376fb78-c479-5749-c6b2-fdf57bf7757d/mzaf_148056524575145576.plus.aac.ep.m4a',
        offset: '0',
    },
    '184': {
        content: '',
        submitted_by: '',
        answers: ["Mood"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/mood-feat-iann-dior/1553571636?i=1553571660&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:22',
        release_year: '2020',
        genre: 'Pop, Hip-Hop/Rap',
        album: 'El Dorado',
        artist: '24kGoldn',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/86/be/93/86be93ec-a04e-b3a3-3437-50fd32311cb5/mzaf_15866774689690496830.plus.aac.p.m4a',
        offset: '0',
    },
    '185': {
        content: '',
        submitted_by: '',
        answers: ["Call Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/call-me/716231242?i=716231650&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:33',
        release_year: '1980',
        genre: '80s, New wave, Disco',
        album: 'American Gigolo',
        artist: 'Blondie',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/85/44/8d/85448dee-23dd-568d-0e88-3c285a0e5276/mzaf_12367336045819065281.plus.aac.ep.m4a',
        offset: '0',
    },
    '186': {
        content: '',
        submitted_by: '',
        answers: ["You Send Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/you-send-me/1088172899?i=1088172910&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:43',
        release_year: '1957',
        genre: 'Soul, Oldies, R&B/Soul',
        album: 'Sam Cooke',
        artist: 'Sam Cooke',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e6/7b/cf/e67bcfb1-4818-adf0-a6c7-8e43f063306a/mzaf_12542947167906034141.plus.aac.ep.m4a',
        offset: '0',
    },
    '187': {
        content: '',
        submitted_by: '',
        answers: ["Joy to the World"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/joy-to-the-world/1443890529?i=1443890867&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:18',
        release_year: '1970',
        genre: 'Classic Rock, 70s, Pop',
        album: 'Naturally',
        artist: 'Three Dog Night',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ec/8a/57/ec8a57a4-df69-6f2a-7f8c-6fbe7b278ac4/mzaf_8299111836606689272.plus.aac.ep.m4a',
        offset: '0',
    },
    '188': {
        content: '',
        submitted_by: '',
        answers: ["Diet Coke"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/diet-coke/1620082258?i=1620082674&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:59',
        release_year: '2022',
        genre: 'Rap, Chipmunk Soul, Hip-Hop',
        album: 'It’s Always Dry',
        artist: 'Pusha T',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/28/5a/4d/285a4de4-1e12-c7c4-44f2-b13ea2bd4f57/mzaf_13259896251372199590.plus.aac.ep.m4a',
        offset: '0',
    },
    '189': {
        content: '',
        submitted_by: '',
        answers: ["Happy"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/happy-from-despicable-me-2/863835302?i=863835363&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:52',
        release_year: '2014',
        genre: 'Pop, Soul',
        album: 'G I R L',
        artist: 'Pharrell Williams',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/e6/98/ea/e698eae4-df88-53a1-b5d4-7b0a2852af2d/mzaf_12719213379146811470.plus.aac.ep.m4a',
        offset: '0',
    },
    '190': {
        content: '',
        submitted_by: '',
        answers: ["Never My Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/never-my-love/300959257?i=300959270&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:07',
        release_year: '1968',
        genre: 'Oldies, Soft Rock',
        album: 'Insight Out',
        artist: 'The Association',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ac/e4/58/ace45872-f158-90d7-d1dc-ad028fdab9f1/mzaf_3211341730396630252.plus.aac.ep.m4a',
        offset: '0',
    },
    '191': {
        content: '',
        submitted_by: '',
        answers: ["Someone Like You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/someone-like-you/1544491232?i=1544491998&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:47',
        release_year: '2011',
        genre: 'Soul, Pop',
        album: '21',
        artist: 'Adele',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ef/18/7b/ef187b7d-f487-e935-4ca1-af5748313710/mzaf_14294143740589280948.plus.aac.ep.m4a',
        offset: '0',
    },
    '192': {
        content: '',
        submitted_by: '',
        answers: ["Mrs. Robinson"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/mrs-robinson/203303421?i=203304387&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:55',
        release_year: '1968',
        genre: 'Classic Rock, Folk',
        album: 'Bookends',
        artist: 'Simon & Garfunkel',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/01/30/47/01304766-38c3-0dee-aadf-32714e3255e3/mzaf_6886372664791598342.plus.aac.ep.m4a',
        offset: '0',
    },
    '193': {
        content: '',
        submitted_by: '',
        answers: ["Moves Like Jagger"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/moves-like-jagger-feat-christina-aguilera/1440858689?i=1440859510&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:21',
        release_year: '2010',
        genre: 'Pop, Dance',
        album: 'Hands All Over',
        artist: 'Maroon 5 feat. Christina Aguilera',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/e4/bb/76/e4bb768b-03e2-9fd1-c4e9-93ff514e9525/mzaf_13518441728298089820.plus.aac.ep.m4a',
        offset: '0',
    },
    '194': {
        content: '',
        submitted_by: '',
        answers: ["Rhythm of the Rain"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rhythm-of-the-rain/1133812913?i=1133812924&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:32',
        release_year: '1962',
        genre: 'Oldies, Pop',
        album: 'Rhythm of the Rain',
        artist: 'The Cascades',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/52/0f/72/520f720b-0e80-cebb-0dcd-9ff7365f0f9b/mzaf_2826288218441218731.plus.aac.ep.m4a',
        offset: '0',
    },
    '195': {
        content: '',
        submitted_by: '',
        answers: ["Unchained Melody"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/unchained-melody-live-at-ann-arbor-mi/257540283?i=257542120&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:17',
        release_year: '1976',
        genre: 'Oldies, Rock n Roll, Pop',
        album: 'Moody Blue',
        artist: 'Elvis Presley',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/fa/d9/85/fad98596-a614-316e-43b2-5f3b820e5fd8/mzaf_2168406900141892526.plus.aac.p.m4a',
        offset: '0',
    },
    '196': {
        content: '',
        submitted_by: '',
        answers: ["Fire and Rain"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/fire-and-rain/1480026454?i=1480026462&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:33',
        release_year: '1970',
        genre: 'Folk, Singer/Songwriter',
        album: 'Sweet Baby James',
        artist: 'James Taylor',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/32/d1/4b/32d14be6-7c64-59d5-8505-2a0b379a9cda/mzaf_8235770135908574691.plus.aac.ep.m4a',
        offset: '0',
    },
    '197': {
        content: '',
        submitted_by: '',
        answers: ["It Wasn't Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/it-wasnt-me-feat-ricardo-ducent/1449315854?i=1449315876&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:48',
        release_year: '2000',
        genre: 'Reggae, Pop',
        album: 'Hot Shot',
        artist: 'Shaggy',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/c1/4d/94/c14d94fe-6c11-c2c6-59d7-aecf6af129f1/mzaf_2702211287145731067.plus.aac.ep.m4a',
        offset: '0',
    },
    '198': {
        content: '',
        submitted_by: '',
        answers: ["Gentle on My Mind"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/gentle-on-my-mind/962451553?i=962451565&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:12',
        release_year: '1967',
        genre: 'Country, Bluegrass',
        album: 'Earthwords & Music',
        artist: 'John Hartford',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/22/3d/0f/223d0fc1-ebd4-9d19-79b5-0bdb9396d972/mzaf_13062554033795167148.plus.aac.ep.m4a',
        offset: '0',
    },
    '199': {
        content: '',
        submitted_by: '',
        answers: ["Listen to the Music"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/listen-to-the-music/1110961242?i=1110962222&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:26',
        release_year: '1972',
        genre: 'Classic Rock, Rock',
        album: 'Toulouse Street',
        artist: 'The Doobie Brothers',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/20/a2/1f/20a21f57-d2c2-0c36-3a62-6f604bbaca3c/mzaf_11431473463715073854.plus.aac.ep.m4a',
        offset: '0',
    },
    '200': {
        content: '',
        submitted_by: '',
        answers: ["The Sound of Silence", "The Sounds of Silence"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/the-sound-of-silence/1006937448?i=1006937459&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1006937459&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '4:01',
        release_year: '2015 (Cover)',
        genre: 'Hard Rock',
        album: 'Immortalized',
        artist: 'Disturbed',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/a5/0b/8a/a50b8a9b-be1d-c43c-9236-e10ce4038db5/mzaf_10082159738866445198.plus.aac.ep.m4a',
        offset: '0',
    },
    '201': {
        content: '',
        submitted_by: '',
        answers: ["Do They Know It's Christmas?"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/do-they-know-its-christmas/1445001415?i=1445001420&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:05',
        release_year: '2004',
        genre: 'Christmas, Holiday',
        album: 'Do They Know It\'s Christmas?',
        artist: 'Band Aid 20',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/71/a5/db/71a5dbdc-d3dd-e885-d41c-30c3a482a74d/mzaf_7154586711101352844.plus.aac.ep.m4a',
        offset: '0',
    },
    '202': {
        content: '',
        submitted_by: '',
        answers: ["Cherish"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cherish/300959257?i=300959292&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:25',
        release_year: '1968',
        genre: 'Oldies, Pop, Rock',
        album: 'And Then... Along Comes the Association',
        artist: 'The Association',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ca/dd/22/cadd223b-3bac-99bb-44c7-ef53bed1e6b2/mzaf_11202004056779470465.plus.aac.ep.m4a',
        offset: '0',
    },
    '203': {
        content: '',
        submitted_by: '',
        answers: ["Starships"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/starships/1440810774?i=1440811640&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:30',
        release_year: '2012',
        genre: 'Pop, Dance, Hip-Hop/Rap',
        album: 'Pink Friday: Roman Reloaded',
        artist: 'Nicki Minaj',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/be/b6/56/beb656ed-879d-e66f-628d-6f4f4fe0b2f0/mzaf_6944614204727859946.plus.aac.ep.m4a',
        offset: '0',
    },
    '204': {
        content: '',
        submitted_by: '',
        answers: ["Margaritaville"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/margaritaville/1440745872?i=1440746286&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:19',
        release_year: '1977',
        genre: 'Classic rock, Beach Music, Pop',
        album: 'Changes in Latitudes, Changes in Attitudes',
        artist: 'Jimmy Buffett',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/8d/b0/15/8db015e0-7552-9ae7-3dbe-3fed0511a5ff/mzaf_8403405595267028325.plus.aac.ep.m4a',
        offset: '0',
    },
    '205': {
        content: '',
        submitted_by: '',
        answers: ["The A Team"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-a-team/448213992?i=448213995&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:59',
        release_year: '2010',
        genre: 'Acoustic, British, Pop',
        album: '+',
        artist: 'Ed Sheeran',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/67/65/39/676539a0-b904-2da4-56a1-12f017592cdb/mzaf_5854979885090818266.plus.aac.ep.m4a',
        offset: '0',
    },
    '206': {
        content: '',
        submitted_by: '',
        answers: ["Brown Eyed Girl"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/brown-eyed-girl/1060521507?i=1060521514&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:04',
        release_year: '1967',
        genre: 'Classic rock, Oldies',
        album: 'Blowin\' Your Mind!',
        artist: 'Van Morrison',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/6c/0a/dd/6c0add80-a484-28e6-706d-89930054aea6/mzaf_9608674354872845336.plus.aac.ep.m4a',
        offset: '0',
    },
    '207': {
        content: '',
        submitted_by: '',
        answers: ["When the Saints Go Marching In", "The Saints"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/when-the-saints-go-marching-in-single-version/1445962080?i=1445962526&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:22',
        release_year: '1938',
        genre: 'Jazz, Swing',
        album: 'The Decca Singles',
        artist: 'Louis Armstrong',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/a0/47/e3/a047e32a-74fa-897e-726b-eed0092a0af6/mzaf_7319518424324342153.plus.aac.ep.m4a',
        offset: '0',
    },
    '208': {
        content: '',
        submitted_by: '',
        answers: ["Gasolina"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/gasolina/1586112407?i=1586112418&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:12',
        release_year: '2004',
        genre: 'Reggaeton, Latin',
        album: 'Barrio Fino',
        artist: 'Daddy Yankee',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/f6/2b/08/f62b08fb-dc89-5e51-873b-d6571ed51558/mzaf_13042230378346847088.plus.aac.ep.m4a',
        offset: '0',
    },
    '209': {
        content: '',
        submitted_by: '',
        answers: ["You Are My Sunshine"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/you-are-my-sunshine/217682967?i=217682989&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:46',
        release_year: '1940',
        genre: 'Country, Oldies, Pop',
        album: 'You Are My Sunshine',
        artist: 'Jimmie Davis',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/96/be/41/96be41c3-162a-12fc-504c-eac4d782ff11/mzaf_10599233605175513561.plus.aac.ep.m4a',
        offset: '0',
    },
    '210': {
        content: '',
        submitted_by: '',
        answers: ["1901"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/1901/1450828963?i=1450829103&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:13',
        release_year: '2009',
        genre: 'Indie, Electronic, Alternative',
        album: 'Wolfgang Amadeus Phoenix',
        artist: 'Phoenix',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/4b/85/09/4b8509ea-969f-c8dc-7d51-c64d9d378c3f/mzaf_17651038935251873564.plus.aac.ep.m4a',
        offset: '0',
    },
    '211': {
        content: '',
        submitted_by: '',
        answers: ["Can\u2019t Get You Out of My Head"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cant-get-you-out-of-my-head/726320287?i=726320692&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:50',
        release_year: '2001',
        genre: 'Pop, Dance',
        album: 'Fever',
        artist: 'Kylie Minogue',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/22/4d/82/224d82d2-4c73-8e99-5d4b-63117210e98e/mzaf_16434056314616768609.plus.aac.ep.m4a',
        offset: '0',
    },
    '212': {
        content: '',
        submitted_by: '',
        answers: ["Dance Away"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dance-away/724490162?i=724490963&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:44',
        release_year: '1979',
        genre: 'Rock',
        album: 'Manifesto',
        artist: 'Roxy Music',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/0a/9a/ba/0a9abad1-55d9-fffa-fc0d-1eb114593386/mzaf_12258675749873302256.plus.aac.ep.m4a',
        offset: '0',
    },
    '213': {
        content: '',
        submitted_by: '',
        answers: ["Jesus Walks"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/jesus-walks/1412872568?i=1412873446&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:55',
        release_year: '2004',
        genre: 'Hiphop, Rap',
        album: 'The College Dropout',
        artist: 'Kanye West',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/34/48/90/344890b7-2fbb-e9bf-e955-7b50f2c94998/mzaf_14467160362193273226.plus.aac.ep.m4a',
        offset: '0',
    },
    '214': {
        content: '',
        submitted_by: '',
        answers: ["Crazy Horses"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/crazy-horses/1443518048?i=1443518463&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:31',
        release_year: '1972',
        genre: 'Rock, Pop',
        album: 'Crazy Horses',
        artist: 'The Osmonds',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/76/8b/ef/768bef65-26d2-ea98-0992-f497d0960bb8/mzaf_13022498742752393951.plus.aac.ep.m4a',
        offset: '0',
    },
    '215': {
        content: '',
        submitted_by: '',
        answers: ["1 Thing"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/1-thing/153437601?i=153437689&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:53',
        release_year: '2005',
        genre: 'R&B, Pop, Soul',
        album: 'Touch',
        artist: 'Amerie',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/2d/ed/6d/2ded6df0-bdc6-1e1f-fab4-e33c7aa23798/mzaf_17624099410437929655.plus.aac.ep.m4a',
        offset: '0',
    },
    '216': {
        content: '',
        submitted_by: '',
        answers: ["Papa Was A Rollin'stone", "Papa Was a Rolling Stone"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/papa-was-a-rollin-stone-single-version/1464880940?i=1464881649&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:57',
        release_year: '1972',
        genre: 'Soul, Motown, R&B',
        album: 'All Directions',
        artist: 'The Temptations',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/20/86/ad/2086ad58-2d3d-cb3e-4a4a-fb9510e174fd/mzaf_7829155319861016127.plus.aac.ep.m4a',
        offset: '0',
    },
    '217': {
        content: '',
        submitted_by: '',
        answers: ["We Belong Together"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/we-belong-together/1476731879?i=1476731889&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:13',
        release_year: '2005',
        genre: 'Rnb, Pop',
        album: 'The Emancipation of Mimi',
        artist: 'Mariah Carey',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/95/df/e5/95dfe5ac-efe4-5422-e39d-a015a9cf2b1f/mzaf_1413674783706714892.plus.aac.ep.m4a',
        offset: '0',
    },
    '218': {
        content: '',
        submitted_by: '',
        answers: ["Lost Cause"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lost-cause/1440733298?i=1440733627&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:48',
        release_year: '2002',
        genre: 'Mellow, Alternative',
        album: 'Sea Change',
        artist: 'Beck',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/bf/c4/1d/bfc41d5a-5ea7-05bd-e3e3-cc0ceda0986f/mzaf_1783179075827106934.plus.aac.ep.m4a',
        offset: '0',
    },
    '219': {
        content: '',
        submitted_by: '',
        answers: ["Play That Funky Music", "Play That Funky Music, White Boy"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/play-that-funky-music/198226103?i=198226129&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:52',
        release_year: '1976',
        genre: 'Funk, Disco, Rock',
        album: 'Wild Cherry',
        artist: 'Wild Cherry',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/66/01/e5/6601e561-3e23-e70d-f81a-fb2a3278dc29/mzaf_4868590828962060537.plus.aac.ep.m4a',
        offset: '0',
    },
    '220': {
        content: '',
        submitted_by: '',
        answers: ["Drop It Like It\u2019s Hot"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/drop-it-like-its-hot/1445287603?i=1445287610&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:24',
        release_year: '2004',
        genre: 'Hip-Hop, Rap',
        album: 'R&G (Rhythm & Gangsta): The Masterpiece',
        artist: 'Snoop Dogg',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/9b/ab/7b/9bab7b22-f1c1-9e55-7476-39530810063e/mzaf_3585557882101124852.plus.aac.ep.m4a',
        offset: '0',
    },
    '221': {
        content: '',
        submitted_by: '',
        answers: ["Bad and Boujee"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bad-and-boujee-feat-lil-uzi-vert/1615495955?i=1615496282&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:32',
        release_year: '2016',
        genre: 'Trap, Rap, Hip-hop',
        album: 'Culture',
        artist: 'Migos feat. Lil Uzi Vert',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/dd/03/c2/dd03c20c-00d8-1985-f78a-bd6d57318256/mzaf_5419380481234552310.plus.aac.ep.m4a',
        offset: '0',
    },
    '222': {
        content: '',
        submitted_by: '',
        answers: ["Call Me Maybe"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/call-me-maybe/1440873664?i=1440874008&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:14',
        release_year: '2011',
        genre: 'Pop, Dance',
        album: 'Kiss',
        artist: 'Carly Rae Jepsen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/3e/b7/24/3eb724df-f611-cbae-6a46-4bd0288b0903/mzaf_3393119667527782186.plus.aac.ep.m4a',
        offset: '0',
    },
    '223': {
        content: '',
        submitted_by: '',
        answers: ["Born to Run"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/born-to-run/310730204?i=310730214&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:33',
        release_year: '1975',
        genre: 'Rock',
        album: 'Born to Run',
        artist: 'Bruce Springsteen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c0/fa/74/c0fa74e3-d6e0-e921-a3d7-f3510642e384/mzaf_17206615972977930812.plus.aac.ep.m4a',
        offset: '0',
    },
    '224': {
        content: '',
        submitted_by: '',
        answers: ["American Idiot"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/american-idiot/207192731?i=207192732&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:56',
        release_year: '2004',
        genre: 'Punk Rock, Alternative',
        album: 'American Idiot',
        artist: 'Green Day',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/cf/29/fe/cf29feaa-2a13-31f9-6aa9-e00d7e421800/mzaf_4193012456237570573.plus.aac.ep.m4a',
        offset: '0',
    },
    '225': {
        content: '',
        submitted_by: '',
        answers: ["Ballroom Blitz", "The Ballroom Blitz"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-ballroom-blitz/724016552?i=724016802&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:51',
        release_year: '1974',
        genre: 'Glam Rock',
        album: 'Desolation Boulevard',
        artist: 'Sweet',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/cf/9c/82/cf9c8228-aac1-fad9-7c1c-e2eaeb27e5c4/mzaf_15599733204864236150.plus.aac.ep.m4a',
        offset: '0',
    },
    '226': {
        content: '',
        submitted_by: '',
        answers: ["Thinkin Bout You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/thinkin-bout-you/1440765580?i=1440766128&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:32',
        release_year: '2012',
        genre: 'Rnb, Soul, Pop',
        album: 'Channel Orange',
        artist: 'Frank Ocean',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/8a/2c/35/8a2c35f6-ac70-560c-0a1c-516e105c6af8/mzaf_16520523398853326231.plus.aac.ep.m4a',
        offset: '0',
    },
    '227': {
        content: '',
        submitted_by: '',
        answers: ["Make It With You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/make-it-with-you/73245616?i=73245024&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:12',
        release_year: '1970',
        genre: 'Soft Rock',
        album: 'On the Waters',
        artist: 'Bread',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3c/8e/6b/3c8e6b63-5062-178d-a45c-17e097a50b71/mzaf_5265417039416231609.plus.aac.ep.m4a',
        offset: '0',
    },
    '228': {
        content: '',
        submitted_by: '',
        answers: ["Beez in the Trap"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/beez-in-the-trap-feat-2-chainz/1440810774?i=1440811090&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:23',
        release_year: '2012',
        genre: 'Hip-Hop, Rap',
        album: 'Pink Friday: Roman Reloaded',
        artist: 'Nicki Minaj',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/1c/52/03/1c52036a-f516-2baa-cd75-1c8b588e7b69/mzaf_17533962457868943668.plus.aac.ep.m4a',
        offset: '0',
    },
    '229': {
        content: '',
        submitted_by: '',
        answers: ["Say So"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/say-so/1486262969?i=1486263180&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:57',
        release_year: '2019',
        genre: 'Pop, Disco, Rnb/Soul',
        album: 'Hot Pink',
        artist: 'Doja Cat',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/d8/ff/71/d8ff71a8-e8e9-022e-7191-0b47880a7f63/mzaf_8915982668855675260.plus.aac.ep.m4a',
        offset: '0',
    },
    '230': {
        content: '',
        submitted_by: '',
        answers: ["Without Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/without-me/1437575234?i=1437575238&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:50',
        release_year: '2018',
        genre: 'Pop, Rnb, Alternative',
        album: 'Maniac',
        artist: 'Halsey',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/ac/65/cb/ac65cba3-2cbe-8bbc-d0f4-ee6a11dc0a2d/mzaf_15796000883083536883.plus.aac.ep.m4a',
        offset: '0',
    },
    '231': {
        content: '',
        submitted_by: '',
        answers: ["In Da Club"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/in-da-club/1440841450?i=1440841857&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:44',
        release_year: '2003',
        genre: 'Hip-hop, Rap',
        album: 'Get Rich or Die Tryin',
        artist: '50 Cent',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/6f/25/3f/6f253f3b-e420-2c15-67f8-fc3ece970eca/mzaf_15108868704803348203.plus.aac.ep.m4a',
        offset: '0',
    },
    '232': {
        content: '',
        submitted_by: '',
        answers: ["Apologize"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/apologize/1440889649?i=1440889785&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:26',
        release_year: '2006',
        genre: 'Alternative, Rock, Pop',
        album: 'Dreaming Out Loud',
        artist: 'OneRepublic',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/f9/72/31/f9723133-ade0-6182-88b9-cb5df93f3052/mzaf_13484462073813284702.plus.aac.ep.m4a',
        offset: '0',
    },
    '233': {
        content: '',
        submitted_by: '',
        answers: ["The Battle of New Orleans"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-battle-of-new-orleans/158628226?i=158628546&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:32',
        release_year: '1954',
        genre: 'Country',
        album: 'Single',
        artist: 'Johnny Horton',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/6f/f9/18/6ff91876-06c3-08b3-e140-f8a0ce43c0cc/mzaf_10430008061533664593.plus.aac.p.m4a',
        offset: '0',
    },
    '234': {
        content: '',
        submitted_by: '',
        answers: ["Havana"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/havana-feat-young-thug/1321216767?i=1321217032&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:36',
        release_year: '2017',
        genre: 'Pop',
        album: 'Camila',
        artist: 'Camila Cabello (feat. Young Thug)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/c7/e0/8e/c7e08e0f-a304-0ae7-edd2-a9d71b45fa67/mzaf_5541627509341774734.plus.aac.ep.m4a',
        offset: '0',
    },
    '235': {
        content: '',
        submitted_by: '',
        answers: ["Kryptonite"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/kryptonite/1440664882?i=1440664900&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:55',
        release_year: '1999',
        genre: 'Rock, Alternative',
        album: 'The Better Life',
        artist: '3 Doors Down',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/3d/04/8e/3d048e75-1aa3-a36f-1346-44b00d5ce576/mzaf_3357451977294377277.plus.aac.ep.m4a',
        offset: '0',
    },
    '236': {
        content: '',
        submitted_by: '',
        answers: ["Straight Up"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/straight-up/723699799?i=723700423&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:49',
        release_year: '1988',
        genre: 'Pop, Dance',
        album: 'Forever Your Girl',
        artist: 'Paula Abdul',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/3c/fb/67/3cfb6714-99bd-434f-d2fb-78c56f8ac202/mzaf_2304391269960470261.plus.aac.ep.m4a',
        offset: '0',
    },
    '237': {
        content: '',
        submitted_by: '',
        answers: ["Cry Me a River"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cry-me-a-river/252606580?i=252606592&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:49',
        release_year: '2002',
        genre: 'Pop, Rnb',
        album: 'Justified',
        artist: 'Justin Timberlake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/46/37/d5/4637d51e-3ffd-01f6-f85b-55ade1cb50ce/mzaf_11145149416651919103.plus.aac.ep.m4a',
        offset: '0',
    },
    '238': {
        content: '',
        submitted_by: '',
        answers: ["Louie Louie"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/louie-louie/121695681?i=121695005&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:44',
        release_year: '1963',
        genre: 'Oldies, Classic Rock',
        album: 'The Kingsmen In Person',
        artist: 'The Kingsmen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/fe/8d/f3/fe8df3a1-e802-4048-a632-ed20a0ade72f/mzaf_9641456045344652411.plus.aac.ep.m4a',
        offset: '0',
    },
    '239': {
        content: '',
        submitted_by: '',
        answers: ["Down Under", "Land Down Under"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/down-under/282960957?i=282960968&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:44',
        release_year: '1981',
        genre: 'Pop, New wave, Rock',
        album: 'Business as Usual',
        artist: 'Men at Work',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/15/1d/b6/151db6e8-a2c8-2b95-52e4-5f9e6c1c0954/mzaf_13879937234511733787.plus.aac.ep.m4a',
        offset: '0',
    },
    '240': {
        content: '',
        submitted_by: '',
        answers: ["One Bad Apple"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/one-bad-apple/1444035930?i=1444036197&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:46',
        release_year: '1971',
        genre: 'Oldies, Pop',
        album: 'Osmonds',
        artist: 'The Osmonds',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f6/1f/73/f61f73cd-a7ae-b7b9-7156-6490e854a3f9/mzaf_3718650423363656316.plus.aac.ep.m4a',
        offset: '0',
    },
    '241': {
        content: '',
        submitted_by: '',
        answers: ["Bottoms Up"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bottoms-up-feat-nicki-minaj/1541341748?i=1541341994&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:20',
        release_year: '2010',
        genre: 'R&B, Hiphop, Soul',
        album: 'Passion, Pain & Pleasure',
        artist: 'Trey Songz feat. Nicki Minaj',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/84/4c/60/844c6033-e919-3256-ebe2-3206042e9e1a/mzaf_3969596507981542708.plus.aac.ep.m4a',
        offset: '0',
    },
    '242': {
        content: '',
        submitted_by: '',
        answers: ["New Rules"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/new-rules/1228739599?i=1228739609&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:30',
        release_year: '2017',
        genre: 'Pop, Dance',
        album: 'Dua Lipa',
        artist: 'Dua Lipa',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/90/9f/2b/909f2b64-04f8-e5d3-17a7-52a24f5f007e/mzaf_6920690085744240018.plus.aac.ep.m4a',
        offset: '0',
    },
    '243': {
        content: '',
        submitted_by: '',
        answers: ["Need to Know"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/need-to-know/1571168930?i=1571169194&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:30',
        release_year: '2021',
        genre: 'Hiphop, Rap, Pop',
        album: 'Planet Her',
        artist: 'Doja Cat',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/4e/ad/0b/4ead0b9f-509e-12fe-200c-c3e3afb965ed/mzaf_13118081493454450097.plus.aac.ep.m4a',
        offset: '0',
    },
    '244': {
        content: '',
        submitted_by: '',
        answers: ["Pompeii"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/pompeii/1440858222?i=1440858321&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:35',
        release_year: '2013',
        genre: 'Indie, Pop, Alternative',
        album: 'Bad Blood',
        artist: 'Bastille',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/5e/17/1e/5e171e94-ca08-9404-de1a-f24089955a14/mzaf_9630307298906773428.plus.aac.ep.m4a',
        offset: '0',
    },
    '245': {
        content: '',
        submitted_by: '',
        answers: ["Let's Dance"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lets-dance-single-version/697650603?i=697651447&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:07',
        release_year: '1983',
        genre: 'Rock, Pop',
        album: 'Let\'s Dance',
        artist: 'David Bowie',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f0/f6/ab/f0f6abb6-09d7-ee3d-a0a9-194371032711/mzaf_4017654098563892474.plus.aac.ep.m4a',
        offset: '0',
    },
    '246': {
        content: '',
        submitted_by: '',
        answers: ["Philadelphia Freedom"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/philadelphia-freedom/1440810893?i=1440811169&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:22',
        release_year: '1975',
        genre: 'Pop, Rock',
        album: 'Captain Fantastic and the Brown Dirt Cowboy',
        artist: 'Elton John',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/fa/9d/10/fa9d1097-5478-fe9a-362a-7a5a6a647136/mzaf_12439496388765706176.plus.aac.ep.m4a',
        offset: '0',
    },
    '247': {
        content: '',
        submitted_by: '',
        answers: ["I Knew You Were Trouble"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-knew-you-were-trouble/1440935340?i=1440935349&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:38',
        release_year: '2012',
        genre: 'Pop, Country',
        album: 'Red',
        artist: 'Taylor Swift',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/02/d6/05/02d60522-2d04-c990-6aea-baf9a34ee279/mzaf_14791711150818700480.plus.aac.ep.m4a',
        offset: '0',
    },
    '248': {
        content: '',
        submitted_by: '',
        answers: ["Another Day in Paradise"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/another-day-in-paradise-2016-remastered/1107820371?i=1107820627&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:19',
        release_year: '1989',
        genre: 'Pop, Soft Rock',
        album: '…But Seriously',
        artist: 'Phil Collins',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e5/2a/35/e52a3556-0bf1-4a37-b55e-0fe4581e218a/mzaf_7386409599355749944.plus.aac.ep.m4a',
        offset: '0',
    },
    '249': {
        content: '',
        submitted_by: '',
        answers: ["Glad You Came"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/glad-you-came/1443173173?i=1443173519&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '2011',
        genre: 'Pop, Dance',
        album: 'Word of Mouth',
        artist: 'The Wanted',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/6b/9f/d0/6b9fd0b6-ef0e-37e4-fd3e-505f58992380/mzaf_8368960385460875429.plus.aac.ep.m4a',
        offset: '0',
    },
    '250': {
        content: '',
        submitted_by: '',
        answers: ["The Motto"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-motto-feat-lil-wayne-bonus-track/1440642493?i=1440643139&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:01',
        release_year: '2011',
        genre: 'Rap, Hiphop',
        album: 'Take Care',
        artist: 'Drake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/06/4f/a4/064fa42b-8b9e-a59d-83b6-ec579bf5abbb/mzaf_10205470259373184062.plus.aac.ep.m4a',
        offset: '0',
    },
    '251': {
        content: '',
        submitted_by: '',
        answers: ["Heartbreak Hotel"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/heartbreak-hotel/217633715?i=217633894&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:24',
        release_year: '1956',
        genre: 'Rock n roll, Oldies,',
        album: 'Single',
        artist: 'Elvis Presley',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ef/23/42/ef2342cc-58ed-52f4-74ed-0edcdf9eef35/mzaf_5193516391741502523.plus.aac.p.m4a',
        offset: '0',
    },
    '252': {
        content: '',
        submitted_by: '',
        answers: ["Someone You Loved"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/someone-you-loved/1441539148?i=1441539154&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:02',
        release_year: '2018',
        genre: 'Pop, Alternative',
        album: 'Breach',
        artist: 'Lewis Capaldi',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/70/b7/6d/70b76dea-864b-7142-2304-74a980071694/mzaf_6969269319214324501.plus.aac.ep.m4a',
        offset: '0',
    },
    '253': {
        content: '',
        submitted_by: '',
        answers: ["Electric Feel"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/electric-feel/264720008?i=264720106&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:50',
        release_year: '2007',
        genre: 'Electronic, Indie, Alternative',
        album: 'Oracular Spectacular',
        artist: 'MGMT',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/5c/37/76/5c377622-6e45-9705-510f-c9c70c8f37b9/mzaf_3314698377023744969.plus.aac.ep.m4a',
        offset: '0',
    },
    '254': {
        content: '',
        submitted_by: '',
        answers: ["Hello"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hello/1544494115?i=1544494392&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:01',
        release_year: '2015',
        genre: 'Soul, Pop',
        album: '25',
        artist: 'Adele',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/93/22/22/93222271-8d55-d923-e0ff-b2964a5abefe/mzaf_18330225476035595009.plus.aac.ep.m4a',
        offset: '0',
    },
    '255': {
        content: '',
        submitted_by: '',
        answers: ["Enter Sandman"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/enter-sandman/579372950?i=579373079&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '7:37',
        release_year: '1991',
        genre: 'Metal',
        album: 'Metallica',
        artist: 'Metallica',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/40/64/66/40646668-82fe-e7f4-6e09-1194fb0ced89/mzaf_321916707498501748.plus.aac.ep.m4a',
        offset: '0',
    },
    '256': {
        content: '',
        submitted_by: '',
        answers: ["good 4 u"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/good-4-u/1560735414?i=1560735551&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:58',
        release_year: '2021',
        genre: 'Pop Rock',
        album: 'SOUR',
        artist: 'Olivia Rodrigo',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/9e/7d/31/9e7d314d-7575-b5a2-7a7e-91d82becd9f9/mzaf_7011987042611957820.plus.aac.ep.m4a',
        offset: '0',
    },
    '257': {
        content: '',
        submitted_by: '',
        answers: ["Will You Love Me Tomorrow", "Will You Still Love Me Tomorrow"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/will-you-still-love-me-tomorrow/220357912?i=220357956&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:39',
        release_year: '1960',
        genre: 'Oldies, Motown, Pop',
        album: 'Tonight\'s the Night',
        artist: 'The Shirelles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/55/e1/7b/55e17b85-10c9-8a63-fb32-ea3c70395e2f/mzaf_4337453467864365069.plus.aac.ep.m4a',
        offset: '0',
    },
    '258': {
        content: '',
        submitted_by: '',
        answers: ["Bridge Over Troubled Water"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bridge-over-troubled-water/324127933?i=324127934&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:54',
        release_year: '1970',
        genre: 'Folk, Classic Rock, Pop',
        album: 'Bridge over Troubled Water',
        artist: 'Simon & Garfunkel',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/02/a2/17/02a21703-ed00-e68a-2411-104437c2a7e4/mzaf_5976479632696252673.plus.aac.ep.m4a',
        offset: '0',
    },
    '259': {
        content: '',
        submitted_by: '',
        answers: ["Closer"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/closer-feat-halsey/1170699510?i=1170699703&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:05',
        release_year: '2016',
        genre: 'Future bass, Pop, Dance',
        album: 'Collage',
        artist: 'The Chainsmokers (feat. Halsey)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0b/43/fa/0b43fa3d-9c08-f4dd-de98-756a72d7db01/mzaf_15476902011721181656.plus.aac.ep.m4a',
        offset: '0',
    },
    '260': {
        content: '',
        submitted_by: '',
        answers: ["Truth Hurts"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/truth-hurts/1494981947?i=1494981961&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:53',
        release_year: '2017',
        genre: 'Pop, Hip Hop',
        album: 'Cuz I Love You (Super Deluxe)',
        artist: 'Lizzo',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/3e/66/f2/3e66f213-6d3b-0817-c0f1-1b410eec3319/mzaf_16479884203380767051.plus.aac.ep.m4a',
        offset: '0',
    },
    '261': {
        content: '',
        submitted_by: '',
        answers: ["Smooth"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/smooth-feat-rob-thomas/265816081?i=265816505&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:02',
        release_year: '1999',
        genre: 'Rock, Latin',
        album: 'Supernatural',
        artist: 'Santana (feat. Rob Thomas)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/d7/fb/5b/d7fb5b6e-62b4-37b4-1bde-79438ce1049c/mzaf_3166655978726110869.plus.aac.ep.m4a',
        offset: '0',
    },
    '262': {
        content: '',
        submitted_by: '',
        answers: ["Another One Bites the Dust"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/another-one-bites-the-dust/1440650428?i=1440650719&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:39',
        release_year: '1980',
        genre: 'Classic Rock',
        album: 'The Game',
        artist: 'Queen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7b/b1/29/7bb129e3-f113-611e-787f-aa6cbbb1089a/mzaf_77307288981213408.plus.aac.ep.m4a',
        offset: '0',
    },
    '263': {
        content: '',
        submitted_by: '',
        answers: ["Despacito"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/despacito/1447401519?i=1447401620&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:49',
        release_year: '2017',
        genre: 'Latin Pop',
        album: 'Vida',
        artist: 'Luis Fonsi  & Daddy Yankee',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/b6/46/55/b646551f-ad6e-26a9-f102-3ed3f6ad8158/mzaf_16614186731652294863.plus.aac.ep.m4a',
        offset: '0',
    },
    '264': {
        content: '',
        submitted_by: '',
        answers: ["Losing My Religion"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/losing-my-religion/1422693791?i=1422693816&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:44',
        release_year: '1991',
        genre: 'Rock, Alternative',
        album: 'Out of Time',
        artist: 'R.E.M.',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/af/be/f5/afbef592-8f00-fe28-8ab4-5c070827b2f8/mzaf_6039171996533634329.plus.aac.ep.m4a',
        offset: '0',
    },
    '265': {
        content: '',
        submitted_by: '',
        answers: ["Don't Stop Believin'"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dont-stop-believin/169003304?i=169003415&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:12',
        release_year: '1981',
        genre: 'Classic Rock',
        album: 'Escape',
        artist: 'Journey',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/77/68/c9/7768c9bc-cfb2-5efc-4a70-018007b5bd95/mzaf_2590322446926972704.plus.aac.ep.m4a',
        offset: '0',
    },
    '266': {
        content: '',
        submitted_by: '',
        answers: ["Wankster", "Wanksta"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/wanksta/1440841450?i=1440842482&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:37',
        release_year: '2002',
        genre: 'Rap, Hip Hop',
        album: '8 Mile: Music from and Inspired by the Motion Picture',
        artist: '50 Cent',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7e/d7/96/7ed79628-19b4-f32e-c4d5-e8e6e9817290/mzaf_12049116910905332731.plus.aac.ep.m4a',
        offset: '0',
    },
    '267': {
        content: '',
        submitted_by: '',
        answers: ["Payphone"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/payphone-feat-wiz-khalifa/1440808308?i=1440808312&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:51',
        release_year: '2012',
        genre: 'Pop',
        album: 'Overexposed',
        artist: 'Maroon 5 (feat. Wiz Khalifa)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/29/9a/ce/299aced5-7745-4619-54fb-86cfd600ca70/mzaf_12383174430775165140.plus.aac.ep.m4a',
        offset: '0',
    },
    '268': {
        content: '',
        submitted_by: '',
        answers: ["Circles"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/circles/1477880265?i=1477880561&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:35',
        release_year: '2019',
        genre: 'Pop, Hip Hop, Rap',
        album: 'Hollywood’s Bleeding',
        artist: 'Post Malone',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c4/7c/64/c47c643a-42ab-8a13-55ce-d8b870c4d1f2/mzaf_3081965583807804865.plus.aac.ep.m4a',
        offset: '0',
    },
    '269': {
        content: '',
        submitted_by: '',
        answers: ["We Are Young"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/we-are-young-feat-janelle-mon%C3%A1e/486040153?i=486040195&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:10',
        release_year: '2011',
        genre: 'Indie, Pop, Alternative',
        album: 'Some Nights',
        artist: 'Fun. (feat. Janelle Monáe)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/93/ce/c5/93cec592-1d9c-0ac5-acbc-cfc71171cd2b/mzaf_7031039176614491019.plus.aac.ep.m4a',
        offset: '0',
    },
    '270': {
        content: '',
        submitted_by: '',
        answers: ["Waterfalls"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/waterfalls/270246704?i=270246724&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:18',
        release_year: '1994',
        genre: 'Rnb, Pop, Soul',
        album: 'CrazySexyCool',
        artist: 'TLC',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ae/15/d4/ae15d48d-74e1-e5ff-0c94-e1cd37b497c1/mzaf_13348324479562298267.plus.aac.ep.m4a',
        offset: '0',
    },
    '271': {
        content: '',
        submitted_by: '',
        answers: ["Ride"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/ride/974485462?i=974485805&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '2015',
        genre: 'Rock, Indie, Alternative',
        album: 'Blurryface',
        artist: 'Twenty One Pilots',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/bc/b2/d8/bcb2d85c-2c3b-0af5-8ee1-3c5c170d374f/mzaf_15014698531207919087.plus.aac.ep.m4a',
        offset: '0',
    },
    '272': {
        content: '',
        submitted_by: '',
        answers: ["Watermelon Sugar"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/watermelon-sugar/1485802965?i=1485802967&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:54',
        release_year: '2019',
        genre: 'Pop, Rock',
        album: 'Fine Line',
        artist: 'Harry Styles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/16/86/f5/1686f50d-8b77-7e32-85f7-5f0e804d68fe/mzaf_1581096760438104595.plus.aac.ep.m4a',
        offset: '0',
    },
    '273': {
        content: '',
        submitted_by: '',
        answers: ["GUMMO"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/gummo/1711599151?i=1711599165&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:35',
        release_year: '2017',
        genre: 'Rap, Hip Hop',
        album: 'Day69: Graduation Day',
        artist: '6ix9ine',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/07/85/80/0785806a-4fe5-f4f2-772f-cd9debffcbe0/mzaf_3112651507493248522.plus.aac.ep.m4a',
        offset: '0',
    },
    '274': {
        content: '',
        submitted_by: '',
        answers: ["SICKO MODE"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sicko-mode/1421241217?i=1421242781&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:12',
        release_year: '2018',
        genre: 'Hip Hop, Trap',
        album: 'ASTROWORLD',
        artist: 'Travis Scott',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/ec/42/16/ec421678-7d11-165b-e3c7-f38be7fb2bdf/mzaf_6881215967720469164.plus.aac.ep.m4a',
        offset: '0',
    },
    '275': {
        content: '',
        submitted_by: '',
        answers: ["Sugar"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sugar/1440855456?i=1440855562&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:53',
        release_year: '2014',
        genre: 'Pop, Romantic',
        album: 'V',
        artist: 'Maroon 5',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d3/da/d6/d3dad616-499d-7a2d-0f60-aba160674a24/mzaf_334197624828843619.plus.aac.ep.m4a',
        offset: '0',
    },
    '276': {
        content: '',
        submitted_by: '',
        answers: ["Like a Virgin"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/like-a-virgin/80815235?i=80815215&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:36',
        release_year: '1984',
        genre: 'Pop, Dance',
        album: 'Like a Virgin',
        artist: 'Madonna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d4/91/00/d4910061-5267-b81a-eb30-059224c36cb3/mzaf_8883434547123677369.plus.aac.ep.m4a',
        offset: '0',
    },
    '277': {
        content: '',
        submitted_by: '',
        answers: ["Chasing Cars"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/chasing-cars/1440758222?i=1440758864&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:27',
        release_year: '2005',
        genre: 'Indie, Alternative',
        album: 'Eyes Open',
        artist: 'Snow Patrol',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ef/86/77/ef8677a6-7410-c7c2-dce6-f5b4994cf284/mzaf_2665726905605570924.plus.aac.ep.m4a',
        offset: '0',
    },
    '278': {
        content: '',
        submitted_by: '',
        answers: ["Drop It Like It's Hot"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/drop-it-like-its-hot/1445287603?i=1445287610&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:30',
        release_year: '2004',
        genre: 'Hip Hop, Rap',
        album: 'R&G (Rhythm & Gangsta): The Masterpiece',
        artist: 'Snoop Dogg featuring Pharrell',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/9b/ab/7b/9bab7b22-f1c1-9e55-7476-39530810063e/mzaf_3585557882101124852.plus.aac.ep.m4a',
        offset: '0',
    },
    '279': {
        content: '',
        submitted_by: '',
        answers: ["Tell Him"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/tell-him/716267375?i=716267527&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:32',
        release_year: '1986',
        genre: 'Oldies, Pop',
        album: 'Tell Him',
        artist: 'The Exciters',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/41/b0/27/41b0273b-21f2-9a9c-555e-19a0b2009c8e/mzaf_11795583964136743340.plus.aac.ep.m4a',
        offset: '0',
    },
    '280': {
        content: '',
        submitted_by: '',
        answers: ["Gentleman"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/gentleman/1445151341?i=1445151352&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:27',
        release_year: '2013',
        genre: 'Kpop, Dance, Pop',
        album: 'Single',
        artist: 'Psy',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/18/9a/4c/189a4c7b-a76f-d278-75b3-ca6fcd36540e/mzaf_3660615475834459549.plus.aac.ep.m4a',
        offset: '0',
    },
    '281': {
        content: '',
        submitted_by: '',
        answers: ["Yakety Yak"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/yakety-yak-remastered/1165518734?i=1165518915&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '1:47',
        release_year: '1958',
        genre: 'Oldies, Rock and Roll, R&B/Soul',
        album: 'Single',
        artist: 'The Coasters',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/9f/f9/d3/9ff9d3a1-3b41-c5c5-41e2-68d63cfc1aab/mzaf_17976767970277885066.plus.aac.p.m4a',
        offset: '0',
    },
    '282': {
        content: '',
        submitted_by: '',
        answers: ["Drip Too Hard"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/drip-too-hard/1437955279?i=1437955726&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:25',
        release_year: '2018',
        genre: 'Trap, Mumble Rap, Hiphop',
        album: 'Drip Harder',
        artist: 'Lil Baby & Gunna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/f6/f7/8c/f6f78cf1-ab2b-5b2d-6503-89034f0c3b54/mzaf_750802035392960562.plus.aac.p.m4a',
        offset: '0',
    },
    '283': {
        content: '',
        submitted_by: '',
        answers: ["Baby It's You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/baby-its-you/288092836?i=288092851&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:39',
        release_year: '1962',
        genre: 'Pop',
        album: 'Single',
        artist: 'The Shirelles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/5a/90/9d/5a909dfc-b374-3976-d9d3-35a3ff184dbe/mzaf_14723376318863008225.plus.aac.ep.m4a',
        offset: '0',
    },
    '284': {
        content: '',
        submitted_by: '',
        answers: ["Summer"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/summer/922876176?i=922876189&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:41',
        release_year: '2014',
        genre: 'Dance, Summer',
        album: 'Motion',
        artist: 'Calvin Harris',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ab/9e/15/ab9e1594-c5bf-9d12-1e82-91b630a68033/mzaf_10394552751496288099.plus.aac.ep.m4a',
        offset: '0',
    },
    '285': {
        content: '',
        submitted_by: '',
        answers: ["This Ain't a Love Song"],
        affiliate_links: [],
        franchise: 'None',
        track_length: '5:06',
        release_year: '1995',
        genre: 'Rock',
        album: 'These Days',
        artist: 'Bon Jovi',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/c0/02/28/c00228eb-78ce-e0db-501d-2fc1493ede4b/mzaf_10470993887393605552.plus.aac.ep.m4a',
        offset: '0',
    },
    '286': {
        content: '',
        submitted_by: '',
        answers: ["Scar Tissue"],
        affiliate_links: [],
        franchise: 'None',
        track_length: '3:37',
        release_year: '1999',
        genre: 'Alternative',
        album: 'Californication',
        artist: 'Red Hot Chili Peppers',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/72/6b/81/726b81ba-51ce-a834-a7e7-f63ef188ba5e/mzaf_2966957168985095299.plus.aac.ep.m4a',
        offset: '0',
    },
    '287': {
        content: '',
        submitted_by: '',
        answers: ["Love Me Like You Do"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/love-me-like-you-do/1440845197?i=1440845808&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:12',
        release_year: '2015',
        genre: 'Love, Pop, Soundtrack',
        album: 'Fifty Shades of Grey (Original Motion Picture Soundtrack)',
        artist: 'Ellie Goulding',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/48/9d/8a/489d8a2c-8f2c-d75d-a7f1-a4fe6414d295/mzaf_7628730683237939014.plus.aac.ep.m4a',
        offset: '0',
    },
    '288': {
        content: '',
        submitted_by: '',
        answers: ["Whoopty"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/whoopty/1552450604?i=1552450905&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:03',
        release_year: '2021',
        genre: 'Rap, Hiphop',
        album: 'Loyalty Over Royalty',
        artist: 'CJ',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/7f/ae/7f/7fae7fea-8ba7-06fc-9c33-f7d5f205eb50/mzaf_14238770386617493319.plus.aac.p.m4a',
        offset: '0',
    },
    '289': {
        content: '',
        submitted_by: '',
        answers: ["Roses"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/roses/1711846796?i=1711847095&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:53',
        release_year: '2016',
        genre: 'Trap, Rnb, Hiphop/Rap',
        album: 'Collection One',
        artist: 'SAINt JHN',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/55/d3/26/55d3265a-727d-5aea-f92f-e0d32acc913c/mzaf_17109324183786122696.plus.aac.ep.m4a',
        offset: '0',
    },
    '290': {
        content: '',
        submitted_by: '',
        answers: ["Popsicles And Icicles"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/popsicles-and-icicles-1963/4628512?i=4628510&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:33',
        release_year: '1963',
        genre: 'Oldies, Pop, Rock',
        album: 'Single',
        artist: 'The Murmaids',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a1/75/64/a1756484-ac8e-c357-2b6d-f5f557bdf57c/mzaf_10480629517214911092.plus.aac.ep.m4a',
        offset: '0',
    },
    '291': {
        content: '',
        submitted_by: '',
        answers: ["High Hopes"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/high-hopes/1361152002?i=1361152303&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:11',
        release_year: '2018',
        genre: 'Alternative, Rock',
        album: 'Pray for the Wicked',
        artist: 'Panic! at the Disco',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/1b/68/b1/1b68b142-72f1-9048-def9-2f47ac835675/mzaf_13594119134368947837.plus.aac.ep.m4a',
        offset: '0',
    },
    '292': {
        content: '',
        submitted_by: '',
        answers: ["Numb"],
        affiliate_links: [],
        franchise: 'Linkin Park',
        track_length: '3:07',
        release_year: '2003',
        genre: 'Hard Rock, Rock, Metal, Alternative',
        album: 'Meteora',
        artist: 'LINKIN PARK',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/9f/dd/b3/9fddb3a2-8ae0-b559-5870-b5a1d8bd5946/mzaf_2794009826535922133.plus.aac.ep.m4a',
        offset: '0',
    },
    '293': {
        content: '',
        submitted_by: '',
        answers: ["abcdefu"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/abcdefu-angrier/1585222329?i=1585222344&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:48',
        release_year: '2021',
        genre: 'Pop rock, Pop punk,',
        album: 'EP A Study of the Human Experience Volume One',
        artist: 'GAYLE',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/6a/d1/cb/6ad1cb77-fd82-0146-a7d8-3fe453d73c18/mzaf_11836347209681979100.plus.aac.ep.m4a',
        offset: '0',
    },
    '294': {
        content: '',
        submitted_by: '',
        answers: ["Bend Me, Shape Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bend-me-shape-me/1442733847?i=1442733850&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:14',
        release_year: '1967',
        genre: 'Oldies, Pop, Rock',
        album: 'Bend Me, Shape Me',
        artist: 'The American Breed',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/15/44/9a/15449a80-590c-540c-096d-24024b639ee4/mzaf_9976546145801491343.plus.aac.p.m4a',
        offset: '0',
    },
    '295': {
        content: '',
        submitted_by: '',
        answers: ["My House"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/my-house/971238498?i=971238507&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:12',
        release_year: '2015',
        genre: 'Pop, Hiphop, Rap',
        album: 'My House',
        artist: 'Flo Rida',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/46/04/da/4604da8f-bd4f-aa4c-10b1-aeb614fe1b22/mzaf_12428678257048482109.plus.aac.ep.m4a',
        offset: '0',
    },
    '296': {
        content: '',
        submitted_by: '',
        answers: ["fukumean"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/fukumean/1692838126?i=1692838486&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:05',
        release_year: '2023',
        genre: 'Trap, Hiphop, Rap',
        album: 'A Gift & a Curse',
        artist: 'Gunna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/24/49/7d/24497d34-fc26-9b47-b11a-e75dfd484d46/mzaf_4851612424774733355.plus.aac.p.m4a',
        offset: '0',
    },
    '297': {
        content: '',
        submitted_by: '',
        answers: ["Amen"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/amen/1444202353?i=1444202354&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:52',
        release_year: '1963',
        genre: 'Gospel, Soul, R&B',
        album: 'Keep On Pushing',
        artist: 'The Impressions',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview118/v4/3c/a7/46/3ca74645-03af-33b2-7971-7ad3f219ce97/mzaf_6547916912271958229.plus.aac.ep.m4a',
        offset: '0',
    },
    '298': {
        content: '',
        submitted_by: '',
        answers: ["XO TOUR Llif3"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/xo-tour-llif3/1274150514?i=1274153124&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:02',
        release_year: '2017',
        genre: 'Emo rap, Trap, Hiphop/Rap',
        album: 'Luv Is Rage 1.5',
        artist: 'Lil Uzi Vert',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/d0/9f/7d/d09f7d47-8924-3747-f295-f92bd48205e0/mzaf_10989621757466633377.plus.aac.ep.m4a',
        offset: '0',
    },
    '299': {
        content: '',
        submitted_by: '',
        answers: ["Gives You Hell"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/gives-you-hell/1440770567?i=1440770575&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:33',
        release_year: '2002',
        genre: 'Alternative, Rock',
        album: 'When the World Comes Down',
        artist: 'The All-American Rejects',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/19/07/88/190788d2-c5d3-2035-4477-d1fcc00f29fe/mzaf_2942651455013499974.plus.aac.ep.m4a',
        offset: '0',
    },
    '300': {
        content: '',
        submitted_by: '',
        answers: ["Pretty Blue Eyes"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/pretty-blue-eyes/1395432133?i=1395432353&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '1:51',
        release_year: '1953',
        genre: 'Jazz, Oldies, Pop',
        album: 'Single',
        artist: 'Steve Lawrence',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f4/c8/c7/f4c8c71e-8ae3-c75a-f25c-aff62641be66/mzaf_17342102082519629207.plus.aac.p.m4a',
        offset: '0',
    },
    '301': {
        content: '',
        submitted_by: '',
        answers: ["Oh My God"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/oh-my-god/1590035691?i=1590036031&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:45',
        release_year: '2021',
        genre: 'Pop, Soul',
        album: '30',
        artist: 'Adele',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/0e/04/ea/0e04ea1a-a423-6c57-89ad-9b0288ba3857/mzaf_8102174182656200205.plus.aac.ep.m4a',
        offset: '0',
    },
    '302': {
        content: '',
        submitted_by: '',
        answers: ["End of the Road"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/end-of-the-road/1440776595?i=1440776985&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:51',
        release_year: '1991',
        genre: 'R&B/Soul',
        album: 'CooleyHighHarmony',
        artist: 'Boyz II Men',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/f2/4e/ca/f24ecadd-23b6-dced-3369-e4b82e458df3/mzaf_2286511664469591603.plus.aac.ep.m4a',
        offset: '0',
    },
    '303': {
        content: '',
        submitted_by: '',
        answers: ["Hollaback Girl"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hollaback-girl/1452869898?i=1452870043&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '2004',
        genre: 'Pop',
        album: 'Love. Angel. Music. Baby',
        artist: 'Gwen Stefani',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/5e/cf/18/5ecf1813-9eab-3d77-08dc-a322017bb9a3/mzaf_694538529319673178.plus.aac.ep.m4a',
        offset: '0',
    },
    '304': {
        content: '',
        submitted_by: '',
        answers: ["Heartless"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/heartless/1441410287?i=1441410309&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '2008',
        genre: 'Hip-Hop/Rap',
        album: '808s & Heartbreak',
        artist: 'Kanye West',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c9/24/96/c9249688-ffdc-40c1-d56e-d84ee8537ed7/mzaf_103857562985126222.plus.aac.ep.m4a',
        offset: '0',
    },
    '305': {
        content: '',
        submitted_by: '',
        answers: ["Eye of the Tiger"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/eye-of-the-tiger/254685010?i=254685026&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:03',
        release_year: '1982',
        genre: 'Rock, Pop,',
        album: 'Eye of the Tiger',
        artist: 'Survivor',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/1a/42/60/1a426070-955c-4d3a-bf66-a10e07373d40/mzaf_18093633597387507787.plus.aac.ep.m4a',
        offset: '0',
    },
    '306': {
        content: '',
        submitted_by: '',
        answers: ["How You Remind Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/how-you-remind-me/214475427?i=214475478&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:43',
        release_year: '2001',
        genre: 'Rock, Pop, Alternative',
        album: 'Silver Side Up',
        artist: 'Nickelback',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/8b/72/4f/8b724fde-05f2-edbf-f063-8024ea7a8565/mzaf_16339570669147595431.plus.aac.ep.m4a',
        offset: '0',
    },
    '307': {
        content: '',
        submitted_by: '',
        answers: ["The Climb"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-climb/1440632617?i=1440632860&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:56',
        release_year: '2009',
        genre: 'Rock, Country, Pop',
        album: 'Hannah Montana: The Movie (Original Motion Picture Soundtrack)',
        artist: 'Miley Cyrus',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f9/09/b1/f909b150-ed98-e7a2-8f49-7affa2b324cb/mzaf_702704000587648839.plus.aac.ep.m4a',
        offset: '0',
    },
    '308': {
        content: '',
        submitted_by: '',
        answers: ["How's It Going to Be"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hows-it-going-to-be/271978738?i=271978750&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:13',
        release_year: '1997',
        genre: 'Alternative, Pop, Rock',
        album: 'Third Eye Blind',
        artist: 'Third Eye Blind',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/0f/0c/fe/0f0cfe64-87b9-49a2-1139-a0818a71cc3b/mzaf_4198504820976315829.plus.aac.ep.m4a',
        offset: '0',
    },
    '309': {
        content: '',
        submitted_by: '',
        answers: ["I Love Rock 'N Roll"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-love-rock-n-roll/1434144397?i=1434145184&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:55',
        release_year: '1981',
        genre: 'Rock',
        album: 'I Love Rock \'N\' Roll',
        artist: 'Joan Jett & The Blackhearts',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/f1/e5/30/f1e53033-88f4-5649-3248-e79b2ff1a42c/mzaf_3023472750482824176.plus.aac.ep.m4a',
        offset: '0',
    },
    '310': {
        content: '',
        submitted_by: '',
        answers: ["Our Lips Are Sealed"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/our-lips-are-sealed/1440843867?i=1440843869&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:46',
        release_year: '1974',
        genre: 'Pop',
        album: 'Beauty and the Beat',
        artist: 'The Go-Go\'s',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/71/1b/41/711b4166-5638-7f54-ddd1-af8bcc1d6ae2/mzaf_8286341027425625541.plus.aac.ep.m4a',
        offset: '0',
    },
    '311': {
        content: '',
        submitted_by: '',
        answers: ["You and Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/you-and-me/1440895067?i=1440895088&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:16',
        release_year: '2005',
        genre: 'Rock',
        album: 'Lifehouse',
        artist: 'Lifehouse',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e1/73/e3/e173e3da-76bc-6358-8802-7c472b72886d/mzaf_3601641725578093631.plus.aac.ep.m4a',
        offset: '0',
    },
    '312': {
        content: '',
        submitted_by: '',
        answers: ["Gimme the Light"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/gimme-the-light/1652781992?i=1652782147&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:48',
        release_year: '2002',
        genre: 'Pop',
        album: 'Dutty Rock',
        artist: 'Sean Paul',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/fb/38/25/fb38258e-d9bf-a8d9-290f-3bab31eeadce/mzaf_13990518235695953473.plus.aac.ep.m4a',
        offset: '0',
    },
    '313': {
        content: '',
        submitted_by: '',
        answers: ["Tubthumping"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/tubthumping/1440629491?i=1440629499&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:38',
        release_year: '1997',
        genre: 'Rock, Alternative, Pop',
        album: 'Tubthumper',
        artist: 'Chumbawamba',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/48/81/79/4881795b-26c8-cfea-2bbf-05f9e16979e8/mzaf_6558093718918026413.plus.aac.ep.m4a',
        offset: '0',
    },
    '314': {
        content: '',
        submitted_by: '',
        answers: ["The Sign"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-sign/512526055?i=512526590&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:11',
        release_year: '1992',
        genre: 'Pop',
        album: 'The Sign',
        artist: 'Ace of Base',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/50/d1/8f/50d18f80-466d-3044-3045-769cd045b582/mzaf_16917958128041240335.plus.aac.ep.m4a',
        offset: '0',
    },
    '315': {
        content: '',
        submitted_by: '',
        answers: ["U Got It Bad"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/u-got-it-bad/268532446?i=268532564&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:07',
        release_year: '2001',
        genre: 'R&B/Soul, Pop',
        album: '8701',
        artist: 'Usher',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview113/v4/7e/4a/22/7e4a22c8-28b0-1a4e-930f-87d7c3748714/mzaf_4643672857051577385.plus.aac.ep.m4a',
        offset: '0',
    },
    '316': {
        content: '',
        submitted_by: '',
        answers: ["My Humps"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/my-humps/1440772272?i=1440772285&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:26',
        release_year: '2005',
        genre: 'Hip-Hop/Rap',
        album: 'Monkey Business',
        artist: 'Black Eyed Peas',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/59/01/0a/59010aae-6ef7-b7ed-1cfc-12d6bf818697/mzaf_6224168084487648132.plus.aac.ep.m4a',
        offset: '0',
    },
    '317': {
        content: '',
        submitted_by: '',
        answers: ["Always On My Mind"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/always-on-my-mind/170922879?i=170923206&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:33',
        release_year: '1982',
        genre: 'Country',
        album: 'Always on My Mind',
        artist: 'Willie Nelson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0a/20/71/0a207163-d51a-44c2-e689-79bc4bec2a24/mzaf_4557455648016554379.plus.aac.ep.m4a',
        offset: '0',
    },
    '318': {
        content: '',
        submitted_by: '',
        answers: ["Under the Bridge"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/under-the-bridge/947701030?i=947701032&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:33',
        release_year: '1991',
        genre: 'Alternative',
        album: 'Blood Sugar Sex Magik',
        artist: 'Red Hot Chili Peppers',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/55/e9/1b/55e91b23-7df2-9cfe-afc1-d8d1067a2b8d/mzaf_571526129190144180.plus.aac.ep.m4a',
        offset: '0',
    },
    '319': {
        content: '',
        submitted_by: '',
        answers: ["Gettin' Jiggy Wit It", "Getting Jiggy Wit It"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/gettin-jiggy-wit-it/161524950?i=161525098&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:47',
        release_year: '1997',
        genre: 'Hip-Hop/Rap, R&B/Soul, Pop',
        album: 'Big Willie Style',
        artist: 'Will Smith',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/4e/b5/7f/4eb57fde-16b2-e64e-cdb3-1362d2ff8c98/mzaf_3039726458039772423.plus.aac.ep.m4a',
        offset: '0',
    },
    '320': {
        content: '',
        submitted_by: '',
        answers: ["Lonely"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lonely-dirty-old-version/1440731129?i=1440731466&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:55',
        release_year: '2005',
        genre: 'R&B/Soul',
        album: 'Trouble',
        artist: 'Akon',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/be/9c/e9/be9ce9ca-6ab2-555b-9810-82238dcaaaf6/mzaf_16756898631929330940.plus.aac.ep.m4a',
        offset: '0',
    },
    '321': {
        content: '',
        submitted_by: '',
        answers: ["The Middle"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-middle/1450030107?i=1450030115&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:45',
        release_year: '2001',
        genre: 'Alternative, EMO, Rock',
        album: 'Bleed American',
        artist: 'Jimmy Eat World',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/fd/d5/6e/fdd56edc-082e-0612-2fc9-2fa9ed797a8d/mzaf_16391962502017678133.plus.aac.ep.m4a',
        offset: '0',
    },
    '322': {
        content: '',
        submitted_by: '',
        answers: ["That Girl"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/that-girl/1446742602?i=1446743193&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:13',
        release_year: '1981',
        genre: 'R&B/Soul',
        album: 'Stevie Wonder\'s Original Musiquarium I',
        artist: 'Stevie Wonder',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fd/65/6a/fd656a0d-c171-39cf-715c-9c151b3cd8de/mzaf_8548265574625943117.plus.aac.ep.m4a',
        offset: '0',
    },
    '323': {
        content: '',
        submitted_by: '',
        answers: ["Again"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/again/1388413681?i=1388414162&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:47',
        release_year: '1993',
        genre: 'Pop',
        album: 'Janet',
        artist: 'Janet Jackson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/87/20/87/87208704-ad5c-4547-5568-c4b55bf8cc85/mzaf_6416098879463740932.plus.aac.ep.m4a',
        offset: '0',
    },
    '324': {
        content: '',
        submitted_by: '',
        answers: ["Wake Me Up When September Ends"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/wake-me-up-when-september-ends/207192731?i=207192838&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:45',
        release_year: '2004',
        genre: 'Alternative, Rock',
        album: 'American Idiot',
        artist: 'Green Day',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/8e/7b/31/8e7b3142-ebac-78e3-860b-8da1ca1610e1/mzaf_7653251747041334982.plus.aac.ep.m4a',
        offset: '0',
    },
    '325': {
        content: '',
        submitted_by: '',
        answers: ["Can't Let Go"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cant-let-go/190173909?i=190174610&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:26',
        release_year: '1991',
        genre: 'Pop',
        album: 'Emotions',
        artist: 'Mariah Carey',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/ce/2c/af/ce2caf7b-cbb3-b8f9-f7d8-c0563a9114b0/mzaf_9834323111195029257.plus.aac.ep.m4a',
        offset: '0',
    },
    '326': {
        content: '',
        submitted_by: '',
        answers: ["Without Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/without-me/1440903530?i=1440903693&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:50',
        release_year: '2002',
        genre: 'Hip-Hop/Rap',
        album: 'The Eminem Show',
        artist: 'Eminem',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f0/95/a5/f095a5b0-9413-2463-eb08-64c4e43130a2/mzaf_5475549423394614688.plus.aac.ep.m4a',
        offset: '0',
    },
    '327': {
        content: '',
        submitted_by: '',
        answers: ["No Surprise"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/no-surprise/320120492?i=320120524&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:29',
        release_year: '2009',
        genre: 'Rock, Pop, Alternative',
        album: 'Leave This Town',
        artist: 'Daughtry',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/8e/fe/f4/8efef4e1-fbf3-cc1d-68c4-47f9f8a3ba3e/mzaf_16435826406553664473.plus.aac.ep.m4a',
        offset: '0',
    },
    '328': {
        content: '',
        submitted_by: '',
        answers: ["Do You Believe In Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/do-you-believe-in-love/725784933?i=725785285&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:27',
        release_year: '1982',
        genre: 'Rock',
        album: 'Picture This',
        artist: 'Huey Lewis & The News',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/e7/7f/82/e77f8286-c14f-c34c-5302-74aef68f690c/mzaf_460478113561525023.plus.aac.ep.m4a',
        offset: '0',
    },
    '329': {
        content: '',
        submitted_by: '',
        answers: ["I Don't Want to Miss a Thing"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-dont-want-to-miss-a-thing/217271260?i=217271368&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:58',
        release_year: '1998',
        genre: 'Pop',
        album: 'Armageddon: The Album',
        artist: 'Aerosmith',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/92/df/59/92df5941-10fa-7356-cfc2-96346d6e2c89/mzaf_17806318795375702771.plus.aac.ep.m4a',
        offset: '0',
    },
    '330': {
        content: '',
        submitted_by: '',
        answers: ["Shine"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/shine/1576789665?i=1576789671&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:06',
        release_year: '1993',
        genre: 'Rock',
        album: 'Hints Allegations and Things Left Unsaid',
        artist: 'Collective Soul',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/86/9d/87/869d8705-9866-fc89-c683-44e4c1bded64/mzaf_14036442809626002512.plus.aac.ep.m4a',
        offset: '0',
    },
    '331': {
        content: '',
        submitted_by: '',
        answers: ["Don't Let Me Get Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dont-let-me-get-me-lp-version-radio-edit/294282994?i=294283096&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:30',
        release_year: '2001',
        genre: 'Pop, Teen Pop, R&B/Soul',
        album: 'Missundaztood',
        artist: 'P!nk',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/04/36/dd/0436dd6a-22ff-274e-c561-89e3d785f7c5/mzaf_12840211587408913467.plus.aac.ep.m4a',
        offset: '0',
    },
    '332': {
        content: '',
        submitted_by: '',
        answers: ["Soul Survivor"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/soul-survivor-feat-akon/1440743075?i=1440743758&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Jeezy',
        track_length: '4:40',
        release_year: '2005',
        genre: 'Hip-Hop/Rap, Gangsta Rap',
        album: 'Let\'s Get It: Thug Motivation 101',
        artist: 'Jeezy feat. Akon',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7c/61/48/7c6148f3-a0d8-8e01-632f-56c0d9407485/mzaf_7684279326910462632.plus.aac.ep.m4a',
        offset: '0',
    },
    '333': {
        content: '',
        submitted_by: '',
        answers: ["RAPSTAR"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rapstar/1569244974?i=1569245440&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:45',
        release_year: '2021',
        genre: 'Hip-Hop/Rap',
        album: 'Hall of Fame',
        artist: 'Polo G',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b0/c4/b5/b0c4b5f6-b30c-7e24-f77e-a604442a4b4e/mzaf_16821027345349946332.plus.aac.ep.m4a',
        offset: '0',
    },
    '334': {
        content: '',
        submitted_by: '',
        answers: ["Heaven"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/heaven/1676125918?i=1676125926&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:05',
        release_year: '1985',
        genre: 'Rock',
        album: 'Reckless',
        artist: 'Bryan Adams',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/b9/e3/9d/b9e39d52-a017-3df0-9dc2-807b2a70c9fa/mzaf_1909517355162660440.plus.aac.ep.m4a',
        offset: '0',
    },
    '335': {
        content: '',
        submitted_by: '',
        answers: ["Fame"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/fame/1195107061?i=1195110202&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:21',
        release_year: '1975',
        genre: 'Pop',
        album: 'Young Americans',
        artist: 'David Bowie',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/7c/24/39/7c243961-14ad-05b0-3b48-24cfd6f3c886/mzaf_3464466676096997008.plus.aac.ep.m4a',
        offset: '0',
    },
    '336': {
        content: '',
        submitted_by: '',
        answers: ["The Box"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-box/1490463311?i=1490463329&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:16',
        release_year: '2019',
        genre: 'Hip-Hop/Rap',
        album: 'Please Excuse Me For Being Antisocial',
        artist: 'Roddy Ricch',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/90/bc/f2/90bcf232-8024-1019-921c-cd29226793e6/mzaf_16008903824599219976.plus.aac.ep.m4a',
        offset: '0',
    },
    '337': {
        content: '',
        submitted_by: '',
        answers: ["Bootylicious"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bootylicious/217363241?i=217363251&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:27',
        release_year: '2001',
        genre: 'R&B/Soul, Pop, Teen Pop, Contemporary R&B',
        album: 'Survivor',
        artist: 'Destiny\'s Child',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e7/70/86/e77086a1-20b4-64e6-8590-d16e46c1a592/mzaf_10563043176810445983.plus.aac.ep.m4a',
        offset: '0',
    },
    '338': {
        content: '',
        submitted_by: '',
        answers: ["Broken Wings"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/broken-wings/302053572?i=302053638&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:43',
        release_year: '1985',
        genre: 'Pop, Pop/Rock',
        album: 'Welcome to the Real World',
        artist: 'Mr. Mister',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/33/51/29/335129ee-eaea-5cb1-c887-e7e9d05195b2/mzaf_5830167938796724781.plus.aac.ep.m4a',
        offset: '0',
    },
    '339': {
        content: '',
        submitted_by: '',
        answers: ["Cheerleader"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cheerleader-felix-jaehn-remix-radio-edit/1033360002?i=1033360497&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:00',
        release_year: '2014',
        genre: 'Pop',
        album: 'Me 4 U',
        artist: 'Omi',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/4e/a2/bf/4ea2bfc1-94ba-b448-1595-89dc4522dc6a/mzaf_7384561986236424798.plus.aac.ep.m4a',
        offset: '0',
    },
    '340': {
        content: '',
        submitted_by: '',
        answers: ["Nice & Slow"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/nice-slow/278845426?i=278845479&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:47',
        release_year: '1997',
        genre: 'R&B/Soul, Hip-Hop, Rock',
        album: 'My Way',
        artist: 'USHER',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/f3/35/fd/f335fdf4-741b-77cd-89f2-6f71698e3d3b/mzaf_1060877346346502787.plus.aac.ep.m4a',
        offset: '0',
    },
    '341': {
        content: '',
        submitted_by: '',
        answers: ["First Class"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/first-class/1618136433?i=1618136917&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:53',
        release_year: '2022',
        genre: 'Hip-Hop/Rap',
        album: 'Come Home The Kids Miss You',
        artist: 'Jack Harlow',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/10/d0/7c/10d07c55-1b42-4292-3751-316d2535ef00/mzaf_1384171866438674434.plus.aac.ep.m4a',
        offset: '0',
    },
    '342': {
        content: '',
        submitted_by: '',
        answers: ["Laughter In the Rain"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/laughter-in-the-rain/1440929082?i=1440929522&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:50',
        release_year: '1974',
        genre: 'Pop',
        album: 'Sedaka\'s Back',
        artist: 'Neil Sedaka',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/43/59/51/43595127-bca2-0029-d71a-5a6330f21ca8/mzaf_5046809844750560026.plus.aac.ep.m4a',
        offset: '0',
    },
    '343': {
        content: '',
        submitted_by: '',
        answers: ["Butterfly"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/butterfly/203303461?i=203304621&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:36',
        release_year: '2000',
        genre: 'Rock',
        album: 'The Gift of Game',
        artist: 'Crazy Town',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/c4/30/dc/c430dc21-71c5-b0ff-5ac8-496648e7a715/mzaf_4162109074924825577.plus.aac.ep.m4a',
        offset: '0',
    },
    '344': {
        content: '',
        submitted_by: '',
        answers: ["WAP"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/wap-feat-megan-thee-stallion/1526746980?i=1526746984&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:07',
        release_year: '2020',
        genre: 'Hip-Hop/Rap',
        album: 'Single',
        artist: 'Cardi B (feat. Megan Thee Stallion)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/4e/91/8b/4e918b65-be6e-d1a6-6322-effda0c754d0/mzaf_6663640843399257272.plus.aac.ep.m4a',
        offset: '0',
    },
    '345': {
        content: '',
        submitted_by: '',
        answers: ["Hanging By a Moment"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hanging-by-a-moment/1440768163?i=1440768181&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:36',
        release_year: '2000',
        genre: 'Rock, Alternative',
        album: 'No Name Face',
        artist: 'Lifehouse',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e6/c0/9f/e6c09f00-75ed-8bab-a66a-303862ce300d/mzaf_2487822413021588309.plus.aac.ep.m4a',
        offset: '0',
    },
    '346': {
        content: '',
        submitted_by: '',
        answers: ["Flowers"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/flowers/1674691585?i=1674691586&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:20',
        release_year: '2023',
        genre: 'Pop',
        album: 'Endless Summer Vacation',
        artist: 'Miley Cyrus',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/91/70/7c/91707c0f-95ef-4e4e-1f1c-dd1b129c9239/mzaf_7335717533882009367.plus.aac.ep.m4a',
        offset: '0',
    },
    '347': {
        content: '',
        submitted_by: '',
        answers: ["My Sharona"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/my-sharona/716268020?i=716268684&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:55',
        release_year: '1979',
        genre: 'Pop, Pop/Rock, Alternative, New Wave',
        album: 'Get the Knack',
        artist: 'The Knack',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/de/73/13/de731300-7556-e180-37c8-f30a3613b336/mzaf_10673443067737309180.plus.aac.ep.m4a',
        offset: '0',
    },
    '348': {
        content: '',
        submitted_by: '',
        answers: ["Harlem Shake"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/harlem-shake/601136812?i=601136935&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:16',
        release_year: '2012',
        genre: 'Electronic',
        album: 'Single',
        artist: 'Baauer',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/8e/51/b4/8e51b4f8-e1dd-ef23-9530-6dc0297eec8d/mzaf_16821827621681376815.plus.aac.ep.m4a',
        offset: '0',
    },
    '349': {
        content: '',
        submitted_by: '',
        answers: ["Last Night"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/last-night/1667990565?i=1667990774&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:43',
        release_year: '2023',
        genre: 'Country',
        album: 'One Thing At A Time',
        artist: 'Morgan Wallen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/89/1e/03/891e03c1-98b8-3cbc-a10a-612427bf354a/mzaf_6519185680716377585.plus.aac.ep.m4a',
        offset: '0',
    },
    '350': {
        content: '',
        submitted_by: '',
        answers: ["I Want to Know What Love Is"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-want-to-know-what-love-is/284531685?i=284531789&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:01',
        release_year: '1984',
        genre: 'Soft Rock, Arena Rock',
        album: 'Agent Provocateur',
        artist: 'Foreigner',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d2/05/76/d2057697-d10a-26fb-3e30-c0b6b04c64b6/mzaf_8553698238053458005.plus.aac.ep.m4a',
        offset: '0',
    },
    '351': {
        content: '',
        submitted_by: '',
        answers: ["Low"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/low-feat-t-pain/275496110?i=275496141&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:51',
        release_year: '2007',
        genre: 'Hip-Hop/Rap, Dirty South, Pop',
        album: 'Mail On Sunday',
        artist: 'Flo Rida (feat. T-Pain)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/29/cd/85/29cd85b5-ebc0-e723-25bf-a327a7f9aee8/mzaf_10291600105308427808.plus.aac.ep.m4a',
        offset: '0',
    },
    '352': {
        content: '',
        submitted_by: '',
        answers: ["Bad Girls"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bad-girls/1425179100?i=1425179375&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:55',
        release_year: '1979',
        genre: 'R&B/Soul',
        album: 'Bad Girls',
        artist: 'Donna Summer',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/57/33/1c/57331c9c-8e5e-a966-faab-6cb1e682c1df/mzaf_10676269488440828909.plus.aac.ep.m4a',
        offset: '0',
    },
    '353': {
        content: '',
        submitted_by: '',
        answers: ["Easy On Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/easy-on-me/1590035691?i=1590036021&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:44',
        release_year: '2021',
        genre: 'Pop, Vocal, Vocal Pop',
        album: '30',
        artist: 'Adele',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/75/28/56/75285676-dbce-d91d-7b36-070084a3546b/mzaf_14592298516945327705.plus.aac.ep.m4a',
        offset: '0',
    },
    '354': {
        content: '',
        submitted_by: '',
        answers: ["Bleeding Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bleeding-love/266450354?i=266450376&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:22',
        release_year: '2007',
        genre: 'Pop, Adult Contemporary, Rock',
        album: 'Spirit',
        artist: 'Leona Lewis',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/e8/0c/36/e80c36be-aa3a-abb8-05f1-83d91857ad1d/mzaf_1492277128096139265.plus.aac.ep.m4a',
        offset: '0',
    },
    '355': {
        content: '',
        submitted_by: '',
        answers: ["Careless Whisper"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/careless-whisper/193084944?i=193085066&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:32',
        release_year: '1984',
        genre: 'Alternative, New Wave, Adult Contemporary',
        album: 'Make It Big',
        artist: 'George Michael',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/3d/0a/d5/3d0ad515-8264-9965-aa29-8be8f9390d1c/mzaf_11969945100277739231.plus.aac.ep.m4a',
        offset: '0',
    },
    '356': {
        content: '',
        submitted_by: '',
        answers: ["Irreplaceable"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/irreplaceable/261707051?i=261707067&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:47',
        release_year: '2006',
        genre: 'Pop, Contemporary R&B',
        album: 'B\'Day',
        artist: 'Beyoncé',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fe/91/83/fe918372-8da5-0f6e-3842-04dcea8e9dfe/mzaf_7975390537863958936.plus.aac.ep.m4a',
        offset: '0',
    },
    '357': {
        content: '',
        submitted_by: '',
        answers: ["Breathe"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/breathe/264602889?i=264603410&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:07',
        release_year: '1999',
        genre: 'Pop, Adult Contemporary, Rock',
        album: 'Breathe',
        artist: 'Faith Hill',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/96/ff/61/96ff61db-e11f-0358-914a-d91c4105aa01/mzaf_13025829576816197584.plus.aac.ep.m4a',
        offset: '0',
    },
    '358': {
        content: '',
        submitted_by: '',
        answers: ["Thrift Shop"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/thrift-shop-feat-wanz/560097651?i=560097715&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:55',
        release_year: '2012',
        genre: 'Hip-Hop/Rap',
        album: 'The Heist',
        artist: 'Macklemore & Ryan Lewis (feat. Wanz)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/16/ce/bd/16cebd2c-0ac3-d066-3f2e-e20da36dd086/mzaf_7427883706918662667.plus.aac.ep.m4a',
        offset: '0',
    },
    '359': {
        content: '',
        submitted_by: '',
        answers: ["Little Things Mean A Lot"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/little-things-mean-a-lot/375991219?i=375991231&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:00',
        release_year: '1953',
        genre: 'Big Band, Jazz',
        album: 'Single',
        artist: 'Kitty Kallen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/47/52/a2/4752a247-5523-e301-1721-caaaed9a014d/mzaf_17902176413069990964.plus.aac.ep.m4a',
        offset: '0',
    },
    '360': {
        content: '',
        submitted_by: '',
        answers: ["Bad Day"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bad-day/56765911?i=56765932&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:53',
        release_year: '2005',
        genre: 'Rock',
        album: 'Daniel Powter',
        artist: 'Daniel Powter',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/88/b7/9b/88b79b6c-288d-dcc7-0a6e-9f6b8c1671da/mzaf_3185629882448096290.plus.aac.ep.m4a',
        offset: '0',
    },
    '361': {
        content: '',
        submitted_by: '',
        answers: ["How Do I Live"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/how-do-i-live/79590636?i=79590476&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:26',
        release_year: '1997',
        genre: 'Country, Rock',
        album: 'Single',
        artist: 'LeAnn Rimes',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/8d/2c/9b/8d2c9b21-6bde-99b0-6216-044f8fc817dd/mzaf_16859871526671174539.plus.aac.ep.m4a',
        offset: '0',
    },
    '362': {
        content: '',
        submitted_by: '',
        answers: ["Promiscuous"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/promiscuous-feat-timbaland/1442463545?i=1442463848&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:02',
        release_year: '2006',
        genre: 'Hip-Hop, Pop',
        album: 'Loose',
        artist: 'Nelly Furtado (feat. Timbaland)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/89/f9/f7/89f9f78c-665c-1a7a-2e3c-2e507211823e/mzaf_18105775904118709235.plus.aac.ep.m4a',
        offset: '0',
    },
    '363': {
        content: '',
        submitted_by: '',
        answers: ["Magic"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/magic/1440743072?i=1440743079&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:31',
        release_year: '1980',
        genre: 'Pop, Soft Rock',
        album: 'Xanadu',
        artist: 'Olivia Newton-John',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/b3/d3/81/b3d3813f-bd4d-7681-8c1b-77ef371945e6/mzaf_18059556005098594354.plus.aac.ep.m4a',
        offset: '0',
    },
    '364': {
        content: '',
        submitted_by: '',
        answers: ["The Sweet Escape"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-sweet-escape-feat-akon/1443822876?i=1443823151&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Gwen Stefani',
        track_length: '4:06',
        release_year: '2006',
        genre: 'Pop',
        album: 'The Sweet Escape',
        artist: 'Gwen Stefani',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/5f/93/8c/5f938cf6-3788-d9b3-3857-d5883cd374d7/mzaf_1521189644235584652.plus.aac.ep.m4a',
        offset: '0',
    },
    '365': {
        content: '',
        submitted_by: '',
        answers: ["Too Young"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/too-young/1440887225?i=1440887754&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:57',
        release_year: '2015',
        genre: 'Hip-Hop/Rap',
        album: 'Stoney',
        artist: 'Post Malone',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7a/db/c3/7adbc31b-ff9b-d01e-8ddb-2ffd6644693c/mzaf_15787777203001420774.plus.aac.ep.m4a',
        offset: '0',
    },
    '366': {
        content: '',
        submitted_by: '',
        answers: ["See You Again"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/see-you-again-feat-charlie-puth/966411595?i=966411602&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:49',
        release_year: '2015',
        genre: 'Hip-Hop, Pop',
        album: 'Furious 7 (Original Motion Picture Soundtrack)',
        artist: 'Wiz Khalifa (feat. Charlie Puth)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/d8/df/0b/d8df0bf5-c518-8e10-3bcb-48ece88a44cb/mzaf_15402632809603686925.plus.aac.ep.m4a',
        offset: '0',
    },
    '367': {
        content: '',
        submitted_by: '',
        answers: ["All for You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/all-for-you/724885014?i=724885384&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:30',
        release_year: '2001',
        genre: 'Pop, R&B/Soul',
        album: 'All for You',
        artist: 'Janet Jackson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/83/3d/d6/833dd6a4-7896-e8cd-84df-93af51c3dfb2/mzaf_11364078701296794177.plus.aac.ep.m4a',
        offset: '0',
    },
    '368': {
        content: '',
        submitted_by: '',
        answers: ["Answer Me, My Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/answer-me-my-love/1444030068?i=1444030559&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:37',
        release_year: '1952',
        genre: 'Easy Listening',
        album: 'Unforgettable',
        artist: 'Nat \"King\" Cole',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/ee/7e/9d/ee7e9dbc-6bed-997e-1316-45bf0b4aefbf/mzaf_16226061323333644988.plus.aac.ep.m4a',
        offset: '0',
    },
    '369': {
        content: '',
        submitted_by: '',
        answers: ["Shut Up and Dance"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/shut-up-and-dance/961298415?i=961298418&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '2014',
        genre: 'Alternative',
        album: 'Talking Is Hard',
        artist: 'Walk the Moon',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d7/5d/d2/d75dd20d-7e9d-3bcb-8659-19c05eedf864/mzaf_10013523052721546659.plus.aac.ep.m4a',
        offset: '0',
    },
    '370': {
        content: '',
        submitted_by: '',
        answers: ["Shivers"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/shivers/1581087024?i=1581087034&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:27',
        release_year: '2021',
        genre: 'Pop',
        album: '=',
        artist: 'Ed Sheeran',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/b7/5d/69/b75d69a5-ab31-ca1a-62aa-3985abaaa59c/mzaf_8964676796616534129.plus.aac.ep.m4a',
        offset: '0',
    },
    '371': {
        content: '',
        submitted_by: '',
        answers: ["How High the Moon"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/how-high-the-moon/1443232793?i=1443232796&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:07',
        release_year: '1953',
        genre: 'Jazz Standard, Jazz',
        album: 'Single',
        artist: 'Les Paul & Mary Ford',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b6/c5/9d/b6c59d82-bfa7-002e-6e86-14503ef7fa05/mzaf_2010008089148554104.plus.aac.p.m4a',
        offset: '0',
    },
    '372': {
        content: '',
        submitted_by: '',
        answers: ["Save the Best for Last"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/save-the-best-for-last/1443918180?i=1443918464&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:38',
        release_year: '1991',
        genre: 'Pop, R&B/Soul',
        album: 'The Comfort Zone',
        artist: 'Vanessa Williams',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b9/48/29/b9482912-1936-4c33-a357-8da6047531c8/mzaf_17553770931913278337.plus.aac.ep.m4a',
        offset: '0',
    },
    '373': {
        content: '',
        submitted_by: '',
        answers: ["Adore You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/adore-you/1485802965?i=1485802970&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:27',
        release_year: '2019',
        genre: 'Pop',
        album: 'Fine Line',
        artist: 'Harry Styles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/1b/59/1f/1b591fec-7157-f069-02fd-46a77c8638af/mzaf_15227483119872129084.plus.aac.ep.m4a',
        offset: '0',
    },
    '374': {
        content: '',
        submitted_by: '',
        answers: ["Baby Got Back"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/baby-got-back/1440811063?i=1440811075&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:22',
        release_year: '1992',
        genre: 'Hip-Hop/Rap',
        album: 'Mack Daddy',
        artist: 'Sir Mix-A-Lot',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/71/1a/e2/711ae2b4-d3cb-8c88-6c0d-0b3e7abb77dd/mzaf_15020203803185844360.plus.aac.ep.m4a',
        offset: '0',
    },
    '375': {
        content: '',
        submitted_by: '',
        answers: ["Life Is Good"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/life-is-good-feat-drake/1513037920?i=1513038036&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:57',
        release_year: '2020',
        genre: 'Hip-Hop/Rap',
        album: 'High Off Life',
        artist: 'Future (feat. Drake)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/6f/2c/11/6f2c111b-0f10-5753-2063-a33afae1842d/mzaf_11237700337699219104.plus.aac.ep.m4a',
        offset: '0',
    },
    '376': {
        content: '',
        submitted_by: '',
        answers: ["I Can't Get Next to You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-cant-get-next-to-you/1443901035?i=1443901051&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:54',
        release_year: '1969',
        genre: 'R&B/Soul',
        album: 'Puzzle People',
        artist: 'The Temptations',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/13/6d/c7/136dc759-1c3a-5293-4392-b13a3f124f9d/mzaf_12915951169707932159.plus.aac.ep.m4a',
        offset: '0',
    },
    '377': {
        content: '',
        submitted_by: '',
        answers: ["Best of My Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/best-of-my-love/170522776?i=170522819&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:40',
        release_year: '1972',
        genre: 'Pop',
        album: 'Rejoice',
        artist: 'The Emotions',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/b2/43/32/b2433228-3980-254e-0ea1-5ff3b76a75e8/mzaf_3904771905883393658.plus.aac.ep.m4a',
        offset: '0',
    },
    '378': {
        content: '',
        submitted_by: '',
        answers: ["Beat It"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/beat-it/269572838?i=269573341&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:18',
        release_year: '1982',
        genre: 'Pop',
        album: 'Thriller',
        artist: 'Michael Jackson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/a8/ad/92/a8ad924b-2912-c721-b7d6-a73c42b0d944/mzaf_4837698027870879200.plus.aac.ep.m4a',
        offset: '0',
    },
    '379': {
        content: '',
        submitted_by: '',
        answers: ["Total Eclipse of the Heart"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/total-eclipse-of-the-heart/1504111277?i=1504111288&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:58',
        release_year: '1975',
        genre: 'Adult Contemporary, Pop',
        album: 'Faster Than the Speed of Night',
        artist: 'Bonnie Tyler',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/34/d1/23/34d1239b-1efd-1bc3-ff86-2d0f947668b8/mzaf_5627408750765089425.plus.aac.ep.m4a',
        offset: '0',
    },
    '380': {
        content: '',
        submitted_by: '',
        answers: ["Somebody That I Used to Know"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/somebody-that-i-used-to-know-feat-kimbra/1440754000?i=1440754487&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:04',
        release_year: '2011',
        genre: 'Alternative',
        album: 'Making Mirrors',
        artist: 'Gotye feat. Kimbra',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f9/9c/55/f99c553e-7be1-91dc-b55e-3da1aad29bba/mzaf_8881595477209725037.plus.aac.ep.m4a',
        offset: '0',
    },
    '381': {
        content: '',
        submitted_by: '',
        answers: ["He'll Have to Go"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hell-have-to-go/298370659?i=298370789&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:18',
        release_year: '1959',
        genre: 'Country, Honky Tonk',
        album: 'He\'ll Have to Go',
        artist: 'Jim Reeves',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/46/7c/45/467c4551-bef2-4145-25e2-a07e7d0b0fdc/mzaf_2677579581695167625.plus.aac.p.m4a',
        offset: '0',
    },
    '382': {
        content: '',
        submitted_by: '',
        answers: ["What Makes You Beautiful"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/what-makes-you-beautiful/482734112?i=482734113&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '2011',
        genre: 'Pop',
        album: 'Up All Night',
        artist: 'One Direction',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/bd/84/31/bd843169-11f8-adc6-7da1-0e744dc889f2/mzaf_14973147178581064096.plus.aac.ep.m4a',
        offset: '0',
    },
    '383': {
        content: '',
        submitted_by: '',
        answers: ["Tossin' and Turnin'", "Tossing & Turning"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/tossin-and-turnin/351516343?i=351516350&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:39',
        release_year: '1961',
        genre: 'Rock, Doo Wop',
        album: 'Tossin\' and Turnin\'',
        artist: 'Bobby Lewis',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fd/b4/24/fdb4244d-7353-1705-26a0-6c498b1a4dbf/mzaf_2348402302646558863.plus.aac.ep.m4a',
        offset: '0',
    },
    '384': {
        content: '',
        submitted_by: '',
        answers: ["No One"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/no-one/294461785?i=294461792&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:13',
        release_year: '2007',
        genre: 'Contemporary R&B, R&B/Soul',
        album: 'As I Am',
        artist: 'Alicia Keys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/13/39/75/1339755d-a4fe-4b16-b37e-ad9fda7c08bf/mzaf_8025240799879723166.plus.aac.ep.m4a',
        offset: '0',
    },
    '385': {
        content: '',
        submitted_by: '',
        answers: ["Sugar, Sugar"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sugar-sugar/1412838346?i=1412839444&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:47',
        release_year: '1968',
        genre: 'Pop, Soft Rock',
        album: 'Everything\'s Archie',
        artist: 'The Archies',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c2/fa/dd/c2fadd02-38ab-9ad0-061a-f8356d3bd371/mzaf_14053609720990297786.plus.aac.ep.m4a',
        offset: '0',
    },
    '386': {
        content: '',
        submitted_by: '',
        answers: ["Lollipop"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lollipop-feat-static-major/1440737099?i=1440737349&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:59',
        release_year: '2008',
        genre: 'Hip-Hop/Rap, Dirty South, Rap, Hardcore Rap',
        album: 'Tha Carter III',
        artist: 'Lil Wayne (feat. Static Major)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/e3/ca/15/e3ca15e0-ee9a-48f1-17d0-d4454a777ac6/mzaf_7754595051182116927.plus.aac.ep.m4a',
        offset: '0',
    },
    '387': {
        content: '',
        submitted_by: '',
        answers: ["Walk Like an Egyptian"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/walk-like-an-egyptian/397587066?i=397587070&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:23',
        release_year: '1986',
        genre: 'Pop/Rock',
        album: 'Different Light',
        artist: 'The Bangles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/c6/4e/62/c64e622f-8ac6-4162-f1e8-8f80faa12715/mzaf_9358303741238401122.plus.aac.ep.m4a',
        offset: '0',
    },
    '388': {
        content: '',
        submitted_by: '',
        answers: ["Dark Horse"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dark-horse-feat-juicy-j/1440818542?i=1440819170&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Katy Perry',
        track_length: '3:35',
        release_year: '2013',
        genre: 'Pop',
        album: 'PRISM',
        artist: 'Katy Perry feat. Juicy J',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/46/2d/cc/462dccd0-55cb-4244-eb0f-dcb46dbb791a/mzaf_9453075009027536461.plus.aac.ep.m4a',
        offset: '0',
    },
    '389': {
        content: '',
        submitted_by: '',
        answers: ["I Just Want To Be Your Everything", "I Just Wanna Be Your Everything"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-just-want-to-be-your-everything/1442252219?i=1442252230&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:45',
        release_year: '1977',
        genre: 'Pop, Soul',
        album: 'Flowing Rivers',
        artist: 'Andy Gibb',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a4/e0/ae/a4e0ae97-2aea-d6d5-c331-34066329befb/mzaf_17226451445606840243.plus.aac.ep.m4a',
        offset: '0',
    },
    '390': {
        content: '',
        submitted_by: '',
        answers: ["Fancy"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/fancy-feat-charli-xcx/1440825109?i=1440825761&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '2014',
        genre: 'Hip-Hop/Rap',
        album: 'The New Classic',
        artist: 'Iggy Azalea (feat. Charli XCX)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/fa/8b/5d/fa8b5d0b-d230-e8ac-150e-b0959ed0eebd/mzaf_9007231782287389611.plus.aac.ep.m4a',
        offset: '0',
    },
    '391': {
        content: '',
        submitted_by: '',
        answers: ["Bad, Bad Leroy Brown", "Bad Leroy Brown"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bad-bad-leroy-brown/1674575337?i=1674575345&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:59',
        release_year: '1973',
        genre: 'Rock',
        album: 'Life & Times',
        artist: 'Jim Croce',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/2c/14/34/2c143489-442c-b5c0-3b88-43170e9c2cc7/mzaf_12173574954972407997.plus.aac.ep.m4a',
        offset: '0',
    },
    '392': {
        content: '',
        submitted_by: '',
        answers: ["I Feel for You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-feel-for-you/140251262?i=140249673&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:43',
        release_year: '1984',
        genre: 'R&B/Soul, Contemporary R&B',
        album: 'I Feel for You',
        artist: 'Chaka Khan',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/fe/58/73/fe58736b-7063-dedc-fe49-b0ccd769cb8d/mzaf_5479456949697188803.plus.aac.ep.m4a',
        offset: '0',
    },
    '393': {
        content: '',
        submitted_by: '',
        answers: ["Bette Davis Eyes"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bette-davis-eyes/714976054?i=714976249&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:48',
        release_year: '1981',
        genre: 'Adult Contemporary, Soft Rock',
        album: 'Mistaken Identity',
        artist: 'Kim Carnes',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/3a/0d/d3/3a0dd359-b1f8-bc75-83a9-15a2cc18a2ae/mzaf_12929204878692015937.plus.aac.ep.m4a',
        offset: '0',
    },
    '394': {
        content: '',
        submitted_by: '',
        answers: ["If You're Gone"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/if-youre-gone/580023802?i=580023900&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:34',
        release_year: '2000',
        genre: 'Rock, Alternative',
        album: 'Mad Season',
        artist: 'Matchbox Twenty',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/ea/ab/55/eaab5582-fa2c-683b-6254-9354eb95f46b/mzaf_10285190379647937653.plus.aac.ep.m4a',
        offset: '0',
    },
    '395': {
        content: '',
        submitted_by: '',
        answers: ["Alone"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/alone/725786124?i=725786574&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:39',
        release_year: '1987',
        genre: 'Adult Contemporary, Arena Rock',
        album: 'Bad Animals',
        artist: 'Heart',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/48/6c/2a/486c2a0b-1857-5653-64d1-d8719b1b9930/mzaf_1521020999564137322.plus.aac.ep.m4a',
        offset: '0',
    },
    '396': {
        content: '',
        submitted_by: '',
        answers: ["Let Me Blow Ya Mind"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/let-me-blow-ya-mind-feat-gwen-stefani-stevie-j/1440898228?i=1440898236&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Eve',
        track_length: '3:50',
        release_year: '2001',
        genre: 'Hip-Hop/Rap',
        album: 'Scorpion',
        artist: 'Eve feat. Gwen Stefani & Stevie J.',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/54/08/a4/5408a454-09af-9310-de96-ca2eb6b403f1/mzaf_8555145267843177463.plus.aac.ep.m4a',
        offset: '0',
    },
    '397': {
        content: '',
        submitted_by: '',
        answers: ["Ghost"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/ghost/1556169567?i=1556170100&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:33',
        release_year: '2021',
        genre: 'Pop',
        album: 'Justice',
        artist: 'Justin Bieber',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/74/fb/a5/74fba575-a188-236d-3d9b-02a3c4bedbe8/mzaf_13113570124208418252.plus.aac.ep.m4a',
        offset: '0',
    },
    '398': {
        content: '',
        submitted_by: '',
        answers: ["Love Will Keep Us Together"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/love-will-keep-us-together/1442300587?i=1442300589&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:25',
        release_year: '1975',
        genre: 'Pop, Soft Rock, Pop/Rock',
        album: 'Love Will Keep Us Together',
        artist: 'Captain & Tennille',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/66/4a/21/664a21e4-9711-236b-acbb-0a12a0079162/mzaf_4002950713228443909.plus.aac.ep.m4a',
        offset: '0',
    },
    '399': {
        content: '',
        submitted_by: '',
        answers: ["Super Gremlin"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/super-gremlin/1613765327?i=1613765773&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:20',
        release_year: '2022',
        genre: 'Hip-Hop/Rap',
        album: 'Back For Everything',
        artist: 'Kodak Black',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/02/26/ae/0226aec5-dc30-3e8c-7f8f-4e4788104f7d/mzaf_126490232187838882.plus.aac.ep.m4a',
        offset: '0',
    },
    '400': {
        content: '',
        submitted_by: '',
        answers: ["I Fall To Pieces"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-fall-to-pieces-feat-the-jordanaires/1485036494?i=1485036499&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:50',
        release_year: '1961',
        genre: 'Country',
        album: 'Showcase',
        artist: 'Patsy Cline (feat. The Jordanaires)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/95/4a/08/954a08b7-3571-cae7-517f-aca38e5cc9b8/mzaf_13601714770514792676.plus.aac.ep.m4a',
        offset: '0',
    },
    '401': {
        content: '',
        submitted_by: '',
        answers: ["Bad Habits"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bad-habits/1571330207?i=1571330212&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:50',
        release_year: '2021',
        genre: 'Pop',
        album: '=',
        artist: 'Ed Sheeran',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/37/67/6c/37676c9d-d0e0-85d3-3f17-0d966b2641bd/mzaf_12024265137946622435.plus.aac.ep.m4a',
        offset: '0',
    },
    '402': {
        content: '',
        submitted_by: '',
        answers: ["We Didn't Start the Fire"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/we-didnt-start-the-fire/158618758?i=158619054&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:51',
        release_year: '1989',
        genre: 'Rock, Pop',
        album: 'Storm Front',
        artist: 'Billy Joel',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/88/64/6d/88646d15-384c-c83a-5d9c-b2acdeee95ad/mzaf_15024326106443825596.plus.aac.ep.m4a',
        offset: '0',
    },
    '403': {
        content: '',
        submitted_by: '',
        answers: ["Fancy Like"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/fancy-like/1595927679?i=1595928077&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:41',
        release_year: '2021',
        genre: 'Country',
        album: 'Country Stuff The Album',
        artist: 'Walker Hayes',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/2e/3f/bf/2e3fbfe5-60fa-ef5a-64de-25b2cdee3098/mzaf_4760056567222486156.plus.aac.ep.m4a',
        offset: '0',
    },
    '404': {
        content: '',
        submitted_by: '',
        answers: ["April In Portugal"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/april-in-portugal/1696597988?i=1696598213&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:43',
        release_year: '1953',
        genre: 'Pop',
        album: 'Capitol Records',
        artist: 'Les Baxter',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/13/59/08/135908d6-aea1-2367-54f3-a8db9392ca9c/mzaf_3362169507674693181.plus.aac.ep.m4a',
        offset: '0',
    },
    '405': {
        content: '',
        submitted_by: '',
        answers: ["Yeah!"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/yeah-feat-lil-jon-ludacris/386153476?i=386153478&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:10',
        release_year: '2004',
        genre: 'R&B/Soul, Pop, Neo soul',
        album: 'Confessions',
        artist: 'Usher (feat. Lil Jon & Ludacris)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/ae/74/84/ae74846b-b9bb-4460-662d-d7c8aa2edd06/mzaf_10219585068951496600.plus.aac.ep.m4a',
        offset: '0',
    },
    '406': {
        content: '',
        submitted_by: '',
        answers: ["Need You Tonight"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/need-you-tonight/836629005?i=836629135&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:01',
        release_year: '1987',
        genre: 'Rock, Pop',
        album: 'Kick',
        artist: 'INXS',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/0a/37/0b/0a370b38-96ee-70b2-5a14-0ff7ad491848/mzaf_2653622145516448898.plus.aac.ep.m4a',
        offset: '0',
    },
    '407': {
        content: '',
        submitted_by: '',
        answers: ["Slow Motion"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/slow-motion-feat-soulja-slim/1443592898?i=1443593314&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:08',
        release_year: '2003',
        genre: 'Hip-Hop/Rap, Dirty South',
        album: 'Juve the Great',
        artist: 'Juvenile (feat. Soulja Slim)',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/11/53/11/11531154-a6f3-dafd-5094-8a39b22611b5/mzaf_16092301367114288383.plus.aac.ep.m4a',
        offset: '0',
    },
    '408': {
        content: '',
        submitted_by: '',
        answers: ["Song from Moulin Rouge", "Where Is Your Heart", "It's April Again"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/song-from-moulin-rouge/290334873?i=290334924&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:15',
        release_year: '1953',
        genre: 'Jazz, Stage & Screen',
        album: 'Moulin Rouge (Film)',
        artist: 'Percy Faith',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a1/c4/eb/a1c4ebe3-27ee-2bea-2125-7e26798170bb/mzaf_3319559092390558464.plus.aac.ep.m4a',
        offset: '0',
    },
    '409': {
        content: '',
        submitted_by: '',
        answers: ["Happier"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/happier/1706191906?i=1706191920&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:34',
        release_year: '2018',
        genre: 'Pop Rock, Electropop, Future Bass',
        album: 'Single',
        artist: 'Marshmello & Bastille',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/ea/43/de/ea43de17-d14d-7f3f-7215-756d9d8f49bf/mzaf_5306928641758047567.plus.aac.ep.m4a',
        offset: '0',
    },
    '410': {
        content: '',
        submitted_by: '',
        answers: ["No Air"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/no-air/268314568?i=268314585&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:24',
        release_year: '2007',
        genre: 'Pop, R&B/Soul, Contemporary R&B',
        album: 'Jordin Sparks',
        artist: 'Jordin Sparks & Chris Brown',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/b0/0d/a8/b00da882-00d6-8245-1b4e-904b33a97c9f/mzaf_9253523097415717550.plus.aac.ep.m4a',
        offset: '0',
    },
    '411': {
        content: '',
        submitted_by: '',
        answers: ["Love Will Lead You Back"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/love-will-lead-you-back/1329359112?i=1329359124&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:37',
        release_year: '1989',
        genre: 'Pop',
        album: 'Can\'t Fight Fate',
        artist: 'Taylor Dayne',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/20/aa/6f/20aa6f73-1405-5882-e72f-e185c5286953/mzaf_4254850602475103208.plus.aac.ep.m4a',
        offset: '0',
    },
    '412': {
        content: '',
        submitted_by: '',
        answers: ["Love Song"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/love-song/258604731?i=258604737&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:18',
        release_year: '2007',
        genre: 'Rock, Alternative, Alternative Folk',
        album: 'Little Voice',
        artist: 'Sara Bareilles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/e7/ba/51/e7ba51e4-11ae-da4b-5ee3-e2550c14dd3c/mzaf_2573351265057273049.plus.aac.ep.m4a',
        offset: '0',
    },
    '413': {
        content: '',
        submitted_by: '',
        answers: ["Faith"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/faith/282658449?i=282658468&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:15',
        release_year: '1987',
        genre: 'Pop, Soft Rock, Adult Contemporary',
        album: 'Twenty Five',
        artist: 'George Michael',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/95/fb/f8/95fbf85a-d154-6f88-b2e1-238d46d46b83/mzaf_9804726367524592478.plus.aac.ep.m4a',
        offset: '0',
    },
    '414': {
        content: '',
        submitted_by: '',
        answers: ["Sunflower"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sunflower-spider-man-into-the-spider-verse/1445949265?i=1445949267&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:38',
        release_year: '2018',
        genre: 'Hip-Hop/Rap',
        album: 'Spider-Man: Into the Spider-Verse (Soundtrack From & Inspired by the Motion Picture)',
        artist: 'Post Malone & Swae Lee',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/80/e6/9f/80e69ff0-2e40-12f6-7d6a-46f18fc278c0/mzaf_12975182101677674386.plus.aac.ep.m4a',
        offset: '0',
    },
    '415': {
        content: '',
        submitted_by: '',
        answers: ["Wrecking Ball"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/wrecking-ball/1460315055?i=1460315319&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:41',
        release_year: '2013',
        genre: 'Pop',
        album: 'Bangerz',
        artist: 'Miley Cyrus',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/de/4e/57/de4e5777-bbdb-0922-fc7f-d057a2cb1e62/mzaf_13139447676198657148.plus.aac.ep.m4a',
        offset: '0',
    },
    '416': {
        content: '',
        submitted_by: '',
        answers: ["Boom Boom Pow"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/boom-boom-pow/1440768902?i=1440769014&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:11',
        release_year: '2009',
        genre: 'Pop',
        album: 'THE E.N.D. (THE ENERGY NEVER DIES)',
        artist: 'Black Eyed Peas',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f9/a7/65/f9a765ad-cae8-7773-3674-b98f9c347f6a/mzaf_17723620626379942205.plus.aac.ep.m4a',
        offset: '0',
    },
    '417': {
        content: '',
        submitted_by: '',
        answers: ["Night Fever"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/night-fever-from-saturday-night-fever-soundtrack/1445668458?i=1445668465&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '1977',
        genre: 'Pop',
        album: 'Saturday Night Fever (The Original Movie Soundtrack)',
        artist: 'Bee Gees',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/45/7c/8c/457c8cc5-2a44-bceb-d1d1-5e0418178244/mzaf_6637529067505265965.plus.aac.ep.m4a',
        offset: '0',
    },
    '418': {
        content: '',
        submitted_by: '',
        answers: ["Sucker"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sucker/1461478261?i=1461478389&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:01',
        release_year: '2019',
        genre: 'Pop',
        album: 'Happiness Begins',
        artist: 'Jonas Brothers',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/a7/37/85/a7378548-d11d-0447-73b5-02838069a9b0/mzaf_17441971641799194333.plus.aac.ep.m4a',
        offset: '0',
    },
    '419': {
        content: '',
        submitted_by: '',
        answers: ["Savior"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/savior/1440851524?i=1440852127&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:02',
        release_year: '2008',
        genre: 'Rock',
        album: 'Appeal to Reason',
        artist: 'Rise Against',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0c/a0/18/0ca01895-ff9a-b64d-4843-1286958ae43d/mzaf_13413102179159008545.plus.aac.ep.m4a',
        offset: '0',
    },
    '420': {
        content: '',
        submitted_by: '',
        answers: ["I'll Be Missing You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/ill-be-missing-you-feat-faith-evans-112/1635494283?i=1635494459&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Puff Daddy',
        track_length: '5:44',
        release_year: '1997',
        genre: 'Hip-Hop/Rap',
        album: 'No Way Out',
        artist: 'Puff Daddy feat. Faith Evans & 112',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/46/85/f3/4685f361-6ebd-645e-b72e-5a4ffec63ffd/mzaf_1282502567821869465.plus.aac.ep.m4a',
        offset: '0',
    },
    '421': {
        content: '',
        submitted_by: '',
        answers: ["everything i wanted"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/everything-i-wanted/1487502456?i=1487502476&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:05',
        release_year: '2019',
        genre: 'Alternative',
        album: 'Single',
        artist: 'Billie Eilish',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/b4/97/56/b497568f-75cd-8165-8450-8123b8ed526f/mzaf_18039484829389070692.plus.aac.ep.m4a',
        offset: '0',
    },
    '422': {
        content: '',
        submitted_by: '',
        answers: ["Lucid Dreams"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lucid-dreams/1407165109?i=1407165118&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:59',
        release_year: '2018',
        genre: 'Hip-Hop/Rap',
        album: 'Goodbye & Good Riddance',
        artist: 'Juice WRLD',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/79/f6/f7/79f6f71a-4a26-cbe8-84ed-e43bed2297e0/mzaf_521980371834768577.plus.aac.ep.m4a',
        offset: '0',
    },
    '423': {
        content: '',
        submitted_by: '',
        answers: ["Bedroom Walls"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bedroom-walls/1619922330?i=1619922488&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:00',
        release_year: '2009',
        genre: 'Rock, Alternative',
        album: 'Ashes of Soma',
        artist: 'Ashes of Soma',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/7b/d7/5f/7bd75fb5-4520-8d12-0bfa-561f527f1b0a/mzaf_3217496833642101636.plus.aac.ep.m4a',
        offset: '0',
    },
    '424': {
        content: '',
        submitted_by: '',
        answers: ["Just Dance"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/just-dance-feat-colby-odonis/1440818588?i=1440818653&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Lady Gaga',
        track_length: '4:02',
        release_year: '2008',
        genre: 'Pop',
        album: 'The Fame',
        artist: 'Lady Gaga feat. Colby O\'Donis',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/4a/a7/60/4aa76006-d297-2bcb-f201-6f75e9554c21/mzaf_15875176649468788968.plus.aac.ep.m4a',
        offset: '0',
    },
    '425': {
        content: '',
        submitted_by: '',
        answers: ["Underclass Hero"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/underclass-hero/1440766058?i=1440766695&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:14',
        release_year: '2007',
        genre: 'Punk, Alternative, Rock',
        album: 'Underclass Hero',
        artist: 'Sum 41',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/64/69/44/6469440c-31d6-7e52-3eac-cb263d75ab2e/mzaf_11931292484031835655.plus.aac.ep.m4a',
        offset: '0',
    },
    '426': {
        content: '',
        submitted_by: '',
        answers: ["That's What I Like"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/thats-what-i-like/1161503945?i=1161504043&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:26',
        release_year: '2016',
        genre: 'Pop',
        album: '24K Magic',
        artist: 'Bruno Mars',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/ad/b1/86/adb18687-4c21-bd3d-6707-51bfb89a9339/mzaf_13409203090842295806.plus.aac.ep.m4a',
        offset: '0',
    },
    '427': {
        content: '',
        submitted_by: '',
        answers: ["Rockstar"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rockstar/214403406?i=214403648&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:15',
        release_year: '2005',
        genre: 'Rock, Pop, Alternative',
        album: 'All the Right Reasons',
        artist: 'Nickelback',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/d2/ba/1f/d2ba1f41-c3be-a8ff-31cc-383d59d717ca/mzaf_8898706175217975402.plus.aac.ep.m4a',
        offset: '0',
    },
    '428': {
        content: '',
        submitted_by: '',
        answers: ["Holy Grail"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/holy-grail-feat-justin-timberlake/1440925178?i=1440925418&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'JAY-Z',
        track_length: '5:38',
        release_year: '2013',
        genre: 'Hip-Hop/Rap',
        album: 'Magna Carta... Holy Grail',
        artist: 'JAY-Z feat. Justin Timberlake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/79/e1/47/79e147ed-c29f-44b4-2167-84c7cf1ded8f/mzaf_17253573420581043886.plus.aac.ep.m4a',
        offset: '0',
    },
    '429': {
        content: '',
        submitted_by: '',
        answers: ["I Believe I Can Fly"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-believe-i-can-fly/303156530?i=303156673&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:20',
        release_year: '1998',
        genre: 'R&B/Soul, Hip-Hop/Rap, Pop',
        album: 'R.',
        artist: 'R. Kelly',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/75/fd/d2/75fdd253-9490-dcfa-57c1-b8ad85e28c7d/mzaf_118799189640248142.plus.aac.ep.m4a',
        offset: '0',
    },
    '430': {
        content: '',
        submitted_by: '',
        answers: ["Break"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/break/330253758?i=330253842&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:13',
        release_year: '2009',
        genre: 'Hard Rock, Metal',
        album: 'Life Starts Now',
        artist: 'Three Days Grace',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/fc/7d/1d/fc7d1d27-9674-0f74-c13c-c6da686715ea/mzaf_13136681749857285602.plus.aac.ep.m4a',
        offset: '0',
    },
    '431': {
        content: '',
        submitted_by: '',
        answers: ["Something Just Like This"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/something-just-like-this/1207120422?i=1207120448&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:07',
        release_year: '2017',
        genre: 'Dance',
        album: 'Memories...Do Not Open',
        artist: 'The Chainsmokers & Coldplay',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/24/76/9a/24769a15-f4e4-0dd6-e69d-561265d003e1/mzaf_14132362212690308666.plus.aac.ep.m4a',
        offset: '0',
    },
    '432': {
        content: '',
        submitted_by: '',
        answers: ["hot girl bummer"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hot-girl-bummer/1576021253?i=1576021255&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:08',
        release_year: '2019',
        genre: 'Pop',
        album: 'everything means nothing',
        artist: 'blackbear',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b1/32/32/b1323232-b747-229e-e916-74fa142885c6/mzaf_4158801728807810014.plus.aac.ep.m4a',
        offset: '0',
    },
    '433': {
        content: '',
        submitted_by: '',
        answers: ["Surfin' U.S.A."],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/surfin-u-s-a/1443101236?i=1443101252&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:28',
        release_year: '1963',
        genre: 'Rock, Pop, Adult Contemporary',
        album: 'Surfin\' USA',
        artist: 'The Beach Boys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/e2/79/a0/e279a09b-eeb6-7745-9c8e-1c54be83f7ba/mzaf_18193822067917636276.plus.aac.p.m4a',
        offset: '0',
    },
    '434': {
        content: '',
        submitted_by: '',
        answers: ["Dancing With A Stranger"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dancing-with-a-stranger-bonus-track/1531499128?i=1531499803&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:51',
        release_year: '2019',
        genre: 'Pop',
        album: 'Love Goes',
        artist: 'Sam Smith & Normani',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/60/4b/31/604b31bf-1403-4aba-ead2-1a474a0468bb/mzaf_7564163000185828374.plus.aac.ep.m4a',
        offset: '0',
    },
    '435': {
        content: '',
        submitted_by: '',
        answers: ["Memories"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/memories-feat-kid-cudi/693244948?i=693246516&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'David Guetta',
        track_length: '3:30',
        release_year: '2009',
        genre: 'Electronic',
        album: 'One Love',
        artist: 'David Guetta feat. Kid Cudi',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/7d/1d/9a/7d1d9ace-7193-981c-1964-2a59bfe0a430/mzaf_10650488565237426739.plus.aac.ep.m4a',
        offset: '0',
    },
    '436': {
        content: '',
        submitted_by: '',
        answers: ["What's My Age Again?"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/whats-my-age-again/1440839912?i=1440840493&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:28',
        release_year: '1999',
        genre: 'Rock',
        album: 'Enema of the State',
        artist: 'blink-182',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/74/ca/8d/74ca8def-99db-d052-2b23-3ee6f58065df/mzaf_7593769475747429397.plus.aac.p.m4a',
        offset: '0',
    },
    '437': {
        content: '',
        submitted_by: '',
        answers: ["Take Me Home, Country Roads"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/take-me-home-country-roads-original-version/511074105?i=511074294&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:13',
        release_year: '1971',
        genre: 'Country',
        album: 'Poems, Prayers & Promises',
        artist: 'John Denver',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/b3/a2/89/b3a28939-24a7-131d-1dec-3bb926860668/mzaf_2849899978747812607.plus.aac.ep.m4a',
        offset: '0',
    },
    '438': {
        content: '',
        submitted_by: '',
        answers: ["American Pie"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/american-pie-full-length-version/1440834532?i=1440834619&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '8:33',
        release_year: '1971',
        genre: 'Rock',
        album: 'American Pie',
        artist: 'Don Mclean',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/d1/6c/20/d16c2055-65e9-b7a6-2008-3120ca1ad504/mzaf_1393934300485276032.plus.aac.ep.m4a',
        offset: '0',
    },
    '439': {
        content: '',
        submitted_by: '',
        answers: ["Before He Cheats"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/before-he-cheats/214695525?i=214696369&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '2005',
        genre: 'Pop, Rock, Country',
        album: 'Some Hearts',
        artist: 'Carrie Underwood',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/0f/3e/d7/0f3ed7fc-f1b3-a5a5-549a-cd81b28df5f4/mzaf_17579288720398065446.plus.aac.ep.m4a',
        offset: '0',
    },
    '440': {
        content: '',
        submitted_by: '',
        answers: ["Shallow"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/shallow/1434371867?i=1434371887&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:35',
        release_year: '2018',
        genre: 'Country',
        album: 'A Star Is Born',
        artist: 'Lady Gaga & Bradley Cooper',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/d4/da/a9/d4daa9c2-91e0-a208-96a5-ff6459eff8da/mzaf_4743074586601726224.plus.aac.ep.m4a',
        offset: '0',
    },
    '441': {
        content: '',
        submitted_by: '',
        answers: ["Look Alive"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/look-alive-feat-drake/1378387466?i=1378387468&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:01',
        release_year: '2018',
        genre: 'Hip-Hop/Rap',
        album: 'Simi',
        artist: 'BlocBoy JB feat. Drake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/fb/e1/d7/fbe1d7e3-d0cc-599f-8bb9-a9125b00aa2f/mzaf_4656293982235400334.plus.aac.ep.m4a',
        offset: '0',
    },
    '442': {
        content: '',
        submitted_by: '',
        answers: ["So Emotional"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/so-emotional/840431884?i=840431939&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:37',
        release_year: '1987',
        genre: 'R&B/Soul, Rock, Pop',
        album: 'Whitney',
        artist: 'Whitney Houston',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/72/1d/53/721d5333-fbc1-fbfb-e35c-74834bd4aeb2/mzaf_9823072112123548723.plus.aac.ep.m4a',
        offset: '0',
    },
    '443': {
        content: '',
        submitted_by: '',
        answers: ["Honky Tonk Women"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/honky-tonk-women/1488653118?i=1488653325&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:02',
        release_year: '1969',
        genre: 'Rock',
        album: 'Single',
        artist: 'The Rolling Stones',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/df/eb/98/dfeb98a1-068f-a6a1-1348-a584e7f03535/mzaf_5414679995107498846.plus.aac.ep.m4a',
        offset: '0',
    },
    '444': {
        content: '',
        submitted_by: '',
        answers: ["Bangarang"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bangarang-feat-sirah/491596645?i=491596647&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:35',
        release_year: '2011',
        genre: 'Dance, Electronic',
        album: 'Bangarang',
        artist: 'Skrillex feat. Sirah',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/66/ab/1f/66ab1fcc-9b5c-a62b-da7c-3e6cf71fba77/mzaf_113852472863626581.plus.aac.ep.m4a',
        offset: '0',
    },
    '445': {
        content: '',
        submitted_by: '',
        answers: ["Playmate of the Year"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/playmate-of-the-year/307677892?i=307677894&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:57',
        release_year: '1998',
        genre: 'Rock, Metal, Alternative',
        album: 'Playmate of the Year',
        artist: 'Zebrahead',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/2d/16/95/2d169530-8f65-b83a-9866-bd82b31728a1/mzaf_8937424299683329706.plus.aac.ep.m4a',
        offset: '0',
    },
    '446': {
        content: '',
        submitted_by: '',
        answers: ["Hey There Delilah"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hey-there-delilah/1443174061?i=1443175247&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:52',
        release_year: '2005',
        genre: 'Alternative',
        album: 'All That We Needed',
        artist: 'Plain White T\'s',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/87/db/04/87db049e-54af-3f14-925f-9c9180da496d/mzaf_12919223628842857387.plus.aac.ep.m4a',
        offset: '0',
    },
    '447': {
        content: '',
        submitted_by: '',
        answers: ["How Can You Mend a Broken Heart", "How Do You Mend a Broken Heart"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/how-can-you-mend-a-broken-heart/1444155081?i=1444155178&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:57',
        release_year: '1971',
        genre: 'Rock, Pop, Soft Rock',
        album: 'Trafalgar',
        artist: 'Bee Gees',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/af/4d/1c/af4d1c25-3bf3-efc7-17cf-a95ea2615487/mzaf_14767312178007500194.plus.aac.ep.m4a',
        offset: '0',
    },
    '448': {
        content: '',
        submitted_by: '',
        answers: ["Too Close"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/too-close/303078571?i=303078576&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:19',
        release_year: '1997',
        genre: 'R&B/Soul',
        album: 'Rated Next',
        artist: 'Next',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/6d/c2/30/6dc2303b-1205-62a0-60d6-139cf4240da1/mzaf_17316032235544213829.plus.aac.ep.m4a',
        offset: '0',
    },
    '449': {
        content: '',
        submitted_by: '',
        answers: ["Endless Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/endless-love/1443896313?i=1443896316&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:26',
        release_year: '1981',
        genre: 'R&B',
        album: 'Endless Love: Original Motion Picture Soundtrack',
        artist: 'Lionel Richie & Diana Ross',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/33/fb/19/33fb193e-353e-1e86-9d3c-8c550ebc5109/mzaf_2956772938708076627.plus.aac.ep.m4a',
        offset: '0',
    },
    '450': {
        content: '',
        submitted_by: '',
        answers: ["Better Now"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/better-now/1373516902?i=1373517313&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:51',
        release_year: '2018',
        genre: 'Hip-Hop/Rap',
        album: 'beerbongs & bentleys',
        artist: 'Post Malone',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/bb/ab/eb/bbabebb7-4863-14e0-bd1e-7dcca7761f12/mzaf_12542246834022872827.plus.aac.ep.m4a',
        offset: '0',
    },
    '451': {
        content: '',
        submitted_by: '',
        answers: ["You Light up My Life"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/you-light-up-my-life/1384657350?i=1384657725&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:36',
        release_year: '1977',
        genre: 'Pop',
        album: 'You Light up My Life',
        artist: 'Debby Boone',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/de/2d/3e/de2d3e6b-a134-8889-7f5f-3eb94558433c/mzaf_3861982915767445047.plus.aac.ep.m4a',
        offset: '0',
    },
    '452': {
        content: '',
        submitted_by: '',
        answers: ["No Guidance"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/no-guidance-feat-drake/1466876988?i=1466877495&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Chris Brown',
        track_length: '4:20',
        release_year: '2019',
        genre: 'R&B, Hip-Hop',
        album: 'Indigo',
        artist: 'Chris Brown feat. Drake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ea/94/16/ea941634-113c-51f3-27d5-9d2821a9fbc2/mzaf_3263812528864202556.plus.aac.ep.m4a',
        offset: '0',
    },
    '453': {
        content: '',
        submitted_by: '',
        answers: ["Because You Loved Me"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/because-you-loved-me-theme-from-up-close-and-personal/1473319237?i=1473319252&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:33',
        release_year: '1996',
        genre: 'Pop',
        album: 'Falling Into You',
        artist: 'Céline Dion',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/a2/27/d4/a227d4f4-f325-9ed7-6163-101289c8382a/mzaf_4351347771756645986.plus.aac.ep.m4a',
        offset: '0',
    },
    '454': {
        content: '',
        submitted_by: '',
        answers: ["Higher"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/higher/1440631060?i=1440631628&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:16',
        release_year: '1999',
        genre: 'Hard Rock',
        album: 'Human Clay',
        artist: 'Creed',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/01/1e/1a/011e1ac5-d7bf-90e0-89ba-e6465d979c5f/mzaf_17341381904512651822.plus.aac.ep.m4a',
        offset: '0',
    },
    '455': {
        content: '',
        submitted_by: '',
        answers: ["Dance Monkey"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dance-monkey/1475930038?i=1475930045&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:29',
        release_year: '2019',
        genre: 'Alternative',
        album: 'The Kids Are Coming',
        artist: 'Tones And I',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/75/e9/bc/75e9bcee-3c48-6378-cccc-100e9c054e35/mzaf_6813630901031843347.plus.aac.ep.m4a',
        offset: '0',
    },
    '456': {
        content: '',
        submitted_by: '',
        answers: ["Silly Love Songs"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/silly-love-songs/1440951376?i=1440951791&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:54',
        release_year: '1976',
        genre: 'Rock',
        album: 'Wings At The Speed Of Sound',
        artist: 'Paul McCartney & Wings',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/4a/87/b1/4a87b1f5-7e59-f8df-23e9-8791c7b594e3/mzaf_2361304827512712534.plus.aac.ep.m4a',
        offset: '0',
    },
    '457': {
        content: '',
        submitted_by: '',
        answers: ["Since U Been Gone"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/since-u-been-gone/275765354?i=275765380&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:08',
        release_year: '2004',
        genre: 'Pop',
        album: 'Breakaway',
        artist: 'Kelly Clarkson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/04/29/ba/0429ba8a-5c0e-ac8c-8def-c7bca5dc86fd/mzaf_2029381052562313073.plus.aac.ep.m4a',
        offset: '0',
    },
    '458': {
        content: '',
        submitted_by: '',
        answers: ["Say My Name"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/say-my-name/266809606?i=266809802&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:31',
        release_year: '1999',
        genre: 'Pop, Hip-Hop, R&B',
        album: 'The Writing\'s On the Wall',
        artist: 'Destiny\'s Child',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/90/0a/91/900a9135-297e-fef8-e7e9-fdeb7b67d6c9/mzaf_15673336851201125138.plus.aac.ep.m4a',
        offset: '0',
    },
    '459': {
        content: '',
        submitted_by: '',
        answers: ["Young At Heart"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/young-at-heart/1440874282?i=1440874672&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:49',
        release_year: '1953',
        genre: 'Pop Ballad, Vocal Pop',
        album: 'Single',
        artist: 'Frank Sinatra',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/7b/0c/9f/7b0c9f66-3c30-bd75-67dc-370dc0dd0f02/mzaf_1767972530315001334.plus.aac.ep.m4a',
        offset: '0',
    },
    '460': {
        content: '',
        submitted_by: '',
        answers: ["Sorry"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sorry/1440829460?i=1440829610&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:20',
        release_year: '2015',
        genre: 'Pop',
        album: 'Purpose',
        artist: 'Justin Bieber',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/fa/e0/ca/fae0cad3-bb0d-70b1-7acf-3d947c2c5d46/mzaf_9825820354006852862.plus.aac.ep.m4a',
        offset: '0',
    },
    '461': {
        content: '',
        submitted_by: '',
        answers: ["Rush Rush"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rush-rush/723758730?i=723758797&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:52',
        release_year: '1991',
        genre: 'Pop',
        album: 'Spellbound',
        artist: 'Paula Abdul',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/02/d6/94/02d69408-5b85-4ba7-0a5b-d51d8fa8b027/mzaf_18125000490579509846.plus.aac.ep.m4a',
        offset: '0',
    },
    '462': {
        content: '',
        submitted_by: '',
        answers: ["no tears left to cry"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/no-tears-left-to-cry/1399202900?i=1399204228&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:25',
        release_year: '2018',
        genre: 'Pop',
        album: 'Sweetener',
        artist: 'Ariana Grande',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/53/f3/6d/53f36dcd-fc3d-a036-feac-31606d0de546/mzaf_12314359478176628113.plus.aac.ep.m4a',
        offset: '0',
    },
    '463': {
        content: '',
        submitted_by: '',
        answers: ["Gangsta's Paradise"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/gangstas-paradise-feat-l-v/1605189366?i=1605189373&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:01',
        release_year: '1995',
        genre: 'Hip-Hop/Rap, West Coast Rap',
        album: 'Gangsta\'s Paradise',
        artist: 'Coolio feat. L.V.',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/56/58/f7/5658f7ec-2bb5-0ef0-0f9f-5adcd1a96363/mzaf_6119064973601148539.plus.aac.ep.m4a',
        offset: '0',
    },
    '464': {
        content: '',
        submitted_by: '',
        answers: ["Right Round"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/right-round-feat-ke%24ha/308841964?i=308842100&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:24',
        release_year: '2009',
        genre: 'Hip-Hop, Pop',
        album: 'R.O.O.T.S.',
        artist: 'Flo Rida feat. Ke$ha',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/b7/9e/aa/b79eaaf3-9faf-3cff-aea0-b3cf86d8f2cd/mzaf_16861039035737389175.plus.aac.ep.m4a',
        offset: '0',
    },
    '465': {
        content: '',
        submitted_by: '',
        answers: ["On Bended Knee"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/on-bended-knee/1440925670?i=1440926503&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:29',
        release_year: '1994',
        genre: 'R&B/Soul, Pop',
        album: 'II',
        artist: 'Boyz II Men',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/13/62/09/1362097c-634e-df21-0372-3a17d48293da/mzaf_17509813785692881585.plus.aac.ep.m4a',
        offset: '0',
    },
    '466': {
        content: '',
        submitted_by: '',
        answers: ["Kill Bill"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/kill-bill/1657869377?i=1657869393&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:33',
        release_year: '2022',
        genre: 'Hip-Hop',
        album: 'SOS',
        artist: 'SZA',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/d2/2b/c4/d22bc499-88a2-38ca-9b33-23f92f25d494/mzaf_1969244298692515654.plus.aac.ep.m4a',
        offset: '0',
    },
    '467': {
        content: '',
        submitted_by: '',
        answers: ["Get Busy"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/get-busy/1652781992?i=1652782418&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '2002',
        genre: 'Dancehall, Hip-Hop',
        album: 'Dutty Rock',
        artist: 'Sean Paul',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/d7/ff/b9/d7ffb967-24e5-f3d9-df31-e5342282b319/mzaf_304639255470679427.plus.aac.ep.m4a',
        offset: '0',
    },
    '468': {
        content: '',
        submitted_by: '',
        answers: ["Out of Touch"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/out-of-touch/217752303?i=217752313&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:21',
        release_year: '1984',
        genre: 'Pop, Alternative, Rock',
        album: 'Big Bam Boom',
        artist: 'Daryl Hall & John Oates',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e8/52/d5/e852d59f-ea33-3620-4150-5ac85e734562/mzaf_9491339683219921244.plus.aac.ep.m4a',
        offset: '0',
    },
    '469': {
        content: '',
        submitted_by: '',
        answers: ["Love In This Club"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/love-in-this-club-feat-young-jeezy/281698028?i=281698335&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:19',
        release_year: '2008',
        genre: 'R&B, Hip-Hop',
        album: 'Here I Stand',
        artist: 'Usher feat. Young Jeezy',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/4d/df/31/4ddf31a5-d532-1e47-e968-aee0a72e47ed/mzaf_7151837136651776202.plus.aac.ep.m4a',
        offset: '0',
    },
    '470': {
        content: '',
        submitted_by: '',
        answers: ["Savage"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/savage/1501734515?i=1501734796&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:35',
        release_year: '2020',
        genre: 'Hip-Hop/Rap',
        album: 'Suga',
        artist: 'Megan Thee Stallion',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/00/a5/ad/00a5ad54-f097-2b5e-33b5-39a7920541f9/mzaf_15164010532918176885.plus.aac.ep.m4a',
        offset: '0',
    },
    '471': {
        content: '',
        submitted_by: '',
        answers: ["Don't Go Breaking My Heart"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dont-go-breaking-my-heart/1440912950?i=1440913387&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:35',
        release_year: '1975',
        genre: 'Pop',
        album: 'Single',
        artist: 'Elton John & Kiki Dee',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/6e/73/f9/6e73f9e1-6a63-8b42-89f1-7f6b877b01f3/mzaf_3403265846399826318.plus.aac.ep.m4a',
        offset: '0',
    },
    '472': {
        content: '',
        submitted_by: '',
        answers: ["Complicated Shadows"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/complicated-shadows/294810308?i=294810317&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:43',
        release_year: '1996',
        genre: 'Pop, Pop/Rock, Alternative',
        album: 'All This Useless Beauty',
        artist: 'Elvis Costello & The Attractions',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/62/2e/d5/622ed562-d8a8-37d9-6831-af0f2c4ba55e/mzaf_4198385544027497172.plus.aac.ep.m4a',
        offset: '0',
    },
    '473': {
        content: '',
        submitted_by: '',
        answers: ["Paparazzi"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/paparazzi/273853449?i=273853528&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:56',
        release_year: '1996',
        genre: 'Hip-Hop',
        album: 'At The Speed Of Life',
        artist: 'Xzibit',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/36/87/99/36879909-894e-606f-66c9-4b56ca653404/mzaf_5377765250652168968.plus.aac.ep.m4a',
        offset: '0',
    },
    '474': {
        content: '',
        submitted_by: '',
        answers: ["Footloose"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/footloose/1164757097?i=1164757333&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:47',
        release_year: '1984',
        genre: 'Rock',
        album: 'Footloose',
        artist: 'Kenny Loggins',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/33/cf/41/33cf4187-ae6b-3148-7c2c-d446379bd964/mzaf_7771272559062829582.plus.aac.ep.m4a',
        offset: '0',
    },
    '475': {
        content: '',
        submitted_by: '',
        answers: ["White Rabbit"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/white-rabbit/281811707?i=281811771&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:30',
        release_year: '1967',
        genre: 'Rock, Psychedelic, Folk Rock',
        album: 'Surrealistic Pillow',
        artist: 'Jefferson Airplane',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/9d/7f/c6/9d7fc652-907c-b040-3ee0-07de78b6e064/mzaf_7971429711489012747.plus.aac.ep.m4a',
        offset: '0',
    },
    '476': {
        content: '',
        submitted_by: '',
        answers: ["Firework"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/firework/716192216?i=716192625&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:47',
        release_year: '2010',
        genre: 'Pop, Teen Pop, Pop/Rock',
        album: 'Teenage Dream',
        artist: 'Katy Perry',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/4f/6b/17/4f6b170c-efa0-ade2-e846-349851af90ca/mzaf_13012672105806655727.plus.aac.ep.m4a',
        offset: '0',
    },
    '477': {
        content: '',
        submitted_by: '',
        answers: ["I Feel Free"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-feel-free/1440822300?i=1440822618&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:53',
        release_year: '1966',
        genre: 'Rock, Hard Rock, Blues',
        album: 'Fresh Cream',
        artist: 'Cream',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/04/c3/94/04c39456-230d-3b6f-02d2-e1ea9fbc45cf/mzaf_18006813932463205353.plus.aac.ep.m4a',
        offset: '0',
    },
    '478': {
        content: '',
        submitted_by: '',
        answers: ["Nothing's Gonna Stop Us Now"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/nothings-gonna-stop-us-now/1446278327?i=1446278331&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:30',
        release_year: '1987',
        genre: 'Rock',
        album: 'No Protection',
        artist: 'Starship',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/db/0b/17/db0b17e9-22a8-63ec-387e-602d285e7c1b/mzaf_9802684107520684649.plus.aac.ep.m4a',
        offset: '0',
    },
    '479': {
        content: '',
        submitted_by: '',
        answers: ["One of These Days"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/one-of-these-days/1065973614?i=1065973615&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:55',
        release_year: '1971',
        genre: 'Rock',
        album: 'Meddle',
        artist: 'Pink Floyd',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/69/c4/25/69c425f3-837a-e31f-c0b0-bc4f8ec93ac2/mzaf_17439095305866339119.plus.aac.ep.m4a',
        offset: '0',
    },
    '480': {
        content: '',
        submitted_by: '',
        answers: ["State Trooper"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/state-trooper/185798980?i=185799306&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:17',
        release_year: '1982',
        genre: 'Rock',
        album: 'Nebraska',
        artist: 'Bruce Springsteen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c7/63/49/c76349ea-f672-4b73-1e83-231895d65068/mzaf_11112117042264043557.plus.aac.ep.m4a',
        offset: '0',
    },
    '481': {
        content: '',
        submitted_by: '',
        answers: ["My Lover's Prayer"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/my-lovers-prayer/995810706?i=995810766&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:08',
        release_year: '1966',
        genre: 'R&B/Soul',
        album: 'Complete & Unbelievable: The Otis Redding Dictionary of Soul',
        artist: 'Otis Redding',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c0/2e/5b/c02e5bd7-4adf-5277-c63e-24c1614b42b6/mzaf_7936899241326714130.plus.aac.ep.m4a',
        offset: '0',
    },
    '482': {
        content: '',
        submitted_by: '',
        answers: ["Rhythmeen"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rhythmeen/299565609?i=299565615&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:52',
        release_year: '1996',
        genre: 'Blues rock, Rock & Roll, Arena Rock',
        album: 'Rhythmeen',
        artist: 'ZZ Top',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/e0/00/5f/e0005f24-1c13-2105-8487-a891653f6899/mzaf_15023067726630609043.plus.aac.ep.m4a',
        offset: '0',
    },
    '483': {
        content: '',
        submitted_by: '',
        answers: ["The Little Drummer Boy"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-little-drummer-boy/1487549700?i=1487550371&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:29',
        release_year: '1987',
        genre: 'Holiday',
        album: 'A Very Special Christmas',
        artist: 'Bob Seger & The Silver Bullet Band',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/e0/46/42/e0464250-897c-5fed-3cf2-e3a0ee7f9504/mzaf_3680552566592787531.plus.aac.ep.m4a',
        offset: '0',
    },
    '484': {
        content: '',
        submitted_by: '',
        answers: ["Kid A"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/kid-a/1097862870?i=1097863120&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:44',
        release_year: '2000',
        genre: 'Alternative',
        album: 'Kid A',
        artist: 'Radiohead',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/dd/17/5b/dd175b86-947b-2833-a955-526977f5c4ba/mzaf_4806319466663321424.plus.aac.ep.m4a',
        offset: '0',
    },
    '485': {
        content: '',
        submitted_by: '',
        answers: ["Undercover of the Night"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/undercover-of-the-night/1440813901?i=1440814199&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:33',
        release_year: '1983',
        genre: 'Rock',
        album: 'Undercover',
        artist: 'The Rolling Stones',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/52/60/53/526053f2-a5ed-9e5c-24e8-72cd5ee8ba27/mzaf_9852326127007256800.plus.aac.ep.m4a',
        offset: '0',
    },
    '486': {
        content: '',
        submitted_by: '',
        answers: ["Party Rock Anthem"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/party-rock-anthem-feat-lauren-bennett-goonrock/1440636622?i=1440636627&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:22',
        release_year: '2011',
        genre: 'Pop',
        album: 'Sorry for Party Rocking',
        artist: 'LMFAO feat. Lauren Bennett & GoonRock',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d7/b1/f6/d7b1f6e1-f72c-9d5d-958b-08e1ea75aab5/mzaf_9706331278553957313.plus.aac.ep.m4a',
        offset: '0',
    },
    '487': {
        content: '',
        submitted_by: '',
        answers: ["Glad Tidings"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/glad-tidings/712727450?i=712727473&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:39',
        release_year: '1970',
        genre: 'Rock, Pop',
        album: 'Moondance',
        artist: 'Van Morrison',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/c4/42/d4/c442d4cc-7191-1884-711b-de791f1887d0/mzaf_16562478504125704281.plus.aac.ep.m4a',
        offset: '0',
    },
    '488': {
        content: '',
        submitted_by: '',
        answers: ["When It's Cold I'd Like to Die"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/when-its-cold-id-like-to-die-feat-mimi-goese/1556721436?i=1556721452&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:14',
        release_year: '1995',
        genre: 'Electronic',
        album: 'Everything Is Wrong',
        artist: 'Moby feat. Mimi Goese',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview114/v4/af/bb/af/afbbafb7-f21e-b0ac-a79d-03396043a86a/mzaf_9180594216930421714.plus.aac.ep.m4a',
        offset: '0',
    },
    '489': {
        content: '',
        submitted_by: '',
        answers: ["I'm Gonna Move To The Outskirts of Town"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/im-gonna-move-to-the-outskirts-of-town/1569552576?i=1569553078&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:42',
        release_year: '1960',
        genre: 'Jazz',
        album: 'Genius + Soul = Jazz',
        artist: 'Ray Charles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/6d/c5/fa/6dc5fa26-3459-b418-18f1-fc53d714abdc/mzaf_6708336509187772170.plus.aac.ep.m4a',
        offset: '0',
    },
    '490': {
        content: '',
        submitted_by: '',
        answers: ["Let It Rock"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/let-it-rock/1443857251?i=1443858763&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '1:42',
        release_year: '1960',
        genre: 'Rock',
        album: 'Rockin\' at the Hops',
        artist: 'Chuck Berry',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/5e/3f/69/5e3f69ab-9ce8-147b-e809-9497ce6e0f3b/mzaf_13440092117073615203.plus.aac.p.m4a',
        offset: '0',
    },
    '491': {
        content: '',
        submitted_by: '',
        answers: ["Valerie"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/valerie-feat-amy-winehouse/259107346?i=259107548&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:39',
        release_year: '2007',
        genre: 'Pop, R&B/Soul',
        album: 'Version',
        artist: 'Mark Ronson featuring Amy Winehouse',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/a8/10/38/a810380e-3b9f-6019-c18b-0545bf29b581/mzaf_7167201124572474696.plus.aac.ep.m4a',
        offset: '0',
    },
    '492': {
        content: '',
        submitted_by: '',
        answers: ["You're Still the One"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/youre-still-the-one/1445668706?i=1445669197&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:32',
        release_year: '1997',
        genre: 'Country',
        album: 'Come On Over',
        artist: 'Shania Twain',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/3f/2c/c0/3f2cc06e-e7c4-1d7a-8397-122091eec96b/mzaf_16550657291999622515.plus.aac.ep.m4a',
        offset: '0',
    },
    '493': {
        content: '',
        submitted_by: '',
        answers: ["An Open Letter to NYC"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/an-open-letter-to-nyc/725839194?i=725839486&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:18',
        release_year: '2004',
        genre: 'Hip-Hop/Rap, Alternative Rap, Rock',
        album: 'To the 5 Boroughs',
        artist: 'Beastie Boys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0e/ed/74/0eed745f-daa7-5ef0-b3ba-2ba4e9040244/mzaf_4374215494762060092.plus.aac.ep.m4a',
        offset: '0',
    },
    '494': {
        content: '',
        submitted_by: '',
        answers: ["City of Stars"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/city-of-stars/1440863506?i=1440864172&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:29',
        release_year: '2016',
        genre: 'Musicals, Soundtrack',
        album: 'La La Land (Original Motion Picture Soundtrack)',
        artist: 'Ryan Gosling & Emma Stone',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/c7/fa/ce/c7face67-3ff9-9b8e-2932-0a5db69ad051/mzaf_6269194343571084138.plus.aac.p.m4a',
        offset: '0',
    },
    '495': {
        content: '',
        submitted_by: '',
        answers: ["Nobody Speak"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/nobody-speak-feat-run-the-jewels/1569903707?i=1569903711&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:15',
        release_year: '2016',
        genre: 'Hip-Hop, Alternative Rap, Electronic',
        album: 'The Mountain Will Fall',
        artist: 'DJ Shadow feat. Run The Jewels',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e0/8c/82/e08c824f-3cba-f6ed-2c57-41657f297607/mzaf_4161737093388616034.plus.aac.ep.m4a',
        offset: '0',
    },
    '496': {
        content: '',
        submitted_by: '',
        answers: ["Thank You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/thank-you/254578536?i=254579170&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:37',
        release_year: '1999',
        genre: 'Pop, Rock, Alternative',
        album: 'No Angel',
        artist: 'Dido',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/53/e9/26/53e92694-adb8-cebd-649e-506154cfb279/mzaf_16359267701559751083.plus.aac.ep.m4a',
        offset: '0',
    },
    '497': {
        content: '',
        submitted_by: '',
        answers: ["Deadly Valentine"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/deadly-valentine/1331594683?i=1331594740&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '6:04',
        release_year: '2017',
        genre: 'Pop, Alternative, Electronic',
        album: 'Rest',
        artist: 'Charlotte Gainsbourg',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/c7/be/a9/c7bea91e-f7d0-d934-1c10-151f336385fb/mzaf_17325712019960773347.plus.aac.ep.m4a',
        offset: '0',
    },
    '498': {
        content: '',
        submitted_by: '',
        answers: ["Uptown Girl"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/uptown-girl/259814641?i=259814810&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:18',
        release_year: '1983',
        genre: 'Pop, Adult Contemporary',
        album: 'An Innocent Man',
        artist: 'Billy Joel',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/95/6b/86/956b86e9-e263-23c3-e616-3e3cd582790d/mzaf_17039299333269426563.plus.aac.ep.m4a',
        offset: '0',
    },
    '499': {
        content: '',
        submitted_by: '',
        answers: ["99 Problems"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/99-problems/1440785433?i=1440785690&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:54',
        release_year: '2003',
        genre: 'Hip-Hop/Rap, East Coast Rap, Hardcore Rap',
        album: 'The Black Album',
        artist: 'JAY-Z',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/95/23/1c/95231c4d-b343-d3cc-5d0c-e557769f00e1/mzaf_10964244390897307932.plus.aac.ep.m4a',
        offset: '0',
    },
    '500': {
        content: '',
        submitted_by: '',
        answers: ["Mona Lisa"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/mona-lisa/1444030068?i=1444030713&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:14',
        release_year: '1950',
        genre: 'Pop, Easy Listening',
        album: 'Unforgettable',
        artist: 'Nat \"King\" Cole',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/69/e4/00/69e40034-409e-fee9-a622-43200c6db867/mzaf_4212470508755582300.plus.aac.ep.m4a',
        offset: '0',
    },
    '501': {
        content: '',
        submitted_by: '',
        answers: ["Cucci Galore"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cucci-galore/573424811?i=573424822&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:25',
        release_year: '2012',
        genre: 'Rock, Southern Rock, American Trad Rock',
        album: 'Rebel Soul',
        artist: 'Kid Rock',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/1c/86/30/1c86302e-72c9-6536-d3ed-6ac2ded74ca4/mzaf_412369000120842498.plus.aac.ep.m4a',
        offset: '0',
    },
    '502': {
        content: '',
        submitted_by: '',
        answers: ["Minority"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/minority/1160081195?i=1160081266&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:49',
        release_year: '2000',
        genre: 'Alternative',
        album: 'Warning',
        artist: 'Green Day',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/4f/36/63/4f366335-e3c9-3c67-0a4d-ff22daa4f7c9/mzaf_14164946009089872256.plus.aac.ep.m4a',
        offset: '0',
    },
    '503': {
        content: '',
        submitted_by: '',
        answers: ["Ungirthed"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/ungirthed/535907905?i=535908172&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:48',
        release_year: '2012',
        genre: 'Electronic',
        album: 'Shrines',
        artist: 'Purity Ring',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/08/85/8b/08858bd4-8c8b-360f-1858-20dc1493cedc/mzaf_8278129747703827710.plus.aac.ep.m4a',
        offset: '0',
    },
    '504': {
        content: '',
        submitted_by: '',
        answers: ["Hello, Dolly!"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hello-dolly/1422000964?i=1422000970&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:27',
        release_year: '1964',
        genre: 'Jazz',
        album: 'Hello, Dolly!',
        artist: 'Louis Armstrong',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a9/cc/59/a9cc59bc-a0cc-aa39-ebd9-356ad0b5cf6d/mzaf_10698196856725887436.plus.aac.p.m4a',
        offset: '0',
    },
    '505': {
        content: '',
        submitted_by: '',
        answers: ["Bad Vibrations"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bad-vibrations/1616232213?i=1616232433&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:27',
        release_year: '2010',
        genre: 'Rock, Alternative, Indie Rock',
        album: 'Phosphene Dream',
        artist: 'The Black Angels',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/b2/5d/0c/b25d0c81-7fd8-8514-3d36-8633705c4356/mzaf_10649127004493808792.plus.aac.ep.m4a',
        offset: '0',
    },
    '506': {
        content: '',
        submitted_by: '',
        answers: ["How I Feel"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/how-i-feel/731197191?i=731197197&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:49',
        release_year: '2013',
        genre: 'Hip-Hop/Rap, Electronic, Dance',
        album: 'Single',
        artist: 'Flo Rida',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/c3/40/52/c340522b-a979-56e5-8d37-eca53c077952/mzaf_14046613301064271356.plus.aac.ep.m4a',
        offset: '0',
    },
    '507': {
        content: '',
        submitted_by: '',
        answers: ["When Doves Cry"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/when-doves-cry/1746833068?i=1746833079&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:54',
        release_year: '1984',
        genre: 'Pop',
        album: 'Purple Rain',
        artist: 'Prince',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/f8/6f/ff/f86ffff8-ae69-74c3-93e5-c821b05b4641/mzaf_10863820880086539291.plus.aac.ep.m4a',
        offset: '0',
    },
    '508': {
        content: '',
        submitted_by: '',
        answers: ["Nookie"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/nookie/1443925845?i=1443925859&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:26',
        release_year: '1999',
        genre: 'Hard Rock, Alternative, Metal',
        album: 'Significant Other',
        artist: 'Limp Bizkit',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/29/55/57/295557a0-801b-4263-d92d-54240b5b8b49/mzaf_1307987475780962216.plus.aac.ep.m4a',
        offset: '0',
    },
    '509': {
        content: '',
        submitted_by: '',
        answers: ["Work"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/work-feat-drake/1440933869?i=1440934019&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:39',
        release_year: '2016',
        genre: 'Pop',
        album: 'ANTI',
        artist: 'Rihanna feat. Drake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/fe/4e/a3/fe4ea3bb-138a-f7bb-6a1f-f16febfca2ca/mzaf_9131588101465896257.plus.aac.ep.m4a',
        offset: '0',
    },
    '510': {
        content: '',
        submitted_by: '',
        answers: ["I Want to Hold Your Hand"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-want-to-hold-your-hand/1441132965?i=1441133348&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:26',
        release_year: '1963',
        genre: 'Rock',
        album: 'Single',
        artist: 'The Beatles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/f0/2c/7a/f02c7a64-249a-6eb7-1976-c5ba58cc1a6c/mzaf_12259718484293318896.plus.aac.p.m4a',
        offset: '0',
    },
    '511': {
        content: '',
        submitted_by: '',
        answers: ["Something I Can Never Have"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/something-i-can-never-have/1440940694?i=1440941076&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:55',
        release_year: '1989',
        genre: 'Alternative, Rock, Electronic, Industrial',
        album: 'Pretty Hate Machine',
        artist: 'Nine Inch Nails',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/62/b4/b8/62b4b8e1-45ca-6485-17dc-eb0e90315474/mzaf_12152585496972353251.plus.aac.ep.m4a',
        offset: '0',
    },
    '512': {
        content: '',
        submitted_by: '',
        answers: ["Mamushi"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/mamushi-feat-yuki-chiba/1752024169?i=1752024398&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:36',
        release_year: '2024',
        genre: 'Hip-Hop/Rap',
        album: 'MEGAN',
        artist: 'Megan Thee Stallion feat. Yuki Chiba',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/31/78/63/3178634d-5795-b86d-678c-f53fc1d15483/mzaf_7580805442475389179.plus.aac.ep.m4a',
        offset: '0',
    },
    '513': {
        content: '',
        submitted_by: '',
        answers: ["Love Story"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/love-story/1452859090?i=1452859410&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:55',
        release_year: '2008',
        genre: 'Country',
        album: 'Fearless',
        artist: 'Taylor Swift',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/01/b2/d3/01b2d3f4-d1ea-0a9e-3090-e6d609b33691/mzaf_4107903060845180785.plus.aac.ep.m4a',
        offset: '0',
    },
    '514': {
        content: '',
        submitted_by: '',
        answers: ["Mountain Song"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/mountain-song/1018518208?i=1018519025&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:03',
        release_year: '1988',
        genre: 'Rock, Alternative',
        album: 'Nothing\'s Shocking',
        artist: 'Jane\'s Addiction',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/2b/d4/94/2bd4947f-f410-e0f4-6c65-b08c31a1b815/mzaf_13995103981008740734.plus.aac.ep.m4a',
        offset: '0',
    },
    '515': {
        content: '',
        submitted_by: '',
        answers: ["The Next Episode"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/the-next-episode-feat-snoop-dogg/1440782221?i=1440783384&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440783384&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '2:41',
        release_year: '1999',
        genre: 'Hip-Hop/Rap',
        album: '2001',
        artist: 'Dr. Dre feat. Snoop Dogg',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/9a/10/d8/9a10d89c-9959-ed56-bb57-abd52a8bbb88/mzaf_16368074934569064927.plus.aac.ep.m4a',
        offset: '0',
    },
    '516': {
        content: '',
        submitted_by: '',
        answers: ["PDLIF"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/pdlif/1507971421?i=1507971697&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:57',
        release_year: '2020',
        genre: 'Alternative',
        album: 'Single',
        artist: 'Bon Iver',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/34/5e/07/345e07e3-7dd7-7240-b0ae-d86473252ead/mzaf_4011664762008042482.plus.aac.ep.m4a',
        offset: '0',
    },
    '517': {
        content: '',
        submitted_by: '',
        answers: ["KEHLANI"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/kehlani/1753904949?i=1753904950&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:02',
        release_year: '2024',
        genre: 'Hip-Hop/Rap',
        album: 'Single',
        artist: 'Jordan Adetunji',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/79/7f/04/797f042c-cac0-5bff-bf30-2b75726d86d0/mzaf_14133415103090442412.plus.aac.p.m4a',
        offset: '0',
    },
    '518': {
        content: '',
        submitted_by: '',
        answers: ["I Will Follow"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-will-follow/1443189101?i=1443189106&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:37',
        release_year: '1980',
        genre: 'Rock',
        album: 'Boy',
        artist: 'U2',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/2c/d6/40/2cd6402f-eecb-fd8b-a12c-0e9bb01944c6/mzaf_2462106958985266926.plus.aac.ep.m4a',
        offset: '0',
    },
    '519': {
        content: '',
        submitted_by: '',
        answers: ["Who's Gonna Save My Soul"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/whos-gonna-save-my-soul/276168665?i=276168717&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:15',
        release_year: '2008',
        genre: 'Alternative, Rock, Hip-Hop/Rap, R&B/Soul',
        album: 'The Odd Couple',
        artist: 'Gnarls Barkley',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/10/23/bc/1023bcf5-6000-674b-119a-b8871db8646d/mzaf_9844275684526349129.plus.aac.ep.m4a',
        offset: '0',
    },
    '520': {
        content: '',
        submitted_by: '',
        answers: ["If I Had a Heart"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/if-i-had-a-heart/1494514593?i=1494514595&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:49',
        release_year: '2008',
        genre: 'Electronic',
        album: 'Fever Ray',
        artist: 'Fever Ray',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/64/73/89/647389d8-f5b8-1bb0-71e8-98b2d0605120/mzaf_7870750096036541192.plus.aac.ep.m4a',
        offset: '0',
    },
    '521': {
        content: '',
        submitted_by: '',
        answers: ["If I Ever Lose My Faith In You"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/if-i-ever-lose-my-faith-in-you/1415203729?i=1415203730&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:30',
        release_year: '1993',
        genre: 'Rock, Pop, Adult Contemporary',
        album: 'Ten Summoner\'s Tales',
        artist: 'Sting',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/d6/4d/a1/d64da128-90ff-de39-d574-83f392d07c11/mzaf_13097552675077973311.plus.aac.ep.m4a',
        offset: '0',
    },
    '522': {
        content: '',
        submitted_by: '',
        answers: ["Dreamlover"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dreamlover/1706160663?i=1706160675&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:53',
        release_year: '1993',
        genre: 'Pop',
        album: 'Music Box',
        artist: 'Mariah Carey',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/46/b7/25/46b725ee-b982-1c78-462f-d2880c8ece0b/mzaf_16824837513413640217.plus.aac.ep.m4a',
        offset: '0',
    },
    '523': {
        content: '',
        submitted_by: '',
        answers: ["Put It On Da Floor Again"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/put-it-on-da-floor-again-feat-cardi-b/1761455575?i=1761455594&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:05',
        release_year: '2024',
        genre: 'Rap, Hip-Hop',
        album: 'Sugar Honey Iced Tea',
        artist: 'Latto feat. Cardi B',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/db/da/b8/dbdab81b-3978-18ca-e47e-098ea1b4afe4/mzaf_10154250220976255106.plus.aac.ep.m4a',
        offset: '0',
    },
    '524': {
        content: '',
        submitted_by: '',
        answers: ["Love Yourself"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/love-yourself/1440829460?i=1440829613&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:53',
        release_year: '2015',
        genre: 'Pop',
        album: 'Purpose',
        artist: 'Justin Bieber',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/be/60/be/be60be68-4814-67db-122d-e0620e13f79a/mzaf_16881176391587519868.plus.aac.ep.m4a',
        offset: '0',
    },
    '525': {
        content: '',
        submitted_by: '',
        answers: ["Clean"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/clean/1440935467?i=1440936206&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:31',
        release_year: '2014',
        genre: 'Pop',
        album: '1989',
        artist: 'Taylor Swift',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/66/4b/ac/664bac15-9db3-dc6b-15e9-34277cda6652/mzaf_8055427146709168228.plus.aac.ep.m4a',
        offset: '0',
    },
    '526': {
        content: '',
        submitted_by: '',
        answers: ["Always Be My Baby"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/always-be-my-baby/169962059?i=169962272&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:18',
        release_year: '1995',
        genre: 'Pop, R&B/Soul, Contemporary R&B',
        album: 'Daydream',
        artist: 'Mariah Carey',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/72/ef/1f/72ef1f42-626b-9c29-4187-e5bfa23e8bfe/mzaf_6751505116182693801.plus.aac.ep.m4a',
        offset: '0',
    },
    '527': {
        content: '',
        submitted_by: '',
        answers: ["ONE MORE TIME"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/one-more-time/1712186278?i=1712186284&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:28',
        release_year: '2023',
        genre: 'Rock',
        album: 'ONE MORE TIME...',
        artist: 'blink-182',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/31/49/a4/3149a4ec-d02b-b5af-6dfd-b04236b85561/mzaf_11349756411517969396.plus.aac.ep.m4a',
        offset: '0',
    },
    '528': {
        content: '',
        submitted_by: '',
        answers: ["Knock Three Times"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/knock-three-times/278281860?i=278281957&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:57',
        release_year: '1970',
        genre: 'Pop, Soft Rock, Pop/Rock, Teen Pop',
        album: 'Candida',
        artist: 'Tony Orlando & Dawn',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/5e/b9/b6/5eb9b6fd-5abb-512f-a660-64914dcd8b20/mzaf_9438168681365580505.plus.aac.ep.m4a',
        offset: '0',
    },
    '529': {
        content: '',
        submitted_by: '',
        answers: ["HUMBLE."],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/humble/1440881047?i=1440881684&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:57',
        release_year: '2017',
        genre: 'Hip-Hop/Rap',
        album: 'DAMN.',
        artist: 'Kendrick Lamar',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/8c/0d/44/8c0d446c-880d-ff14-20f8-12c4e5f019c1/mzaf_2396850990950895944.plus.aac.ep.m4a',
        offset: '0',
    },
    '530': {
        content: '',
        submitted_by: '',
        answers: ["Sprinter"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sprinter/1690775575?i=1690775810&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:49',
        release_year: '2023',
        genre: 'Hip-Hop/Rap',
        album: 'Split Decision - EP',
        artist: 'Dave & Central Cee',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/c3/8a/ea/c38aeae5-0606-81e1-5600-8d2bbf7cbadc/mzaf_16802009328590078834.plus.aac.ep.m4a',
        offset: '0',
    },
    '531': {
        content: '',
        submitted_by: '',
        answers: ["Vienna"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/vienna/158617952?i=158618071&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:33',
        release_year: '1977',
        genre: 'Rock, Arena Rock, Pop, Soft Rock',
        album: 'The Stranger',
        artist: 'Billy Joel',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/ba/ac/48/baac48ce-e1ba-3e61-baa4-25848e28e6af/mzaf_17061958709893974021.plus.aac.ep.m4a',
        offset: '0',
    },
    '532': {
        content: '',
        submitted_by: '',
        answers: ["Take a Look Around"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/take-a-look-around/1440842682?i=1440843451&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:21',
        release_year: '2000',
        genre: 'Hard Rock, Rock',
        album: 'Chocolate Starfish and the Hot Dog Flavored Water',
        artist: 'Limp Bizkit',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/f8/99/24/f89924d5-e33d-c463-90a4-34ca39b44d06/mzaf_17063632383722029751.plus.aac.ep.m4a',
        offset: '0',
    },
    '533': {
        content: '',
        submitted_by: '',
        answers: ["Chandelier"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/chandelier/882945378?i=882945383&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:36',
        release_year: '2014',
        genre: 'Pop',
        album: '1000 Forms of Fear',
        artist: 'Sia',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/42/18/1d/42181d34-d6aa-71de-4ec9-c4f9efe78690/mzaf_10528137640321452857.plus.aac.ep.m4a',
        offset: '0',
    },
    '534': {
        content: '',
        submitted_by: '',
        answers: ["Hell of a View"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hell-of-a-view/1547677830?i=1547678056&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:55',
        release_year: '2020',
        genre: 'Country',
        album: 'Soul',
        artist: 'Eric Church',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/48/0c/30/480c3047-8df3-e8d3-1914-afe3fe1ccff7/mzaf_5908513557862954236.plus.aac.ep.m4a',
        offset: '0',
    },
    '535': {
        content: '',
        submitted_by: '',
        answers: ["That's the Way Love Goes"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/thats-the-way-love-goes/1388413681?i=1388413850&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:25',
        release_year: '1993',
        genre: 'Pop',
        album: 'Janet.',
        artist: 'Janet Jackson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f3/d5/8e/f3d58e49-749d-4096-cdf2-c703c9786e2a/mzaf_10368896872964962533.plus.aac.ep.m4a',
        offset: '0',
    },
    '536': {
        content: '',
        submitted_by: '',
        answers: ["Dancing In the Moonlight"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/dancing-in-the-moonlight/208819125?i=208819580&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=208819580&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:01',
        release_year: '1973',
        genre: 'Rock, Pop',
        album: 'Dancing In the Moonlight',
        artist: 'King Harvest',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/1b/89/e7/1b89e797-7b29-9008-0774-34d41e5f0ab5/mzaf_13239995656705602944.plus.aac.ep.m4a',
        offset: '0',
    },
    '537': {
        content: '',
        submitted_by: '',
        answers: ["HOT TO GO!"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/hot-to-go/1698723205?i=1698723221&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:04',
        release_year: '2023',
        genre: 'Pop',
        album: 'The Rise and Fall of a Midwest Princess',
        artist: 'Chappell Roan',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/3a/ca/b3/3acab33a-1ecf-0d2e-e4f3-83948fd3e2e8/mzaf_12383546531068205060.plus.aac.ep.m4a',
        offset: '0',
    },
    '538': {
        content: '',
        submitted_by: '',
        answers: ["Give Me One Reason"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/give-me-one-reason/293102727?i=293102967&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:28',
        release_year: '1995',
        genre: 'Rock, Alternative',
        album: 'New Beginning',
        artist: 'Tracy Chapman',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/65/60/85/6560851f-5fd8-85e6-2a51-b49254b8a710/mzaf_17788474194644437755.plus.aac.ep.m4a',
        offset: '0',
    },
    '539': {
        content: '',
        submitted_by: '',
        answers: ["Espresso"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/espresso/1752214909?i=1752214923&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:55',
        release_year: '2024',
        genre: 'Pop',
        album: 'Short n\' Sweet',
        artist: 'Sabrina Carpenter',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/a3/00/52/a30052f3-d803-f52e-1548-9d29e3205b7f/mzaf_2324200437021344303.plus.aac.ep.m4a',
        offset: '0',
    },
    '540': {
        content: '',
        submitted_by: '',
        answers: ["You Proof"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/you-proof/1668202221?i=1668202550&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:37',
        release_year: '2022',
        genre: 'Country',
        album: 'One Thing At A Time',
        artist: 'Morgan Wallen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/a1/da/cf/a1dacfb0-fd3f-4da8-1b40-3e42658c6253/mzaf_14264349998546274205.plus.aac.ep.m4a',
        offset: '0',
    },
    '541': {
        content: '',
        submitted_by: '',
        answers: ["Landslide"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/landslide-remastered/1308648830?i=1308648844&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:19',
        release_year: '1975',
        genre: 'Rock',
        album: 'Fleetwood Mac',
        artist: 'Fleetwood Mac',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/df/33/01/df330182-3755-e3d4-2cdf-83a2d407c2e9/mzaf_12634107618794474495.plus.aac.ep.m4a',
        offset: '0',
    },
    '542': {
        content: '',
        submitted_by: '',
        answers: ["Barbie World"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/barbie-world-with-aqua-from-barbie-the-album/1692268040?i=1692268134&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1692268134&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '1:49',
        release_year: '2023',
        genre: 'Hip-Hop/Rap',
        album: 'Barbie The Album',
        artist: 'Aqua, Nicki Minaj & Ice Spice',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/74/ae/7e/74ae7e78-436f-b6b7-1757-526f159f6c36/mzaf_5245718006639286610.plus.aac.p.m4a',
        offset: '0',
    },
    '543': {
        content: '',
        submitted_by: '',
        answers: ["Wildest Dreams"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/wildest-dreams/1440933512?i=1440933656&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:40',
        release_year: '2014',
        genre: 'Pop',
        album: '1989',
        artist: 'Taylor Swift',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e0/2c/56/e02c56b1-e34a-2252-c2f6-ab5e0b3c812c/mzaf_16682811207683651169.plus.aac.ep.m4a',
        offset: '0',
    },
    '544': {
        content: '',
        submitted_by: '',
        answers: ["Stick Season"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/stick-season/1632285378?i=1632285403&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:02',
        release_year: '2022',
        genre: 'Alternative',
        album: 'Stick Season',
        artist: 'Noah Kahan',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/5b/c5/df/5bc5df5d-b458-8a6b-ce2b-d1af88a2095a/mzaf_2708999605015187498.plus.aac.ep.m4a',
        offset: '0',
    },
    '545': {
        content: '',
        submitted_by: '',
        answers: ["Bulls in the Bronx"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/bulls-in-the-bronx/1440941951?i=1440941975&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:27',
        release_year: '2012',
        genre: 'Alternative',
        album: 'Collide With the Sky',
        artist: 'Pierce the Veil',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/69/a9/30/69a930cb-2187-6bff-fecb-392ecf1ce3bb/mzaf_4217590482112740825.plus.aac.ep.m4a',
        offset: '0',
    },
    '546': {
        content: '',
        submitted_by: '',
        answers: ["Don't Start Now"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/dont-start-now/1538003494?i=1538003635&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:03',
        release_year: '2019',
        genre: 'Pop',
        album: 'Future Nostalgia',
        artist: 'Dua Lipa',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/c9/31/42/c9314202-9710-6364-6090-d0bc3f5cca2b/mzaf_10241956196819243036.plus.aac.ep.m4a',
        offset: '0',
    },
    '547': {
        content: '',
        submitted_by: '',
        answers: ["Peaches"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/peaches-feat-daniel-caesar-giv%C4%93on/1556175419?i=1556175857&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:18',
        release_year: '2021',
        genre: 'Pop',
        album: 'Justice',
        artist: 'Justin Bieber feat. Daniel Caesar & GIVĒON',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/8f/a3/65/8fa365ea-51e6-71af-7afa-835745d139ad/mzaf_4979626867108862672.plus.aac.ep.m4a',
        offset: '0',
    },
    '548': {
        content: '',
        submitted_by: '',
        answers: ["Animals"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/animals/1443749410?i=1443749676&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:03',
        release_year: '2013',
        genre: 'Dance',
        album: 'Gold Skies - EP',
        artist: 'Martin Garrix',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/4d/50/6a/4d506a04-b7c7-6629-f061-9ae08a789a61/mzaf_10910470793203365302.plus.aac.ep.m4a',
        offset: '0',
    },
    '549': {
        content: '',
        submitted_by: '',
        answers: ["I Had Some Help"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-had-some-help-feat-morgan-wallen/1752536575?i=1752536590&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:58',
        release_year: '2024',
        genre: 'Country',
        album: 'F-1 Trillion',
        artist: 'Post Malone feat. Morgan Wallen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/ae/0b/89/ae0b89e2-5d03-a1dd-3a13-42a8889dc38c/mzaf_14150262582852920946.plus.aac.ep.m4a',
        offset: '0',
    },
    '550': {
        content: '',
        submitted_by: '',
        answers: ["Die With A Smile"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/die-with-a-smile/1762656724?i=1762656732&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:11',
        release_year: '2024',
        genre: 'Pop',
        album: 'Single',
        artist: 'Lady Gaga & Bruno Mars',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/e9/d1/46/e9d14699-9505-493e-cd27-a501095c81ff/mzaf_5048207501441467254.plus.aac.ep.m4a',
        offset: '0',
    },
    '551': {
        content: '',
        submitted_by: '',
        answers: ["Rapper's Delight"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rappers-delight-hip-hop-remix-long-version/40459285?i=40459296&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '14:37',
        release_year: '1979',
        genre: 'Hip-Hop/Rap, Electronic, Old School Rap',
        album: 'Sugarhill Gang',
        artist: 'The Sugarhill Gang',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/7a/d0/65/7ad06517-7f6c-80d2-0760-564c93833dd8/mzaf_4457033196740754289.plus.aac.ep.m4a',
        offset: '0',
    },
    '552': {
        content: '',
        submitted_by: '',
        answers: ["Not Like Us"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/not-like-us/1744776162?i=1744776167&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:34',
        release_year: '2024',
        genre: 'Hip-Hop/Rap',
        album: 'Single',
        artist: 'Kendrick Lamar',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/df/05/e7/df05e74c-c4a1-b6ca-61b0-e2c36df3ebfd/mzaf_8217126226728128596.plus.aac.ep.m4a',
        offset: '0',
    },
    '553': {
        content: '',
        submitted_by: '',
        answers: ["BIRDS OF A FEATHER"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/birds-of-a-feather/1739659134?i=1739659142&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:30',
        release_year: '2024',
        genre: 'Alternative',
        album: 'HIT ME HARD AND SOFT',
        artist: 'Billie Eilish',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/06/ff/37/06ff37b5-02b3-af0c-2a75-8d8bb11ce3fc/mzaf_706563222211952879.plus.aac.ep.m4a',
        offset: '0',
    },
    '554': {
        content: '',
        submitted_by: '',
        answers: ["Good Luck, Babe!"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/good-luck-babe/1737497078?i=1737497080&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:38',
        release_year: '2024',
        genre: 'Pop',
        album: 'Single',
        artist: 'Chappell Roan',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/ae/57/5d/ae575db4-68db-508a-c012-421ee79bfa62/mzaf_4831959511345069194.plus.aac.ep.m4a',
        offset: '0',
    },
    '555': {
        content: '',
        submitted_by: '',
        answers: ["The Kill"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-kill-bury-me/1440839104?i=1440839332&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Thirty Seconds to Mars',
        track_length: '3:51',
        release_year: '2005',
        genre: 'Rock',
        album: 'A Beautiful Lie',
        artist: 'Thirty Seconds to Mars',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/2b/ba/81/2bba814d-50dc-1b44-c97c-6313fe1bb9a7/mzaf_14612149003470048252.plus.aac.ep.m4a',
        offset: '0',
    },
    '556': {
        content: '',
        submitted_by: '',
        answers: ["We Found Love"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/we-found-love-feat-calvin-harris/1440758930?i=1440758945&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:35',
        release_year: '2011',
        genre: 'Pop, Electronic',
        album: 'Talk That Talk',
        artist: 'Rihanna feat. Calvin Harris',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/ab/9a/c7/ab9ac789-09d8-e0d5-9d6f-09d814b35cdf/mzaf_18221761731488820585.plus.aac.ep.m4a',
        offset: '0',
    },
    '557': {
        content: '',
        submitted_by: '',
        answers: ["Fight the Power"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/fight-the-power/1440837282?i=1440838444&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:45',
        release_year: '1989',
        genre: 'Hip-Hop/Rap',
        album: 'Fear of a Black Planet',
        artist: 'Public Enemy',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/09/e2/9d/09e29dbc-44f5-acaf-ff17-d0dd4a6509b3/mzaf_10653997382355462194.plus.aac.ep.m4a',
        offset: '0',
    },
    '558': {
        content: '',
        submitted_by: '',
        answers: ["Titanium"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/titanium-feat-sia/693225996?i=693226464&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:05',
        release_year: '2011',
        genre: 'Dance, Electronic',
        album: 'Nothing But the Beat',
        artist: 'David Guetta feat. Sia',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/31/cd/6b/31cd6b1d-f6e9-3a87-9156-fd87a54458ed/mzaf_17522807834405511836.plus.aac.ep.m4a',
        offset: '0',
    },
    '559': {
        content: '',
        submitted_by: '',
        answers: ["The Bird and the Worm"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-bird-and-the-worm/254115609?i=254115685&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:45',
        release_year: '2004',
        genre: 'Alternative, Punk, Rock',
        album: 'Lies for the Liars',
        artist: 'The Used',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/66/78/99/6678991d-4402-47d0-bb38-5dd9ff551652/mzaf_8110850673903083437.plus.aac.ep.m4a',
        offset: '0',
    },
    '560': {
        content: '',
        submitted_by: '',
        answers: ["Blank Space"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/blank-space/1440935467?i=1440935808&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:51',
        release_year: '2014',
        genre: 'Pop',
        album: '1989',
        artist: 'Taylor Swift',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/57/dc/f4/57dcf476-1af6-3706-c0b4-aa2d97c702ce/mzaf_7845295713106910148.plus.aac.ep.m4a',
        offset: '0',
    },
    '561': {
        content: '',
        submitted_by: '',
        answers: ["Nuthin' But A \"G\" Thang"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/nuthin-but-a-g-thang/1668460963?i=1668461604&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:57',
        release_year: '1992',
        genre: 'Hip-Hop/Rap',
        album: 'The Chronic',
        artist: 'Dr. Dre & Snoop Dogg',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/22/ff/61/22ff6153-ea4e-8a27-a788-effaca0f5ce3/mzaf_7634902689295362241.plus.aac.ep.m4a',
        offset: '0',
    },
    '562': {
        content: '',
        submitted_by: '',
        answers: ["One Dance"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/one-dance-feat-wizkid-kyla/1440841363?i=1440841384&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:53',
        release_year: '2016',
        genre: 'Hip-Hop/Rap',
        album: 'Views',
        artist: 'Drake feat. Wizkid & Kyla',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/a8/bd/68/a8bd68a2-dcd2-9024-3859-3a9fd6d53576/mzaf_12821857492279849886.plus.aac.ep.m4a',
        offset: '0',
    },
    '563': {
        content: '',
        submitted_by: '',
        answers: ["Sweet Child O' Mine"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/sweet-child-o-mine/1377813284?i=1377813701&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '5:56',
        release_year: '1987',
        genre: 'Hard Rock, Metal',
        album: 'Appetite for Destruction',
        artist: 'Guns N\' Roses',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/66/e9/46/66e9462c-bfc8-a92f-6917-da0da7c11b60/mzaf_15425643855155646143.plus.aac.ep.m4a',
        offset: '0',
    },
    '564': {
        content: '',
        submitted_by: '',
        answers: ["Juicy"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/juicy/204669415?i=204669680&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'The Notorious B.I.G.',
        track_length: '4:46',
        release_year: '1994',
        genre: 'Hip-Hop/Rap',
        album: 'Ready to Die',
        artist: 'The Notorious B.I.G.',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/60/37/6c/60376c7c-1490-4f10-a9e0-9ec4715f7a9b/mzaf_3207578970357816441.plus.aac.ep.m4a',
        offset: '0',
    },
    '565': {
        content: '',
        submitted_by: '',
        answers: ["Too Sweet"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/too-sweet/1735414393?i=1735414394&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1735414394&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Hozier',
        track_length: '4:11',
        release_year: '2024',
        genre: 'Alternative',
        album: 'Unheard - EP',
        artist: 'Hozier',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/7d/89/47/7d89470a-0a88-7096-f714-c0bf13545666/mzaf_13790917912622583721.plus.aac.ep.m4a',
        offset: '0',
    },
    '566': {
        content: '',
        submitted_by: '',
        answers: ["Lean On"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lean-on-feat-m%C3%B8/1694819032?i=1694819214&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:56',
        release_year: '2015',
        genre: 'Electronic',
        album: 'Peace Is The Mission',
        artist: 'Major Lazer & DJ Snake feat. MØ',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/b7/70/42/b77042e7-2609-d4e0-4f83-f9d0e9e670d0/mzaf_416226977090684814.plus.aac.ep.m4a',
        offset: '0',
    },
    '567': {
        content: '',
        submitted_by: '',
        answers: ["Senorita", "Se\u00f1orita"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/se%C3%B1orita/1468910011?i=1468910018&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:10',
        release_year: '2019',
        genre: 'Pop',
        album: 'Shawn Mendes (Deluxe) and Romance',
        artist: 'Shawn Mendes & Camila Cabello',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/3a/7b/bb/3a7bbbec-3ac2-4992-a9af-fbb28cf301a1/mzaf_4053531488415856583.plus.aac.ep.m4a',
        offset: '0',
    },
    '568': {
        content: '',
        submitted_by: '',
        answers: ["Lush Life"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lush-life/1201058048?i=1201058113&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:20',
        release_year: '2017',
        genre: 'Pop',
        album: 'So Good',
        artist: 'Zara Larsson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/f9/e5/c6/f9e5c610-34a6-b622-64b3-a9d264c95b37/mzaf_15281169424782551932.plus.aac.ep.m4a',
        offset: '0',
    },
    '569': {
        content: '',
        submitted_by: '',
        answers: ["King for a Day"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/king-for-a-day-feat-kellin-quinn/1440941951?i=1440941962&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:56',
        release_year: '2012',
        genre: 'Alternative',
        album: 'Collide With the Sky',
        artist: 'Pierce the Veil feat. Kellin Quinn',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0d/1b/1b/0d1b1b32-fccb-40ae-0115-1eab99544e1c/mzaf_3871695749996426983.plus.aac.ep.m4a',
        offset: '0',
    },
    '570': {
        content: '',
        submitted_by: '',
        answers: ["Beautiful Things"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/beautiful-things/1736051351?i=1736051619&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'Benson Boone',
        track_length: '3:00',
        release_year: '2024',
        genre: 'Pop',
        album: 'Fireworks & Rollerblades',
        artist: 'Benson Boone',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/68/48/a4/6848a498-05bd-6428-908c-d647a5e2c497/mzaf_10670468703874711122.plus.aac.ep.m4a',
        offset: '0',
    },
    '571': {
        content: '',
        submitted_by: '',
        answers: ["You're Gonna Go Far, Kid"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/youre-gonna-go-far-kid/1440886892?i=1440887175&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:57',
        release_year: '2008',
        genre: 'Rock',
        album: 'Rise and Fall, Rage and Grace',
        artist: 'The Offspring',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/37/ba/3a/37ba3a93-872f-4cd2-3b91-c6c81b743c58/mzaf_13281493005605210286.plus.aac.ep.m4a',
        offset: '0',
    },
    '572': {
        content: '',
        submitted_by: '',
        answers: ["drivers license"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/drivers-license/1560734944?i=1560735480&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:02',
        release_year: '2021',
        genre: 'Pop',
        album: 'SOUR',
        artist: 'Olivia Rodrigo',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/6b/24/e5/6b24e529-c04f-7a3e-c755-66f77148def4/mzaf_12610214773869067198.plus.aac.ep.m4a',
        offset: '0',
    },
    '573': {
        content: '',
        submitted_by: '',
        answers: ["Straight Outta Compton"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/straight-outta-compton/1440816032?i=1440816033&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:18',
        release_year: '1988',
        genre: 'Hip-Hop/Rap, Gangsta Rap, West Coast Rap, Hardcore Rap',
        album: 'Straight Outta Compton',
        artist: 'N.W.A',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/31/62/33/31623313-cabc-af27-165e-b7bc001bb251/mzaf_1003520758449313576.plus.aac.ep.m4a',
        offset: '0',
    },
    '574': {
        content: '',
        submitted_by: '',
        answers: ["Lonely Road"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/lonely-road/1758458540?i=1758458541&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:09',
        release_year: '2024',
        genre: 'Alternative',
        album: 'Single',
        artist: 'mgk & Jelly Roll',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/a2/0e/d6/a20ed690-894e-02f7-cb18-f725d8f931fb/mzaf_11485888479043350317.plus.aac.ep.m4a',
        offset: '0',
    },
    '575': {
        content: '',
        submitted_by: '',
        answers: ["thank u, next"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/thank-u-next/1450330588?i=1450330686&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:27',
        release_year: '2018',
        genre: 'Pop',
        album: 'thank u, next',
        artist: 'Ariana Grande',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/17/97/3b/17973b5f-ae9b-374f-a24d-83c9ca9f89f5/mzaf_5476813666014454580.plus.aac.ep.m4a',
        offset: '0',
    },
    '576': {
        content: '',
        submitted_by: '',
        answers: ["The Anthem"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/the-anthem/288051938?i=288051950&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:55',
        release_year: '2002',
        genre: 'Alternative, Punk, Reggae, Ska',
        album: 'The Young and the Hopeless',
        artist: 'Good Charlotte',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0f/66/4b/0f664b6e-860c-1078-b0f3-49a312d01d5c/mzaf_7761126760339110012.plus.aac.ep.m4a',
        offset: '0',
    },
    '577': {
        content: '',
        submitted_by: '',
        answers: ["Saturn"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/saturn/1732348411?i=1732348414&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:06',
        release_year: '2024',
        genre: 'R&B/Soul',
        album: 'Lana',
        artist: 'SZA',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/5f/92/7c/5f927cac-b570-20e4-7d0a-aeee7e99ebb2/mzaf_11312709025434480480.plus.aac.ep.m4a',
        offset: '0',
    },
    '578': {
        content: '',
        submitted_by: '',
        answers: ["CAN'T STOP THE FEELING!"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/cant-stop-the-feeling/1154238159?i=1154239184&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:57',
        release_year: '2016',
        genre: 'Soundtrack, Pop',
        album: 'TROLLS (Original Motion Picture Soundtrack)',
        artist: 'Justin Timberlake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/ce/c4/57/cec45793-8034-bf33-3111-d1487dff9bcb/mzaf_3823445641226057233.plus.aac.ep.m4a',
        offset: '0',
    },
    '579': {
        content: '',
        submitted_by: '',
        answers: ["Basket Case"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/basket-case/1160081985?i=1160082180&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:02',
        release_year: '1994',
        genre: 'Alternative',
        album: 'Dookie',
        artist: 'Green Day',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/c2/c9/0f/c2c90fef-f994-111d-2afc-17747de6bc03/mzaf_5053488711318951154.plus.aac.ep.m4a',
        offset: '0',
    },
    '580': {
        content: '',
        submitted_by: '',
        answers: ["360"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/360/1739079974?i=1739079976&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:13',
        release_year: '2024',
        genre: 'Dance',
        album: 'BRAT',
        artist: 'Charli xcx',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/8e/52/6a/8e526a4d-7c5e-bfd1-0e07-7c6ccd09b441/mzaf_2733590427269194365.plus.aac.p.m4a',
        offset: '0',
    },
    '581': {
        content: '',
        submitted_by: '',
        answers: ["Houdini"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/houdini/1748971747?i=1748971748&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:47',
        release_year: '2024',
        genre: 'Hip-Hop/Rap',
        album: 'The Death of Slim Shady (Coup de Grâce)',
        artist: 'Eminem',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/84/f9/83/84f98389-13a3-06d4-030f-29f2d110e689/mzaf_7244361084067294721.plus.aac.ep.m4a',
        offset: '0',
    },
    '582': {
        content: '',
        submitted_by: '',
        answers: ["ROCKSTAR"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/rockstar-feat-roddy-ricch/1508023035?i=1508023280&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '3:01',
        release_year: '2020',
        genre: 'Hip-Hop/Rap',
        album: 'BLAME IT ON BABY',
        artist: 'DaBaby feat. Roddy Ricch',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/1d/e9/82/1de9827e-fc29-f852-a51b-356d7144e31a/mzaf_14736250221907049031.plus.aac.ep.m4a',
        offset: '0',
    },
    '583': {
        content: '',
        submitted_by: '',
        answers: ["Have You Ever Seen the Rain?"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/have-you-ever-seen-the-rain/1440948020?i=1440948088&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '2:42',
        release_year: '1968',
        genre: 'Rock',
        album: 'Pendulum',
        artist: 'Creedence Clearwater Revival',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/98/fc/f3/98fcf3ac-883b-30ae-76e6-b7fbaef66bd5/mzaf_8337205604926066027.plus.aac.ep.m4a',
        offset: '0',
    },
    '584': {
        content: '',
        submitted_by: '',
        answers: ["I Am Not Okay"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/i-am-not-okay/1770872725?i=1770872740&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1770872740&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Jelly Roll',
        track_length: '3:17',
        release_year: '2024',
        genre: 'Country',
        album: 'Beautifully Broken',
        artist: 'Jelly Roll',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/ae/51/85/ae518589-5e4f-8ab9-a745-42638d177d7e/mzaf_2223512445415850391.plus.aac.ep.m4a',
        offset: '0',
    },
    '585': {
        content: '',
        submitted_by: '',
        answers: ["I Disappear"],
        affiliate_links: ["<iframe id=\"embedPlayer\" src=\"https://embed.music.apple.com/us/album/i-disappear/579145449?i=579145451&amp;app=music&amp;itsct=music_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto\" height=\"175px\" frameborder=\"0\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"width: 100%; max-width: 660px; overflow: hidden; border-radius: 10px; transform: translateZ(0px); animation: 2s ease 0s 6 normal none running loading-indicator; background-color: rgb(228, 228, 228);\"></iframe>"],
        franchise: 'None',
        track_length: '4:26',
        release_year: '2000',
        genre: 'Metal, Rock, Hard Rock',
        album: 'Mission: Impossible 2',
        artist: 'Metallica',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/60/00/81/600081ce-70f1-36df-15e7-51a2acc9e9f0/mzaf_9381051931007450706.plus.aac.ep.m4a',
        offset: '0',
    },
    '586': {
        content: '',
        submitted_by: '',
        answers: ["The Feels"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/the-feels/1591509653?i=1591510042&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1591510042&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'TWICE',
        track_length: '3:18',
        release_year: '2021',
        genre: 'K-pop',
        album: 'Formula of Love: O+T=<3',
        artist: 'TWICE',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/31/0c/0a/310c0a76-27bb-5e01-69fe-19f4f2de1b59/mzaf_145974559754040622.plus.aac.ep.m4a',
        offset: '1',
    },
    '587': {
        content: '',
        submitted_by: '',
        answers: ["Don't You Worry Child"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/dont-you-worry-child-feat-john-martin/1021728914?i=1021729332&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1021729332&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '6:43',
        release_year: '2012',
        genre: 'Dance',
        album: 'Until Now',
        artist: 'Swedish House Mafia ft. John Martin',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/36/af/a1/36afa172-9c6f-604c-1083-8edd9d6e2b4c/mzaf_9899479584972627394.plus.aac.ep.m4a',
        offset: '0',
    },
    '588': {
        content: '',
        submitted_by: '',
        answers: ["Paranoid"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/paranoid/785232473?i=785232521&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=785232521&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '2:48',
        release_year: '1970',
        genre: 'Metal',
        album: 'Paranoid',
        artist: 'Black Sabbath',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/87/7e/c4/877ec400-6e44-8bc4-bb22-b88273a3330d/mzaf_15381783367282008011.plus.aac.p.m4a',
        offset: '0',
    },
    '589': {
        content: '',
        submitted_by: '',
        answers: ["Got My Mind Set on You"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/got-my-mind-set-on-you-2004-remaster/1666655521?i=1666655533&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1666655533&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:54',
        release_year: '1987',
        genre: 'Rock',
        album: 'Cloud Nine',
        artist: 'George Harrison',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/06/04/0e/06040e20-53e2-f524-bd59-b361beafa9ca/mzaf_5383145002704334757.plus.aac.p.m4a',
        offset: '0',
    },
    '590': {
        content: '',
        submitted_by: '',
        answers: ["Vbs"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/vbs/1586471742?i=1586471746&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1586471746&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:56',
        release_year: '2021',
        genre: 'Alternative',
        album: 'Home Video',
        artist: 'Lucy Dacus',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/0e/71/ed/0e71ed5b-e55e-6748-be8e-d20965a8e512/mzaf_10101556635442152259.plus.aac.p.m4a',
        offset: '0',
    },
    '591': {
        content: '',
        submitted_by: '',
        answers: ["I Drink Wine"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/i-drink-wine/1590035691?i=1590036250&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1590036250&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '6:16',
        release_year: '2021',
        genre: 'Pop',
        album: '30',
        artist: 'Adele',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/88/b9/94/88b99485-c67d-efcc-95cb-ce7221437772/mzaf_8510685023029319351.plus.aac.p.m4a',
        offset: '0',
    },
    '592': {
        content: '',
        submitted_by: '',
        answers: ["Happier Than Ever"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/happier-than-ever/1564530719?i=1564531202&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1564531202&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:58',
        release_year: '2021',
        genre: 'Alternative',
        album: 'Happier Than Ever',
        artist: 'Billie Eilish',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/01/e5/e5/01e5e5ed-8c7d-671d-7169-e58658da0996/mzaf_8601459077514002340.plus.aac.p.m4a',
        offset: '0',
    },
    '593': {
        content: '',
        submitted_by: '',
        answers: ["Gold Digger"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/gold-digger-feat-jamie-foxx/1440763821?i=1440763833&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440763833&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:27',
        release_year: '2005',
        genre: 'Hip-Hop/Rap',
        album: 'Late Registration',
        artist: 'Kanye West feat. Jamie Foxx',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/3e/3d/10/3e3d10bb-41c8-cf98-e7de-126cf72b186a/mzaf_14398557118566661017.plus.aac.p.m4a',
        offset: '0',
    },
    '594': {
        content: '',
        submitted_by: '',
        answers: ["Levels"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/levels/1440787344?i=1440787349&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440787349&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '5:38',
        release_year: '2011',
        genre: 'Dance',
        album: 'Single',
        artist: 'Avicii',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/f1/ba/45/f1ba4522-3f3d-d922-4c92-0ab3202dc906/mzaf_789755752315117723.plus.aac.p.m4a',
        offset: '0',
    },
    '595': {
        content: '',
        submitted_by: '',
        answers: ["WITHOUT YOU"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/without-you-miley-cyrus-remix/1563294249?i=1563294262&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1563294262&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '2:41',
        release_year: '2021',
        genre: 'Pop',
        album: 'WITHOUT YOU (Miley Cyrus Remix) - Single',
        artist: 'The Kid LAROI & Miley Cyrus',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/0b/07/e9/0b07e944-eb32-5cec-48a2-d8b0f6a63124/mzaf_517867097540876849.plus.aac.p.m4a',
        offset: '0',
    },
    '596': {
        content: '',
        submitted_by: '',
        answers: ["New Person, Old Place"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/new-person-old-place/1566314408?i=1566314420&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1566314420&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:06',
        release_year: '2021',
        genre: 'Alternative',
        album: 'History of a Feeling',
        artist: 'Madi Diaz',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/81/72/59/81725947-914a-f896-2fbc-71b7b8b236fb/mzaf_7747272566905289446.plus.aac.p.m4a',
        offset: '0',
    },
    '597': {
        content: '',
        submitted_by: '',
        answers: ["By Your Side"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/by-your-side/1549099882?i=1549099884&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1549099884&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '6:07',
        release_year: '2021',
        genre: 'Electronic',
        album: 'Fresia Magdalena',
        artist: 'Sofia Kourtesis',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d9/60/3a/d9603ac4-2677-1480-320e-e5808dd2bc1d/mzaf_10117406993507117484.plus.aac.p.m4a',
        offset: '0',
    },
    '598': {
        content: '',
        submitted_by: '',
        answers: ["Solar Power"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/solar-power/1572940891?i=1572941107&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1572941107&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '3:12',
        release_year: '2021',
        genre: 'Alternative',
        album: 'Solar Power',
        artist: 'Lorde',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/6e/c2/c3/6ec2c3ce-7a84-3a23-f44b-4eabb3478eca/mzaf_17613063072519989798.plus.aac.p.m4a',
        offset: '0',
    },
    '599': {
        content: '',
        submitted_by: '',
        answers: ["Paid In Full"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/paid-in-full/1440841577?i=1440841589&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440841589&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:48',
        release_year: '1987',
        genre: 'Hip-Hop/Rap',
        album: 'Paid In Full',
        artist: 'Eric B. & Rakim',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/1a/05/85/1a0585cc-fb3a-0479-d678-356f4a3a9d3d/mzaf_6389461792317294971.plus.aac.p.m4a',
        offset: '0',
    },
    '600': {
        content: '',
        submitted_by: '',
        answers: ["Independent Women, Pt. 1", "Independent Women, Part I"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/independent-women-pt-1/1301493743?i=1301495398&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1301495398&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '3:41',
        release_year: '2000',
        genre: 'R&B/Soul',
        album: 'Survivor',
        artist: 'Destiny\'s Child',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/cc/c5/1d/ccc51dff-5a0b-6a89-410d-a9cb972d559c/mzaf_7510509976162028330.plus.aac.p.m4a',
        offset: '0',
    },
    '601': {
        content: '',
        submitted_by: '',
        answers: ["Bleed It Out"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/bleed-it-out/590427297?i=590427308&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=590427308&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Linkin Park',
        track_length: '2:44',
        release_year: '2007',
        genre: 'Hard Rock',
        album: 'Minutes to Midnight',
        artist: 'Linkin Park',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/21/3f/b8/213fb846-cdfc-1dac-3e8d-879d70b3ae7d/mzaf_7058261016036822188.plus.aac.p.m4a',
        offset: '0',
    },
    '602': {
        content: '',
        submitted_by: '',
        answers: ["Take On Me"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/take-on-me/380907762?i=380907765&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=380907765&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:48',
        release_year: '1985',
        genre: 'Pop',
        album: 'Hunting High and Low',
        artist: 'a-ha',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/d4/4f/6b/d44f6b5d-b44e-fb3b-3131-fc4de6cb7dd8/mzaf_7019720770149502742.plus.aac.p.m4a',
        offset: '0',
    },
    '603': {
        content: '',
        submitted_by: '',
        answers: ["Wilder Days"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/wilder-days/1578973602?i=1578973604&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1578973604&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:11',
        release_year: '2021',
        genre: 'Country',
        album: 'Reckless',
        artist: 'Morgan Wade',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/91/e8/28/91e828bc-0aac-fa4c-fbcc-ee099ff96630/mzaf_4051518414870268630.plus.aac.p.m4a',
        offset: '0',
    },
    '604': {
        content: '',
        submitted_by: '',
        answers: ["Dear Maria, Count Me In"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/dear-maria-count-me-in/1550005014?i=1550005022&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1550005022&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '3:02',
        release_year: '2007',
        genre: 'Rock',
        album: 'So Wrong, It\'s Right',
        artist: 'All Time Low',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/e0/3a/8d/e03a8dfe-981c-2eaf-df9e-3ac7f4e37e50/mzaf_17007634225527906563.plus.aac.p.m4a',
        offset: '0',
    },
    '605': {
        content: '',
        submitted_by: '',
        answers: ["One Sweet Day"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/one-sweet-day-feat-boyz-ii-men/169962059?i=169962196&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=169962196&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '4:41',
        release_year: '1995',
        genre: 'Pop',
        album: 'Daydream',
        artist: 'Mariah Carey feat. Boyz II Men',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/56/43/26/5643265f-6952-efde-0fe4-544905ecad25/mzaf_15917488917736896717.plus.aac.p.m4a',
        offset: '0',
    },
    '606': {
        content: '',
        submitted_by: '',
        answers: ["Yellow"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/yellow/1122782080?i=1122782283&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1122782283&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:29',
        release_year: '2000',
        genre: 'Alternative',
        album: 'Parachutes',
        artist: 'Coldplay',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/52/a5/d2/52a5d2f1-2501-9f3a-e5c1-420c17c3e244/mzaf_11020025762956009770.plus.aac.p.m4a',
        offset: '0',
    },
    '607': {
        content: '',
        submitted_by: '',
        answers: ["The First Time Ever I Saw Your Face"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/the-first-time-ever-i-saw-your-face/355178034?i=355178117&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=355178117&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:20',
        release_year: '1969',
        genre: 'Pop',
        album: 'First Take',
        artist: 'Roberta Flack',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/45/87/bd/4587bdb8-2401-2564-51cc-688e05c65d39/mzaf_14032172550467718508.plus.aac.p.m4a',
        offset: '0',
    },
    '608': {
        content: '',
        submitted_by: '',
        answers: ["Mr. Brightside"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/mr-brightside/1526194184?i=1526194192&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1526194192&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:42',
        release_year: '2003',
        genre: 'Rock',
        album: 'Hot Fuss',
        artist: 'The Killers',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/6d/fa/dd/6dfadd67-9c4b-cc0a-e290-7655cda962d6/mzaf_8214746159839868426.plus.aac.p.m4a',
        offset: '0',
    },
    '609': {
        content: '',
        submitted_by: '',
        answers: ["The Way We Were"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/the-way-we-were/193021926?i=193022060&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=193022060&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '1973',
        genre: 'Vocal',
        album: 'The Way We Were',
        artist: 'Barbra Streisand',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/fb/c0/11/fbc01102-9f9a-2f16-d973-bd9682c76db7/mzaf_17048496909968673684.plus.aac.p.m4a',
        offset: '0',
    },
    '610': {
        content: '',
        submitted_by: '',
        answers: ["One More Try"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/one-more-try/13313961?i=13313926&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=13313926&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:29',
        release_year: '1990',
        genre: 'Pop',
        album: 'Time After Time',
        artist: 'Timmy T',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/fb/f0/66/fbf06661-4ffd-c9d2-26d8-666bee41d036/mzaf_15028282445134495595.plus.aac.p.m4a',
        offset: '0',
    },
    '611': {
        content: '',
        submitted_by: '',
        answers: ["Don't Stop Me Now"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/dont-stop-me-now/1440770171?i=1440770897&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440770897&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '3:29',
        release_year: '1978',
        genre: 'Rock',
        album: 'Jazz',
        artist: 'Queen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a8/15/5f/a8155f50-e888-361f-ce54-1482af22ea83/mzaf_4398539357937452873.plus.aac.p.m4a',
        offset: '0',
    },
    '612': {
        content: '',
        submitted_by: '',
        answers: ["The Boy Is Mine"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/the-boy-is-mine/20913004?i=20913041&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=20913041&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:54',
        release_year: '1998',
        genre: 'Pop',
        album: 'Never Say Never',
        artist: 'Brandy & Monica',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/9a/a4/f2/9aa4f2e2-f819-02a3-f894-05c491e21df8/mzaf_5587362706639459326.plus.aac.p.m4a',
        offset: '0',
    },
    '613': {
        content: '',
        submitted_by: '',
        answers: ["Just the Way You Are"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/just-the-way-you-are/576654788?i=576655095&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=576655095&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:40',
        release_year: '2010',
        genre: 'Pop',
        album: 'Doo-Wops & Hooligans',
        artist: 'Bruno Mars',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/0c/9d/26/0c9d266f-632d-dbda-770d-55cdded795f8/mzaf_18078867637438469059.plus.aac.p.m4a',
        offset: '0',
    },
    '614': {
        content: '',
        submitted_by: '',
        answers: ["Running Bear"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/running-bear/1597264057?i=1597264328&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1597264328&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '2:37',
        release_year: '1960',
        genre: 'Pop',
        album: 'Single',
        artist: 'Johnny Preston',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/12/1d/4f/121d4f8e-8844-51b4-5258-3407cbac007a/mzaf_9461464604659631094.plus.aac.p.m4a',
        offset: '0',
    },
    '615': {
        content: '',
        submitted_by: '',
        answers: ["Bad Romance"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/bad-romance/1476727669?i=1476727670&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1476727670&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:54',
        release_year: '2009',
        genre: 'Pop',
        album: 'The Fame Monster',
        artist: 'Lady Gaga',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/9b/60/09/9b600987-e495-baa3-d39f-9dc6e53bee72/mzaf_7761634997932900312.plus.aac.p.m4a',
        offset: '0',
    },
    '616': {
        content: '',
        submitted_by: '',
        answers: ["Empire State Of Mind"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/empire-state-of-mind-feat-alicia-keys/1440932009?i=1440932018&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440932018&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:36',
        release_year: '2009',
        genre: 'Hip-Hop/Rap',
        album: 'The Blueprint 3',
        artist: 'JAY-Z feat. Alicia Keys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/2a/4a/89/2a4a8964-80d6-f90c-abd2-36146600f812/mzaf_5605071403977318204.plus.aac.p.m4a',
        offset: '0',
    },
    '617': {
        content: '',
        submitted_by: '',
        answers: ["Sugar, We're Goin Down", "Sugar, We're Going Down"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/sugar-were-goin-down/1440748840?i=1440749374&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440749374&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Fall Out Boy',
        track_length: '3:49',
        release_year: '2005',
        genre: 'Alternative',
        album: 'From Under the Cork Tree',
        artist: 'Fall Out Boy',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/f5/1a/63/f51a6348-a9cb-3d54-4f97-857732005569/mzaf_6928479956423508785.plus.aac.p.m4a',
        offset: '0',
    },
    '618': {
        content: '',
        submitted_by: '',
        answers: ["I'll Be There for You"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/ill-be-there-for-you-theme-from-friends/373220996?i=373221221&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=373221221&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:08',
        release_year: '1995',
        genre: 'Rock',
        album: 'L.P.',
        artist: 'The Rembrandts',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/64/8e/84/648e84d9-d491-a92d-5a06-b4c1e6457671/mzaf_18405791090191220886.plus.aac.p.m4a',
        offset: '0',
    },
    '619': {
        content: '',
        submitted_by: '',
        answers: ["Get Low"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/get-low/898408993?i=898408997&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=898408997&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:32',
        release_year: '2014',
        genre: 'Dance',
        album: 'Money Sucks, Friends Rule',
        artist: 'Dillon Francis & DJ Snake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/c1/d0/06/c1d00692-c1a8-8720-0be1-d1ea6ae10d47/mzaf_8398372739073820790.plus.aac.p.m4a',
        offset: '0',
    },
    '620': {
        content: '',
        submitted_by: '',
        answers: ["Sweet Home Alabama"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/sweet-home-alabama/1440846806?i=1440846819&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440846819&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:43',
        release_year: '1974',
        genre: 'Rock',
        album: 'Second Helping',
        artist: 'Lynyrd Skynyrd',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/c6/2c/87/c62c87b9-dc42-e08c-ee29-285683fb3f06/mzaf_2669064700536790535.plus.aac.p.m4a',
        offset: '0',
    },
    '621': {
        content: '',
        submitted_by: '',
        answers: ["Shape of My Heart"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/shape-of-my-heart/303171231?i=303171282&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=303171282&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:50',
        release_year: '2000',
        genre: 'Pop',
        album: 'Black & Blue',
        artist: 'Backstreet Boys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/cd/0a/b7/cd0ab7c1-71d8-8a4e-485e-8cdaee4568c0/mzaf_2820640310521474856.plus.aac.p.m4a',
        offset: '0',
    },
    '622': {
        content: '',
        submitted_by: '',
        answers: ["Power Trip"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/power-trip-feat-miguel/1529504197?i=1529504220&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1529504220&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:01',
        release_year: '2013',
        genre: 'Hip-Hop/Rap',
        album: 'Born Sinner',
        artist: 'J. Cole feat. Miguel',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/15/90/1e/15901e79-802f-71ab-161c-1b1ace396ce0/mzaf_826777063245532329.plus.aac.p.m4a',
        offset: '0',
    },
    '623': {
        content: '',
        submitted_by: '',
        answers: ["Misery Business"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/misery-business/604804755?i=604804763&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=604804763&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '2007',
        genre: 'Alternative',
        album: 'Riot!',
        artist: 'Paramore',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/47/71/fc/4771fcd0-22e1-f85a-d2b6-5504e491e179/mzaf_3393359742475527534.plus.aac.p.m4a',
        offset: '0',
    },
    '624': {
        content: '',
        submitted_by: '',
        answers: ["Where Is My Mind?"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/where-is-my-mind-2007-remaster/1027465077?i=1027465608&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1027465608&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:54',
        release_year: '1988',
        genre: 'Alternative',
        album: 'Surfer Rosa',
        artist: 'Pixies',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f2/94/56/f2945690-8739-02b1-86da-034f98db1c8b/mzaf_13477163061266397683.plus.aac.p.m4a',
        offset: '0',
    },
    '625': {
        content: '',
        submitted_by: '',
        answers: ["Clarity"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/clarity-feat-foxes/1440861402?i=1440861976&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440861976&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:31',
        release_year: '2012',
        genre: 'Dance',
        album: 'Clarity',
        artist: 'Zedd feat. Foxes',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/dc/73/a0/dc73a02a-0d6d-8931-0eba-90845253cd8e/mzaf_13265866442777153519.plus.aac.p.m4a',
        offset: '0',
    },
    '626': {
        content: '',
        submitted_by: '',
        answers: ["Let It Go"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/let-it-go/1440618177?i=1440618281&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440618281&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:44',
        release_year: '2013',
        genre: 'Musicals',
        album: 'Frozen (Original Motion Picture Soundtrack)',
        artist: 'Idina Menzel',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/5c/69/a6/5c69a6b1-22bc-c349-8c41-3c0cb6e3ce80/mzaf_13879733702837442568.plus.aac.p.m4a',
        offset: '0',
    },
    '627': {
        content: '',
        submitted_by: '',
        answers: ["Good Kid"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/good-kid/1471263898?i=1471263911&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1471263911&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:34',
        release_year: '2012',
        genre: 'Hip-Hop/Rap',
        album: 'good kid, m.A.A.d city',
        artist: 'Kendrick Lamar',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/df/e1/75/dfe17502-7557-3f27-98b3-749f6444b4fd/mzaf_15684203468945560862.plus.aac.p.m4a',
        offset: '0',
    },
    '628': {
        content: '',
        submitted_by: '',
        answers: ["Ebony and Ivory"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/ebony-and-ivory-2015-remix/1440943879?i=1440944564&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440944564&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:46',
        release_year: '1982',
        genre: 'Rock',
        album: 'Tug of War',
        artist: 'Paul McCartney & Stevie Wonder',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/10/73/f2/1073f204-c90a-6965-41d9-f635019df549/mzaf_845155503820645673.plus.aac.p.m4a',
        offset: '0',
    },
    '629': {
        content: '',
        submitted_by: '',
        answers: ["I Write Sins Not Tragedies"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/i-write-sins-not-tragedies/80456435?i=80456409&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=80456409&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:06',
        release_year: '2005',
        genre: 'Alternative',
        album: 'A Fever You Can\'t Sweat Out',
        artist: 'Panic! At the Disco',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/91/c1/13/91c11394-7357-5807-bd99-b3109eaf43bc/mzaf_9003654864131597490.plus.aac.p.m4a',
        offset: '0',
    },
    '630': {
        content: '',
        submitted_by: '',
        answers: ["I Wanna Dance with Somebody"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/i-wanna-dance-with-somebody-who-loves-me/840431884?i=840431935&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=840431935&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:52',
        release_year: '1987',
        genre: 'R&B/Soul',
        album: 'Whitney',
        artist: 'Whitney Houston',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/0b/00/d7/0b00d75d-ae3d-5fc8-fef0-a6c52f23f7b4/mzaf_16979047125783463977.plus.aac.p.m4a',
        offset: '0',
    },
    '631': {
        content: '',
        submitted_by: '',
        answers: ["Turn Down for What"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/turn-down-for-what/786489553?i=786489670&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=786489670&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:33',
        release_year: '2013',
        genre: 'Dance',
        album: 'Single',
        artist: 'DJ Snake & Lil Jon',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/45/7e/71/457e7160-a799-20b6-a5a6-71bf292ae5b1/mzaf_2090080094698921593.plus.aac.p.m4a',
        offset: '0',
    },
    '632': {
        content: '',
        submitted_by: '',
        answers: ["Toxic"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/toxic/251947909?i=251948354&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=251948354&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:18',
        release_year: '2003',
        genre: 'Pop',
        album: 'In the Zone',
        artist: 'Britney Spears',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/56/86/9b/56869bd0-9c87-9706-ab26-087ccc0a0359/mzaf_3475359314516004491.plus.aac.p.m4a',
        offset: '0',
    },
    '633': {
        content: '',
        submitted_by: '',
        answers: ["Run This Town"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/run-this-town-feat-rihanna-kanye-west/1440932009?i=1440932016&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440932016&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:27',
        release_year: '2009',
        genre: 'Hip-Hop/Rap',
        album: 'The Blueprint 3',
        artist: 'JAY-Z feat. Rihanna & Kanye West',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/8b/de/c2/8bdec2fc-c42f-e950-0176-075b5c0cb880/mzaf_11012604234261603165.plus.aac.p.m4a',
        offset: '0',
    },
    '634': {
        content: '',
        submitted_by: '',
        answers: ["You've Got Time"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/youve-got-time/1440743635?i=1440743644&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440743644&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '3:09',
        release_year: '2013',
        genre: 'Soundtrack',
        album: 'Orange Is the New Black (Music From the Original Series)',
        artist: 'Regina Spektor',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/43/64/99/4364996c-c7ae-1550-015d-5d1230f27504/mzaf_11550474827017703886.plus.aac.p.m4a',
        offset: '0',
    },
    '635': {
        content: '',
        submitted_by: '',
        answers: ["The Reason"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/the-reason/1722839954?i=1722840331&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1722840331&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:52',
        release_year: '2003',
        genre: 'Alternative',
        album: 'The Reason',
        artist: 'Hoobastank',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/e3/16/fd/e316fda4-7ae9-f201-9074-329f34f7e915/mzaf_8751397174828133192.plus.aac.p.m4a',
        offset: '0',
    },
    '636': {
        content: '',
        submitted_by: '',
        answers: ["Taki Taki"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/taki-taki-feat-selena-gomez-ozuna-cardi-b/1473654374?i=1473654752&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1473654752&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '3:32',
        release_year: '2018',
        genre: 'Dance',
        album: 'Carte Blanche',
        artist: 'DJ Snake feat. Selena Gomez, Ozuna & Cardi B',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/e5/0e/0f/e50e0fab-32e5-172b-242b-a2074c4f51f0/mzaf_9966379381044053975.plus.aac.p.m4a',
        offset: '0',
    },
    '637': {
        content: '',
        submitted_by: '',
        answers: ["Physical"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/physical/1584202939?i=1584203337&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1584203337&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:45',
        release_year: '1981',
        genre: 'Pop',
        album: 'Physical',
        artist: 'Olivia Newton-John',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/e1/90/e1/e190e1e3-1575-f5e1-441d-e1498932f65b/mzaf_3717818441587666869.plus.aac.p.m4a',
        offset: '0',
    },
    '638': {
        content: '',
        submitted_by: '',
        answers: ["Roar"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/roar/1440871208?i=1440871461&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440871461&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:43',
        release_year: '2013',
        genre: 'Pop',
        album: 'PRISM',
        artist: 'Katy Perry',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c2/04/6d/c2046d07-d74f-9daf-f3b6-a16ea3c1800f/mzaf_3567420453640960863.plus.aac.p.m4a',
        offset: '0',
    },
    '639': {
        content: '',
        submitted_by: '',
        answers: ["Love the Way You Lie"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/love-the-way-you-lie-feat-rihanna/1517894593?i=1517894609&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1517894609&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:23',
        release_year: '2010',
        genre: 'Hip-Hop/Rap',
        album: 'Recovery',
        artist: 'Eminem feat. Rihanna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/0c/99/f3/0c99f3e5-6513-b782-547b-95905a95c219/mzaf_7328992109900298721.plus.aac.p.m4a',
        offset: '0',
    },
    '640': {
        content: '',
        submitted_by: '',
        answers: ["Boulevard of Broken Dreams"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/boulevard-of-broken-dreams/1161539183?i=1161539476&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1161539476&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:20',
        release_year: '2004',
        genre: 'Alternative',
        album: 'American Idiot',
        artist: 'Green Day',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/2c/a1/e8/2ca1e8d9-7b18-f6b4-adef-bff1de9fc1ab/mzaf_3501478233965070726.plus.aac.p.m4a',
        offset: '0',
    },
    '641': {
        content: '',
        submitted_by: '',
        answers: ["Hallelujah"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/hallelujah/1046187510?i=1046187586&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1046187586&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Jeff Buckley',
        track_length: '6:53',
        release_year: '1994',
        genre: 'Rock',
        album: 'Grace',
        artist: 'Jeff Buckley',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/62/33/0c/62330cb0-a9c1-9f1c-cedf-e6dd6f3865fa/mzaf_6213954653962856085.plus.aac.p.m4a',
        offset: '11',
    },
    '642': {
        content: '',
        submitted_by: '',
        answers: ["I Could Be the One"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/i-could-be-the-one-avicii-vs-nicky-romero-nicktim-radio-edit/1445300194?i=1445300197&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1445300197&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '3:28',
        release_year: '2012',
        genre: 'Dance',
        album: 'Single',
        artist: 'Avicii & Nicky Romero',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/7c/db/fc/7cdbfcbb-9c82-9e06-701a-b19dca643b84/mzaf_3601182773745923486.plus.aac.p.m4a',
        offset: '0',
    },
    '643': {
        content: '',
        submitted_by: '',
        answers: ["Bad Blood"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/bad-blood/1440935467?i=1440936028&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440936028&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '3:31',
        release_year: '2014',
        genre: 'Pop',
        album: '1989',
        artist: 'Taylor Swift',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/80/e6/a8/80e6a803-1117-cd3f-48da-a3e6799eea3c/mzaf_10174351058545549372.plus.aac.p.m4a',
        offset: '0',
    },
    '644': {
        content: '',
        submitted_by: '',
        answers: ["Just Give Me a Reason"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/just-give-me-a-reason-feat-nate-ruess/545398133?i=545398139&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=545398139&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '4:02',
        release_year: '2012',
        genre: 'Pop',
        album: 'The Truth About Love',
        artist: 'P!nk feat. Nate Ruess',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/57/38/52/573852b0-6b53-4b2e-f8d1-857ec7f60632/mzaf_6157346851900450639.plus.aac.p.m4a',
        offset: '0',
    },
    '645': {
        content: '',
        submitted_by: '',
        answers: ["Another Brick In the Wall, Pt. 2"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/another-brick-in-the-wall-pt-2/1065975633?i=1065975638&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1065975638&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'None',
        track_length: '3:58',
        release_year: '1979',
        genre: 'Rock',
        album: 'The Wall',
        artist: 'Pink Floyd',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f8/a2/cd/f8a2cd4a-2af9-f34f-3684-89e3304c102c/mzaf_376000686139681175.plus.aac.p.m4a',
        offset: '0',
    },
    '646': {
        content: '',
        submitted_by: '',
        answers: ["I Don't Want To Wait"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/i-dont-want-to-wait-album-version/128104419?i=128104411&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=128104411&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '5:20',
        release_year: '1996',
        genre: 'Rock',
        album: 'This Fire',
        artist: 'Paula Cole',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/44/ae/e0/44aee05a-aa23-570a-a69c-7d28fee7c44d/mzaf_6101136988487341148.plus.aac.p.m4a',
        offset: '0',
    },
    '647': {
        content: '',
        submitted_by: '',
        answers: ["What Do You Mean?"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/what-do-you-mean/1440825845?i=1440826311&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440826311&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'None',
        track_length: '3:25',
        release_year: '2015',
        genre: 'Pop',
        album: 'Purpose',
        artist: 'Justin Bieber',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/05/75/60/05756062-4f76-f6e0-2674-6c718c7b1c0e/mzaf_7136077526527310985.plus.aac.p.m4a',
        offset: '0',
    },
    '648': {
        content: '',
        submitted_by: '',
        answers: ["My Own Worst Enemy"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/my-own-worst-enemy/258611802?i=258611995&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=258611995&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Lit',
        track_length: '2:49',
        release_year: '1999',
        genre: 'Alternative',
        album: 'A Place In The Sun',
        artist: 'Lit',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e7/7a/72/e77a7282-7789-8045-3d36-4a3a1af4dbd4/mzaf_16968735621251485110.plus.aac.p.m4a',
        offset: '0',
    },
    '649': {
        content: '',
        submitted_by: '',
        answers: ["Faded"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/faded/1445140820?i=1445141316&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1445141316&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Alan Walker',
        track_length: '3:32',
        release_year: '2015',
        genre: 'Dance',
        album: 'Different World',
        artist: 'Alan Walker',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/d2/c1/c4/d2c1c496-4001-d10f-bbf6-626eb5bcf2cf/mzaf_7717849628751219162.plus.aac.p.m4a',
        offset: '0',
    },
    '650': {
        content: '',
        submitted_by: '',
        answers: ["All The Stars"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/all-the-stars/1440903005?i=1440903307&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440903307&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Kendrick Lamar, SZA',
        track_length: '3:52',
        release_year: '2018',
        genre: 'Hip-Hop/Rap',
        album: 'Black Panther: The Album',
        artist: 'Kendrick Lamar, SZA',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview123/v4/d2/4e/fd/d24efde0-a6bb-035d-62c9-09dab7597a75/mzaf_7663218671447241642.plus.aac.p.m4a',
        offset: '0',
    },
    '651': {
        content: '',
        submitted_by: '',
        answers: ["California Gurls"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/california-gurls-feat-snoop-dogg/716192216?i=716192621&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=716192621&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Katy Perry',
        track_length: '3:54',
        release_year: '2010',
        genre: 'Pop',
        album: 'Teenage Dream',
        artist: 'Katy Perry feat. Snoop Dogg',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/32/c9/49/32c949d9-ddf4-a8c6-6654-53f4d5f12242/mzaf_6246679475845576457.plus.aac.p.m4a',
        offset: '0',
    },
    '652': {
        content: '',
        submitted_by: '',
        answers: ["Ain't No Sunshine"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/aint-no-sunshine/293521570?i=293521573&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=293521573&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Bill Withers',
        track_length: '2:06',
        release_year: '1971',
        genre: 'R&B/Soul',
        album: 'Just As I Am',
        artist: 'Bill Withers',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/c1/97/a5/c197a504-bc13-0709-3f6f-904026c782dd/mzaf_11719507637175591282.plus.aac.p.m4a',
        offset: '0',
    },
    '653': {
        content: '',
        submitted_by: '',
        answers: ["No Role Modelz"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/no-role-modelz/1600772499?i=1600773036&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1600773036&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'J. Cole',
        track_length: '4:52',
        release_year: '2014',
        genre: 'Hip-Hop/Rap',
        album: '2014 Forest Hills Drive',
        artist: 'J. Cole',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/81/a2/33/81a2333c-1a07-0505-5375-2f1aa74ddf8e/mzaf_17607571914784018837.plus.aac.p.m4a',
        offset: '0',
    },
    '654': {
        content: '',
        submitted_by: '',
        answers: ["Born to Be Wild"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/born-to-be-wild/1440859623?i=1440860171&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440860171&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Steppenwolf',
        track_length: '3:30',
        release_year: '1968',
        genre: 'Rock',
        album: 'Steppenwolf',
        artist: 'Steppenwolf',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/79/1f/26/791f26bf-abc4-d9fc-4dd1-f93a27533059/mzaf_7059983360387846257.plus.aac.p.m4a',
        offset: '0',
    },
    '655': {
        content: '',
        submitted_by: '',
        answers: ["Mad World"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/mad-world-feat-gary-jules/906900583?i=906900619&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=906900619&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Michael Andrews',
        track_length: '3:04',
        release_year: '2001',
        genre: 'Soundtrack',
        album: 'Donnie Darko (Original Motion Picture Soundtrack)',
        artist: 'Michael Andrews feat. Gary Jules',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/60/68/1d/60681d58-e802-1426-b10b-3302724f13b6/mzaf_2148957271547856794.plus.aac.p.m4a',
        offset: '0',
    },
    '656': {
        content: '',
        submitted_by: '',
        answers: ["Viva La Vida"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/viva-la-vida/1122773394?i=1122773680&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1122773680&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Coldplay',
        track_length: '4:01',
        release_year: '2008',
        genre: 'Alternative',
        album: 'Viva La Vida or Death and All His Friends',
        artist: 'Coldplay',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/2b/04/65/2b0465c3-2db1-e461-2362-14b528456b8f/mzaf_1805426141027060154.plus.aac.p.m4a',
        offset: '0',
    },
    '657': {
        content: '',
        submitted_by: '',
        answers: ["Adam's Song"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/adams-song/1440839912?i=1440840502&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440840502&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'blink-182',
        track_length: '4:09',
        release_year: '1999',
        genre: 'Rock',
        album: 'Enema of the State',
        artist: 'blink-182',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/99/20/72/99207248-6c48-fec2-6b75-1f8fd4f78794/mzaf_16318012030183713467.plus.aac.p.m4a',
        offset: '0',
    },
    '658': {
        content: '',
        submitted_by: '',
        answers: ["Just Can't Get Enough"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/just-cant-get-enough/1443060056?i=1443060227&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1443060227&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Black Eyed Peas',
        track_length: '3:39',
        release_year: '2010',
        genre: 'Pop',
        album: 'The Beginning',
        artist: 'Black Eyed Peas',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/15/7d/3e/157d3e33-b084-5e30-1a76-8647b28e1bea/mzaf_7191417747819057036.plus.aac.p.m4a',
        offset: '0',
    },
    '659': {
        content: '',
        submitted_by: '',
        answers: ["Take It Easy"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/take-it-easy/635791801?i=635791802&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=635791802&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Eagles',
        track_length: '3:31',
        release_year: '1972',
        genre: 'Rock',
        album: 'Eagles',
        artist: 'Eagles',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/84/f6/86/84f686a9-6b79-668a-c182-e8d816ff3687/mzaf_12814377987078818013.plus.aac.p.m4a',
        offset: '0',
    },
    '660': {
        content: '',
        submitted_by: '',
        answers: ["Bodak Yellow"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/bodak-yellow/1368156323?i=1368156577&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1368156577&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Cardi B',
        track_length: '3:43',
        release_year: '2017',
        genre: 'Hip-Hop/Rap',
        album: 'Invasion of Privacy',
        artist: 'Cardi B',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/3e/ae/cb/3eaecb7a-f1b1-5ac5-f725-af99f492ac1a/mzaf_9271524952561330291.plus.aac.p.m4a',
        offset: '0',
    },
    '661': {
        content: '',
        submitted_by: '',
        answers: ["Make Your Own Kind of Music"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/make-your-own-kind-of-music/1415197628?i=1415197764&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1415197764&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Cass Elliot',
        track_length: '2:19',
        release_year: '1971',
        genre: 'Pop',
        album: 'Mama\'s Big Ones',
        artist: 'Cass Elliot',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/eb/9f/fe/eb9ffe48-6be5-6088-d926-e25bc3b2f750/mzaf_15008822968397611028.plus.aac.p.m4a',
        offset: '0',
    },
    '662': {
        content: '',
        submitted_by: '',
        answers: ["I Miss You"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/i-miss-you/1440846730?i=1440846785&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440846785&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'blink-182',
        track_length: '3:47',
        release_year: '2003',
        genre: 'Rock',
        album: 'Blink-182',
        artist: 'blink-182',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/d4/dc/1e/d4dc1e18-5871-858b-70b5-2870c75428db/mzaf_6510282745118014100.plus.aac.p.m4a',
        offset: '0',
    },
    '663': {
        content: '',
        submitted_by: '',
        answers: ["Party In the U.S.A."],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/party-in-the-u-s-a/1444112972?i=1444113373&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1444113373&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Miley Cyrus',
        track_length: '3:22',
        release_year: '2009',
        genre: 'Pop',
        album: 'The Time of Our Lives',
        artist: 'Miley Cyrus',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/ca/05/a9/ca05a940-c161-b6c7-536d-b95af3e468fb/mzaf_1168374273031113883.plus.aac.p.m4a',
        offset: '0',
    },
    '664': {
        content: '',
        submitted_by: '',
        answers: ["Don't Stop the Music"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/dont-stop-the-music/1472503760?i=1472504156&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1472504156&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Rihanna',
        track_length: '4:27',
        release_year: '2007',
        genre: 'R&B/Soul',
        album: 'Good Girl Gone Bad',
        artist: 'Rihanna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/98/82/e5/9882e569-317c-ab07-72d2-8b51a088dcb7/mzaf_571417175396723390.plus.aac.p.m4a',
        offset: '0',
    },
    '665': {
        content: '',
        submitted_by: '',
        answers: ["A Thousand Years"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/a-thousand-years/467967158?i=467967170&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=467967170&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Christina Perri',
        track_length: '4:45',
        release_year: '2011',
        genre: 'Pop, Soundtrack',
        album: 'The Twilight Saga: Breaking Dawn - Pt. 1 (Original Motion Picture Soundtrack)',
        artist: 'Christina Perri',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/db/6a/e4/db6ae48b-1c4b-0cf5-c8b4-e6382a1e3201/mzaf_6472755524493528360.plus.aac.p.m4a',
        offset: '0',
    },
    '666': {
        content: '',
        submitted_by: '',
        answers: ["TEXAS HOLD 'EM"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/texas-hold-em/1738370746?i=1738370967&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1738370967&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Beyoncé',
        track_length: '3:53',
        release_year: '2024',
        genre: 'Country',
        album: 'COWBOY CARTER',
        artist: 'Beyoncé',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/7f/b6/d0/7fb6d036-cb17-cf78-49cb-55dbef9704a2/mzaf_6828473469451839772.plus.aac.p.m4a',
        offset: '0',
    },
    '667': {
        content: '',
        submitted_by: '',
        answers: ["Sweet Dreams (Are Made of This)", "Sweet Dreams"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/sweet-dreams-are-made-of-this/255965840?i=255966421&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=255966421&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Eurythmics',
        track_length: '3:37',
        release_year: '1983',
        genre: 'Alternative',
        album: 'Sweet Dreams (Are Made of This)',
        artist: 'Eurythmics',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/a7/dd/3e/a7dd3e79-dacc-460a-ba25-c9827938a7f1/mzaf_9265723838672937705.plus.aac.p.m4a',
        offset: '0',
    },
    '668': {
        content: '',
        submitted_by: '',
        answers: ["Breathe Me"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/breathe-me/1444005606?i=1444005733&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1444005733&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Sia',
        track_length: '4:32',
        release_year: '2004',
        genre: 'Pop',
        album: 'Colour the Small One',
        artist: 'Sia',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/28/5b/8f/285b8fc5-e7d2-7dbb-3a4d-3cb7c345ced5/mzaf_2790839020061120985.plus.aac.p.m4a',
        offset: '0',
    },
    '669': {
        content: '',
        submitted_by: '',
        answers: ["Piano Man"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/piano-man/158617297?i=158617575&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=158617575&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Billy Joel',
        track_length: '5:39',
        release_year: '1973',
        genre: 'Rock',
        album: 'Piano Man',
        artist: 'Billy Joel',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/13/90/25/139025cf-1d8e-ba76-b046-99c57afdd2b6/mzaf_10876850544092254096.plus.aac.p.m4a',
        offset: '0',
    },
    '670': {
        content: '',
        submitted_by: '',
        answers: ["Teenagers"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/teenagers/209388277?i=209388998&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=209388998&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'My Chemical Romance',
        track_length: '2:41',
        release_year: '2006',
        genre: 'Alternative',
        album: 'The Black Parade',
        artist: 'My Chemical Romance',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/bf/24/a4/bf24a497-a3c4-7531-3472-e9cd8ac27c90/mzaf_3457508798731622918.plus.aac.p.m4a',
        offset: '0',
    },
    '671': {
        content: '',
        submitted_by: '',
        answers: ["In the End"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/in-the-end/528436018?i=528437613&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=528437613&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Linkin Park',
        track_length: '3:36',
        release_year: '2000',
        genre: 'Hard Rock',
        album: 'Hybrid Theory',
        artist: 'Linkin Park',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/6c/60/cf/6c60cf91-e098-84bc-79af-8f3615b57b19/mzaf_12714078272888357351.plus.aac.p.m4a',
        offset: '0',
    },
    '672': {
        content: '',
        submitted_by: '',
        answers: ["Alright"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/alright/1440828886?i=1440829463&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440829463&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Kendrick Lamar',
        track_length: '3:39',
        release_year: '2015',
        genre: 'Hip-Hop/Rap',
        album: 'To Pimp a Butterfly',
        artist: 'Kendrick Lamar',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/73/a2/24/73a224a8-9aa7-5a7c-e369-c06aee28da80/mzaf_1866300536224236488.plus.aac.p.m4a',
        offset: '0',
    },
    '673': {
        content: '',
        submitted_by: '',
        answers: ["Please Please Please"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/please-please-please/1750307020?i=1750307080&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1750307080&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Sabrina Carpenter',
        track_length: '3:06',
        release_year: '2024',
        genre: 'Pop',
        album: 'Short n\' Sweet',
        artist: 'Sabrina Carpenter',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/05/88/bd/0588bd5d-201e-f532-227c-f70dd467f92d/mzaf_13424037184330234624.plus.aac.p.m4a',
        offset: '0',
    },
    '674': {
        content: '',
        submitted_by: '',
        answers: ["The Night We Met"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/the-night-we-met/962688212?i=962688837&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=962688837&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Lord Huron',
        track_length: '3:28',
        release_year: '2015',
        genre: 'Indie Rock',
        album: 'Strange Trails',
        artist: 'Lord Huron',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/31/1f/92/311f9256-eea4-332f-60b8-1175a5e51151/mzaf_10603254290419235531.plus.aac.p.m4a',
        offset: '0',
    },
    '675': {
        content: '',
        submitted_by: '',
        answers: ["BLACKJACK"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/blackjack/1423772324?i=1423772802&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1423772802&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Aminé',
        track_length: '1:57',
        release_year: '2018',
        genre: 'Hip-Hop/Rap',
        album: 'ONEPOINTFIVE',
        artist: 'Aminé',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/fb/7c/80/fb7c809b-0ed2-a2b7-f39a-10024c14be1f/mzaf_16959662948838157087.plus.aac.p.m4a',
        offset: '0',
    },
    '676': {
        content: '',
        submitted_by: '',
        answers: ["Best of You"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/best-of-you/300855107?i=300855110&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=300855110&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Foo Fighters',
        track_length: '4:15',
        release_year: '2005',
        genre: 'Alternative',
        album: 'In Your Honor',
        artist: 'Foo Fighters',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/a9/07/7d/a9077d0b-d5bd-107e-7ce8-e29fdfb0cfd7/mzaf_5511817666600351413.plus.aac.p.m4a',
        offset: '0',
    },
    '677': {
        content: '',
        submitted_by: '',
        answers: ["November Rain"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/november-rain/1440896026?i=1440896193&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440896193&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Guns N\' Roses',
        track_length: '8:56',
        release_year: '1991',
        genre: 'Hard Rock',
        album: 'Use Your Illusion I',
        artist: 'Guns N\' Roses',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/3c/eb/6b/3ceb6b17-b62b-9cb9-ee69-6c161aea7506/mzaf_11294109330794350435.plus.aac.p.m4a',
        offset: '0',
    },
    '678': {
        content: '',
        submitted_by: '',
        answers: ["Times Like These"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/times-like-these/538257183?i=538257188&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=538257188&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Foo Fighters',
        track_length: '4:25',
        release_year: '2002',
        genre: 'Alternative',
        album: 'One By One',
        artist: 'Foo Fighters',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/40/11/0f/40110f74-3ff1-a06d-f2cd-9e802264bb9c/mzaf_15678636074677930829.plus.aac.p.m4a',
        offset: '0',
    },
    '679': {
        content: '',
        submitted_by: '',
        answers: ["Stan"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/stan-feat-dido/1440906504?i=1440906513&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440906513&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Eminem',
        track_length: '6:43',
        release_year: '2000',
        genre: 'Hip-Hop/Rap',
        album: 'The Marshall Mathers LP',
        artist: 'Eminem feat. Dido',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/40/73/de/4073de0c-ef24-2208-9282-d3feaac3c393/mzaf_7215714695763452889.plus.aac.p.m4a',
        offset: '0',
    },
    '680': {
        content: '',
        submitted_by: '',
        answers: ["Riptide"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/riptide/690407046?i=690407061&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=690407061&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Vance Joy',
        track_length: '3:22',
        release_year: '2013',
        genre: 'Alternative',
        album: 'God Loves You When You\'re Dancing',
        artist: 'Vance Joy',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/c3/15/69/c31569e7-542a-6d56-6737-45fe867f65a3/mzaf_3244237740846681022.plus.aac.p.m4a',
        offset: '0',
    },
    '681': {
        content: '',
        submitted_by: '',
        answers: ["Runaway Love"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/runaway-love-feat-mary-j-blige/1440756826?i=1440756996&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440756996&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Ludacris',
        track_length: '4:40',
        release_year: '2006',
        genre: 'Hip-Hop/Rap',
        album: 'Release Therapy',
        artist: 'Ludacris feat. Mary J. Blige',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/3a/f6/68/3af66849-b112-f7e6-f5da-a6104576b305/mzaf_10125446849250335931.plus.aac.p.m4a',
        offset: '0',
    },
    '682': {
        content: '',
        submitted_by: '',
        answers: ["Material Girl"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/material-girl/80815235?i=80815209&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=80815209&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Madonna',
        track_length: '4:00',
        release_year: '1984',
        genre: 'Pop',
        album: 'Like a Virgin',
        artist: 'Madonna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/f5/5b/51/f55b512a-9d2d-c3cf-e78a-2c2187393dba/mzaf_4554099718140384374.plus.aac.p.m4a',
        offset: '0',
    },
    '683': {
        content: '',
        submitted_by: '',
        answers: ["DiE4u"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/die4u/1744406677?i=1744407406&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1744407406&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Bring Me The Horizon',
        track_length: '3:27',
        release_year: '2021',
        genre: 'Hard Rock',
        album: 'POST HUMAN: NeX GEn',
        artist: 'Bring Me The Horizon',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/25/ce/de/25cededa-7668-5180-86e0-c35d68a7821d/mzaf_1429463348459116334.plus.aac.p.m4a',
        offset: '0',
    },
    '684': {
        content: '',
        submitted_by: '',
        answers: ["Don't Stop"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/dont-stop/594061854?i=594061858&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=594061858&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Fleetwood Mac',
        track_length: '3:13',
        release_year: '1977',
        genre: 'Rock',
        album: 'Rumours',
        artist: 'Fleetwood Mac',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/f7/dc/d2/f7dcd2b2-15ce-167b-c504-6756a9bea216/mzaf_15991346947865453796.plus.aac.p.m4a',
        offset: '0',
    },
    '685': {
        content: '',
        submitted_by: '',
        answers: ["Teenage Dream"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/teenage-dream/716192216?i=716192601&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=716192601&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Katy Perry',
        track_length: '3:47',
        release_year: '2010',
        genre: 'Pop',
        album: 'Teenage Dream',
        artist: 'Katy Perry',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/29/e4/9e/29e49ee0-830c-3fea-9e2b-addecc4141c8/mzaf_13401872643263464454.plus.aac.p.m4a',
        offset: '0',
    },
    '686': {
        content: '',
        submitted_by: '',
        answers: ["Falling Slowly"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/falling-slowly/254347531?i=254347632&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=254347632&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Glen Hansard',
        track_length: '4:04',
        release_year: '2006',
        genre: 'Soundtrack',
        album: 'Once (Music from the Motion Picture)',
        artist: 'Glen Hansard & Markéta Irglová',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/93/37/1d/93371ded-9973-e608-f31b-8618f49b4739/mzaf_11613273655005975509.plus.aac.p.m4a',
        offset: '0',
    },
    '687': {
        content: '',
        submitted_by: '',
        answers: ["Mercy"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/mercy-feat-big-sean-pusha-t-2-chainz/1445169359?i=1445169364&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1445169364&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Kanye West',
        track_length: '5:29',
        release_year: '2012',
        genre: 'Hip-Hop/Rap',
        album: 'Cruel Summer',
        artist: 'Kanye West feat. Big Sean, Pusha T & 2 Chainz',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/c9/a2/5d/c9a25d6f-21ff-4e54-ca96-40db81caefb1/mzaf_14370116391813217722.plus.aac.p.m4a',
        offset: '0',
    },
    '688': {
        content: '',
        submitted_by: '',
        answers: ["Back In Black"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/back-in-black/574050396?i=574050602&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=574050602&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'AC/DC',
        track_length: '4:16',
        release_year: '1980',
        genre: 'Hard Rock',
        album: 'Back In Black',
        artist: 'AC/DC',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/b5/14/aa/b514aa08-4772-93db-c69f-420fbe0bb24c/mzaf_17779796508174317776.plus.aac.p.m4a',
        offset: '0',
    },
    '689': {
        content: '',
        submitted_by: '',
        answers: ["When I Come Around"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/when-i-come-around/1160081985?i=1160082350&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1160082350&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Green Day',
        track_length: '2:57',
        release_year: '1994',
        genre: 'Alternative',
        album: 'Dookie',
        artist: 'Green Day',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/a9/46/e1/a946e1a8-52b4-67e6-4365-0603a478a1ae/mzaf_16849772022084832336.plus.aac.p.m4a',
        offset: '0',
    },
    '690': {
        content: '',
        submitted_by: '',
        answers: ["Turn Up the Speakers"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/turn-up-the-speakers/1336608347?i=1336608370&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1336608370&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'AFROJACK',
        track_length: '5:43',
        release_year: '2014',
        genre: 'Dance',
        album: 'Single',
        artist: 'AFROJACK & Martin Garrix',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/aa/cf/60/aacf60e8-b64d-0c55-4ec0-61f75a1da73a/mzaf_18425772469558277147.plus.aac.p.m4a',
        offset: '0',
    },
    '691': {
        content: '',
        submitted_by: '',
        answers: ["Timeless"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/timeless/1770393193?i=1770393194&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1770393194&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'The Weeknd',
        track_length: '4:16',
        release_year: '2024',
        genre: 'R&B/Soul',
        album: 'Hurry Up Tomorrow',
        artist: 'The Weeknd & Playboi Carti',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/c9/92/bc/c992bcf6-eeea-d862-d4b3-a3aedfd94b96/mzaf_9443660129086191685.plus.aac.p.m4a',
        offset: '0',
    },
    '692': {
        content: '',
        submitted_by: '',
        answers: ["Wicked Game"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/wicked-game/1570349614?i=1570350115&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1570350115&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Chris Isaak',
        track_length: '4:49',
        release_year: '1989',
        genre: 'Rock',
        album: 'Heart Shaped World',
        artist: 'Chris Isaak',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/d4/f5/6f/d4f56fb1-f9ce-627a-2cc3-5f5b28e96692/mzaf_7197782946271526766.plus.aac.p.m4a',
        offset: '0',
    },
    '693': {
        content: '',
        submitted_by: '',
        answers: ["Money Trees"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/money-trees-feat-jay-rock/1471277623?i=1471277629&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1471277629&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Kendrick Lamar',
        track_length: '6:26',
        release_year: '2012',
        genre: 'Hip-Hop/Rap',
        album: 'good kid, m.A.A.d city',
        artist: 'Kendrick Lamar feat. Jay Rock',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/9e/1c/95/9e1c95e4-83fe-b011-d803-7cdd7f2db725/mzaf_6922494251348312966.plus.aac.p.m4a',
        offset: '0',
    },
    '694': {
        content: '',
        submitted_by: '',
        answers: ["I Want to Break Free"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/i-want-to-break-free/1440763432?i=1440763977&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440763977&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Queen',
        track_length: '3:21',
        release_year: '1984',
        genre: 'Rock',
        album: 'The Works',
        artist: 'Queen',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/9e/60/bc/9e60bc2a-ae27-cc54-9821-429e7d2e8541/mzaf_17496100486957198237.plus.aac.p.m4a',
        offset: '0',
    },
    '695': {
        content: '',
        submitted_by: '',
        answers: ["No Church in the Wild"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/no-church-in-the-wild-feat-frank-ocean-the-dream/1440848092?i=1440848093&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440848093&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'JAY-Z',
        track_length: '4:32',
        release_year: '2011',
        genre: 'Hip-Hop/Rap',
        album: 'Watch the Throne',
        artist: 'JAY-Z & Kanye West feat. Frank Ocean & The-Dream',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/e0/c3/0a/e0c30af3-b855-a84d-97be-0ee437b6ab5b/mzaf_8081236935591137157.plus.aac.p.m4a',
        offset: '0',
    },
    '696': {
        content: '',
        submitted_by: '',
        answers: ["Falling"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/falling/300947363?i=300947365&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=300947365&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Julee Cruise',
        track_length: '5:21',
        release_year: '1989',
        genre: 'Pop',
        album: 'Floating Into the Night',
        artist: 'Julee Cruise',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/2d/d7/a3/2dd7a357-516a-d0d0-fa1e-ffcdd6baa93e/mzaf_4293167495314421220.plus.aac.p.m4a',
        offset: '0',
    },
    '697': {
        content: '',
        submitted_by: '',
        answers: ["Started From the Bottom"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/started-from-the-bottom/1440824484?i=1440824492&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440824492&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Drake',
        track_length: '2:53',
        release_year: '2013',
        genre: 'Hip-Hop/Rap',
        album: 'Nothing Was the Same',
        artist: 'Drake',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/9f/2b/70/9f2b70ee-0c3f-a2df-4cec-668f0ae073c7/mzaf_11270505716933928903.plus.aac.p.m4a',
        offset: '0',
    },
    '698': {
        content: '',
        submitted_by: '',
        answers: ["Highway to Hell"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/highway-to-hell/574043989?i=574044008&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=574044008&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'AC/DC',
        track_length: '3:28',
        release_year: '1979',
        genre: 'Hard Rock',
        album: 'Highway to Hell',
        artist: 'AC/DC',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/cb/f4/3f/cbf43f47-747d-bcc2-19cf-6bf2c523f5d8/mzaf_9504019990576453182.plus.aac.p.m4a',
        offset: '0',
    },
    '699': {
        content: '',
        submitted_by: '',
        answers: ["Jessie's Girl"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/jessies-girl/286005745?i=286005748&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=286005748&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Rick Springfield',
        track_length: '3:14',
        release_year: '1981',
        genre: 'Hard Rock',
        album: 'Working Class Dog',
        artist: 'Rick Springfield',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/26/31/1f/26311fec-ba74-c97d-6890-a69241ee5994/mzaf_10363221406220325156.plus.aac.p.m4a',
        offset: '1',
    },
    '700': {
        content: '',
        submitted_by: '',
        answers: ["Carry On"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/carry-on/486040153?i=486040196&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=486040196&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Fun.',
        track_length: '4:38',
        release_year: '2012',
        genre: 'Alternative',
        album: 'Some Nights',
        artist: 'Fun.',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/57/09/b6/5709b6e7-e124-28d4-42d4-2a9f4c9d95d4/mzaf_16497650630032354968.plus.aac.p.m4a',
        offset: '0',
    },
    '701': {
        content: '',
        submitted_by: '',
        answers: ["No Problem"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/no-problem-feat-lil-wayne-2-chainz/1113239004?i=1113239443&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1113239443&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Chance the Rapper',
        track_length: '5:04',
        release_year: '2016',
        genre: 'Hip-Hop/Rap',
        album: 'Coloring Book',
        artist: 'Chance the Rapper feat. Lil Wayne & 2 Chainz',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/30/48/f2/3048f279-aa8c-d61f-9e45-22ddb0088f34/mzaf_1052934644680774329.plus.aac.p.m4a',
        offset: '0',
    },
    '702': {
        content: '',
        submitted_by: '',
        answers: ["Bitter Sweet Symphony"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/bitter-sweet-symphony/1443258184?i=1443258189&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1443258189&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'The Verve',
        track_length: '5:57',
        release_year: '1997',
        genre: 'Pop',
        album: 'Urban Hymns',
        artist: 'The Verve',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/0e/3b/4a/0e3b4a89-3f5f-b2f4-f99b-0d51e1568ade/mzaf_17530686600831005668.plus.aac.p.m4a',
        offset: '0',
    },
    '703': {
        content: '',
        submitted_by: '',
        answers: ["Sweet Caroline"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/sweet-caroline/1422898547?i=1422898880&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1422898880&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Neil Diamond',
        track_length: '3:23',
        release_year: '1969',
        genre: 'Pop',
        album: 'Brother Love\'s Travelling Salvation Show',
        artist: 'Neil Diamond',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/ec/de/1e/ecde1e3d-0547-d7cd-e882-6d5c1f32a629/mzaf_10178406616306671754.plus.aac.p.m4a',
        offset: '0',
    },
    '704': {
        content: '',
        submitted_by: '',
        answers: ["My Hero"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/my-hero/362133451?i=362133488&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=362133488&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Foo Fighters',
        track_length: '4:20',
        release_year: '1997',
        genre: 'Rock',
        album: 'The Colour And The Shape',
        artist: 'Foo Fighters',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/ee/63/6c/ee636cb7-97ae-913e-fa53-7b24a39509db/mzaf_9214650052645213035.plus.aac.p.m4a',
        offset: '0',
    },
    '705': {
        content: '',
        submitted_by: '',
        answers: ["No Sleep Till Brooklyn"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/no-sleep-till-brooklyn/1440912031?i=1440912354&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440912354&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Beastie Boys',
        track_length: '4:06',
        release_year: '1986',
        genre: 'Hip-Hop/Rap',
        album: 'Licensed to Ill',
        artist: 'Beastie Boys',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/94/9e/27/949e27e2-cad4-c6c7-a133-967353cec07f/mzaf_3264922967896254073.plus.aac.p.m4a',
        offset: '0',
    },
    '706': {
        content: '',
        submitted_by: '',
        answers: ["All Star"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/all-star/1440915299?i=1440915693&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440915693&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Smash Mouth',
        track_length: '3:20',
        release_year: '1999',
        genre: 'Alternative',
        album: 'Astro Lounge',
        artist: 'Smash Mouth',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/4c/27/53/4c275370-e4d5-11fd-4a6c-36d5118e12da/mzaf_17523360478135608663.plus.aac.p.m4a',
        offset: '0',
    },
    '707': {
        content: '',
        submitted_by: '',
        answers: ["Carry On Wayward Son"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/carry-on-wayward-son/190655957?i=190655992&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=190655992&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Kansas',
        track_length: '5:23',
        release_year: '1976',
        genre: 'Rock',
        album: 'Leftoverture',
        artist: 'Kansas',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/a9/53/1f/a9531f42-9e61-e9e7-f4aa-9aa8f790df1a/mzaf_12422186337724231520.plus.aac.p.m4a',
        offset: '4',
    },
    '708': {
        content: '',
        submitted_by: '',
        answers: ["Fade Into Darkness"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/fade-into-darkness-vocal-radio-mix/1440797338?i=1440797552&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440797552&amp;theme=auto\" id=\"embedPlayer\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\" style=\"border: 0px; border-radius: 12px; width: 100%; height: 175px; max-width: 660px;\"></iframe>"],
        franchise: 'Avicii',
        track_length: '3:18',
        release_year: '2011',
        genre: 'Electronic',
        album: 'Fade Into Darkness',
        artist: 'Avicii',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/a6/d3/53/a6d3531d-dc88-3365-2553-40d78540c95a/mzaf_6516518640589441337.plus.aac.p.m4a',
        offset: '0',
    },
    '709': {
        content: '',
        submitted_by: '',
        answers: ["Smooth Criminal"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/smooth-criminal/559334659?i=559334764&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=559334764&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Michael Jackson',
        track_length: '4:17',
        release_year: '1987',
        genre: 'Pop',
        album: 'Bad',
        artist: 'Michael Jackson',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/26/f1/b3/26f1b37e-eace-d523-6840-ccb86be93b39/mzaf_15405179866811235280.plus.aac.p.m4a',
        offset: '0',
    },
    '710': {
        content: '',
        submitted_by: '',
        answers: ["Dream On"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/dream-on/1658644936?i=1658644941&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1658644941&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Aerosmith',
        track_length: '4:27',
        release_year: '1973',
        genre: 'Hard Rock',
        album: 'Aerosmith',
        artist: 'Aerosmith',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/21/a8/d2/21a8d2e0-bbf0-2438-d379-081bb2d7bb8c/mzaf_3553851117838477367.plus.aac.p.m4a',
        offset: '0',
    },
    '711': {
        content: '',
        submitted_by: '',
        answers: ["I Kissed a Girl"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/i-kissed-a-girl/715891425?i=715891620&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=715891620&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Katy Perry',
        track_length: '2:59',
        release_year: '2008',
        genre: 'Pop',
        album: 'One of the Boys',
        artist: 'Katy Perry',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/9d/43/13/9d4313c9-c7fe-1a71-3f39-a57195eb1b00/mzaf_11611368505693752240.plus.aac.p.m4a',
        offset: '0',
    },
    '712': {
        content: '',
        submitted_by: '',
        answers: ["Scary Monsters and Nice Sprites"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/scary-monsters-and-nice-sprites/409001929?i=409001935&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=409001935&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Skrillex',
        track_length: '4:03',
        release_year: '2010',
        genre: 'Dance',
        album: 'Scary Monsters and Nice Sprites',
        artist: 'Skrillex',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/6a/f5/f2/6af5f2fd-4fe3-6981-8b01-3bb3a7417027/mzaf_2676481089062971648.plus.aac.p.m4a',
        offset: '0',
    },
    '713': {
        content: '',
        submitted_by: '',
        answers: ["The Man"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/the-man/1442651784?i=1442652100&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1442652100&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Aloe Blacc',
        track_length: '4:15',
        release_year: '2013',
        genre: 'Pop',
        album: 'Lift Your Spirit',
        artist: 'Aloe Blacc',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview221/v4/98/a4/cc/98a4ccba-a1ba-13f7-860a-5425f19a6057/mzaf_8303405547101415120.plus.aac.p.m4a',
        offset: '0',
    },
    '714': {
        content: '',
        submitted_by: '',
        answers: ["No Hands"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/no-hands-feat-roscoe-dash-and-wale/393797951?i=393797956&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=393797956&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Waka Flocka Flame',
        track_length: '4:23',
        release_year: '2010',
        genre: 'Hip-Hop/Rap',
        album: 'Flockaveli',
        artist: 'Waka Flocka Flame feat. Roscoe Dash and Wale',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/b4/8a/4a/b48a4ac6-4c26-4a82-8db7-d840ca4bfe10/mzaf_18159115369488921311.plus.aac.p.m4a',
        offset: '0',
    },
    '715': {
        content: '',
        submitted_by: '',
        answers: ["Under Pressure"],
        affiliate_links: ["<iframe height=\"175\" width=\"100%\" title=\"Media player\" src=\"https://embed.music.apple.com/us/album/under-pressure/1440805941?i=1440806739&amp;itscg=30200&amp;itsct=music_box_player&amp;ls=1&amp;app=music&amp;mttnsubad=1440806739&amp;theme=auto\" id=\"embedPlayer\" style=\"border:0;border-radius:12px;width:100%;height:175px;max-width:660px\" sandbox=\"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation\" allow=\"autoplay *; encrypted-media *; clipboard-write\"></iframe>"],
        franchise: 'Queen',
        track_length: '4:06',
        release_year: '1981',
        genre: 'Rock',
        album: 'Hot Space',
        artist: 'Queen & David Bowie',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/74/42/5f/74425f2e-4c63-0514-951a-891ad9753097/mzaf_3082293095615590695.plus.aac.p.m4a',
        offset: '0',
    },
    '716': {
        content: '',
        submitted_by: '',
        answers: ["Dynamite"],
        affiliate_links: [],
        franchise: 'BTS',
        track_length: '3:19',
        release_year: '2020',
        genre: 'K-Pop',
        album: 'BE',
        artist: 'BTS',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview126/v4/3b/f2/5c/3bf25cc9-a395-6858-1ef8-5c29956afaf6/mzaf_6007556042949037280.plus.aac.p.m4a',
        offset: '0',
    },
    '717': {
        content: '',
        submitted_by: '',
        answers: ["Last Resort"],
        affiliate_links: [],
        franchise: 'Papa Roach',
        track_length: '3:20',
        release_year: '2000',
        genre: 'Rock',
        album: 'Infest',
        artist: 'Papa Roach',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/a5/da/ba/a5daba4d-5e57-3cc0-35b9-160a4e8e2b47/mzaf_10705555032195750568.plus.aac.p.m4a',
        offset: '0',
    },
    '718': {
        content: '',
        submitted_by: '',
        answers: ["Sandstorm"],
        affiliate_links: [],
        franchise: 'Darude',
        track_length: '6:40',
        release_year: '2001',
        genre: 'Dance',
        album: 'Before the Storm',
        artist: 'Darude',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/20/32/6f/20326ff3-49e4-a9d3-d1f0-8f231731479d/mzaf_10863530844880662698.plus.aac.p.m4a',
        offset: '0',
    },
    '719': {
        content: '',
        submitted_by: '',
        answers: ["Power"],
        affiliate_links: [],
        franchise: 'Kanye West',
        track_length: '4:52',
        release_year: '2010',
        genre: 'Hip-Hop/Rap',
        album: 'My Beautiful Dark Twisted Fantasy',
        artist: 'Kanye West',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview125/v4/1e/bc/2a/1ebc2ac8-e91c-9b9c-8f23-ba8dde8da35b/mzaf_14277297399106375720.plus.aac.p.m4a',
        offset: '0',
    },
    '720': {
        content: '',
        submitted_by: '',
        answers: ["Super Freak"],
        affiliate_links: [],
        franchise: 'Rick James',
        track_length: '3:24',
        release_year: '1981',
        genre: 'R&B/Soul',
        album: 'Street Songs',
        artist: 'Rick James',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/33/d2/b0/33d2b0c0-0567-1b0f-ea91-70855ef91488/mzaf_5815523409365441502.plus.aac.p.m4a',
        offset: '0',
    },
    '721': {
        content: '',
        submitted_by: '',
        answers: ["Oops!\u2026 I Did It Again"],
        affiliate_links: [],
        franchise: 'Britney Spears',
        track_length: '3:31',
        release_year: '2000',
        genre: 'Pop',
        album: 'Oops!... I Did It Again',
        artist: 'Britney Spears',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview211/v4/49/ee/f6/49eef6b2-7c05-1a4c-b9eb-5fd4bc95298e/mzaf_1580555973792495451.plus.aac.p.m4a',
        offset: '0',
    },
    '722': {
        content: '',
        submitted_by: '',
        answers: ["Diva"],
        affiliate_links: [],
        franchise: 'Beyoncé',
        track_length: '3:20',
        release_year: '2008',
        genre: 'Pop',
        album: 'I Am... Sasha Fierce',
        artist: 'Beyoncé',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview115/v4/e7/10/15/e7101504-a0af-e2f3-e00b-0e24b9015f74/mzaf_16711774196536112549.plus.aac.p.m4a',
        offset: '0',
    },
    '723': {
        content: '',
        submitted_by: '',
        answers: ["This Is What You Came For"],
        affiliate_links: [],
        franchise: 'Calvin Harris & Rihanna',
        track_length: '3:41',
        release_year: '2016',
        genre: 'Dance',
        album: 'This Is What You Came For',
        artist: 'Calvin Harris & Rihanna',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview116/v4/e6/4c/8a/e64c8a6b-49e1-6ead-4f22-7d81dea2768e/mzaf_1927716231528220099.plus.aac.p.m4a',
        offset: '0',
    },
    '724': {
        content: '',
        submitted_by: '',
        answers: ["Misery"],
        affiliate_links: [],
        franchise: 'Maroon 5',
        track_length: '3:36',
        release_year: '2010',
        genre: 'Pop',
        album: 'Hands All Over',
        artist: 'Maroon 5',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview122/v4/82/be/05/82be05a0-cc4e-0a1e-4947-fd3bab171328/mzaf_4279977169622682854.plus.aac.p.m4a',
        offset: '0',
    },
    '725': {
        content: '',
        submitted_by: '',
        answers: ["Hot Stuff"],
        affiliate_links: [],
        franchise: 'Donna Summer',
        track_length: '5:14',
        release_year: '1979',
        genre: 'Disco',
        album: 'Bad Girls',
        artist: 'Donna Summer',
        preview_url: 'https://audio-ssl.itunes.apple.com/itunes-assets/AudioPreview112/v4/7c/71/05/7c7105af-7701-bf11-5ff9-fe7e74390cdf/mzaf_3620462538256381572.plus.aac.p.m4a',
        offset: '0',
    },
};
