// @flow
import { useDispatch } from 'react-redux';

import React from 'react';
import type { TDispatch } from '../../store/store';
import { useSettings } from '../../settings/useSettings';

const RedirectModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();

    const website_url = `https://${settings.website_url}/`

    return (
        <div className="modal redirect-modal">
            <p>
                Continue playing at&nbsp;
                <a href={website_url} target="_top" >
                    {settings.website_title}™
                </a>
            </p>
            <a href={website_url} target="_top" style={{ textDecoration: 'none' }} >
                <button type="button" className="mainButton redirectButton" >
                    Bring me there!
                </button>
            </a>
        </div>
    );
};

export default RedirectModal;
