// @flow

import type { GameSettings } from './settings/game_settings';

import { CORRECT_EMOJI, INCORRECT_EMOJI, PARTIAL_EMOJI, REMAINING_EMOJI } from './constants';
import { DEV_MODE } from './globals';

// import { useNavigate } from 'react-router-dom';

function isIOS(): boolean {
    // also tests if it is safari
    let platform = navigator?.userAgent || navigator?.platform || 'unknown';
    const isSafari = !!window.GestureEvent;

    return /iPhone|iPod|Mac|iPad/.test(platform) || isSafari;
}

export const assembleClipboard = (
    numberOfGuesses: number,
    didTheyWin: boolean,
    franchiseMatch: Array<boolean>,
    maxGuesses: number,
    franchiseToggle: boolean,
): Array<string> => {
    // number of guesses is the number of guesses the user made.
    // didTheyWin is a boolean that is true if the user won, false if they lost. It is used to determine the color of the last emoji.

    // Create an array with the appropriate emojis for each guess
    return Array.from({ length: maxGuesses }, (_, i) =>
        i < numberOfGuesses
            ? i === numberOfGuesses - 1 && didTheyWin
                ? CORRECT_EMOJI
                : franchiseToggle
                ? franchiseMatch[i]
                    ? PARTIAL_EMOJI
                    : INCORRECT_EMOJI
                : INCORRECT_EMOJI
            : REMAINING_EMOJI,
    );
};

export function copyToClipboard(text: string) {
    try {
        navigator.clipboard.writeText(text).then(
            () => {
                if (isIOS() && navigator.share) {
                    navigator
                        .share({
                            text: text,
                        })
                        .then(() => {})
                        .catch((err) => {
                            switch (err.name) {
                                case 'AbortError': {
                                    break;
                                }
                                default: {
                                    console.log('share failed: ', err);
                                }
                            }
                        });
                }
            },
            () => {
                /* clipboard write failed */
                console.log('clipboard write failed, try it the old way');
                const textarea = document.createElement('textarea');
                textarea.value = text;
                // $FlowIgnore document.body does exist
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                // $FlowIgnore document.body does exist
                document.body.removeChild(textarea);
            },
        );
    } catch (err) {
        console.log('clipboard write failed, try it the old way: ', err);
        const textarea = document.createElement('textarea');
        textarea.value = text;
        // $FlowIgnore document.body does exist
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        // $FlowIgnore document.body does exist
        document.body.removeChild(textarea);
    }
}

export const goToDay = (dayNumber: number, currentPage: string, settings: GameSettings, itemId?: number) => {
    console.log('gotoday', dayNumber, currentPage);
    // if devmode false
    if (!DEV_MODE) {
        // make sure the user cant go pass todays game
        if (dayNumber > settings.num_days_from_start_date() || dayNumber < 1) {
            return;
        }
    }
    let path;
    switch (currentPage) {
        case 'puzzle':
            path = settings.paths.puzzle.replace(':puzzleId', dayNumber);
            break;
        case 'original_images':
            path = settings.paths.original_images.replace(':puzzleId', dayNumber);
            break;
        case 'stats':
            path = settings.paths.stats.replace(':puzzleId', dayNumber);
            break;
        case 'guess_the_cover':
            path = settings.paths.guess_the_cover.replace(':puzzleId?', dayNumber.toString());
            break;
        case 'guess_the_quest':
            if (itemId === undefined) {
                console.error('itemId is required for guess_the_quest');
                return;
            }
            path = settings.paths.guess_the_quest
                .replace(':puzzleId', dayNumber.toString())
                .replace(':itemId', itemId.toString());
            break;
        default:
            console.log('unknown page');
            return;
    }
    console.log('path', path);
    window.location.assign(path);
};

export const checkIfEmbeddedExternally = () => {
    // This should work fine for typical iFrames.
    // How about browser extensions that embed the site?
    const isEmbedded = window.self !== window.top;
    return isEmbedded
}

