// @flow
import { KOFI_URL, SEARCH_ITEM_SUBMISSION_URL } from '../../constants';
import { setModal } from '../../store/slices/uxSlice';

import { useDispatch } from 'react-redux';

import React from 'react';
import type { TDispatch } from '../../store/store';
import { useSettings } from '../../settings/useSettings';
import CloseModalButton from './CloseModalButton';

const LoveModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();

    return (
        <div className="modal love-modal">
            <div className="love-modal-inner">
                <CloseModalButton />

                <h3>Support Us</h3>

                <p>
                    I hope you enjoy this daily puzzle game! If it has brightened your day, the best way you can support
                    us is to share the site with a friend, post your daily score on reddit, discord or your favorite
                    website, or share {settings.website_title} with your favorite news outlet!
                </p>
                <>
                    <h4>Support Us on Ko-fi</h4>

                    <p className="login">
                        If you would like to support {settings.website_title} development, you can make a donation or
                        become a monthly member at{' '}
                        <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                            <span style={{ whiteSpace: 'nowrap' }}>Ko-Fi</span>
                        </a>
                        <br />
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <a tabIndex="-1" href={KOFI_URL} target="_blank" rel="noreferrer noopener">
                                <img src={'/img/kofi_s_tag_dark.png'} alt="Ko-Fi" style={{ height: '50px' }} />
                            </a>
                        </div>
                    </p>
                </>
                <>
                    <p>
                        Did you try searching for a {settings.guess_item.toLowerCase()} only to be disappointed that you
                        couldn't find it?{' '}
                        <a href={SEARCH_ITEM_SUBMISSION_URL} target="_blank" rel="noreferrer noopener">
                            Submit a {settings.guess_item.toLowerCase()} to our database
                        </a>
                    </p>
                </>
            </div>
        </div>
    );
};

export default LoveModal;
