// @flow
import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { TGTGQAnswer } from '../puzzles/gtgq_puzzles';
import type { AnswerUnion, GameSettings } from './game_settings';

import { PUZZLE_TYPES } from '../constants';
import { answers } from '../puzzles/gtgc_puzzles';
import { GTGSettings } from './gtg_settings';

export class GTGQSettings extends GTGSettings {
    title: string = 'Guess The Game Quest';

    clipboard_tag: string = '#GuessTheGameQuest';

    puzzle_type: PUZZLE_TYPES_TYPE = PUZZLE_TYPES.GTGQ;
    isQuestType: boolean = true;
    root_url: string = '/' + this.puzzle_type;
    storage_keys: { guess: string, game_state: string } = {
        // eslint-disable-next-line no-useless-concat
        guess: this.puzzle_type + '_guess' + 'V1', // TODO VERSIONING
        // eslint-disable-next-line no-useless-concat
        game_state: this.puzzle_type + '_gamestate' + 'V1', // TODO VERSIONING
    };
    answers: AnswerUnion = answers;
    max_guesses: number = 300;
    // todo game url
    game_url(puzzleId: string, itemId: string): string {
        return `https://${this.website_url}${GTGQSettings.PATHS.guess_the_quest
            .replace(':puzzleId', puzzleId)
            .replace(':itemId', itemId)}`;
    }
    num_days_from_start_date(): number {
        const today = new Date();
        // $FlowIgnore
        const diffTime = Math.abs(today - this.puzzle_start_date);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    get_hint_text(imageIndex: number, puzzle: TGTGQAnswer, settings: GameSettings): string {
        return `${puzzle.hint}`;
    }

    progress_ranks: {} = {};

    puzzle_url(): string {
        return GTGQSettings.PATHS.guess_the_quest;
    }

    TEXTS: any = {
        modal: {
            AboutModal: {
                modalTexts: [''],
            },
            HowToPlayModal: {
                modalTexts: [
                    'You are presented with screenshot, video or audio clip from a video game revolving around a theme. Try to guess what game it is!',
                    'Gather some friends and create a party to solve this quest! Your goal is to get the best score possible!',
                    'You have unlimited guesses and there is no penalty for guessing wrong.',
                ],
            },
        },
    };
}
